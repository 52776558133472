import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IOrganisation_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IOrganisation_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  KurumSelectById: any[];
  KurumSelectById_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  KurumTipiAll: any[];
  KurumTipiAll_dummy: any[];
  KurumAll: any[];
  KurumAll_dummy: any[];
  SelectKurumGyms: any[];
  SelectKurumGyms_dummy: any[];
  SelectOkulBransAll: any[];
  SelectOkulBransAll_dummy: any[];
  PhotoTake: string;
  PhotoTake_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  NewId: any[];
  NewId_dummy: any[];
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  ManageIsOrtagi: number;
  ManageIsOrtagi_dummy: number;
  isComp367895Visible: "visible" | "hidden";
}

export class Organisation_ScreenBase extends React.PureComponent<IOrganisation_ScreenProps, any> {
  organisation_152481_value_kuikaSelectBoxRef: React.RefObject<any>;
  organisation_41200_value_kuikaSelectBoxRef: React.RefObject<any>;
  organisation_730191_value_kuikaMultiSelectRef: React.RefObject<any>;
  organisation_402365_value_kuikaDateRef: React.RefObject<any>;
  organisation_806357_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "c143b48a-ab86-4c2a-b7b3-f01b24b758e8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kurum Tanımı" },
        { Id: 770829, PropertyName: "value", Value: "Kurum Tipi" },
        { Id: 152481, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 8269673, PropertyName: "value", Value: "Takvim Kodu" },
        { Id: 79007, PropertyName: "value", Value: "Ünvanı" },
        { Id: 2535732, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 6844602, PropertyName: "value", Value: "Web Adresi" },
        { Id: 627815, PropertyName: "value", Value: "Kurum e-posta" },
        { Id: 899775, PropertyName: "value", Value: "İş Ortağı Kurum" },
        { Id: 41200, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 537833, PropertyName: "value", Value: "Açık Adres" },
        { Id: 183322, PropertyName: "value", Value: "Verebileceği Hizmetler" },
        { Id: 730191, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 899523, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 402365, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 98929, PropertyName: "value", Value: "Puan" },
        { Id: 194708, PropertyName: "value", Value: "Platformda Gösterilsin" },
        { Id: 195335, PropertyName: "value", Value: "Kulüp Branşı" },
        { Id: 806357, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.organisation_152481_value_kuikaSelectBoxRef = React.createRef();
    this.organisation_41200_value_kuikaSelectBoxRef = React.createRef();
    this.organisation_730191_value_kuikaMultiSelectRef = React.createRef();
    this.organisation_402365_value_kuikaDateRef = React.createRef();
    this.organisation_806357_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      KurumSelectById: [],
      ServiceAll: [],
      KurumTipiAll: [],
      KurumAll: [],
      SelectKurumGyms: [],
      SelectOkulBransAll: [],
      PhotoTake: "",
      SetValueOf: "",
      PhotoFromGallery: "",
      SaveRecord: false,
      IfThenElse: false,
      ManageIsOrtagi: 0,
      isComp367895Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("organisation", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.OrganisationPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("organisation", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("organisation", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.OrganisationPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      organisation_3804750_value: this.state.KurumSelectById?.at?.(0)?.logo ?? undefined,
      organisation_152481_value: this.state.KurumSelectById?.at?.(0)?.kurumTipiId ?? undefined,
      organisation_811979_value: this.state.KurumSelectById?.at?.(0)?.kod ?? undefined,
      organisation_4899477_value: this.state.KurumSelectById?.at?.(0)?.unvani ?? undefined,
      organisation_5528101_value: this.state.KurumSelectById?.at?.(0)?.phoneNumber ?? undefined,
      organisation_9391623_value: this.state.KurumSelectById?.at?.(0)?.webAdresi ?? undefined,
      organisation_300195_value: this.state.KurumSelectById?.at?.(0)?.kurumEposta ?? undefined,
      organisation_41200_value: this.state.KurumSelectById?.at?.(0)?.isOrtagiKurumId ?? undefined,
      organisation_905608_value: this.state.KurumSelectById?.at?.(0)?.acikAdres ?? undefined,
      organisation_730191_value: this.state.KurumSelectById?.at?.(0)?.verebilecegiHizmetler ?? undefined,
      organisation_402365_value: this.state.KurumSelectById?.at?.(0)?.licenceExpiry ?? undefined,
      organisation_241634_value: this.state.KurumSelectById?.at?.(0)?.rate ?? undefined,
      organisation_112163_value: this.state.KurumSelectById?.at?.(0)?.platformdaGosterilsinMi ?? undefined,
      organisation_806357_value: this.state.KurumSelectById?.at?.(0)?.brans ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  OrganisationPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Organisation/OrganisationPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KurumSelectById = result?.data.kurumSelectById;
    formVars.organisation_3804750_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );
    formVars.organisation_152481_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumTipiId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumTipiId
        : null;
    formVars.organisation_152481_options = stateVars.KurumTipiAll;
    formVars.organisation_811979_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kod
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kod
        : null
    );
    formVars.organisation_4899477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );
    formVars.organisation_5528101_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].phoneNumber
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].phoneNumber
        : null
    );
    formVars.organisation_9391623_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].webAdresi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].webAdresi
        : null
    );
    formVars.organisation_300195_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumEposta
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumEposta
        : null
    );
    formVars.organisation_41200_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].isOrtagiKurumId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].isOrtagiKurumId
        : null;
    formVars.organisation_41200_options = stateVars.SelectKurumGyms;
    formVars.organisation_905608_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].acikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].acikAdres
        : null
    );
    formVars.organisation_730191_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].verebilecegiHizmetler
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].verebilecegiHizmetler
        : null;
    formVars.organisation_730191_options = stateVars.ServiceAll;
    formVars.organisation_402365_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].licenceExpiry
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].licenceExpiry
        : null
    );
    formVars.organisation_112163_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].platformdaGosterilsinMi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].platformdaGosterilsinMi
        : null
    );
    formVars.organisation_806357_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].brans
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].brans
        : null;
    formVars.organisation_806357_options = stateVars.SelectOkulBransAll;
    stateVars.ServiceAll = result?.data.serviceAll;

    formVars.organisation_730191_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].verebilecegiHizmetler
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].verebilecegiHizmetler
        : null;
    formVars.organisation_730191_options = stateVars.ServiceAll;
    stateVars.KurumTipiAll = result?.data.kurumTipiAll;

    formVars.organisation_152481_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumTipiId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumTipiId
        : null;
    formVars.organisation_152481_options = stateVars.KurumTipiAll;
    stateVars.KurumAll = result?.data.kurumAll;
    stateVars.SelectKurumGyms = result?.data.selectKurumGyms;

    formVars.organisation_41200_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].isOrtagiKurumId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].isOrtagiKurumId
        : null;
    formVars.organisation_41200_options = stateVars.SelectKurumGyms;
    stateVars.SelectOkulBransAll = result?.data.selectOkulBransAll;

    formVars.organisation_806357_value =
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].brans
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].brans
        : null;
    formVars.organisation_806357_options = stateVars.SelectOkulBransAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OrganisationPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrganisationPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.organisation_3804750_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].logo
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].logo
        : null
    );

    formVars.organisation_152481_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumTipiId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumTipiId
        : null
    );

    stateVars.dataSource_152481 = this.state.KurumTipiAll;
    stateVars.dataSource_152481 = this.state.KurumTipiAll;
    formVars.organisation_811979_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kod
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kod
        : null
    );

    formVars.organisation_4899477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].unvani
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].unvani
        : null
    );

    formVars.organisation_5528101_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].phoneNumber
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].phoneNumber
        : null
    );

    formVars.organisation_9391623_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].webAdresi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].webAdresi
        : null
    );

    formVars.organisation_300195_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].kurumEposta
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].kurumEposta
        : null
    );

    formVars.organisation_41200_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].isOrtagiKurumId
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].isOrtagiKurumId
        : null
    );

    stateVars.dataSource_41200 = this.state.SelectKurumGyms;
    stateVars.dataSource_41200 = this.state.SelectKurumGyms;
    formVars.organisation_905608_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].acikAdres
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].acikAdres
        : null
    );

    formVars.organisation_730191_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].verebilecegiHizmetler
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].verebilecegiHizmetler
        : null
    );

    stateVars.dataSource_730191 = this.state.ServiceAll;
    stateVars.dataSource_730191 = this.state.ServiceAll;
    formVars.organisation_402365_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].licenceExpiry
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].licenceExpiry
        : null
    );

    formVars.organisation_241634_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].rate
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].rate
        : null
    );

    formVars.organisation_112163_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].platformdaGosterilsinMi
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].platformdaGosterilsinMi
        : null
    );

    stateVars.isComp367895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_152481_value", "value", "KurumTipiAll", "id", "kod")
        ),
        "KT_Club"
      ) === true
        ? "visible"
        : "hidden";
    formVars.organisation_806357_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumSelectById?.length > 0
        ? stateVars.KurumSelectById[0].brans
        : this.state.KurumSelectById?.length > 0
        ? this.state.KurumSelectById[0].brans
        : null
    );

    stateVars.dataSource_806357 = this.state.SelectOkulBransAll;
    stateVars.dataSource_806357 = this.state.SelectOkulBransAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  OrganisationComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationComponent_8006524_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoTake = await ReactSystemFunctions.takePhotoKuikaFunction(this, false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "organisation_3804750_value",
      ReactSystemFunctions.value(this, stateVars.PhotoTake),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationComponent_5559147_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "organisation_3804750_value",
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationComponent_9413089_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "organisation_3804750_value", "", null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationComponent_152481_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.isComp367895Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_152481_value", "value", "KurumTipiAll", "id", "kod")
        ),
        "KT_Club"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  OrganisationComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_4899477_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "organisation_4899477_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_152481_value", "value", "KurumTipiAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "organisation_152481_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_402365_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "organisation_402365_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "organisation_152481_value",
            "value",
            "KurumTipiAll",
            "id",
            "expirationTakipEdilsin"
          )
        ),
        true
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_300195_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "organisation_300195_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      Logo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_3804750_value", "value", "", "", "")
        ),
        "string"
      ),
      PhoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_5528101_value", "value", "", "", "")
        ),
        "string"
      ),
      Unvani_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_4899477_value", "value", "", "", "")
        ),
        "string"
      ),
      WebAdresi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_9391623_value", "value", "", "", "")
        ),
        "string"
      ),
      AcikAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_905608_value", "value", "", "", "")
        ),
        "string"
      ),
      VerebilecegiHizmetler_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_730191_value", "value", "ServiceAll", "id", "id")
        ),
        "string"
      ),
      KurumTipiId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_152481_value", "value", "KurumTipiAll", "id", "id")
        ),
        "Guid"
      ),
      PlatformdaGosterilsinMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_112163_value", "value", "", "", "")
        ),
        "boolean"
      ),
      LicenceExpiry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_402365_value", "value", "", "", "")
        ),
        "Date"
      ),
      Rate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_241634_value", "value", "", "", "")
        ),
        "number"
      ),
      Kod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_811979_value", "value", "", "", "")
        ),
        "string"
      ),
      KurumEposta_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "organisation_300195_value", "value", "", "", "")
        ),
        "string"
      ),
      IsOrtagiKurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_41200_value", "value", "SelectKurumGyms", "id", "id")
        ),
        "Guid"
      ),
      Brans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "organisation_806357_value", "value", "SelectOkulBransAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Organisation/OrganisationComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OrganisationComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrganisationComponent_9544_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      existingId_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      Id_0_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Organisation/OrganisationComponent_9544_onClick_if1",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NewId = result?.data.newId;
    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  OrganisationComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KurumSelectById?.length > 0
            ? stateVars.KurumSelectById[0].hesapId
            : this.state.KurumSelectById?.length > 0
            ? this.state.KurumSelectById[0].hesapId
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.OrganisationComponent_9544_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OrganisationComponent_9544_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  OrganisationComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Organisation/OrganisationComponent_9544_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ManageIsOrtagi = result?.data.manageIsOrtagi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.OrganisationComponent_9544_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  OrganisationComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
