import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IServiceF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IServiceF_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  ProtokolAdmin: any[];
  ProtokolAdmin_dummy: any[];
  ServiceSelectById: any[];
  ServiceSelectById_dummy: any[];
  PhotoFromGallery: string;
  PhotoFromGallery_dummy: string;
  SetValueOf: any;
  SetValueOf_dummy: any;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp342423Visible: "visible" | "hidden";
  isComp967695Enabled: "enabled" | "disabled";
  isComp320142Enabled: "enabled" | "disabled";
}

export class ServiceF_ScreenBase extends React.PureComponent<IServiceF_ScreenProps, any> {
  servicef_414835_value_kuikaSelectBoxRef: React.RefObject<any>;
  servicef_764981_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9b27e1c3-ac4e-4b75-9d35-2683c129eb72",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Hizmet Tanımı" },
        { Id: 8112592, PropertyName: "value", Value: "Hizmet Resmi" },
        { Id: 825227, PropertyName: "value", Value: "Hizmet Tanımı" },
        { Id: 7729896, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 87049, PropertyName: "value", Value: "Hizmet Tanımı (EN)" },
        { Id: 725259, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 98907, PropertyName: "value", Value: "Hizmet Açıklama" },
        { Id: 314522, PropertyName: "value", Value: "Hizmet Tipi" },
        { Id: 414835, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 295249, PropertyName: "value", Value: "Protokol" },
        { Id: 764981, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 331521, PropertyName: "value", Value: "Süresi" },
        { Id: 784190, PropertyName: "placeholder", Value: "15" },
        { Id: 220897, PropertyName: "value", Value: "Kredisi" },
        { Id: 103122, PropertyName: "placeholder", Value: "15" },
        { Id: 503541, PropertyName: "value", Value: "Geçerli Yaş Aralığı" },
        { Id: 967695, PropertyName: "placeholder", Value: "En Küçük" },
        { Id: 320142, PropertyName: "placeholder", Value: "En Büyük" },
        { Id: 268379, PropertyName: "value", Value: "Renk" },
        { Id: 469252, PropertyName: "placeholder", Value: "#DDDDDD" },
        { Id: 941162, PropertyName: "value", Value: "Randevuda Görünür" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.servicef_414835_value_kuikaSelectBoxRef = React.createRef();
    this.servicef_764981_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ProtokolAdmin: [],
      ServiceSelectById: [],
      PhotoFromGallery: "",
      SetValueOf: "",
      ChangeEnabledOf: "",
      ChangeVisibilityOf: "",
      SaveRecord: false,
      dataSource_414835: [
        { key: "Protokollu", text: "Protokollü" },
        { key: "Protokolsuz", text: "Protokolsüz" }
      ],
      isComp342423Visible: "hidden",
      isComp967695Enabled: "disabled",
      isComp320142Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("servicef", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.ServiceFPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("servicef", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("servicef", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ServiceFPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      servicef_256235_value: this.state.ServiceSelectById?.at?.(0)?.image ?? undefined,
      servicef_7729896_value: this.state.ServiceSelectById?.at?.(0)?.title ?? undefined,
      servicef_725259_value: this.state.ServiceSelectById?.at?.(0)?.titleEN ?? undefined,
      servicef_595087_value: this.state.ServiceSelectById?.at?.(0)?.aciklama ?? undefined,
      servicef_414835_value: this.state.ServiceSelectById?.at?.(0)?.serviceType ?? undefined,
      servicef_764981_value: this.state.ServiceSelectById?.at?.(0)?.protokolId ?? undefined,
      servicef_784190_value: this.state.ServiceSelectById?.at?.(0)?.sure ?? undefined,
      servicef_103122_value: this.state.ServiceSelectById?.at?.(0)?.kredi ?? undefined,
      servicef_967695_value: this.state.ServiceSelectById?.at?.(0)?.gecerliEnKucukYas ?? undefined,
      servicef_320142_value: this.state.ServiceSelectById?.at?.(0)?.gecerliEnBuyukYas ?? undefined,
      servicef_469252_value: this.state.ServiceSelectById?.at?.(0)?.renk ?? undefined,
      servicef_274409_value: this.state.ServiceSelectById?.at?.(0)?.randevuGorunur ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ServiceFPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ServiceF/ServiceFPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ProtokolAdmin = result?.data.protokolAdmin;

    formVars.servicef_764981_value =
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].protokolId
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].protokolId
        : null;
    formVars.servicef_764981_options = stateVars.ProtokolAdmin;
    stateVars.ServiceSelectById = result?.data.serviceSelectById;
    formVars.servicef_256235_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].image
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].image
        : null
    );
    formVars.servicef_7729896_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].title
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].title
        : null
    );
    formVars.servicef_725259_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].titleEN
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].titleEN
        : null
    );
    formVars.servicef_595087_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].aciklama
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].aciklama
        : null
    );
    formVars.servicef_414835_value =
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].serviceType
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].serviceType
        : null;
    formVars.servicef_764981_value =
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].protokolId
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].protokolId
        : null;
    formVars.servicef_764981_options = stateVars.ProtokolAdmin;
    formVars.servicef_784190_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].sure
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].sure
        : null
    );
    formVars.servicef_103122_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].kredi
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].kredi
        : null
    );
    formVars.servicef_967695_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].gecerliEnKucukYas
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].gecerliEnKucukYas
        : null
    );
    formVars.servicef_320142_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].gecerliEnBuyukYas
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].gecerliEnBuyukYas
        : null
    );
    formVars.servicef_469252_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].renk
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].renk
        : null
    );
    formVars.servicef_274409_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].randevuGorunur
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].randevuGorunur
        : null
    );
    stateVars.isComp342423Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp967695Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp320142Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ServiceFPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ServiceFPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.servicef_256235_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].image
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].image
        : null
    );

    formVars.servicef_7729896_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].title
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].title
        : null
    );

    formVars.servicef_725259_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].titleEN
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].titleEN
        : null
    );

    formVars.servicef_595087_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].aciklama
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].aciklama
        : null
    );

    formVars.servicef_414835_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].serviceType
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].serviceType
        : null
    );

    stateVars.isComp342423Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "visible"
        : "hidden";
    formVars.servicef_764981_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].protokolId
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].protokolId
        : null
    );

    stateVars.dataSource_764981 = this.state.ProtokolAdmin;
    stateVars.dataSource_764981 = this.state.ProtokolAdmin;
    formVars.servicef_784190_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].sure
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].sure
        : null
    );

    formVars.servicef_103122_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].kredi
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].kredi
        : null
    );

    formVars.servicef_967695_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].gecerliEnKucukYas
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].gecerliEnKucukYas
        : null
    );

    stateVars.isComp967695Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.servicef_320142_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].gecerliEnBuyukYas
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].gecerliEnBuyukYas
        : null
    );

    stateVars.isComp320142Enabled =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.ServiceSelectById?.length > 0
            ? stateVars.ServiceSelectById[0].serviceType
            : this.state.ServiceSelectById?.length > 0
            ? this.state.ServiceSelectById[0].serviceType
            : null
        ),
        "Protokollu"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.servicef_469252_value = ReactSystemFunctions.toString(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].renk
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].renk
        : null
    );

    formVars.servicef_274409_value = ReactSystemFunctions.value(
      this,
      stateVars.ServiceSelectById?.length > 0
        ? stateVars.ServiceSelectById[0].randevuGorunur
        : this.state.ServiceSelectById?.length > 0
        ? this.state.ServiceSelectById[0].randevuGorunur
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ServiceFComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ServiceFComponent_778610_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.PhotoFromGallery = await ReactSystemFunctions.getPhotoFromGallery(false, "");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "servicef_256235_value",
      ReactSystemFunctions.value(this, stateVars.PhotoFromGallery),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ServiceFComponent_414835_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp967695Enabled", "enabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp320142Enabled", "enabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ServiceFComponent_414835_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ServiceFComponent_414835_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "Protokollu"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp967695Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ServiceFComponent_414835_onChange2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "Protokollu"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp320142Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ServiceFComponent_414835_onChange3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp342423Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ServiceFComponent_414835_onChange4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ServiceFComponent_414835_onChange4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "Protokollu"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp342423Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ServiceFComponent_414835_onChange5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ServiceFComponent_414835_onChange5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "servicef_764981_value", null, null, "Id");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ServiceFComponent_764981_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_764981_value", "value", "ProtokolAdmin", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_764981_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "Protokollu"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "servicef_967695_value",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "servicef_764981_value", "value", "ProtokolAdmin", "id", "gecerliEnKucukYas")
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "servicef_320142_value",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "servicef_764981_value", "value", "ProtokolAdmin", "id", "gecerliEnBuyukYas")
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ServiceFComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_595087_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_595087_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_7729896_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_7729896_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_967695_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_967695_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_320142_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_320142_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_103122_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_103122_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_414835_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_764981_value", "value", "ProtokolAdmin", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "servicef_764981_value",
      condition: ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "Protokollu"
      )
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_595087_value", "value", "", "", "")
        ),
        "string"
      ),
      Title_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_7729896_value", "value", "", "", "")
        ),
        "string"
      ),
      TitleEN_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_725259_value", "value", "", "", "")
        ),
        "string"
      ),
      Sure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_784190_value", "value", "", "", "")
        ),
        "number"
      ),
      Image_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_256235_value", "value", "", "", "")
        ),
        "string"
      ),
      Renk_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_469252_value", "value", "", "", "")
        ),
        "string"
      ),
      GecerliEnKucukYas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_967695_value", "value", "", "", "")
        ),
        "number"
      ),
      GecerliEnBuyukYas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_320142_value", "value", "", "", "")
        ),
        "number"
      ),
      Kredi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_103122_value", "value", "", "", "")
        ),
        "number"
      ),
      ServiceType_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "servicef_414835_value", "value", "", "key", "")
        ),
        "string"
      ),
      ProtokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_764981_value", "value", "ProtokolAdmin", "id", "id")
        ),
        "Guid"
      ),
      RandevuGorunur_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "servicef_274409_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ServiceF/ServiceFComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ServiceFComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ServiceFComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
