import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboardBilesenDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboardBilesenDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  Kirilim2SelectById: any[];
  Kirilim2SelectById_dummy: any[];
  DashboardFitskorDetay: any[];
  DashboardFitskorDetay_dummy: any[];
  DashboardFitskorDetayHeader: any[];
  DashboardFitskorDetayHeader_dummy: any[];
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  isComp17011Visible: "visible" | "hidden";
  isComp598327Visible: "visible" | "hidden";
  isComp299648Visible: "visible" | "hidden";
  isComp700706Visible: "visible" | "hidden";
  isComp185910Visible: "visible" | "hidden";
  isComp153903Visible: "visible" | "hidden";
  isComp130239Visible: "visible" | "hidden";
  isComp606708Visible: "visible" | "hidden";
  isComp390657Visible: "visible" | "hidden";
  isComp228704Visible: "visible" | "hidden";
  isComp774594Visible: "visible" | "hidden";
  isComp67117Visible: "visible" | "hidden";
  isComp388437Visible: "visible" | "hidden";
}

export class DashboardBilesenDetay_ScreenBase extends React.PureComponent<IDashboardBilesenDetay_ScreenProps, any> {
  dashboardbilesendetay_613837_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 994532, PropertyName: "value", Value: "FitSkor® Components", TagName: "Label2_value" },
        { Id: 260145, PropertyName: "title", Value: "School Number", TagName: "TableTemplateColumn1_0_title" },
        { Id: 666862, PropertyName: "title", Value: "Student Name", TagName: "TableTemplateColumn16_title" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "31b861e7-5cf4-45d1-b009-acf70b72985f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 994532, PropertyName: "value", Value: "FİTNESS BİLEŞENLERİ" },
        { Id: 260145, PropertyName: "title", Value: "Okul No" },
        { Id: 62715, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 666862, PropertyName: "title", Value: "Öğrenci Adı" },
        { Id: 791120, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 17011, PropertyName: "title", Value: "Title" },
        { Id: 525837, PropertyName: "value", Value: "[datafield:bilesen1puan]" },
        { Id: 598327, PropertyName: "title", Value: "Title" },
        { Id: 27851, PropertyName: "value", Value: "[datafield:bilesen2puan]" },
        { Id: 299648, PropertyName: "title", Value: "Title" },
        { Id: 343877, PropertyName: "value", Value: "[datafield:bilesen3puan]" },
        { Id: 700706, PropertyName: "title", Value: "Title" },
        { Id: 75662, PropertyName: "value", Value: "[datafield:bilesen4puan]" },
        { Id: 185910, PropertyName: "title", Value: "Title" },
        { Id: 143793, PropertyName: "value", Value: "[datafield:bilesen5puan]" },
        { Id: 153903, PropertyName: "title", Value: "Title" },
        { Id: 702474, PropertyName: "value", Value: "[datafield:bilesen6puan]" },
        { Id: 130239, PropertyName: "title", Value: "Title" },
        { Id: 237569, PropertyName: "value", Value: "[datafield:bilesen7puan]" },
        { Id: 606708, PropertyName: "title", Value: "Title" },
        { Id: 481735, PropertyName: "value", Value: "[datafield:bilesen8puan]" },
        { Id: 390657, PropertyName: "title", Value: "Title" },
        { Id: 205778, PropertyName: "value", Value: "[datafield:bilesen9puan]" },
        { Id: 228704, PropertyName: "title", Value: "Title" },
        { Id: 222213, PropertyName: "value", Value: "[datafield:bilesen10puan]" },
        { Id: 774594, PropertyName: "title", Value: "Title" },
        { Id: 21285, PropertyName: "value", Value: "[datafield:bilesen11puan]" },
        { Id: 67117, PropertyName: "title", Value: "Title" },
        { Id: 26666, PropertyName: "value", Value: "[datafield:bilesen12puan]" },
        { Id: 388437, PropertyName: "title", Value: "Title" },
        { Id: 954461, PropertyName: "value", Value: "[datafield:bilesen13puan]" },
        { Id: 849708, PropertyName: "title", Value: "Title" },
        { Id: 936935, PropertyName: "value", Value: "[datafield:fitskor]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboardbilesendetay_613837_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      Kirilim2SelectById: [],
      DashboardFitskorDetay: [],
      DashboardFitskorDetayHeader: [],
      ProtokolSelectById: [],
      isComp17011Visible: "hidden",
      isComp598327Visible: "hidden",
      isComp299648Visible: "hidden",
      isComp700706Visible: "hidden",
      isComp185910Visible: "hidden",
      isComp153903Visible: "hidden",
      isComp130239Visible: "hidden",
      isComp606708Visible: "hidden",
      isComp390657Visible: "hidden",
      isComp228704Visible: "hidden",
      isComp774594Visible: "hidden",
      isComp67117Visible: "hidden",
      isComp388437Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboardbilesendetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.DashboardBilesenDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboardbilesendetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboardbilesendetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardBilesenDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  DashboardBilesenDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      kirilim2Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Kirilim2ID ?? this.props.screenInputs.kirilim2id,
        "Guid"
      ),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      ),
      Id_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProtokolId ?? this.props.screenInputs.protokolid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "DashboardBilesenDetay/DashboardBilesenDetayPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.Kirilim2SelectById = result?.data.kirilim2SelectById;
    stateVars.DashboardFitskorDetay = result?.data.dashboardFitskorDetay;

    stateVars.DashboardFitskorDetayHeader = result?.data.dashboardFitskorDetayHeader;

    stateVars.DashboardFitskorDetayHeader = result?.data.dashboardFitskorDetayHeader;

    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.isComp17011Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598327Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299648Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp700706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp185910Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp153903Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp130239Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606708Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390657Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp228704Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774594Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp67117Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp388437Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardBilesenDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardBilesenDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_613837 = this.state.DashboardFitskorDetay;
    formVars.dashboardbilesendetay_594718_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardFitskorDetayHeader?.length > 0
        ? stateVars.DashboardFitskorDetayHeader[0].header
        : this.state.DashboardFitskorDetayHeader?.length > 0
        ? this.state.DashboardFitskorDetayHeader[0].header
        : null
    );

    stateVars.isComp17011Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598327Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299648Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp700706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp185910Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp153903Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp130239Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp606708Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390657Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp228704Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774594Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp67117Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp388437Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardBilesenDetayComponent_297690_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
