import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface INorm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface INorm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  NormById: any[];
  NormById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class Norm_ScreenBase extends React.PureComponent<INorm_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "9c6ec104-223e-408f-8b9e-ae5bc61201f5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Norm Tanımı" },
        { Id: 5793845, PropertyName: "value", Value: "Norm Adı" },
        { Id: 2133775, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 711259, PropertyName: "value", Value: "Eşik Adedi" },
        { Id: 567155, PropertyName: "placeholder", Value: "15" },
        { Id: 498839, PropertyName: "value", Value: "Normal Min Eşiği Sıralaması" },
        { Id: 136674, PropertyName: "placeholder", Value: "15" },
        { Id: 955614, PropertyName: "value", Value: "Normal Max Eşiği Sıralaması" },
        { Id: 615489, PropertyName: "placeholder", Value: "15" },
        { Id: 9544, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      NormById: [],
      SaveRecord: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("norm", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.NormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("norm", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("norm", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.NormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      norm_2133775_value: this.state.NormById?.at?.(0)?.tanim ?? undefined,
      norm_567155_value: this.state.NormById?.at?.(0)?.esikAdedi ?? undefined,
      norm_136674_value: this.state.NormById?.at?.(0)?.normalMinSinifi ?? undefined,
      norm_615489_value: this.state.NormById?.at?.(0)?.normalMaxSinifi ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  NormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      normId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NormId ?? this.props.screenInputs.normid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Norm/NormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NormById = result?.data.normById;
    formVars.norm_2133775_value = ReactSystemFunctions.toString(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].tanim
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].tanim
        : null
    );
    formVars.norm_567155_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].esikAdedi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].esikAdedi
        : null
    );
    formVars.norm_136674_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].normalMinSinifi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].normalMinSinifi
        : null
    );
    formVars.norm_615489_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].normalMaxSinifi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].normalMaxSinifi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.NormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.norm_2133775_value = ReactSystemFunctions.toString(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].tanim
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].tanim
        : null
    );

    formVars.norm_567155_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].esikAdedi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].esikAdedi
        : null
    );

    formVars.norm_136674_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].normalMinSinifi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].normalMinSinifi
        : null
    );

    formVars.norm_615489_value = ReactSystemFunctions.value(
      this,
      stateVars.NormById?.length > 0
        ? stateVars.NormById[0].normalMaxSinifi
        : this.state.NormById?.length > 0
        ? this.state.NormById[0].normalMaxSinifi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  NormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  NormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "norm_2133775_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "norm_2133775_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_567155_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "norm_567155_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_567155_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "norm_615489_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_567155_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "norm_136674_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.NormId ?? this.props.screenInputs.normid,
        "Guid"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "norm_2133775_value", "value", "", "", "")
        ),
        "string"
      ),
      EsikAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_567155_value", "value", "", "", "")),
        "number"
      ),
      NormalMaxSinifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_615489_value", "value", "", "", "")),
        "number"
      ),
      NormalMinSinifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "norm_136674_value", "value", "", "", "")),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Norm/NormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.NormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  NormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
