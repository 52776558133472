import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPersons_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPersons_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  KisiTanimlayabilirMi: any[];
  KisiTanimlayabilirMi_dummy: any[];
  KisilerYetki: any[];
  KisilerYetki_dummy: any[];
  IsSinifExisting: any[];
  IsSinifExisting_dummy: any[];
  KisiCount: any[];
  KisiCount_dummy: any[];
  KisiSearch: any[];
  KisiSearch_dummy: any[];
  RolePerKurumTipi: any[];
  RolePerKurumTipi_dummy: any[];
  RoleToCountLabel: any[];
  RoleToCountLabel_dummy: any[];
  Rol: any[];
  Rol_dummy: any[];
  AktifKirilim1AltKirilim2leri: any[];
  AktifKirilim1AltKirilim2leri_dummy: any[];
  Sheet1select: any[];
  Sheet1select_dummy: any[];
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  EmptySheet1: number;
  EmptySheet1_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  ImportExcelBase64: string;
  ImportExcelBase64_dummy: string;
  ImportDanisanFromSheet1: number;
  ImportDanisanFromSheet1_dummy: number;
  SampleData: any[];
  SampleData_dummy: any[];
  ExportExcel: any;
  ExportExcel_dummy: any;
  SampleExcel2: any[];
  SampleExcel2_dummy: any[];
  EmptySheet2: number;
  EmptySheet2_dummy: number;
  Sheet2Select: any[];
  Sheet2Select_dummy: any[];
  ImportDanisanFromSheet2: number;
  ImportDanisanFromSheet2_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  KUserByEposta: any[];
  KUserByEposta_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  SaveUser: any;
  SaveUser_dummy: any;
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  VeliBilgilendirmeSave: number;
  VeliBilgilendirmeSave_dummy: number;
  DeleteRecord: number;
  DeleteRecord_dummy: number;
  KisiDeleteById: number;
  KisiDeleteById_dummy: number;
  isComp30960Visible: "visible" | "hidden";
  isComp724766Visible: "visible" | "hidden";
  isComp3236Visible: "visible" | "hidden";
  isComp222810Visible: "visible" | "hidden";
  isComp396447Visible: "visible" | "hidden";
  isComp230104Visible: "visible" | "hidden";
  isComp22832Visible: "visible" | "hidden";
  isComp133816Visible: "visible" | "hidden";
  isComp416063Visible: "visible" | "hidden";
  isComp814413Visible: "visible" | "hidden";
  isComp608005Visible: "visible" | "hidden";
  isComp771759Visible: "visible" | "hidden";
  isComp62810Visible: "visible" | "hidden";
  isComp9622778Visible: "visible" | "hidden";
  isComp3001244Visible: "visible" | "hidden";
  isComp588607Visible: "visible" | "hidden";
  isComp532054Visible: "visible" | "hidden";
  isComp602948Visible: "visible" | "hidden";
  isComp5477140Visible: "visible" | "hidden";
  isComp928945Visible: "visible" | "hidden";
  isComp247909Visible: "visible" | "hidden";
  isComp637427Visible: "visible" | "hidden";
  isComp724202Visible: "visible" | "hidden";
  isComp307279Visible: "visible" | "hidden";
  isComp603478Visible: "visible" | "hidden";
  isComp71769Visible: "visible" | "hidden";
  isComp810485Visible: "visible" | "hidden";
  isComp74440Visible: "visible" | "hidden";
  isComp956466Visible: "visible" | "hidden";
  isComp775302Visible: "visible" | "hidden";
  isComp248772Visible: "visible" | "hidden";
  isComp892303Visible: "visible" | "hidden";
  isComp660911Visible: "visible" | "hidden";
  isComp175914Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Persons_ScreenBase extends React.PureComponent<IPersons_ScreenProps, any> {
  persons_22832_value_kuikaSelectBoxRef: React.RefObject<any>;
  persons_133816_value_kuikaSelectBoxRef: React.RefObject<any>;
  persons_399769_value_kuikaTableRef: React.RefObject<any>;
  persons_172599_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d14cb250-d550-437c-8692-4e71202c8130",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 71408, PropertyName: "value", Value: "bulundu." },
        { Id: 30960, PropertyName: "label", Value: "Upload" },
        { Id: 724766, PropertyName: "label", Value: "Sample" },
        { Id: 3236, PropertyName: "label", Value: "Upload" },
        { Id: 222810, PropertyName: "label", Value: "Sample" },
        { Id: 396447, PropertyName: "label", Value: "Örnek .xlsx" },
        { Id: 230104, PropertyName: "label", Value: "Toplu Yükleme" },
        { Id: 22832, PropertyName: "placeholder", Value: "Rol Filtresi..." },
        { Id: 416063, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 399769, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 999699, PropertyName: "value", Value: "[datafield:kirilim2]" },
        { Id: 771759, PropertyName: "title", Value: "Okul Numarası" },
        { Id: 85766, PropertyName: "value", Value: "[datafield:okulnumarasi]" },
        { Id: 695026, PropertyName: "title", Value: "Adı" },
        { Id: 600713, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 8904678, PropertyName: "title", Value: "Soyadı" },
        { Id: 3886180, PropertyName: "value", Value: "[datafield:soyadi]" },
        { Id: 338727, PropertyName: "title", Value: "Yaş" },
        { Id: 350167, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 607282, PropertyName: "title", Value: "Cinsiyet" },
        { Id: 628289, PropertyName: "value", Value: "[datafield:cinsiyet]" },
        { Id: 62810, PropertyName: "title", Value: "Branş" },
        { Id: 254529, PropertyName: "value", Value: "[datafield:okulbrans]" },
        { Id: 9622778, PropertyName: "title", Value: "Eposta" },
        { Id: 8081341, PropertyName: "value", Value: "[datafield:eposta]" },
        { Id: 3001244, PropertyName: "title", Value: "Telefon" },
        { Id: 2747075, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 588607, PropertyName: "title", Value: "Freelance?" },
        { Id: 5477140, PropertyName: "title", Value: "Veliler" },
        { Id: 984657, PropertyName: "value", Value: "[datafield:velicount]" },
        { Id: 928945, PropertyName: "title", Value: "Check-Up" },
        { Id: 191502, PropertyName: "value", Value: "[datafield:checkupadedi]" },
        { Id: 125813, PropertyName: "value", Value: "[datafield:ogrencicount]" },
        { Id: 174819, PropertyName: "value", Value: "[datafield:kirilim2adedi]" },
        { Id: 724202, PropertyName: "title", Value: "Profil" },
        { Id: 835173, PropertyName: "value", Value: "[datafield:profilsayisi]" },
        { Id: 307279, PropertyName: "title", Value: "Kullanıcı" },
        { Id: 810485, PropertyName: "title", Value: "Rapor Gönder" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.persons_22832_value_kuikaSelectBoxRef = React.createRef();
    this.persons_133816_value_kuikaSelectBoxRef = React.createRef();
    this.persons_399769_value_kuikaTableRef = React.createRef();
    this.persons_172599_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      KisiTanimlayabilirMi: [],
      KisilerYetki: [],
      IsSinifExisting: [],
      KisiCount: [],
      KisiSearch: [],
      RolePerKurumTipi: [],
      RoleToCountLabel: [],
      Rol: [],
      AktifKirilim1AltKirilim2leri: [],
      Sheet1select: [],
      KurumTipiSelectById: [],
      EmptySheet1: 0,
      UploadFile: "",
      ImportExcelBase64: "",
      ImportDanisanFromSheet1: 0,
      SampleData: [],
      ExportExcel: "",
      SampleExcel2: [],
      EmptySheet2: 0,
      Sheet2Select: [],
      ImportDanisanFromSheet2: 0,
      SetValueOf: "",
      NAVIGATE: "",
      SaveRecord: false,
      KUserByEposta: [],
      IfThenElse: false,
      BaseUrl: [],
      SendMail: false,
      VeliBilgilendirmeSave: 0,
      DeleteRecord: false,
      KisiDeleteById: 0,
      isComp30960Visible: "hidden",
      isComp724766Visible: "hidden",
      isComp3236Visible: "hidden",
      isComp222810Visible: "hidden",
      isComp396447Visible: "hidden",
      isComp230104Visible: "hidden",
      isComp22832Visible: "hidden",
      isComp133816Visible: "hidden",
      isComp416063Visible: "hidden",
      isComp814413Visible: "hidden",
      isComp608005Visible: "hidden",
      isComp771759Visible: "hidden",
      isComp62810Visible: "hidden",
      isComp9622778Visible: "hidden",
      isComp3001244Visible: "hidden",
      isComp588607Visible: "hidden",
      isComp532054Visible: "hidden",
      isComp602948Visible: "hidden",
      isComp5477140Visible: "hidden",
      isComp928945Visible: "hidden",
      isComp247909Visible: "hidden",
      isComp637427Visible: "hidden",
      isComp724202Visible: "hidden",
      isComp307279Visible: "hidden",
      isComp603478Visible: "hidden",
      isComp71769Visible: "hidden",
      isComp810485Visible: "hidden",
      isComp74440Visible: "hidden",
      isComp956466Visible: "hidden",
      isComp775302Visible: "hidden",
      isComp248772Visible: "hidden",
      isComp892303Visible: "hidden",
      isComp660911Visible: "hidden",
      isComp175914Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("persons", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.PersonsPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("persons", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("persons", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PersonsPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.PersonsPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      persons_22832_value: this.props.screenInputs.role ?? this.props.screenInputs.role,
      persons_133816_value: this.props.screenInputs.role ?? this.props.screenInputs.role
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  PersonsPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      role_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      role_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      rolename_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      searchFor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      roleFilter_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kirilim2Id_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      role_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.KisiTanimlayabilirMi = result?.data.kisiTanimlayabilirMi;
    stateVars.KisilerYetki = result?.data.kisilerYetki;
    stateVars.isComp3236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].excelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].excelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222810Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].excelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].excelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp396447Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].ogrenciExcelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].ogrenciExcelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230104Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].ogrenciExcelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].ogrenciExcelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp133816Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisilerYetki?.length > 0
              ? stateVars.KisilerYetki[0].sinifFiltre
              : this.state.KisilerYetki?.length > 0
              ? this.state.KisilerYetki[0].sinifFiltre
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp416063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].arama
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].arama
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp814413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].yeniButton
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].yeniButton
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771759Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].okulNumarasiColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].okulNumarasiColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62810Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].okulBransColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].okulBransColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9622778Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].epostaColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].epostaColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3001244Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].telefonNumarasi
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].telefonNumarasi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588607Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].freelanceColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].freelanceColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5477140Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].velileriColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].velileriColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].checkuplarColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].checkuplarColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp247909Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].velisiOlduguOgrenciColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].velisiOlduguOgrenciColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp637427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].siniflariColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].siniflariColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp724202Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].profilColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].profilColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307279Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].kullaniciColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].kullaniciColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp810485Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].mailGonderColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].mailGonderColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp892303Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].gozIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].gozIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp660911Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].editIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].editIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175914Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].deleteIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].deleteIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.IsSinifExisting = result?.data.isSinifExisting;
    stateVars.isComp133816Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisilerYetki?.length > 0
              ? stateVars.KisilerYetki[0].sinifFiltre
              : this.state.KisilerYetki?.length > 0
              ? this.state.KisilerYetki[0].sinifFiltre
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp608005Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiCount = result?.data.kisiCount;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    stateVars.RolePerKurumTipi = result?.data.rolePerKurumTipi;
    formVars.persons_737045_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );
    stateVars.RoleToCountLabel = result?.data.roleToCountLabel;
    formVars.persons_264407_value = ReactSystemFunctions.toString(
      this,
      stateVars.RoleToCountLabel?.length > 0
        ? stateVars.RoleToCountLabel[0].label
        : this.state.RoleToCountLabel?.length > 0
        ? this.state.RoleToCountLabel[0].label
        : null
    );
    formVars.persons_814413_label = ReactSystemFunctions.toString(
      this,
      stateVars.RoleToCountLabel?.length > 0
        ? stateVars.RoleToCountLabel[0].yeniLabel
        : this.state.RoleToCountLabel?.length > 0
        ? this.state.RoleToCountLabel[0].yeniLabel
        : null
    );
    stateVars.Rol = result?.data.rol;

    formVars.persons_22832_options = stateVars.Rol;
    stateVars.AktifKirilim1AltKirilim2leri = result?.data.aktifKirilim1AltKirilim2leri;

    formVars.persons_133816_options = stateVars.AktifKirilim1AltKirilim2leri;
    formVars.persons_133816_placeholder =
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2Filtresi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2Filtresi
        : null;
    stateVars.Sheet1select = result?.data.sheet1select;
    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.persons_133816_options = stateVars.AktifKirilim1AltKirilim2leri;
    formVars.persons_133816_placeholder =
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2Filtresi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2Filtresi
        : null;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    formVars.persons_264407_value = ReactSystemFunctions.toString(
      this,
      stateVars.RoleToCountLabel?.length > 0
        ? stateVars.RoleToCountLabel[0].label
        : this.state.RoleToCountLabel?.length > 0
        ? this.state.RoleToCountLabel[0].label
        : null
    );

    formVars.persons_737045_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );

    stateVars.isComp3236Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].excelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].excelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222810Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].excelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].excelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp396447Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].ogrenciExcelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].ogrenciExcelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230104Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].ogrenciExcelUploadandSample
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].ogrenciExcelUploadandSample
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_22832 = this.state.Rol;
    stateVars.dataSource_22832 = this.state.Rol;
    stateVars.isComp22832Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.role ?? this.props.screenInputs.role, null) === true
        ? "visible"
        : "hidden";

    formVars.persons_133816_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2Filtresi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2Filtresi
        : null
    );

    stateVars.dataSource_133816 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.dataSource_133816 = this.state.AktifKirilim1AltKirilim2leri;
    stateVars.isComp133816Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisilerYetki?.length > 0
              ? stateVars.KisilerYetki[0].sinifFiltre
              : this.state.KisilerYetki?.length > 0
              ? this.state.KisilerYetki[0].sinifFiltre
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp416063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].arama
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].arama
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.persons_814413_label = ReactSystemFunctions.toString(
      this,
      stateVars.RoleToCountLabel?.length > 0
        ? stateVars.RoleToCountLabel[0].yeniLabel
        : this.state.RoleToCountLabel?.length > 0
        ? this.state.RoleToCountLabel[0].yeniLabel
        : null
    );

    stateVars.isComp814413Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].yeniButton
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].yeniButton
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_608005_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    stateVars.isComp608005Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.IsSinifExisting?.length > 0
            ? stateVars.IsSinifExisting[0].isSinifExisting
            : this.state.IsSinifExisting?.length > 0
            ? this.state.IsSinifExisting[0].isSinifExisting
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp771759Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].okulNumarasiColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].okulNumarasiColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp62810Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].okulBransColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].okulBransColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9622778Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].epostaColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].epostaColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3001244Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].telefonNumarasi
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].telefonNumarasi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588607Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].freelanceColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].freelanceColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp532054Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "persons_399769_value", "freelanceMi"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp602948Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(this, "persons_399769_value", "freelanceMi"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5477140Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].velileriColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].velileriColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp928945Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].checkuplarColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].checkuplarColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.persons_247909_title = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].velisioldugu
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].velisioldugu
        : null
    );

    stateVars.isComp247909Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].velisiOlduguOgrenciColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].velisiOlduguOgrenciColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.persons_637427_title = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kirilim2lari
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kirilim2lari
        : null
    );

    stateVars.isComp637427Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].siniflariColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].siniflariColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp724202Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].profilColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].profilColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp307279Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].kullaniciColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].kullaniciColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp603478Visible =
      ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "kUserId"), null) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp71769Visible =
      ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "kUserId"), null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp810485Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].mailGonderColumn
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].mailGonderColumn
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74440Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "persons_399769_value", "bildirimDurum"), "3") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp956466Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "persons_399769_value", "bildirimDurum"), "2") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp775302Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "persons_399769_value", "bildirimDurum"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp248772Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "persons_399769_value", "scheduleDegistirilebilirMi"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp892303Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].gozIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].gozIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp660911Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].editIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].editIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp175914Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisilerYetki?.length > 0
            ? stateVars.KisilerYetki[0].deleteIcon
            : this.state.KisilerYetki?.length > 0
            ? this.state.KisilerYetki[0].deleteIcon
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersonsComponent_30960_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_30960_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EmptySheet1 = result?.data.emptySheet1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_30960_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_30960_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_30960_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_30960_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      tableName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kurumId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      currentRole_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_30960_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.Sheet1select = result?.data.sheet1select;
    stateVars.ImportDanisanFromSheet1 = result?.data.importDanisanFromSheet1;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_30960_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_30960_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_724766_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      header_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_724766_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SampleData = result?.data.sampleData;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonsComponent_3236_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_3236_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EmptySheet1 = result?.data.emptySheet1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_3236_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_3236_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_3236_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_3236_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      tableName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kurumId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      currentRole_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_3236_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.Sheet1select = result?.data.sheet1select;
    stateVars.ImportDanisanFromSheet1 = result?.data.importDanisanFromSheet1;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_3236_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_3236_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_222810_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      header_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName(null, "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_222810_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SampleData = result?.data.sampleData;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonsComponent_396447_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      header_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      footer_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_396447_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SampleExcel2 = result?.data.sampleExcel2;
    stateVars.ExportExcel = result?.data.exportExcel;
    ReactSystemFunctions.downloadFile(result?.data?.exportExcel);
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonsComponent_230104_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_230104_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.EmptySheet2 = result?.data.emptySheet2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_230104_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_230104_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_230104_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_230104_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      excelBase64_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      tableName_0: ReactSystemFunctions.convertToTypeByName("sheet2", "string"),
      primaryKeyName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kurumId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim1Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      currentRole_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "persons_22832_value", "value", "Rol", "val", "val")
        ),
        "string"
      ),
      kurumId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_230104_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ImportExcelBase64 = result?.data.importExcelBase64;
    stateVars.Sheet2Select = result?.data.sheet2Select;
    stateVars.ImportDanisanFromSheet2 = result?.data.importDanisanFromSheet2;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_230104_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_230104_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_22832_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "persons_22832_value", "value", "Rol", "val", "val")
        ),
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_22832_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_22832_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_22832_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "persons_172599_value", 1, "current");
    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_133816_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_133816_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_133816_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_133816_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "persons_172599_value", 1, "current");
    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_416063_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "persons_172599_value", 1, "current");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_416063_onPressEnter1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_416063_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      role_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumTipi_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiKod
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiKod
            : null
        ),
        "string"
      ),
      myRole_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      lang_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_416063_onPressEnter1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    stateVars.KisiCount = result?.data.kisiCount;
    stateVars.RolePerKurumTipi = result?.data.rolePerKurumTipi;
    formVars.persons_737045_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_416063_onPressEnter2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_416063_onPressEnter2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    formVars.persons_737045_value = ReactSystemFunctions.toString(
      this,
      stateVars.RolePerKurumTipi?.length > 0
        ? stateVars.RolePerKurumTipi[0].label
        : this.state.RolePerKurumTipi?.length > 0
        ? this.state.RolePerKurumTipi[0].label
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_814413_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Person", "kisiId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables("Person", "Kirilim2Id", null);
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "584409",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_532054_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsFreelance_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "profileId"),
        "Guid"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_532054_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_532054_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_532054_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_602948_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IsFreelance_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "profileId"),
        "Guid"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_602948_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_602948_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_602948_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_527161_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "KisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "profilID",
      ReactSystemFunctions.value(this, "persons_399769_value", "profileId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "PersonConnected",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "189029",
      null,
      "right",
      null,
      "700px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_298698_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonCard",
      "kisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "PersonCard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_645540_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "KisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnected",
      "profilID",
      ReactSystemFunctions.value(this, "persons_399769_value", "profileId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "PersonConnected",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "552664",
      null,
      "right",
      null,
      "700px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_627973_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "OgretmenSiniflari",
      "KisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "OgretmenSiniflari",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "870237",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_626422_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Profiles",
      "KisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "Profiles",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "207805",
      null,
      "right",
      null,
      "850px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_71769_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_71769_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KUserByEposta = result?.data.kUserByEposta;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_71769_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_71769_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3ebb3f01_e77f_4865_91d1_6ab44823bd05_notify",
        this.defaultML,
        "Kullanıcı yaratabilmek için eposta zorunluudur."
      ),
      "warning",
      "bottom-right",
      10,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_71769_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_71769_onClick_else11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_71769_onClick_else1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "fcd2c170_767e_3ce7_52ff_1bb08439805f_notify",
        this.defaultML,
        "Bu Eposta ile Bir Kullanıcı Mevcut"
      ),
      "warning",
      "bottom-right",
      10,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_71769_onClick_else1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "adi"),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "soyadi"),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("94593018-6663-47a8-b1c3-9e3cff619781", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "telefon"),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName("", "number"),
      templateId_3: ReactSystemFunctions.convertToTypeByName("77e1b837-fbfa-41b4-b5a3-baf6dcff9dfb", "string"),
      receivers_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      subject_3: ReactSystemFunctions.convertToTypeByName("MeMap Fitness Platformuna Davet Edildiniz", "string"),
      sender_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_3: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      providerEmail_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      kurumlogo_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "okulImgUrl"),
        "string"
      ),
      ad_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "adi"),
        "string"
      ),
      soyad_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "soyadi"),
        "string"
      ),
      gonderen_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].fullname
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].fullname
            : null
        ),
        "string"
      ),
      currentRole_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_71769_onClick_else1e1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveUser = result?.data.saveUser;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.SendMail = result?.data.sendMail;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonsComponent_71769_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.PersonsComponent_71769_onClick_else1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonsComponent_71769_onClick_else1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_71769_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "eposta"), null)) {
      isErrorOccurred = await this.PersonsComponent_71769_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonsComponent_71769_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_956466_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_1: ReactSystemFunctions.convertToTypeByName("1a7fea78-fd2f-4f8b-8309-6b2e7ee68a74", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      subject_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "emailBaslik3"),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      veliAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "adi"),
        "string"
      ),
      username_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      veliSoyadi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "soyadi"),
        "string"
      ),
      kurumlogo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "okulImgUrl"),
        "string"
      ),
      velikisiid_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "id"),
        "Guid"
      ),
      currentRole_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_956466_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.SendMail = result?.data.sendMail;
    stateVars.VeliBilgilendirmeSave = result?.data.veliBilgilendirmeSave;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_956466_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_956466_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_775302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_775302_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KUserByEposta = result?.data.kUserByEposta;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_775302_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "1ede81f8_5b2c_50f6_0a10_b12defa017c8_notify",
        this.defaultML,
        "Kullanıcı yaratabilmek için eposta zorunluudur."
      ),
      "warning",
      "bottom-right",
      10,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_775302_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_775302_onClick_else11_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "22346207_d555_258e_5364_4d123e744f27_notify",
        this.defaultML,
        "Bu Eposta ile Bir Kullanıcı Mevcut"
      ),
      "warning",
      "bottom-right",
      10,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_775302_onClick_else1e1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e11_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1e11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "adi"),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "soyadi"),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("94593018-6663-47a8-b1c3-9e3cff619781", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "telefon"),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      twoFactorAuthenticationType_0: ReactSystemFunctions.convertToTypeByName("", "number")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "eposta"), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Persons/PersonsComponent_775302_onClick_else1e11_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e12_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1e12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.SaveUser?.id),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "eposta"), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Persons/PersonsComponent_775302_onClick_else1e12_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.TokenOfUser = result?.data.tokenOfUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e13_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1e13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_1: ReactSystemFunctions.convertToTypeByName("59535ae9-62f0-4f87-a480-614f254640cc", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      subject_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "emailBaslik"),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      veliAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "adi"),
        "string"
      ),
      username_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "eposta"),
        "string"
      ),
      pass_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        "string"
      ),
      kurumlogo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "okulImgUrl"),
        "string"
      ),
      velisoyadi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "soyadi"),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_775302_onClick_else1e13_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BaseUrl = result?.data.baseUrl;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e14_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1e14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      velikisiid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "id"),
        "Guid"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "eposta"), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Persons/PersonsComponent_775302_onClick_else1e14_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.VeliBilgilendirmeSave = result?.data.veliBilgilendirmeSave;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e15_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_775302_onClick_else1e15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      currentRole_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_775302_onClick_else1e15_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonsComponent_775302_onClick_else11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.PersonsComponent_775302_onClick_else1f1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonsComponent_775302_onClick_else1e1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonsComponent_775302_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.value(this, "persons_399769_value", "eposta"), null)) {
      isErrorOccurred = await this.PersonsComponent_775302_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonsComponent_775302_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_248772_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EgitmenAyarlari",
      "ProfileId",
      ReactSystemFunctions.value(this, "persons_399769_value", "profileId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "EgitmenAyarlari",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "544097",
      null,
      "right",
      null,
      "975px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_401940_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonCard",
      "kisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "PersonCard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_860665_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kisiId",
      ReactSystemFunctions.value(this, "persons_399769_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "role",
      this.props.screenInputs.role ?? this.props.screenInputs.role
    );
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "Kirilim2Id",
      ReactSystemFunctions.value(this, "persons_399769_value", "mevcutKirilim2Id")
    );
    KuikaAppManager.addToPageInputVariables(
      "Person",
      "kurumID",
      ReactSystemFunctions.value(this, "persons_399769_value", "kurumID")
    );
    KuikaAppManager.addToPageInputVariables("Person", "profilID", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("Person", "Ne", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Persons",
      "Person",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "256479",
      null,
      "right",
      null,
      "450px",
      "",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_505853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "profileId"),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_MemapAdmin"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_505853_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteRecord = result?.data.deleteRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_505853_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_505853_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_505853_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "persons_399769_value", "id"),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_MemapAdmin"
      )
    ) {
      stateVars.Confirm = await Swal.fire({
        title: ReactSystemFunctions.translateCustomActions(
          this.ml,
          "fe852656_41cb_0d69_f310_feba936ad060_confirmation",
          this.defaultML,
          "Are you sure you want to delete this record?"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Onayla"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Confirmer"
            : "Confirm",
        cancelButtonText:
          localStorage.getItem("ml") === "tr_TR"
            ? "Vazgeç"
            : localStorage.getItem("ml") === "fr_FR"
            ? "Annuler"
            : "Cancel",
        customClass: {
          title: "swal2-font-poppins"
        }
      });

      if (stateVars.Confirm.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_505853_onClick1_", localVar);
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.KisiDeleteById = result?.data.kisiDeleteById;
        this.props.form.setFieldsValue(formVars);

        await new Promise((resolve, reject) => {
          this.setState(stateVars, async () => {
            isErrorOccurred = await this.PersonsComponent_505853_onClick2_();
            resolve();
          });
        });
      }
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonsComponent_505853_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_505853_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "persons_22832_value", "value", "Rol", "val", "val")
        ),
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_505853_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCount = result?.data.kisiCount;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_505853_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_505853_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonsComponent_172599_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      searchFor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentRole_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      currentProfileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      roleFilter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "persons_22832_value", "value", "Rol", "val", "val")
        ),
        "string"
      ),
      kurumId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      kirilim2Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_133816_value", "value", "AktifKirilim1AltKirilim2leri", "id", "id")
        ),
        "Guid"
      ),
      searchFor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "persons_416063_value", "value", "", "", "")),
        "string"
      ),
      currentpage_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "persons_172599_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Persons/PersonsComponent_172599_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCount = result?.data.kisiCount;
    stateVars.KisiSearch = result?.data.kisiSearch;

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonsComponent_172599_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonsComponent_172599_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.persons_123046_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    stateVars.dataSource_399769 = this.state.KisiSearch;
    formVars.persons_172599_total = ReactSystemFunctions.value(
      this,
      stateVars.KisiSearch?.length > 0
        ? stateVars.KisiSearch[0].personCount
        : this.state.KisiSearch?.length > 0
        ? this.state.KisiSearch[0].personCount
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [584409, 189029, 552664, 870237, 207805, 544097, 256479] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PersonsPageInit();
    }
  }
}
