import React from "react";
import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import { Redirect } from "../auth/redirect";
import { NoMatch404 } from "../components/common/no-match-404";
import { Dashboard, ForgotPassword, Login, ResetPassword } from "../components/default_screens/index";
import { PrivateRoute } from "./private-route";
import { Route } from "./route";
import { TestFilter_Screen } from "../screens/testfilter/testfilter";
import { MySchedule_Screen } from "../screens/myschedule/myschedule";
import { ProtokolAltBilesenF_Screen } from "../screens/protokolaltbilesenf/protokolaltbilesenf";
import { Kirilim2List_Screen } from "../screens/kirilim2list/kirilim2list";
import { AltBilesenTestler_Screen } from "../screens/altbilesentestler/altbilesentestler";
import { Deneme_Screen } from "../screens/deneme/deneme";
import { Master2_Screen } from "../screens/master2/master2";
import { TestF2_Screen } from "../screens/testf2/testf2";
import { Xxx_Screen } from "../screens/xxx/xxx";
import { Tests_Screen } from "../screens/tests/tests";
import { Resources_Screen } from "../screens/resources/resources";
import { Kaynaklar_Screen } from "../screens/kaynaklar/kaynaklar";
import { CheckupReport_Screen } from "../screens/checkupreport/checkupreport";
import { RandevuSelfService2_Screen } from "../screens/randevuselfservice2/randevuselfservice2";
import { EkTestGecmisi_Screen } from "../screens/ektestgecmisi/ektestgecmisi";
import { MySchedule2_Screen } from "../screens/myschedule2/myschedule2";
import { SinifYeniOgrenciSezon_Screen } from "../screens/sinifyeniogrencisezon/sinifyeniogrencisezon";
import { MyProfile_Screen } from "../screens/myprofile/myprofile";
import { NormDetay_Screen } from "../screens/normdetay/normdetay";
import { TestF_Screen } from "../screens/testf/testf";
import { CheckupDonemiForm_Screen } from "../screens/checkupdonemiform/checkupdonemiform";
import { ParQFormcopy_Screen } from "../screens/parqformcopy/parqformcopy";
import { Services_Screen } from "../screens/services/services";
import { ParQForm_Screen } from "../screens/parqform/parqform";
import { BasvuruKurumDetay_Screen } from "../screens/basvurukurumdetay/basvurukurumdetay";
import { MailTest_Screen } from "../screens/mailtest/mailtest";
import { Master_Screen } from "../screens/master/master";
import { PersonConnectedList_Screen } from "../screens/personconnectedlist/personconnectedlist";
import { Classification_Screen } from "../screens/classification/classification";
import { MyScheduleBurhan_Screen } from "../screens/myscheduleburhan/myscheduleburhan";
import { Simay_Screen } from "../screens/simay/simay";
import { RandevuFromEgitmen2_Screen } from "../screens/randevufromegitmen2/randevufromegitmen2";
import { KurumTipiForm_Screen } from "../screens/kurumtipiform/kurumtipiform";
import { KVKKAydinlatmaSozlesmesi_Screen } from "../screens/kvkkaydinlatmasozlesmesi/kvkkaydinlatmasozlesmesi";
import { KrediYukle_Screen } from "../screens/krediyukle/krediyukle";
import { ServiceF_Screen } from "../screens/servicef/servicef";
import { RandevuSelfService3_Screen } from "../screens/randevuselfservice3/randevuselfservice3";
import { StatisticsFitskorDetay_Screen } from "../screens/statisticsfitskordetay/statisticsfitskordetay";
import { SinifYeniOgrenci_Screen } from "../screens/sinifyeniogrenci/sinifyeniogrenci";
import { Norms_Screen } from "../screens/norms/norms";
import { CheckupReportcopycopy_Screen } from "../screens/checkupreportcopycopy/checkupreportcopycopy";
import { VerificationCode_Screen } from "../screens/verificationcode/verificationcode";
import { ResetPassword_Screen } from "../screens/resetpassword/resetpassword";
import { MesaiSaatiForm_Screen } from "../screens/mesaisaatiform/mesaisaatiform";
import { ProtokolAltBilesenF2_Screen } from "../screens/protokolaltbilesenf2/protokolaltbilesenf2";
import { KurumBasvuruForm_Screen } from "../screens/kurumbasvuruform/kurumbasvuruform";
import { Protocol2_Screen } from "../screens/protocol2/protocol2";
import { BilesenAltBilesenleriList_Screen } from "../screens/bilesenaltbilesenlerilist/bilesenaltbilesenlerilist";
import { Personcopy_Screen } from "../screens/personcopy/personcopy";
import { ProtocolBilesenF2_Screen } from "../screens/protocolbilesenf2/protocolbilesenf2";
import { TakvimKapat_Screen } from "../screens/takvimkapat/takvimkapat";
import { CheckupReportcopy_Screen } from "../screens/checkupreportcopy/checkupreportcopy";
import { PopulasyonForm_Screen } from "../screens/populasyonform/populasyonform";
import { Invitation_Screen } from "../screens/invitation/invitation";
import { Profiles_Screen } from "../screens/profiles/profiles";
import { Protocols_Screen } from "../screens/protocols/protocols";
import { Composant_Screen } from "../screens/composant/composant";
import { EgitmenAyarlari_Screen } from "../screens/egitmenayarlari/egitmenayarlari";
import { LeftMenu_Screen } from "../screens/leftmenu/leftmenu";
import { Settings_Screen } from "../screens/settings/settings";
import { EgitmenSozlesmesiYeni_Screen } from "../screens/egitmensozlesmesiyeni/egitmensozlesmesiyeni";
import { CheckupF_Screen } from "../screens/checkupf/checkupf";
import { DashboardBilesenDetay_Screen } from "../screens/dashboardbilesendetay/dashboardbilesendetay";
import { BasvuruEgitmenDetay_Screen } from "../screens/basvuruegitmendetay/basvuruegitmendetay";
import { StatisticsDetay_Screen } from "../screens/statisticsdetay/statisticsdetay";
import { PopulasyonList_Screen } from "../screens/populasyonlist/populasyonlist";
import { NormDetayList_Screen } from "../screens/normdetaylist/normdetaylist";
import { RandevuFromEgitmen_Screen } from "../screens/randevufromegitmen/randevufromegitmen";
import { RandevuSelfService_Screen } from "../screens/randevuselfservice/randevuselfservice";
import { PersonCard_Screen } from "../screens/personcard/personcard";
import { OrganisationCard_Screen } from "../screens/organisationcard/organisationcard";
import { NormDetayKopyala_Screen } from "../screens/normdetaykopyala/normdetaykopyala";
import { SlotGenerator_Screen } from "../screens/slotgenerator/slotgenerator";
import { Organisation_Screen } from "../screens/organisation/organisation";
import { Kirilim1List_Screen } from "../screens/kirilim1list/kirilim1list";
import { SinifinOgrencileri_Screen } from "../screens/sinifinogrencileri/sinifinogrencileri";
import { Persons_Screen } from "../screens/persons/persons";
import { Statistics_Screen } from "../screens/statistics/statistics";
import { CheckupReport3_Screen } from "../screens/checkupreport3/checkupreport3";
import { TopluOlcumListSinif_Screen } from "../screens/topluolcumlistsinif/topluolcumlistsinif";
import { TestDeneme2Test_Screen } from "../screens/testdeneme2test/testdeneme2test";
import { Organisations_Screen } from "../screens/organisations/organisations";
import { KurumProfile_Screen } from "../screens/kurumprofile/kurumprofile";
import { MyReports_Screen } from "../screens/myreports/myreports";
import { EgitmenSozlesmesi_Screen } from "../screens/egitmensozlesmesi/egitmensozlesmesi";
import { Profile_Screen } from "../screens/profile/profile";
import { Composants_Screen } from "../screens/composants/composants";
import { RandevuSlot_Screen } from "../screens/randevuslot/randevuslot";
import { Invitations_Screen } from "../screens/invitations/invitations";
import { Dashboard_Screen } from "../screens/dashboard/dashboard";
import { SiniflandirmaSeviye_Screen } from "../screens/siniflandirmaseviye/siniflandirmaseviye";
import { CheckupDonemList_Screen } from "../screens/checkupdonemlist/checkupdonemlist";
import { MesaiSaatiForm2_Screen } from "../screens/mesaisaatiform2/mesaisaatiform2";
import { Signup_Screen } from "../screens/signup/signup";
import { ProtokolBilesenF_Screen } from "../screens/protokolbilesenf/protokolbilesenf";
import { Person_Screen } from "../screens/person/person";
import { KurumSozlesmesi_Screen } from "../screens/kurumsozlesmesi/kurumsozlesmesi";
import { ProtokolBilesenList_Screen } from "../screens/protokolbilesenlist/protokolbilesenlist";
import { TopluCheckupForm_Screen } from "../screens/toplucheckupform/toplucheckupform";
import { Basvurular_Screen } from "../screens/basvurular/basvurular";
import { RandevuFromMerkez_Screen } from "../screens/randevufrommerkez/randevufrommerkez";
import { DanisanSozlesmesiYeni_Screen } from "../screens/danisansozlesmesiyeni/danisansozlesmesiyeni";
import { MyAgenda_Screen } from "../screens/myagenda/myagenda";
import { Signin_Screen } from "../screens/signin/signin";
import { OgretmenSiniflari_Screen } from "../screens/ogretmensiniflari/ogretmensiniflari";
import { PersonConnected_Screen } from "../screens/personconnected/personconnected";
import { KurumTipiList_Screen } from "../screens/kurumtipilist/kurumtipilist";
import { MerkezBasvuruForm_Screen } from "../screens/merkezbasvuruform/merkezbasvuruform";
import { ForgotPassword_Screen } from "../screens/forgotpassword/forgotpassword";
import { AltBilesenForm_Screen } from "../screens/altbilesenform/altbilesenform";
import { EgitmenAyarlaricopy_Screen } from "../screens/egitmenayarlaricopy/egitmenayarlaricopy";
import { RandevuFromMerkez2_Screen } from "../screens/randevufrommerkez2/randevufrommerkez2";
import { SiniflandirmaSeviyeList_Screen } from "../screens/siniflandirmaseviyelist/siniflandirmaseviyelist";
import { CheckupPasta_Screen } from "../screens/checkuppasta/checkuppasta";
import { EkTestF_Screen } from "../screens/ektestf/ektestf";
import { Kirilim1Form_Screen } from "../screens/kirilim1form/kirilim1form";
import { HomePage_Screen } from "../screens/homepage/homepage";
import { EgitmenBasvuruFormu_Screen } from "../screens/egitmenbasvuruformu/egitmenbasvuruformu";
import { ProtokolAltBilesenler_Screen } from "../screens/protokolaltbilesenler/protokolaltbilesenler";
import { Kirilim2Form_Screen } from "../screens/kirilim2form/kirilim2form";
import { RandevuSummary_Screen } from "../screens/randevusummary/randevusummary";
import { SigninPro_Screen } from "../screens/signinpro/signinpro";
import { Protocol_Screen } from "../screens/protocol/protocol";
import { KrediTransferi_Screen } from "../screens/kreditransferi/kreditransferi";
import { BasvuruMerkezDetay_Screen } from "../screens/basvurumerkezdetay/basvurumerkezdetay";
import { Norm_Screen } from "../screens/norm/norm";
import { Classifications_Screen } from "../screens/classifications/classifications";
import { DashboardKatilimDetay_Screen } from "../screens/dashboardkatilimdetay/dashboardkatilimdetay";
import { TestDenemeDegiskenF_Screen } from "../screens/testdenemedegiskenf/testdenemedegiskenf";
import { TestDegisken_Screen } from "../screens/testdegisken/testdegisken";
import { Protocol3_Screen } from "../screens/protocol3/protocol3";



import { IAppMode, IAuthType } from "../bootstrapper";
import { KuikaInfo } from "../components/default_screens/kuika-info";
import { MfeLogin } from "../components/mfe/mfe-login";
import { MsalLogin } from "../components/msal/msal-login";
import { MsalRedirect } from "../components/msal/msal-redirect";
import { WFMailInteraction } from "../screens/wf-mail-interaction";
import { KMainFunctions } from "../shared/utilty/main-functions";

const confJson = require("../shared/json/conf.json");

interface IRouterState {}

export type IRouterType = "HashRouter" | "BrowserRouter";

interface IRouterProps {
  authType: IAuthType;
  routerType?: IRouterType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

declare let window: any;
export class Router extends React.Component<IRouterProps, IRouterState> {
  timerId: any = null;

  componentDidMount = () => {
    window.onfocus = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = true;
    };

    window.onblur = function () {
      if (window.kuika === undefined) {
        window.kuika = {};
      }
      window.kuika.isTabActive = false;
    };

    if (!window.styler_speed) {
      window.styler_speed = 20;
    } else if (window.styler_speed < 500) {
      window.styler_speed += 100;
    }

    if (window?.kuika?.activeKuikaComponent !== undefined) {
      return;
    }
    const runTimeout = () => {
      this.timerId = setTimeout(() => {
        const allFormItems: NodeListOf<HTMLElement> = document.querySelectorAll(".kFormItem");
        if (allFormItems) {
          allFormItems.forEach((formItem) => {
            if (formItem.getAttribute("data-display-affected") === "true") {
              return;
            }
            const antFormItemControlInputContent: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content"
            );
            const antFormItemControlInput: HTMLElement = formItem.querySelector(
              ".ant-form-item-control .ant-form-item-control-input"
            );
            const antFormItemControl: HTMLElement = formItem.querySelector(".ant-form-item-control");

            if (
              antFormItemControlInputContent &&
              antFormItemControlInputContent.children &&
              antFormItemControlInputContent.children.length > 0
            ) {
              const formChild: HTMLElement = antFormItemControlInputContent.children[0] as HTMLElement;
              let calculatedDisplay: string = formChild.style.display;
              if (
                calculatedDisplay &&
                antFormItemControl &&
                antFormItemControlInput &&
                antFormItemControlInputContent
              ) {
                if (calculatedDisplay === "inline-flex" || calculatedDisplay === "inline-block") {
                  calculatedDisplay = "";
                  formItem.style.width = formChild.style.width ?? "auto";
                  formItem.style.display = "inline-block";
                  formItem.setAttribute("data-display-affected", "true");
                }
              }
            }
          });
        }
        this.componentDidMount();
      }, window.styler_speed);
    };

    runTimeout();

    setTimeout(() => {
      clearTimeout(this.timerId);
    }, 10000);
  };

  render() {
    const KuikaRouter = this.KuikaRouter();
    return (
      <>
        <KuikaRouter>
          <Switch key="Switch">
            <PrivateRoute exact path="/" component={Dashboard_Screen} />
                        <PrivateRoute exact path="/testfilter" component={TestFilter_Screen} />
            <PrivateRoute exact path="/myschedule" component={MySchedule_Screen} />
            <PrivateRoute exact path="/protokolaltbilesenf" component={ProtokolAltBilesenF_Screen} />
            <PrivateRoute exact path="/kirilim2list" component={Kirilim2List_Screen} />
            <PrivateRoute exact path="/altbilesentestler" component={AltBilesenTestler_Screen} />
            <PrivateRoute exact path="/deneme" component={Deneme_Screen} />
            <PrivateRoute exact path="/testf2" component={TestF2_Screen} />
            <Route exact path="/xxx" component={Xxx_Screen} />
            <PrivateRoute exact path="/tests" component={Tests_Screen} />
            <PrivateRoute exact path="/resources" component={Resources_Screen} />
            <PrivateRoute exact path="/kaynaklar" component={Kaynaklar_Screen} />
            <PrivateRoute exact path="/checkupreport" component={CheckupReport_Screen} />
            <PrivateRoute exact path="/randevuselfservice2" component={RandevuSelfService2_Screen} />
            <PrivateRoute exact path="/ektestgecmisi" component={EkTestGecmisi_Screen} />
            <PrivateRoute exact path="/myschedule2" component={MySchedule2_Screen} />
            <PrivateRoute exact path="/sinifyeniogrencisezon" component={SinifYeniOgrenciSezon_Screen} />
            <PrivateRoute exact path="/myprofile" component={MyProfile_Screen} />
            <PrivateRoute exact path="/normdetay" component={NormDetay_Screen} />
            <PrivateRoute exact path="/testf" component={TestF_Screen} />
            <PrivateRoute exact path="/checkupdonemiform" component={CheckupDonemiForm_Screen} />
            <Route exact path="/parqformcopy" component={ParQFormcopy_Screen} />
            <PrivateRoute exact path="/services" component={Services_Screen} />
            <Route exact path="/parqform" component={ParQForm_Screen} />
            <PrivateRoute exact path="/basvurukurumdetay" component={BasvuruKurumDetay_Screen} />
            <PrivateRoute exact path="/mailtest" component={MailTest_Screen} />
            <PrivateRoute exact path="/personconnectedlist" component={PersonConnectedList_Screen} />
            <PrivateRoute exact path="/classification" component={Classification_Screen} />
            <PrivateRoute exact path="/myscheduleburhan" component={MyScheduleBurhan_Screen} />
            <PrivateRoute exact path="/simay" component={Simay_Screen} />
            <PrivateRoute exact path="/randevufromegitmen2" component={RandevuFromEgitmen2_Screen} />
            <PrivateRoute exact path="/kurumtipiform" component={KurumTipiForm_Screen} />
            <PrivateRoute exact path="/kvkkaydinlatmasozlesmesi" component={KVKKAydinlatmaSozlesmesi_Screen} />
            <PrivateRoute exact path="/krediyukle" component={KrediYukle_Screen} />
            <PrivateRoute exact path="/servicef" component={ServiceF_Screen} />
            <PrivateRoute exact path="/randevuselfservice3" component={RandevuSelfService3_Screen} />
            <PrivateRoute exact path="/statisticsfitskordetay" component={StatisticsFitskorDetay_Screen} />
            <PrivateRoute exact path="/sinifyeniogrenci" component={SinifYeniOgrenci_Screen} />
            <PrivateRoute exact path="/norms" component={Norms_Screen} />
            <PrivateRoute exact path="/checkupreportcopycopy" component={CheckupReportcopycopy_Screen} />
            <Route exact path="/verificationcode" component={VerificationCode_Screen} />
            <Route exact path="/resetpassword" component={ResetPassword_Screen} />
            <PrivateRoute exact path="/mesaisaatiform" component={MesaiSaatiForm_Screen} />
            <PrivateRoute exact path="/protokolaltbilesenf2" component={ProtokolAltBilesenF2_Screen} />
            <Route exact path="/kurumbasvuruform" component={KurumBasvuruForm_Screen} />
            <PrivateRoute exact path="/protocol2" component={Protocol2_Screen} />
            <PrivateRoute exact path="/bilesenaltbilesenlerilist" component={BilesenAltBilesenleriList_Screen} />
            <PrivateRoute exact path="/personcopy" component={Personcopy_Screen} />
            <PrivateRoute exact path="/protocolbilesenf2" component={ProtocolBilesenF2_Screen} />
            <PrivateRoute exact path="/takvimkapat" component={TakvimKapat_Screen} />
            <PrivateRoute exact path="/checkupreportcopy" component={CheckupReportcopy_Screen} />
            <PrivateRoute exact path="/populasyonform" component={PopulasyonForm_Screen} />
            <PrivateRoute exact path="/invitation" component={Invitation_Screen} />
            <PrivateRoute exact path="/profiles" component={Profiles_Screen} />
            <PrivateRoute exact path="/protocols" component={Protocols_Screen} />
            <PrivateRoute exact path="/composant" component={Composant_Screen} />
            <PrivateRoute exact path="/egitmenayarlari" component={EgitmenAyarlari_Screen} />
            <PrivateRoute exact path="/leftmenu" component={LeftMenu_Screen} />
            <PrivateRoute exact path="/settings" component={Settings_Screen} />
            <PrivateRoute exact path="/egitmensozlesmesiyeni" component={EgitmenSozlesmesiYeni_Screen} />
            <PrivateRoute exact path="/checkupf" component={CheckupF_Screen} />
            <PrivateRoute exact path="/dashboardbilesendetay" component={DashboardBilesenDetay_Screen} />
            <PrivateRoute exact path="/basvuruegitmendetay" component={BasvuruEgitmenDetay_Screen} />
            <PrivateRoute exact path="/statisticsdetay" component={StatisticsDetay_Screen} />
            <PrivateRoute exact path="/populasyonlist" component={PopulasyonList_Screen} />
            <PrivateRoute exact path="/normdetaylist" component={NormDetayList_Screen} />
            <PrivateRoute exact path="/randevufromegitmen" component={RandevuFromEgitmen_Screen} />
            <PrivateRoute exact path="/randevuselfservice" component={RandevuSelfService_Screen} />
            <PrivateRoute exact path="/personcard" component={PersonCard_Screen} />
            <PrivateRoute exact path="/organisationcard" component={OrganisationCard_Screen} />
            <PrivateRoute exact path="/normdetaykopyala" component={NormDetayKopyala_Screen} />
            <PrivateRoute exact path="/slotgenerator" component={SlotGenerator_Screen} />
            <PrivateRoute exact path="/organisation" component={Organisation_Screen} />
            <PrivateRoute exact path="/kirilim1list" component={Kirilim1List_Screen} />
            <PrivateRoute exact path="/sinifinogrencileri" component={SinifinOgrencileri_Screen} />
            <PrivateRoute exact path="/persons" component={Persons_Screen} />
            <PrivateRoute exact path="/statistics" component={Statistics_Screen} />
            <PrivateRoute exact path="/checkupreport3" component={CheckupReport3_Screen} />
            <PrivateRoute exact path="/topluolcumlistsinif" component={TopluOlcumListSinif_Screen} />
            <PrivateRoute exact path="/testdeneme2test" component={TestDeneme2Test_Screen} />
            <PrivateRoute exact path="/organisations" component={Organisations_Screen} />
            <PrivateRoute exact path="/kurumprofile" component={KurumProfile_Screen} />
            <PrivateRoute exact path="/myreports" component={MyReports_Screen} />
            <PrivateRoute exact path="/egitmensozlesmesi" component={EgitmenSozlesmesi_Screen} />
            <PrivateRoute exact path="/profile" component={Profile_Screen} />
            <PrivateRoute exact path="/composants" component={Composants_Screen} />
            <PrivateRoute exact path="/randevuslot" component={RandevuSlot_Screen} />
            <PrivateRoute exact path="/invitations" component={Invitations_Screen} />
            <PrivateRoute exact path="/dashboard" component={Dashboard_Screen} />
            <PrivateRoute exact path="/siniflandirmaseviye" component={SiniflandirmaSeviye_Screen} />
            <PrivateRoute exact path="/checkupdonemlist" component={CheckupDonemList_Screen} />
            <PrivateRoute exact path="/mesaisaatiform2" component={MesaiSaatiForm2_Screen} />
            <Route exact path="/signup" component={Signup_Screen} />
            <PrivateRoute exact path="/protokolbilesenf" component={ProtokolBilesenF_Screen} />
            <PrivateRoute exact path="/person" component={Person_Screen} />
            <PrivateRoute exact path="/kurumsozlesmesi" component={KurumSozlesmesi_Screen} />
            <PrivateRoute exact path="/protokolbilesenlist" component={ProtokolBilesenList_Screen} />
            <Route exact path="/toplucheckupform" component={TopluCheckupForm_Screen} />
            <PrivateRoute exact path="/basvurular" component={Basvurular_Screen} />
            <PrivateRoute exact path="/randevufrommerkez" component={RandevuFromMerkez_Screen} />
            <PrivateRoute exact path="/danisansozlesmesiyeni" component={DanisanSozlesmesiYeni_Screen} />
            <PrivateRoute exact path="/myagenda" component={MyAgenda_Screen} />
            <Route exact path="/signin" component={this.LoginComponent()} />
            <PrivateRoute exact path="/ogretmensiniflari" component={OgretmenSiniflari_Screen} />
            <PrivateRoute exact path="/personconnected" component={PersonConnected_Screen} />
            <PrivateRoute exact path="/kurumtipilist" component={KurumTipiList_Screen} />
            <Route exact path="/merkezbasvuruform" component={MerkezBasvuruForm_Screen} />
            <Route exact path="/forgotpassword" component={ForgotPassword_Screen} />
            <PrivateRoute exact path="/altbilesenform" component={AltBilesenForm_Screen} />
            <PrivateRoute exact path="/egitmenayarlaricopy" component={EgitmenAyarlaricopy_Screen} />
            <PrivateRoute exact path="/randevufrommerkez2" component={RandevuFromMerkez2_Screen} />
            <PrivateRoute exact path="/siniflandirmaseviyelist" component={SiniflandirmaSeviyeList_Screen} />
            <PrivateRoute exact path="/checkuppasta" component={CheckupPasta_Screen} />
            <PrivateRoute exact path="/ektestf" component={EkTestF_Screen} />
            <PrivateRoute exact path="/kirilim1form" component={Kirilim1Form_Screen} />
            <PrivateRoute exact path="/homepage" component={HomePage_Screen} />
            <Route exact path="/egitmenbasvuruformu" component={EgitmenBasvuruFormu_Screen} />
            <PrivateRoute exact path="/protokolaltbilesenler" component={ProtokolAltBilesenler_Screen} />
            <PrivateRoute exact path="/kirilim2form" component={Kirilim2Form_Screen} />
            <PrivateRoute exact path="/randevusummary" component={RandevuSummary_Screen} />
            <Route exact path="/signinpro" component={SigninPro_Screen} />
            <PrivateRoute exact path="/protocol" component={Protocol_Screen} />
            <PrivateRoute exact path="/kreditransferi" component={KrediTransferi_Screen} />
            <PrivateRoute exact path="/basvurumerkezdetay" component={BasvuruMerkezDetay_Screen} />
            <PrivateRoute exact path="/norm" component={Norm_Screen} />
            <PrivateRoute exact path="/classifications" component={Classifications_Screen} />
            <PrivateRoute exact path="/dashboardkatilimdetay" component={DashboardKatilimDetay_Screen} />
            <PrivateRoute exact path="/testdenemedegiskenf" component={TestDenemeDegiskenF_Screen} />
            <PrivateRoute exact path="/testdegisken" component={TestDegisken_Screen} />
            <PrivateRoute exact path="/protocol3" component={Protocol3_Screen} />

            <Route path="/redirect" component={Redirect} />
            <Route path={"/login"} component={this.LoginComponent()} />
            
            
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/kuikainfo" component={KuikaInfo} />
            <Route exact path="/msalredirect" component={MsalRedirect} />
            <Route exact path="/wfmailinteraction" component={WFMailInteraction} />
            <Route path="*" component={NoMatch404} />
          </Switch>
        </KuikaRouter>
      </>
    );
  }

  KuikaRouter = () => {
    // Web component case
    let isWebComponentApp: any;
    isWebComponentApp = this.props.appMode === "WebComponent";
    if (
      confJson?.domainName !== undefined &&
      confJson?.applicationName !== undefined &&
      confJson?.applicationName !== "" &&
      window.kuika !== undefined &&
      window.kuika?.apps !== undefined &&
      window.kuika?.apps.length !== undefined &&
      window.kuika?.apps.length > 0
    ) {
      window.kuika?.apps.forEach((app: any) => {
        if (app.appName === confJson.applicationName && app.workspace === confJson.domainName) {
          isWebComponentApp = true;
        }
      });
    }
    if (window.kuika === undefined) {
      window.kuika = {};
    }
    window.kuika.isWebComponentApp = isWebComponentApp;

    if (isWebComponentApp === true || this.props.routerType === "HashRouter") {
      return (props: any) => {
        return <HashRouter key="HashRouter">{props.children}</HashRouter>;
      };
    }
    return (props: any) => {
      if (KMainFunctions.virtualDirectoryURL === "auto" || window?.location?.protocol === "file:") {
        return <BrowserRouter key="BrowserRouter">{props.children}</BrowserRouter>;
      }
      return (
        <BrowserRouter basename={KMainFunctions.virtualDirectoryURL} key="BrowserRouter">
          {props.children}
        </BrowserRouter>
      );
    };
  };

  LoginComponent = () => {
    if (this.props.authType === "MSAL") {
      return MsalLogin;
    }

    const { external_access_token } = this.props;
    const { external_refresh_token } = this.props;
    const { external_user } = this.props;
    if (
      external_access_token !== null &&
      external_access_token !== undefined &&
      external_access_token !== "" &&
      external_refresh_token !== null &&
      external_refresh_token !== undefined &&
      external_refresh_token !== ""
    ) {
      return (props: any) => {
        return (
          <MfeLogin
            external_user={external_user}
            external_access_token={external_access_token}
            external_refresh_token={external_refresh_token}
          ></MfeLogin>
        );
      };
    }

    if (this.props.authType === "Kuika") {
      return Signin_Screen;
    }
    return <></>;
  };
}
