import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IBasvuruMerkezDetay_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IBasvuruMerkezDetay_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  SelectBasvuruMerkez: any[];
  SelectBasvuruMerkez_dummy: any[];
  KurumunEgitmenleri: any[];
  KurumunEgitmenleri_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  BasvuruMerkeziReddet: number;
  BasvuruMerkeziReddet_dummy: number;
  BasvuruMerkezOnayla: number;
  BasvuruMerkezOnayla_dummy: number;
  KUserByEposta: any[];
  KUserByEposta_dummy: any[];
  CreateUser: string;
  CreateUser_dummy: string;
  KisiByEmail: any[];
  KisiByEmail_dummy: any[];
  UserByEmail: any[];
  UserByEmail_dummy: any[];
  TokenOfUser: any[];
  TokenOfUser_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  BaseUrl: any[];
  BaseUrl_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  isComp3041535Visible: "visible" | "hidden";
  isComp4950217Visible: "visible" | "hidden";
  isComp9986812Visible: "visible" | "hidden";
  isComp4811451Visible: "visible" | "hidden";
  isComp4231359Visible: "visible" | "hidden";
  isComp320520Visible: "visible" | "hidden";
  isComp79349Visible: "visible" | "hidden";
  isComp931489Visible: "visible" | "hidden";
  isComp875529Enabled: "enabled" | "disabled";
  isComp709110Enabled: "enabled" | "disabled";
  isComp722655Enabled: "enabled" | "disabled";
  isComp588758Enabled: "enabled" | "disabled";
  isComp120886Enabled: "enabled" | "disabled";
  isComp818039Enabled: "enabled" | "disabled";
  isComp259019Enabled: "enabled" | "disabled";
  isComp857935Enabled: "enabled" | "disabled";
}

export class BasvuruMerkezDetay_ScreenBase extends React.PureComponent<IBasvuruMerkezDetay_ScreenProps, any> {
  basvurumerkezdetay_448056_value_kuikaMultiSelectRef: React.RefObject<any>;
  basvurumerkezdetay_561869_value_kuikaMultiSelectRef: React.RefObject<any>;
  basvurumerkezdetay_786971_value_kuikaDateRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Location Application", TagName: "lblHead_value" },
        { Id: 106495, PropertyName: "value", Value: "Name", TagName: "Label2_value" },
        { Id: 7826344, PropertyName: "value", Value: "Surname", TagName: "lblAd_7826344_value" },
        { Id: 6002481, PropertyName: "value", Value: "Biological Gender", TagName: "lblCinsiyet_6002481_value" },
        { Id: 7845220, PropertyName: "value", Value: "E-mail", TagName: "lblEposta_7845220_value" },
        { Id: 7097982, PropertyName: "value", Value: "Phone Number", TagName: "lblTelefon_7097982_value" },
        { Id: 5986501, PropertyName: "value", Value: "Location Type", TagName: "lblAdres_5986501_value" },
        { Id: 183108, PropertyName: "value", Value: "Adress", TagName: "label2_value" },
        {
          Id: 1168348,
          PropertyName: "value",
          Value: "Location Phone number",
          TagName: "lblMerkezTelefon_1168348_value"
        },
        { Id: 7125791, PropertyName: "value", Value: "Web adress", TagName: "lblWebAdres_7125791_value" },
        { Id: 9176620, PropertyName: "value", Value: "Operating years", TagName: "lblFaaliyetSure_9176620_value" },
        {
          Id: 6719841,
          PropertyName: "value",
          Value: "Consultation Area with Client",
          TagName: "lblDanisanGorus_6719841_value"
        },
        { Id: 259176, PropertyName: "value", Value: "Blood Pressure Measurement Device", TagName: "Label1_0_value" },
        { Id: 3025032, PropertyName: "value", Value: "Heart Rate Monitor", TagName: "lblNabizOlcer_3025032_value" },
        { Id: 3277773, PropertyName: "value", Value: "Computer", TagName: "lblKanBasinc_3277773_value" },
        {
          Id: 2181343,
          PropertyName: "value",
          Value: "Body Composition Measurement choices",
          TagName: "lblVKO_2181343_value"
        },
        {
          Id: 6168120,
          PropertyName: "value",
          Value: "Cardiorespiratory equipment choices",
          TagName: "lblKardio_6168120_value"
        },
        {
          Id: 9031968,
          PropertyName: "value",
          Value: "Strength and flexibility equipment choices ",
          TagName: "lblKasKuvvet_9031968_value"
        },
        {
          Id: 1919603,
          PropertyName: "value",
          Value: "The services to be provided at the center",
          TagName: "lblHizmet_1919603_value"
        },
        { Id: 3477945, PropertyName: "value", Value: "Group classes", TagName: "lblGrupDers_3477945_value" },
        {
          Id: 1389709,
          PropertyName: "value",
          Value: "Programs related to special populations",
          TagName: "lblPopi_1389709_value"
        },
        { Id: 869552, PropertyName: "value", Value: "Phone number", TagName: "Label6_value" },
        {
          Id: 3966948,
          PropertyName: "value",
          Value: "Other activity areas",
          TagName: "lblAktiviteAlanlari_3966948_value"
        },
        { Id: 226175, PropertyName: "value", Value: "Select your club's sports branches", TagName: "Label1_0_value" },
        {
          Id: 288738,
          PropertyName: "value",
          Value: "Select which students in your school will participate the Fitness Check-Up.",
          TagName: "Label1_0_value"
        },
        {
          Id: 116784,
          PropertyName: "value",
          Value: "Select the physical facilities at your school.",
          TagName: "Label1_0_value"
        },
        {
          Id: 816800,
          PropertyName: "value",
          Value: "Would you like to provide Fitness Check-Up service to students outside your school?",
          TagName: "Label1_1_value"
        },
        {
          Id: 75997,
          PropertyName: "value",
          Value: "Would you like to provide Adult Fitness Check-Up service for parents?",
          TagName: "Label1_1_value"
        },
        {
          Id: 6149798,
          PropertyName: "value",
          Value: "I would like to receive information about the benefits of the MeMap platform.",
          TagName: "lblMemapFayda_6149798_value"
        },
        {
          Id: 5577885,
          PropertyName: "value",
          Value: "Would you like to take part in the MeMap platform?",
          TagName: "lblMemapYer_5577885_value"
        },
        { Id: 806561, PropertyName: "value", Value: "Accredited Services", TagName: "Label1_0_value" },
        { Id: 265894, PropertyName: "value", Value: "Fitness Trainers", TagName: "Label3_value" },
        { Id: 509471, PropertyName: "value", Value: "Rate", TagName: "Label5_value" },
        { Id: 190573, PropertyName: "value", Value: "License Expiration Date", TagName: "Label1_0_value" },
        { Id: 786971, PropertyName: "placeholder", Value: "Choose...", TagName: "dtExpiraion_placeholder" },
        { Id: 685622, PropertyName: "value", Value: "Credit to be uploaded", TagName: "Label4_value" },
        { Id: 944099, PropertyName: "placeholder", Value: "15", TagName: "numCredit_placeholder" },
        { Id: 382167, PropertyName: "value", Value: "Platform view", TagName: "Label1_0_value" },
        { Id: 280183, PropertyName: "label", Value: "Reject", TagName: "Button0_0_label" },
        { Id: 217769, PropertyName: "label", Value: "Approve", TagName: "Button0_0_label" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "c9280922-ed05-4ec7-8676-f38b11636db5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Başvuru Merkez" },
        { Id: 106495, PropertyName: "value", Value: "Ad" },
        { Id: 7826344, PropertyName: "value", Value: "Soyad" },
        { Id: 6002481, PropertyName: "value", Value: "Biyolojik cinsiyet" },
        { Id: 7845220, PropertyName: "value", Value: "E-posta" },
        { Id: 869552, PropertyName: "value", Value: "Telefon" },
        { Id: 7097982, PropertyName: "value", Value: "Doğum tarihi" },
        { Id: 5986501, PropertyName: "value", Value: "Merkezin Türü" },
        { Id: 183108, PropertyName: "value", Value: "Adres" },
        { Id: 1168348, PropertyName: "value", Value: "Merkez Telefonu" },
        { Id: 7125791, PropertyName: "value", Value: "Web Adresi" },
        { Id: 9176620, PropertyName: "value", Value: "Faaliyet Süresi" },
        { Id: 6719841, PropertyName: "value", Value: "Danışan ile Görüşme Alanı" },
        { Id: 259176, PropertyName: "value", Value: "Kan Basıncı Ölçüm Cihazı" },
        { Id: 3025032, PropertyName: "value", Value: "Nabız Ölçer" },
        { Id: 3277773, PropertyName: "value", Value: "Bilgisayar" },
        { Id: 2181343, PropertyName: "value", Value: "Vücut kompozisyonu ölçüm olanakları" },
        { Id: 6168120, PropertyName: "value", Value: "Kardiyorespiratuar ekipman olanakları" },
        { Id: 9031968, PropertyName: "value", Value: "Kas kuvveti ve esneklik ekipman olanakları" },
        { Id: 1919603, PropertyName: "value", Value: "Merkezde verilmek istenen hizmetler" },
        { Id: 3477945, PropertyName: "value", Value: "Grup dersleri" },
        { Id: 1389709, PropertyName: "value", Value: "Özel popülasyonlarla ilgili programlar" },
        { Id: 3966948, PropertyName: "value", Value: "Diğer aktivite alanları" },
        { Id: 226175, PropertyName: "value", Value: "Branş" },
        {
          Id: 288738,
          PropertyName: "value",
          Value: "Okulunuzda hangi öğrencilere Fitness Check-Up yapılacağını seçiniz."
        },
        { Id: 116784, PropertyName: "value", Value: "Okulunuzdaki fiziksel olanakları seçiniz." },
        {
          Id: 816800,
          PropertyName: "value",
          Value: "Okulunuz dışındaki öğrencilere Fitness Check-Up hizmeti vermek istermisiniz?"
        },
        {
          Id: 75997,
          PropertyName: "value",
          Value: "Velilere yönelik Yetişkin Fitness Check-Up hizmeti vermek ister misiniz?"
        },
        { Id: 6149798, PropertyName: "value", Value: "MeMap platformunun faydaları hakkında bilgi almak istiyorum." },
        { Id: 5577885, PropertyName: "value", Value: "MeMap platformunda yer almak ister misiniz?" },
        { Id: 806561, PropertyName: "value", Value: "Akredite Hizmetler" },
        { Id: 448056, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 265894, PropertyName: "value", Value: "Fitness Eğitmenleri" },
        { Id: 561869, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 509471, PropertyName: "value", Value: "Rate" },
        { Id: 190573, PropertyName: "value", Value: "Lisans Bitiş Tarihi" },
        { Id: 786971, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 685622, PropertyName: "value", Value: "Yüklenecek Kredi" },
        { Id: 944099, PropertyName: "placeholder", Value: "15" },
        { Id: 382167, PropertyName: "value", Value: "Platform Görünümü" },
        { Id: 280183, PropertyName: "label", Value: "Reddet" },
        { Id: 217769, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.basvurumerkezdetay_448056_value_kuikaMultiSelectRef = React.createRef();
    this.basvurumerkezdetay_561869_value_kuikaMultiSelectRef = React.createRef();
    this.basvurumerkezdetay_786971_value_kuikaDateRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      SelectBasvuruMerkez: [],
      KurumunEgitmenleri: [],
      ServiceAll: [],
      ChangeEnabledOf: "",
      BasvuruMerkeziReddet: 0,
      BasvuruMerkezOnayla: 0,
      KUserByEposta: [],
      CreateUser: "",
      KisiByEmail: [],
      UserByEmail: [],
      TokenOfUser: [],
      SaveRecord: false,
      BaseUrl: [],
      IfThenElse: false,
      isComp3041535Visible: "hidden",
      isComp4950217Visible: "hidden",
      isComp9986812Visible: "hidden",
      isComp4811451Visible: "hidden",
      isComp4231359Visible: "hidden",
      isComp320520Visible: "hidden",
      isComp79349Visible: "hidden",
      isComp931489Visible: "hidden",
      isComp875529Enabled: "enabled",
      isComp709110Enabled: "enabled",
      isComp722655Enabled: "enabled",
      isComp588758Enabled: "enabled",
      isComp120886Enabled: "enabled",
      isComp818039Enabled: "enabled",
      isComp259019Enabled: "enabled",
      isComp857935Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("basvurumerkezdetay", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.BasvuruMerkezDetayPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("basvurumerkezdetay", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("basvurumerkezdetay", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.BasvuruMerkezDetayPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      basvurumerkezdetay_2457184_value: this.state.SelectBasvuruMerkez?.at?.(0)?.logo ?? undefined,
      basvurumerkezdetay_3088491_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoBir ?? undefined,
      basvurumerkezdetay_5580946_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoiki ?? undefined,
      basvurumerkezdetay_9057722_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoUc ?? undefined,
      basvurumerkezdetay_7666951_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoDort ?? undefined,
      basvurumerkezdetay_4888150_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoBes ?? undefined,
      basvurumerkezdetay_2478302_value: this.state.SelectBasvuruMerkez?.at?.(0)?.mekanFotoAlti ?? undefined,
      basvurumerkezdetay_722655_value: this.state.SelectBasvuruMerkez?.at?.(0)?.danisanGorus ?? undefined,
      basvurumerkezdetay_875529_value: this.state.SelectBasvuruMerkez?.at?.(0)?.kanBasinc ?? undefined,
      basvurumerkezdetay_120886_value: this.state.SelectBasvuruMerkez?.at?.(0)?.nabizOlcer ?? undefined,
      basvurumerkezdetay_709110_value: this.state.SelectBasvuruMerkez?.at?.(0)?.bilgisayar ?? undefined,
      basvurumerkezdetay_259019_value: this.state.SelectBasvuruMerkez?.at?.(0)?.okulFitness ?? undefined,
      basvurumerkezdetay_857935_value: this.state.SelectBasvuruMerkez?.at?.(0)?.okulVei ?? undefined,
      basvurumerkezdetay_588758_value: this.state.SelectBasvuruMerkez?.at?.(0)?.memapFayda ?? undefined,
      basvurumerkezdetay_818039_value: this.state.SelectBasvuruMerkez?.at?.(0)?.memapYer ?? undefined,
      basvurumerkezdetay_113142_value: 3,
      basvurumerkezdetay_944099_value: undefined,
      basvurumerkezdetay_532262_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  BasvuruMerkezDetayPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_1: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      kurumId_2: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "BasvuruMerkezDetay/BasvuruMerkezDetayPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.SelectBasvuruMerkez = result?.data.selectBasvuruMerkez;
    formVars.basvurumerkezdetay_5165577_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].merkezAdi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].merkezAdi
        : null
    );
    formVars.basvurumerkezdetay_2457184_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].logo
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].logo
        : null
    );
    formVars.basvurumerkezdetay_3088491_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoBir
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoBir
        : null
    );
    formVars.basvurumerkezdetay_5580946_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoiki
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoiki
        : null
    );
    formVars.basvurumerkezdetay_9057722_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoUc
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoUc
        : null
    );
    formVars.basvurumerkezdetay_7666951_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoDort
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoDort
        : null
    );
    formVars.basvurumerkezdetay_4888150_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoBes
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoBes
        : null
    );
    formVars.basvurumerkezdetay_2478302_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoAlti
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoAlti
        : null
    );
    formVars.basvurumerkezdetay_1185662_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].ad
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].ad
        : null
    );
    formVars.basvurumerkezdetay_9976439_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].soyad
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].soyad
        : null
    );
    formVars.basvurumerkezdetay_3654551_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].cinsiyet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].cinsiyet
        : null
    );
    formVars.basvurumerkezdetay_5417189_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].eposta
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].eposta
        : null
    );
    formVars.basvurumerkezdetay_723543_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].telefon
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].telefon
        : null
    );
    formVars.basvurumerkezdetay_4307517_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].dogumTarihi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].dogumTarihi
        : null
    );
    formVars.basvurumerkezdetay_3509379_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].tip
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].tip
        : null
    );
    formVars.basvurumerkezdetay_844489_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].adres
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].adres
        : null
    );
    formVars.basvurumerkezdetay_2570251_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].merkezTelefon
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].merkezTelefon
        : null
    );
    formVars.basvurumerkezdetay_7970217_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].webAdres
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].webAdres
        : null
    );
    formVars.basvurumerkezdetay_5193664_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].faaliyetSure
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].faaliyetSure
        : null
    );
    formVars.basvurumerkezdetay_722655_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].danisanGorus
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].danisanGorus
        : null
    );
    formVars.basvurumerkezdetay_875529_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kanBasinc
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kanBasinc
        : null
    );
    formVars.basvurumerkezdetay_120886_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].nabizOlcer
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].nabizOlcer
        : null
    );
    formVars.basvurumerkezdetay_709110_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].bilgisayar
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].bilgisayar
        : null
    );
    formVars.basvurumerkezdetay_6213710_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].vko
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].vko
        : null
    );
    formVars.basvurumerkezdetay_9003611_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kardio
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kardio
        : null
    );
    formVars.basvurumerkezdetay_7082853_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kasKuvvet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kasKuvvet
        : null
    );
    formVars.basvurumerkezdetay_4997480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].hizmet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].hizmet
        : null
    );
    formVars.basvurumerkezdetay_9928368_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].grupDers
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].grupDers
        : null
    );
    formVars.basvurumerkezdetay_2432978_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].popi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].popi
        : null
    );
    formVars.basvurumerkezdetay_8526448_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].aktiviteAlanlari
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].aktiviteAlanlari
        : null
    );
    formVars.basvurumerkezdetay_715942_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].bransTanim
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].bransTanim
        : null
    );
    formVars.basvurumerkezdetay_263643_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulOgrenci
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulOgrenci
        : null
    );
    formVars.basvurumerkezdetay_427457_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulFizikselOlanak
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulFizikselOlanak
        : null
    );
    formVars.basvurumerkezdetay_259019_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulFitness
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulFitness
        : null
    );
    formVars.basvurumerkezdetay_857935_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulVei
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulVei
        : null
    );
    formVars.basvurumerkezdetay_588758_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].memapFayda
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].memapFayda
        : null
    );
    formVars.basvurumerkezdetay_818039_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].memapYer
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].memapYer
        : null
    );
    stateVars.isComp3041535Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp4950217Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9986812Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp4811451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Fitness Merkezi"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp4231359Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Fitness Merkezi"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp320520Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Spor Kulübü"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp79349Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp931489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KurumunEgitmenleri = result?.data.kurumunEgitmenleri;

    formVars.basvurumerkezdetay_561869_options = stateVars.KurumunEgitmenleri;
    stateVars.ServiceAll = result?.data.serviceAll;

    formVars.basvurumerkezdetay_448056_options = stateVars.ServiceAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruMerkezDetayPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruMerkezDetayPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp875529Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp709110Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp722655Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp588758Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp120886Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp818039Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp259019Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp857935Enabled", "disabled");
    formVars.basvurumerkezdetay_5165577_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].merkezAdi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].merkezAdi
        : null
    );

    formVars.basvurumerkezdetay_2457184_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].logo
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].logo
        : null
    );

    formVars.basvurumerkezdetay_3088491_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoBir
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoBir
        : null
    );

    formVars.basvurumerkezdetay_5580946_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoiki
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoiki
        : null
    );

    formVars.basvurumerkezdetay_9057722_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoUc
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoUc
        : null
    );

    formVars.basvurumerkezdetay_7666951_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoDort
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoDort
        : null
    );

    formVars.basvurumerkezdetay_4888150_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoBes
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoBes
        : null
    );

    formVars.basvurumerkezdetay_2478302_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].mekanFotoAlti
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].mekanFotoAlti
        : null
    );

    formVars.basvurumerkezdetay_1185662_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].ad
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].ad
        : null
    );

    formVars.basvurumerkezdetay_9976439_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].soyad
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].soyad
        : null
    );

    formVars.basvurumerkezdetay_3654551_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].cinsiyet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].cinsiyet
        : null
    );

    formVars.basvurumerkezdetay_5417189_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].eposta
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].eposta
        : null
    );

    formVars.basvurumerkezdetay_723543_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].telefon
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].telefon
        : null
    );

    formVars.basvurumerkezdetay_4307517_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].dogumTarihi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].dogumTarihi
        : null
    );

    formVars.basvurumerkezdetay_3509379_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].tip
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].tip
        : null
    );

    formVars.basvurumerkezdetay_844489_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].adres
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].adres
        : null
    );

    formVars.basvurumerkezdetay_2570251_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].merkezTelefon
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].merkezTelefon
        : null
    );

    formVars.basvurumerkezdetay_7970217_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].webAdres
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].webAdres
        : null
    );

    formVars.basvurumerkezdetay_5193664_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].faaliyetSure
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].faaliyetSure
        : null
    );

    stateVars.isComp3041535Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_722655_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].danisanGorus
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].danisanGorus
        : null
    );

    formVars.basvurumerkezdetay_875529_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kanBasinc
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kanBasinc
        : null
    );

    formVars.basvurumerkezdetay_120886_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].nabizOlcer
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].nabizOlcer
        : null
    );

    formVars.basvurumerkezdetay_709110_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].bilgisayar
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].bilgisayar
        : null
    );

    stateVars.isComp4950217Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_6213710_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].vko
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].vko
        : null
    );

    formVars.basvurumerkezdetay_9003611_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kardio
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kardio
        : null
    );

    stateVars.isComp9986812Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_7082853_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].kasKuvvet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].kasKuvvet
        : null
    );

    formVars.basvurumerkezdetay_4997480_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].hizmet
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].hizmet
        : null
    );

    stateVars.isComp4811451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Fitness Merkezi"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_9928368_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].grupDers
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].grupDers
        : null
    );

    formVars.basvurumerkezdetay_2432978_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].popi
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].popi
        : null
    );

    stateVars.isComp4231359Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Fitness Merkezi"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_8526448_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].aktiviteAlanlari
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].aktiviteAlanlari
        : null
    );

    stateVars.isComp320520Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Spor Kulübü"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_715942_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].bransTanim
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].bransTanim
        : null
    );

    stateVars.isComp79349Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_263643_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulOgrenci
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulOgrenci
        : null
    );

    formVars.basvurumerkezdetay_427457_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulFizikselOlanak
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulFizikselOlanak
        : null
    );

    stateVars.isComp931489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].tip
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].tip
            : null
        ),
        "Okul"
      ) === true
        ? "visible"
        : "hidden";
    formVars.basvurumerkezdetay_259019_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulFitness
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulFitness
        : null
    );

    formVars.basvurumerkezdetay_857935_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].okulVei
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].okulVei
        : null
    );

    formVars.basvurumerkezdetay_588758_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].memapFayda
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].memapFayda
        : null
    );

    formVars.basvurumerkezdetay_818039_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruMerkez?.length > 0
        ? stateVars.SelectBasvuruMerkez[0].memapYer
        : this.state.SelectBasvuruMerkez?.length > 0
        ? this.state.SelectBasvuruMerkez[0].memapYer
        : null
    );

    stateVars.dataSource_448056 = this.state.ServiceAll;
    stateVars.dataSource_448056 = this.state.ServiceAll;

    stateVars.dataSource_561869 = this.state.KurumunEgitmenleri;
    stateVars.dataSource_561869 = this.state.KurumunEgitmenleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  BasvuruMerkezDetayComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruMerkezDetayComponent_280183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruMerkezId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruMerkezDetay/BasvuruMerkezDetayComponent_280183_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruMerkeziReddet = result?.data.basvuruMerkeziReddet;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruMerkezDetayComponent_280183_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruMerkezDetayComponent_280183_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  BasvuruMerkezDetayComponent_217769_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_448056_value", "value", "ServiceAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurumerkezdetay_448056_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_113142_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurumerkezdetay_113142_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_786971_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurumerkezdetay_786971_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_944099_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "basvurumerkezdetay_944099_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      basvuruMerkezId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.Id ?? this.props.screenInputs.id,
        "Guid"
      ),
      akrediteHizmetIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_448056_value", "value", "ServiceAll", "id", "id")
        ),
        "string"
      ),
      fitnesEgitmenleriIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_561869_value", "value", "KurumunEgitmenleri", "id", "")
        ),
        "string"
      ),
      rate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_113142_value", "value", "", "", "")
        ),
        "number"
      ),
      licenceExpirationDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_786971_value", "value", "", "", "")
        ),
        "Date"
      ),
      credit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_944099_value", "value", "", "", "")
        ),
        "number"
      ),
      platformGorunumu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "basvurumerkezdetay_532262_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Email_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruMerkezDetay/BasvuruMerkezDetayComponent_217769_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.BasvuruMerkezOnayla = result?.data.basvuruMerkezOnayla;
    stateVars.KUserByEposta = result?.data.kUserByEposta;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruMerkezDetayComponent_217769_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      stateVars.CreateUser = await ReactSystemFunctions.createUser(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].ad
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].ad
            : null
        ),
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].soyad
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].soyad
            : null
        ),
        true,
        "",
        ""
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  BasvuruMerkezDetayComponent_217769_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      email_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      ),
      email_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruMerkezDetay/BasvuruMerkezDetayComponent_217769_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiByEmail = result?.data.kisiByEmail;
    stateVars.UserByEmail = result?.data.userByEmail;
    stateVars.TokenOfUser = result?.data.tokenOfUser;
    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.BaseUrl = result?.data.baseUrl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  BasvuruMerkezDetayComponent_217769_onClick_if3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("912b1e84-8a2a-478a-86ce-4cffc534c1aa", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap İş Hesabın Açıldı", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      ),
      verificationCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.TokenOfUser?.length > 0
            ? stateVars.TokenOfUser[0].token
            : this.state.TokenOfUser?.length > 0
            ? this.state.TokenOfUser[0].token
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].kurumImgUrl
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruMerkezDetay/BasvuruMerkezDetayComponent_217769_onClick_if3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruMerkezDetayComponent_217769_onClick_else3 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("55f69deb-a389-4d15-9c8b-a84d0d8b1e95", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap Yeni İş Hesabın Açıldı", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      baseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.BaseUrl?.length > 0
            ? stateVars.BaseUrl[0].baseUrl
            : this.state.BaseUrl?.length > 0
            ? this.state.BaseUrl[0].baseUrl
            : null
        ),
        "string"
      ),
      username_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].eposta
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].eposta
            : null
        ),
        "string"
      ),
      language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      kurumlogo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruMerkez?.length > 0
            ? stateVars.SelectBasvuruMerkez[0].kurumImgUrl
            : this.state.SelectBasvuruMerkez?.length > 0
            ? this.state.SelectBasvuruMerkez[0].kurumImgUrl
            : null
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "BasvuruMerkezDetay/BasvuruMerkezDetayComponent_217769_onClick_else3",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  BasvuruMerkezDetayComponent_217769_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KUserByEposta?.length > 0
            ? stateVars.KUserByEposta[0].id
            : this.state.KUserByEposta?.length > 0
            ? this.state.KUserByEposta[0].id
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick_if3();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.BasvuruMerkezDetayComponent_217769_onClick_else3();
      if (isErrorOccurred) return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
