import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IParQForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IParQForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  FormParQById: any[];
  FormParQById_dummy: any[];
  FormParQSoru3ById: any[];
  FormParQSoru3ById_dummy: any[];
  FormParQSoru6ById: any[];
  FormParQSoru6ById_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ToggleVisibilityOf: string;
  ToggleVisibilityOf_dummy: string;
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  Divide: number;
  Divide_dummy: number;
  FormParQBelirtiSemptom: any[];
  FormParQBelirtiSemptom_dummy: any[];
  FormParQSonuc: any[];
  FormParQSonuc_dummy: any[];
  FormParQSave: number;
  FormParQSave_dummy: number;
  FormParQSoru3Save: number;
  FormParQSoru3Save_dummy: number;
  FormParQSoru6Save: number;
  FormParQSoru6Save_dummy: number;
  isComp26011Visible: "visible" | "hidden";
  isComp329331Visible: "visible" | "hidden";
  isComp851647Visible: "visible" | "hidden";
  isComp319278Visible: "visible" | "hidden";
  isComp127283Visible: "visible" | "hidden";
  isComp80854Visible: "visible" | "hidden";
  isComp217409Visible: "visible" | "hidden";
  isComp210725Visible: "visible" | "hidden";
  isComp673097Visible: "visible" | "hidden";
  isComp23734Visible: "visible" | "hidden";
  isComp707761Visible: "visible" | "hidden";
  isComp177991Visible: "visible" | "hidden";
  isComp911981Visible: "visible" | "hidden";
  isComp710369Visible: "visible" | "hidden";
  isComp714589Visible: "visible" | "hidden";
  isComp118539Visible: "visible" | "hidden";
  isComp696700Visible: "visible" | "hidden";
  isComp821128Visible: "visible" | "hidden";
  isComp532130Visible: "visible" | "hidden";
  isComp882479Visible: "visible" | "hidden";
  isComp503462Visible: "visible" | "hidden";
  isComp311406Visible: "visible" | "hidden";
  isComp761377Visible: "visible" | "hidden";
  isComp50002Visible: "visible" | "hidden";
  isComp629359Visible: "visible" | "hidden";
  isComp449366Visible: "visible" | "hidden";
  isComp273435Visible: "visible" | "hidden";
  isComp688878Visible: "visible" | "hidden";
  isComp892138Visible: "visible" | "hidden";
  isComp502091Visible: "visible" | "hidden";
  isComp165687Visible: "visible" | "hidden";
  isComp164785Visible: "visible" | "hidden";
  isComp494078Visible: "visible" | "hidden";
  isComp629321Visible: "visible" | "hidden";
  isComp908768Visible: "visible" | "hidden";
  isComp661928Visible: "visible" | "hidden";
  isComp33607Visible: "visible" | "hidden";
  isComp388124Visible: "visible" | "hidden";
  isComp679493Visible: "visible" | "hidden";
  isComp311202Visible: "visible" | "hidden";
  isComp389701Visible: "visible" | "hidden";
  isComp346751Visible: "visible" | "hidden";
  isComp950581Visible: "visible" | "hidden";
  isComp671904Visible: "visible" | "hidden";
  isComp189397Visible: "visible" | "hidden";
  isComp468286Visible: "visible" | "hidden";
  isComp943000Visible: "visible" | "hidden";
  isComp673600Visible: "visible" | "hidden";
  isComp161683Visible: "visible" | "hidden";
  isComp337464Enabled: "enabled" | "disabled";
}

export class ParQForm_ScreenBase extends React.PureComponent<IParQForm_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "ca39e1fc-c3cb-4b85-b1f4-d804d708754b",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 975017, PropertyName: "value", Value: "Egzersize katılım öncesi sağlık değerlendirmesi" },
        {
          Id: 322808,
          PropertyName: "value",
          Value:
            "Fiziksel aktiviteye başlamadan ya da egzersiz düzeyinizi artırmadan önce, kalbinizin ve vücudunuzun egzersize hazır olduğundan emin olmak önemlidir. Bu kısa test, egzersiz sırasında ortaya çıkabilecek riskleri işaret eden bazı belirtiler veya durumlardan bahsedecek. Her soruyu dürüstçe yanıtlayın. Bu sorulardan herhangi birine 'evet' cevabı verirseniz, bir egzersiz programına başlamadan önce bir sağlık uzmanına danışmanız gerekebilir."
        },
        {
          Id: 437761,
          PropertyName: "value",
          Value: "1. Son 3 aydaki fiziksel aktivite seviyenizi en iyi tarif eden hangisidir?"
        },
        { Id: 762231, PropertyName: "value", Value: "Egzersiz yapmıyorum, hareketli sayılmam." },
        { Id: 577778, PropertyName: "value", Value: "Egzersiz yapmıyorum ama hareketli sayılırım." },
        { Id: 399056, PropertyName: "value", Value: "Egzersiz yapıyorum." },
        { Id: 694750, PropertyName: "value", Value: "2-3 gün/hafta" },
        { Id: 149168, PropertyName: "value", Value: "4-5 gün/hafta" },
        { Id: 654962, PropertyName: "value", Value: "6-7 gün/hafta" },
        {
          Id: 56739,
          PropertyName: "value",
          Value:
            "2. Kardiyovasküler, Metabolik ve/veya Böbrek hastalığı varlığına işaret edebillecek belirti ve semptomlar"
        },
        {
          Id: 190181,
          PropertyName: "value",
          Value: "1. Dinlenme halinde veya fiziksel aktivite sırasında göğüs ağrısı tecrübe ediyor musunuz?"
        },
        { Id: 874284, PropertyName: "value", Value: "Evet" },
        { Id: 349882, PropertyName: "value", Value: "Hayır" },
        {
          Id: 585217,
          PropertyName: "value",
          Value:
            "2. Kısa yürüyüşlerinizde bile özellikle alt bacaklarınızda yanma veya kramp hissini tecrübe ediyor musunuz?"
        },
        { Id: 598663, PropertyName: "value", Value: "Evet" },
        { Id: 314974, PropertyName: "value", Value: "Hayır" },
        {
          Id: 456374,
          PropertyName: "value",
          Value: "3. Dinlenme esnasında veya düşük bir eforda nefes nefese kalır mısınız?"
        },
        { Id: 904937, PropertyName: "value", Value: "Evet" },
        { Id: 119575, PropertyName: "value", Value: "Hayır" },
        {
          Id: 663262,
          PropertyName: "value",
          Value: "4. Sizi rahatsız edecek  şekilde güçlü, hızlı, düzensiz kalp atışları fark ediyor musunuz?"
        },
        { Id: 530474, PropertyName: "value", Value: "Evet" },
        { Id: 347412, PropertyName: "value", Value: "Hayır" },
        {
          Id: 930564,
          PropertyName: "value",
          Value: "5. Genellikle geceleri ortaya çıkan nefes darlığı veya öksürük krizleri yaşar mısınız?"
        },
        { Id: 81485, PropertyName: "value", Value: "Evet" },
        { Id: 270344, PropertyName: "value", Value: "Hayır" },
        {
          Id: 571342,
          PropertyName: "value",
          Value: "6. Doktorunuz size kalbinizde her hangi bir üfürüm olduğunu söylemiş miydi?"
        },
        { Id: 849761, PropertyName: "value", Value: "Evet" },
        { Id: 62741, PropertyName: "value", Value: "Hayır" },
        {
          Id: 130222,
          PropertyName: "value",
          Value:
            "7. Baş dönmesi nedeniyle dengenizi kaybeder misiniz veya bilinç kaybı (bayılma) tecrübe ediyor musunuz?"
        },
        { Id: 114485, PropertyName: "value", Value: "Evet" },
        { Id: 317121, PropertyName: "value", Value: "Hayır" },
        { Id: 162989, PropertyName: "value", Value: "8. Ayak bileklerinizde ödem tecrübe ediyor musunuz?" },
        { Id: 331108, PropertyName: "value", Value: "Evet" },
        { Id: 144219, PropertyName: "value", Value: "Hayır" },
        {
          Id: 79987,
          PropertyName: "value",
          Value:
            "3. Daha önce geçirdiğiniz veya şu anda teşhisli kardiyovasküler, metabolik yada böbrek hastalıklarından herhangi birine sahip misiniz?"
        },
        { Id: 857349, PropertyName: "value", Value: "Evet" },
        { Id: 200887, PropertyName: "value", Value: "Hayır" },
        { Id: 663005, PropertyName: "value", Value: "1. Kalp krizi - Miyokard Enfarktüs" },
        { Id: 814910, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 641316, PropertyName: "value", Value: "Evet" },
        { Id: 582886, PropertyName: "value", Value: "Hayır" },
        { Id: 724982, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 146506, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 574243, PropertyName: "value", Value: "2. Kalp ameliyatı" },
        { Id: 600933, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 534318, PropertyName: "value", Value: "Evet" },
        { Id: 727452, PropertyName: "value", Value: "Hayır" },
        { Id: 562563, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 552938, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 131686, PropertyName: "value", Value: "3. Kalp nakli" },
        { Id: 804448, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 321075, PropertyName: "value", Value: "Evet" },
        { Id: 350045, PropertyName: "value", Value: "Hayır" },
        { Id: 428617, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 441666, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 774792, PropertyName: "value", Value: "4. Kalp pili" },
        { Id: 446609, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 669942, PropertyName: "value", Value: "Evet" },
        { Id: 894753, PropertyName: "value", Value: "Hayır" },
        { Id: 940581, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 366768, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 452576, PropertyName: "value", Value: "5. Hipertansiyon" },
        { Id: 299770, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 744963, PropertyName: "value", Value: "Evet" },
        { Id: 960725, PropertyName: "value", Value: "Hayır" },
        { Id: 757058, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 394297, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 514560, PropertyName: "value", Value: "6. Anjina ve Sessiz Miyokard İskemisi" },
        { Id: 712384, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 804886, PropertyName: "value", Value: "Evet" },
        { Id: 548106, PropertyName: "value", Value: "Hayır" },
        { Id: 249272, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 503137, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 390141, PropertyName: "value", Value: "7. Kronik kalp yetmezliği" },
        { Id: 861592, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 188318, PropertyName: "value", Value: "Evet" },
        { Id: 420791, PropertyName: "value", Value: "Hayır" },
        { Id: 730058, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 875104, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 688292, PropertyName: "value", Value: "8. Tip I Diyabet" },
        { Id: 712349, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 320690, PropertyName: "value", Value: "Evet" },
        { Id: 668229, PropertyName: "value", Value: "Hayır" },
        { Id: 759525, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 202020, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 122468, PropertyName: "value", Value: "9. Tip II Diyabet" },
        { Id: 504945, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 68485, PropertyName: "value", Value: "Evet" },
        { Id: 505907, PropertyName: "value", Value: "Hayır" },
        { Id: 327153, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 410924, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 134744, PropertyName: "value", Value: "10. Obezite" },
        { Id: 218611, PropertyName: "value", Value: "Boy" },
        { Id: 35554, PropertyName: "placeholder", Value: "15" },
        { Id: 259130, PropertyName: "value", Value: "Kilo" },
        { Id: 84142, PropertyName: "placeholder", Value: "15" },
        { Id: 578084, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 157830, PropertyName: "value", Value: "Evet" },
        { Id: 827795, PropertyName: "value", Value: "Hayır" },
        { Id: 96979, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 813285, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 873189, PropertyName: "value", Value: "11. Hiperlipidemi" },
        { Id: 947118, PropertyName: "value", Value: "T.K." },
        { Id: 981775, PropertyName: "placeholder", Value: "15" },
        { Id: 585600, PropertyName: "value", Value: "HDL" },
        { Id: 138481, PropertyName: "placeholder", Value: "15" },
        { Id: 18997, PropertyName: "value", Value: "LDL" },
        { Id: 816505, PropertyName: "placeholder", Value: "15" },
        { Id: 123296, PropertyName: "value", Value: "TK/HDL Hesaplama" },
        { Id: 337464, PropertyName: "placeholder", Value: "15" },
        { Id: 842082, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 763656, PropertyName: "value", Value: "Evet" },
        { Id: 617004, PropertyName: "value", Value: "Hayır" },
        { Id: 326609, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 751235, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 303986, PropertyName: "value", Value: "12. Kronik Böbrek Hastalığı" },
        { Id: 210238, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 792475, PropertyName: "value", Value: "Evet" },
        { Id: 793117, PropertyName: "value", Value: "Hayır" },
        { Id: 191371, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 492488, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 617018, PropertyName: "value", Value: "13. Kronik Karaciğer Hastalığı" },
        { Id: 72534, PropertyName: "value", Value: "İlaçla tedavi var mı ?" },
        { Id: 35887, PropertyName: "value", Value: "Evet" },
        { Id: 815810, PropertyName: "value", Value: "Hayır" },
        { Id: 377463, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 56334, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 321877,
          PropertyName: "value",
          Value:
            "4. Anneniz 65 yaş öncesinde, babanız ise 55 yaş öncesinde, kalp krizi, bypass ameliyatı ya da ani ölüm hikayesi var mı?"
        },
        { Id: 694573, PropertyName: "value", Value: "1. Anne" },
        { Id: 50042, PropertyName: "value", Value: "1. Ani Ölüm" },
        { Id: 5157, PropertyName: "value", Value: "2. Geçirilmiş bypass ameliyatı" },
        { Id: 29101, PropertyName: "value", Value: "3. Miyokard Enfarktüs" },
        { Id: 982948, PropertyName: "value", Value: "2. Baba" },
        { Id: 866182, PropertyName: "value", Value: "1. Ani Ölüm" },
        { Id: 551692, PropertyName: "value", Value: "2. Geçirilmiş bypass ameliyatı" },
        { Id: 434693, PropertyName: "value", Value: "3. Miyokard Enfarktüs" },
        { Id: 518513, PropertyName: "value", Value: "Eforlu testten hiç geçtiniz mi?" },
        { Id: 510650, PropertyName: "value", Value: "Evet" },
        { Id: 90415, PropertyName: "value", Value: "Hayır" },
        {
          Id: 477169,
          PropertyName: "value",
          Value: "Test sonucunda doktorunuz herhangi bir probleminiz olduğunu söyledi mi?"
        },
        { Id: 115186, PropertyName: "value", Value: "Evet" },
        { Id: 237158, PropertyName: "value", Value: "Hayır" },
        {
          Id: 861037,
          PropertyName: "value",
          Value: "5. Teşhis edilmiş veya egzersizle kötüleşebilecek bir kemik ya da eklem rahatsızlığını var mıdır?"
        },
        { Id: 506887, PropertyName: "value", Value: "Evet" },
        { Id: 305491, PropertyName: "value", Value: "Hayır" },
        { Id: 47450, PropertyName: "value", Value: "1. Akut Sakatlık" },
        { Id: 332615, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 103815, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 810830, PropertyName: "value", Value: "2. Kronik Sakatlık" },
        { Id: 689354, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 654161, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 783708, PropertyName: "value", Value: "3. Osteoporoz" },
        { Id: 877911, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 445930, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 363855, PropertyName: "value", Value: "4. Kronik bel ağrısı" },
        { Id: 243374, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 342330, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 58800, PropertyName: "value", Value: "5. Artrit" },
        { Id: 522812, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 339185, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 804090,
          PropertyName: "value",
          Value: "6. Teşhis edilmiş başka herhangi bir kronik rahatsızlığınız var mıdır?"
        },
        { Id: 838754, PropertyName: "value", Value: "Evet" },
        { Id: 55922, PropertyName: "value", Value: "Hayır" },
        { Id: 171841, PropertyName: "value", Value: "1. Akciğer Hastalıkları" },
        { Id: 734857, PropertyName: "value", Value: "1. Kronik Obstrüktif Akciğer Hastalığı" },
        { Id: 735732, PropertyName: "value", Value: "2. Kronik Restriktif Akciğer Hastalığı" },
        { Id: 91016, PropertyName: "value", Value: "3. Kistik Fibrozis" },
        { Id: 130735, PropertyName: "value", Value: "4. Astım" },
        { Id: 75184, PropertyName: "value", Value: "5. Akciğer / Akciğer Kalp Transplantasyonu" },
        { Id: 271513, PropertyName: "value", Value: "2. Imunolojik Rahatsızlıklar" },
        { Id: 29143, PropertyName: "value", Value: "1. Kanser" },
        { Id: 395102, PropertyName: "value", Value: "2. AIDS" },
        { Id: 578435, PropertyName: "value", Value: "3. Kronik Yorgunluk" },
        { Id: 491809, PropertyName: "value", Value: "4. Abdominal Organ Transplantasyonu" },
        { Id: 619032, PropertyName: "value", Value: "5. Kanama ve Pıhtılaşma Bozukluğu" },
        { Id: 541070, PropertyName: "value", Value: "6. Kanama ve Pıhtılaşma Bozukluğu" },
        { Id: 481603, PropertyName: "value", Value: "7. Fibromiyalji" },
        { Id: 543653, PropertyName: "value", Value: "3. Nörolojik Hastalıklar" },
        { Id: 92447, PropertyName: "value", Value: "1. İnme ve Beyin Hasarı" },
        { Id: 154024, PropertyName: "value", Value: "2. Omurilik Felci" },
        { Id: 537637, PropertyName: "value", Value: "3. Kas Distrofisi" },
        { Id: 275155, PropertyName: "value", Value: "4. Epilepsi" },
        { Id: 916059, PropertyName: "value", Value: "5. Multiple Skleroz" },
        { Id: 24314, PropertyName: "value", Value: "6. Polio/Post Polio Sendromu" },
        { Id: 76225, PropertyName: "value", Value: "7. Amyotrofik Lateral Skleroz" },
        { Id: 611441, PropertyName: "value", Value: "8. Beyin Felci" },
        { Id: 998704, PropertyName: "value", Value: "9. Parkinson" },
        { Id: 455997, PropertyName: "value", Value: "4. Bilişsel, Psikolojik ve Duyusal Bozukluklar" },
        { Id: 427024, PropertyName: "value", Value: "1. Entelektüel Yetersizlik" },
        { Id: 592129, PropertyName: "value", Value: "2. Alzheimer Hastalığı" },
        { Id: 624899, PropertyName: "value", Value: "3. Akıl Hastalığı" },
        { Id: 634418, PropertyName: "value", Value: "4. Stres ve Anksiyete Bozukluğu" },
        { Id: 103752, PropertyName: "value", Value: "5. İşitme Engelli/İşitme Kaybı" },
        { Id: 946837, PropertyName: "value", Value: "6. Görme Bozukluğu" },
        { Id: 957270, PropertyName: "value", Value: "5. Diğer" },
        { Id: 910320, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 739962, PropertyName: "placeholder", Value: "Type here..." },
        {
          Id: 295033,
          PropertyName: "value",
          Value: "7. Şu anda yaşadığınız geçici herhangi bir özel durum var mıdır?"
        },
        { Id: 139166, PropertyName: "value", Value: "Evet" },
        { Id: 652907, PropertyName: "value", Value: "Hayır" },
        { Id: 244591, PropertyName: "value", Value: "1. Soğuk Algınlığı" },
        { Id: 330244, PropertyName: "value", Value: "2. Hamilelik" },
        { Id: 422118, PropertyName: "value", Value: "3. Diğer" },
        { Id: 652993, PropertyName: "value", Value: "Lütfen Belirtin" },
        { Id: 357526, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 358320, PropertyName: "value", Value: "8. Sigara içer misiniz?" },
        { Id: 404667, PropertyName: "value", Value: "Evet" },
        { Id: 319040, PropertyName: "value", Value: "Hayır" },
        { Id: 505635, PropertyName: "value", Value: "Günde" },
        { Id: 673600, PropertyName: "placeholder", Value: "15" },
        { Id: 859604, PropertyName: "value", Value: "Haftada" },
        { Id: 161683, PropertyName: "placeholder", Value: "15" },
        { Id: 354030, PropertyName: "value", Value: "Kaç senedir içiyorsunuz?" },
        { Id: 656623, PropertyName: "placeholder", Value: "15" },
        { Id: 518528, PropertyName: "label", Value: "Kaydeteski" },
        { Id: 92022, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 974898, PropertyName: "placeholder", Value: "Type here..." }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      FormParQById: [],
      FormParQSoru3ById: [],
      FormParQSoru6ById: [],
      SetValueOf: "",
      ToggleVisibilityOf: "",
      ChangeVisibilityOf: "",
      IfThenElse: false,
      TriggerEvent: "",
      Divide: 0,
      FormParQBelirtiSemptom: [],
      FormParQSonuc: [],
      FormParQSave: 0,
      FormParQSoru3Save: 0,
      FormParQSoru6Save: 0,
      isComp26011Visible: "hidden",
      isComp329331Visible: "hidden",
      isComp851647Visible: "hidden",
      isComp319278Visible: "hidden",
      isComp127283Visible: "hidden",
      isComp80854Visible: "hidden",
      isComp217409Visible: "hidden",
      isComp210725Visible: "hidden",
      isComp673097Visible: "hidden",
      isComp23734Visible: "hidden",
      isComp707761Visible: "hidden",
      isComp177991Visible: "hidden",
      isComp911981Visible: "hidden",
      isComp710369Visible: "hidden",
      isComp714589Visible: "hidden",
      isComp118539Visible: "hidden",
      isComp696700Visible: "hidden",
      isComp821128Visible: "hidden",
      isComp532130Visible: "hidden",
      isComp882479Visible: "hidden",
      isComp503462Visible: "hidden",
      isComp311406Visible: "hidden",
      isComp761377Visible: "hidden",
      isComp50002Visible: "hidden",
      isComp629359Visible: "hidden",
      isComp449366Visible: "hidden",
      isComp273435Visible: "hidden",
      isComp688878Visible: "hidden",
      isComp892138Visible: "hidden",
      isComp502091Visible: "hidden",
      isComp165687Visible: "hidden",
      isComp164785Visible: "hidden",
      isComp494078Visible: "hidden",
      isComp629321Visible: "hidden",
      isComp908768Visible: "hidden",
      isComp661928Visible: "hidden",
      isComp33607Visible: "hidden",
      isComp388124Visible: "hidden",
      isComp679493Visible: "hidden",
      isComp311202Visible: "hidden",
      isComp389701Visible: "hidden",
      isComp346751Visible: "hidden",
      isComp950581Visible: "hidden",
      isComp671904Visible: "hidden",
      isComp189397Visible: "hidden",
      isComp468286Visible: "hidden",
      isComp943000Visible: "hidden",
      isComp673600Visible: "hidden",
      isComp161683Visible: "hidden",
      isComp337464Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("parqform", "");
      return;
    }

    if (true) {
      await this.ParQFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("parqform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("parqform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.ParQFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      parqform_68315_value: this.state.FormParQById?.at?.(0)?.s1x1 ?? undefined,
      parqform_183279_value: this.state.FormParQById?.at?.(0)?.s1x2 ?? undefined,
      parqform_790913_value: this.state.FormParQById?.at?.(0)?.s1x3 ?? undefined,
      parqform_43178_value: this.state.FormParQById?.at?.(0)?.s1x3x1 ?? undefined,
      parqform_567403_value: this.state.FormParQById?.at?.(0)?.s1x3x2 ?? undefined,
      parqform_235714_value: this.state.FormParQById?.at?.(-1)?.s1x3x3 ?? undefined,
      parqform_235137_value: this.state.FormParQById?.at?.(0)?.s2x1 ?? undefined,
      parqform_576370_value: false,
      parqform_95662_value: this.state.FormParQById?.at?.(0)?.s2x2 ?? undefined,
      parqform_15437_value: false,
      parqform_517539_value: this.state.FormParQById?.at?.(0)?.s2x3 ?? undefined,
      parqform_744579_value: false,
      parqform_3707_value: this.state.FormParQById?.at?.(0)?.s2x4 ?? undefined,
      parqform_105425_value: false,
      parqform_125450_value: this.state.FormParQById?.at?.(0)?.s2x5 ?? undefined,
      parqform_380976_value: false,
      parqform_57759_value: this.state.FormParQById?.at?.(0)?.s2x6 ?? undefined,
      parqform_976315_value: false,
      parqform_802213_value: this.state.FormParQById?.at?.(0)?.s2x7 ?? undefined,
      parqform_157821_value: false,
      parqform_90450_value: this.state.FormParQById?.at?.(0)?.s2x8 ?? undefined,
      parqform_537463_value: false,
      parqform_774297_value: this.state.FormParQById?.at?.(-1)?.s3 ?? undefined,
      parqform_303408_value: false,
      parqform_481773_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1 ?? undefined,
      parqform_851781_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1 ?? undefined,
      parqform_24523_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1 ?? undefined,
      parqform_578820_value: false,
      parqform_146506_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x1x1 ?? undefined,
      parqform_425265_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2 ?? undefined,
      parqform_200490_value: false,
      parqform_738934_value: false,
      parqform_552938_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x2x1 ?? undefined,
      parqform_815856_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3 ?? undefined,
      parqform_324203_value: false,
      parqform_86276_value: false,
      parqform_441666_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x3x1 ?? undefined,
      parqform_301053_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4 ?? undefined,
      parqform_350858_value: false,
      parqform_696936_value: false,
      parqform_366768_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x4x1 ?? undefined,
      parqform_316213_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5 ?? undefined,
      parqform_155638_value: false,
      parqform_681775_value: false,
      parqform_394297_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x5x1 ?? undefined,
      parqform_395667_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6 ?? undefined,
      parqform_270593_value: false,
      parqform_597696_value: false,
      parqform_503137_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x6x1 ?? undefined,
      parqform_858125_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7 ?? undefined,
      parqform_246596_value: false,
      parqform_354052_value: false,
      parqform_875104_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x7x1 ?? undefined,
      parqform_985460_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8 ?? undefined,
      parqform_558941_value: false,
      parqform_323027_value: false,
      parqform_202020_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x8x1 ?? undefined,
      parqform_113946_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9 ?? undefined,
      parqform_604531_value: false,
      parqform_649868_value: false,
      parqform_410924_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x9x1 ?? undefined,
      parqform_221532_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10 ?? undefined,
      parqform_35554_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xBoy ?? undefined,
      parqform_84142_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10xKilo ?? undefined,
      parqform_117386_value: false,
      parqform_212645_value: false,
      parqform_813285_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x10x1 ?? undefined,
      parqform_245992_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11 ?? undefined,
      parqform_981775_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xTK ?? undefined,
      parqform_138481_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xHDL ?? undefined,
      parqform_816505_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xLDL ?? undefined,
      parqform_337464_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11xResult ?? undefined,
      parqform_703610_value: false,
      parqform_117027_value: false,
      parqform_751235_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x11x1 ?? undefined,
      parqform_137075_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12 ?? undefined,
      parqform_943372_value: false,
      parqform_386786_value: false,
      parqform_492488_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x12x1 ?? undefined,
      parqform_683570_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13 ?? undefined,
      parqform_443284_value: false,
      parqform_167149_value: false,
      parqform_56334_value: this.state.FormParQSoru3ById?.at?.(0)?.s3x13x1 ?? undefined,
      parqform_485590_value: this.state.FormParQById?.at?.(0)?.s4x1 ?? undefined,
      parqform_527506_value: this.state.FormParQById?.at?.(0)?.s4x1x1 ?? undefined,
      parqform_139967_value: this.state.FormParQById?.at?.(0)?.s4x1x2 ?? undefined,
      parqform_426278_value: this.state.FormParQById?.at?.(0)?.s4x1x3 ?? undefined,
      parqform_925296_value: this.state.FormParQById?.at?.(0)?.s4x2 ?? undefined,
      parqform_726714_value: this.state.FormParQById?.at?.(0)?.s4x2x1 ?? undefined,
      parqform_289204_value: this.state.FormParQById?.at?.(0)?.s4x2x2 ?? undefined,
      parqform_752131_value: this.state.FormParQById?.at?.(0)?.s4x2x3 ?? undefined,
      parqform_254468_value: this.state.FormParQById?.at?.(0)?.s4xEfor ?? undefined,
      parqform_245234_value: false,
      parqform_378575_value: this.state.FormParQById?.at?.(0)?.s4xEforxProblem ?? undefined,
      parqform_336992_value: false,
      parqform_329156_value: this.state.FormParQById?.at?.(0)?.s5 ?? undefined,
      parqform_692037_value: false,
      parqform_477451_value: this.state.FormParQById?.at?.(0)?.s5X1 ?? undefined,
      parqform_103815_value: this.state.FormParQById?.at?.(0)?.s5X1 ?? undefined,
      parqform_75034_value: this.state.FormParQById?.at?.(0)?.s5x2 ?? undefined,
      parqform_654161_value: this.state.FormParQById?.at?.(0)?.s5x2 ?? undefined,
      parqform_85355_value: this.state.FormParQById?.at?.(0)?.s5x3 ?? undefined,
      parqform_445930_value: this.state.FormParQById?.at?.(0)?.s5x3 ?? undefined,
      parqform_38196_value: this.state.FormParQById?.at?.(0)?.s5x4 ?? undefined,
      parqform_342330_value: this.state.FormParQById?.at?.(0)?.s5x4 ?? undefined,
      parqform_918726_value: this.state.FormParQById?.at?.(0)?.s5x5 ?? undefined,
      parqform_339185_value: this.state.FormParQById?.at?.(0)?.s5x5 ?? undefined,
      parqform_210515_value: this.state.FormParQById?.at?.(0)?.s6 ?? undefined,
      parqform_213310_value: false,
      parqform_538276_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1 ?? undefined,
      parqform_713132_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x1 ?? undefined,
      parqform_154499_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x2 ?? undefined,
      parqform_867208_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x3 ?? undefined,
      parqform_622758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x4 ?? undefined,
      parqform_590152_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x1x5 ?? undefined,
      parqform_700493_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2 ?? undefined,
      parqform_528451_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x1 ?? undefined,
      parqform_581396_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x2 ?? undefined,
      parqform_165036_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x3 ?? undefined,
      parqform_996331_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x4 ?? undefined,
      parqform_761897_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x5 ?? undefined,
      parqform_782070_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x6 ?? undefined,
      parqform_590758_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x2x7 ?? undefined,
      parqform_847131_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3 ?? undefined,
      parqform_996970_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x1 ?? undefined,
      parqform_736647_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x2 ?? undefined,
      parqform_718707_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x3 ?? undefined,
      parqform_285485_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x4 ?? undefined,
      parqform_710319_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x5 ?? undefined,
      parqform_659579_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x6 ?? undefined,
      parqform_257868_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x7 ?? undefined,
      parqform_560619_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x8 ?? undefined,
      parqform_275784_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x3x9 ?? undefined,
      parqform_736437_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4 ?? undefined,
      parqform_883656_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x1 ?? undefined,
      parqform_627907_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x2 ?? undefined,
      parqform_893733_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x3 ?? undefined,
      parqform_786497_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x4 ?? undefined,
      parqform_365318_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x5 ?? undefined,
      parqform_342249_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x4x6 ?? undefined,
      parqform_486661_value: false,
      parqform_739962_value: this.state.FormParQSoru6ById?.at?.(0)?.s6x5 ?? undefined,
      parqform_424187_value: this.state.FormParQById?.at?.(0)?.s7 ?? undefined,
      parqform_753927_value: false,
      parqform_74533_value: this.state.FormParQById?.at?.(0)?.s7x1 ?? undefined,
      parqform_740586_value: this.state.FormParQById?.at?.(0)?.s7x2 ?? undefined,
      parqform_216720_value: this.state.FormParQById?.at?.(0)?.s7x3 ?? undefined,
      parqform_357526_value: this.state.FormParQById?.at?.(0)?.s7x3 ?? undefined,
      parqform_716276_value: this.state.FormParQById?.at?.(0)?.s8 ?? undefined,
      parqform_241259_value: false,
      parqform_617340_value: false,
      parqform_673600_value: this.state.FormParQById?.at?.(0)?.s8xGunde ?? undefined,
      parqform_247127_value: false,
      parqform_161683_value: this.state.FormParQById?.at?.(0)?.s8xHaftada ?? undefined,
      parqform_656623_value: this.state.FormParQById?.at?.(0)?.s8xKacSene ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  ParQFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParQForm/ParQFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQById = result?.data.formParQById;
    formVars.parqform_68315_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x1
        : null
    );
    formVars.parqform_183279_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x2
        : null
    );
    formVars.parqform_790913_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3
        : null
    );
    formVars.parqform_43178_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x1
        : null
    );
    formVars.parqform_567403_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x2
        : null
    );
    formVars.parqform_235714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s1x3x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s1x3x3
        : null
    );
    formVars.parqform_235137_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x1
        : null
    );
    formVars.parqform_95662_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x2
        : null
    );
    formVars.parqform_517539_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x3
        : null
    );
    formVars.parqform_3707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x4
        : null
    );
    formVars.parqform_125450_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x5
        : null
    );
    formVars.parqform_57759_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x6
        : null
    );
    formVars.parqform_802213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x7
        : null
    );
    formVars.parqform_90450_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x8
        : null
    );
    formVars.parqform_774297_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s3
        : null
    );
    formVars.parqform_485590_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1
        : null
    );
    formVars.parqform_527506_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x1
        : null
    );
    formVars.parqform_139967_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x2
        : null
    );
    formVars.parqform_426278_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x3
        : null
    );
    formVars.parqform_925296_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2
        : null
    );
    formVars.parqform_726714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x1
        : null
    );
    formVars.parqform_289204_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x2
        : null
    );
    formVars.parqform_752131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x3
        : null
    );
    formVars.parqform_254468_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEfor
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEfor
        : null
    );
    formVars.parqform_378575_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEforxProblem
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEforxProblem
        : null
    );
    formVars.parqform_329156_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5
        : null
    );
    formVars.parqform_477451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );
    formVars.parqform_103815_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );
    formVars.parqform_75034_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );
    formVars.parqform_654161_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );
    formVars.parqform_85355_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );
    formVars.parqform_445930_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );
    formVars.parqform_38196_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );
    formVars.parqform_342330_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );
    formVars.parqform_918726_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );
    formVars.parqform_339185_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );
    formVars.parqform_210515_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s6
        : null
    );
    formVars.parqform_424187_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7
        : null
    );
    formVars.parqform_74533_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x1
        : null
    );
    formVars.parqform_740586_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x2
        : null
    );
    formVars.parqform_216720_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );
    formVars.parqform_357526_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );
    formVars.parqform_716276_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8
        : null
    );
    formVars.parqform_673600_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xGunde
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xGunde
        : null
    );
    formVars.parqform_161683_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xHaftada
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xHaftada
        : null
    );
    formVars.parqform_656623_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xKacSene
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xKacSene
        : null
    );
    stateVars.FormParQSoru3ById = result?.data.formParQSoru3ById;
    formVars.parqform_481773_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );
    formVars.parqform_851781_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );
    formVars.parqform_24523_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );
    formVars.parqform_146506_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1x1
        : null
    );
    formVars.parqform_425265_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2
        : null
    );
    formVars.parqform_552938_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2x1
        : null
    );
    formVars.parqform_815856_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );
    formVars.parqform_441666_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3x1
        : null
    );
    formVars.parqform_301053_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4
        : null
    );
    formVars.parqform_366768_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4x1
        : null
    );
    formVars.parqform_316213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5
        : null
    );
    formVars.parqform_394297_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5x1
        : null
    );
    formVars.parqform_395667_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6
        : null
    );
    formVars.parqform_503137_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6x1
        : null
    );
    formVars.parqform_858125_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7
        : null
    );
    formVars.parqform_875104_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7x1
        : null
    );
    formVars.parqform_985460_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8
        : null
    );
    formVars.parqform_202020_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8x1
        : null
    );
    formVars.parqform_113946_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9
        : null
    );
    formVars.parqform_410924_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9x1
        : null
    );
    formVars.parqform_221532_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10
        : null
    );
    formVars.parqform_35554_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xBoy
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xBoy
        : null
    );
    formVars.parqform_84142_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xKilo
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xKilo
        : null
    );
    formVars.parqform_813285_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10x1
        : null
    );
    formVars.parqform_245992_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11
        : null
    );
    formVars.parqform_981775_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xTK
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xTK
        : null
    );
    formVars.parqform_138481_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xHDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xHDL
        : null
    );
    formVars.parqform_816505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xLDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xLDL
        : null
    );
    formVars.parqform_337464_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xResult
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xResult
        : null
    );
    formVars.parqform_751235_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11x1
        : null
    );
    formVars.parqform_137075_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12
        : null
    );
    formVars.parqform_492488_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12x1
        : null
    );
    formVars.parqform_683570_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13
        : null
    );
    formVars.parqform_56334_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13x1
        : null
    );
    stateVars.FormParQSoru6ById = result?.data.formParQSoru6ById;
    formVars.parqform_538276_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1
        : null
    );
    formVars.parqform_713132_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x1
        : null
    );
    formVars.parqform_154499_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x2
        : null
    );
    formVars.parqform_867208_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x3
        : null
    );
    formVars.parqform_622758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x4
        : null
    );
    formVars.parqform_590152_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x5
        : null
    );
    formVars.parqform_700493_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2
        : null
    );
    formVars.parqform_528451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x1
        : null
    );
    formVars.parqform_581396_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x2
        : null
    );
    formVars.parqform_165036_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x3
        : null
    );
    formVars.parqform_996331_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x4
        : null
    );
    formVars.parqform_761897_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x5
        : null
    );
    formVars.parqform_782070_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x6
        : null
    );
    formVars.parqform_590758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x7
        : null
    );
    formVars.parqform_847131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3
        : null
    );
    formVars.parqform_996970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x1
        : null
    );
    formVars.parqform_736647_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x2
        : null
    );
    formVars.parqform_718707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x3
        : null
    );
    formVars.parqform_285485_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x4
        : null
    );
    formVars.parqform_710319_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x5
        : null
    );
    formVars.parqform_659579_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x6
        : null
    );
    formVars.parqform_257868_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x7
        : null
    );
    formVars.parqform_560619_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x8
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x8
        : null
    );
    formVars.parqform_275784_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x9
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x9
        : null
    );
    formVars.parqform_736437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4
        : null
    );
    formVars.parqform_883656_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x1
        : null
    );
    formVars.parqform_627907_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x2
        : null
    );
    formVars.parqform_893733_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x3
        : null
    );
    formVars.parqform_786497_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x4
        : null
    );
    formVars.parqform_365318_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x5
        : null
    );
    formVars.parqform_342249_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x6
        : null
    );
    formVars.parqform_739962_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x5
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.parqform_68315_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x1
        : null
    );

    formVars.parqform_183279_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x2
        : null
    );

    formVars.parqform_790913_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3
        : null
    );

    formVars.parqform_43178_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x1
        : null
    );

    formVars.parqform_567403_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s1x3x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s1x3x2
        : null
    );

    formVars.parqform_235714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s1x3x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s1x3x3
        : null
    );

    formVars.parqform_235137_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x1
        : null
    );

    formVars.parqform_95662_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x2
        : null
    );

    formVars.parqform_517539_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x3
        : null
    );

    formVars.parqform_3707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x4
        : null
    );

    formVars.parqform_125450_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x5
        : null
    );

    formVars.parqform_57759_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x6
        : null
    );

    formVars.parqform_802213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x7
        : null
    );

    formVars.parqform_90450_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s2x8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s2x8
        : null
    );

    formVars.parqform_774297_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[stateVars.FormParQById.length - 1].s3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[this.state.FormParQById.length - 1].s3
        : null
    );

    formVars.parqform_481773_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );

    stateVars.isComp851647Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_481773_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqform_851781_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );

    formVars.parqform_24523_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1
        : null
    );

    formVars.parqform_146506_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x1x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x1x1
        : null
    );

    formVars.parqform_425265_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2
        : null
    );

    formVars.parqform_552938_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x2x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x2x1
        : null
    );

    formVars.parqform_815856_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3
        : null
    );

    formVars.parqform_441666_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x3x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x3x1
        : null
    );

    formVars.parqform_301053_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4
        : null
    );

    formVars.parqform_366768_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x4x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x4x1
        : null
    );

    formVars.parqform_316213_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5
        : null
    );

    formVars.parqform_394297_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x5x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x5x1
        : null
    );

    formVars.parqform_395667_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6
        : null
    );

    formVars.parqform_503137_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x6x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x6x1
        : null
    );

    formVars.parqform_858125_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7
        : null
    );

    formVars.parqform_875104_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x7x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x7x1
        : null
    );

    formVars.parqform_985460_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8
        : null
    );

    formVars.parqform_202020_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x8x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x8x1
        : null
    );

    formVars.parqform_113946_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9
        : null
    );

    formVars.parqform_410924_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x9x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x9x1
        : null
    );

    formVars.parqform_221532_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10
        : null
    );

    formVars.parqform_35554_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xBoy
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xBoy
        : null
    );

    formVars.parqform_84142_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10xKilo
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10xKilo
        : null
    );

    formVars.parqform_813285_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x10x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x10x1
        : null
    );

    formVars.parqform_245992_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11
        : null
    );

    formVars.parqform_981775_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xTK
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xTK
        : null
    );

    formVars.parqform_138481_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xHDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xHDL
        : null
    );

    formVars.parqform_816505_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xLDL
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xLDL
        : null
    );

    formVars.parqform_337464_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11xResult
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11xResult
        : null
    );

    formVars.parqform_751235_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x11x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x11x1
        : null
    );

    formVars.parqform_137075_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12
        : null
    );

    formVars.parqform_492488_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x12x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x12x1
        : null
    );

    formVars.parqform_683570_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13
        : null
    );

    formVars.parqform_56334_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru3ById?.length > 0
        ? stateVars.FormParQSoru3ById[0].s3x13x1
        : this.state.FormParQSoru3ById?.length > 0
        ? this.state.FormParQSoru3ById[0].s3x13x1
        : null
    );

    formVars.parqform_485590_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1
        : null
    );

    formVars.parqform_527506_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x1
        : null
    );

    formVars.parqform_139967_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x2
        : null
    );

    formVars.parqform_426278_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x1x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x1x3
        : null
    );

    formVars.parqform_925296_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2
        : null
    );

    formVars.parqform_726714_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x1
        : null
    );

    formVars.parqform_289204_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x2
        : null
    );

    formVars.parqform_752131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4x2x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4x2x3
        : null
    );

    formVars.parqform_254468_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEfor
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEfor
        : null
    );

    formVars.parqform_378575_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s4xEforxProblem
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s4xEforxProblem
        : null
    );

    formVars.parqform_329156_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5
        : null
    );

    formVars.parqform_477451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );

    formVars.parqform_103815_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5X1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5X1
        : null
    );

    formVars.parqform_75034_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );

    formVars.parqform_654161_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x2
        : null
    );

    formVars.parqform_85355_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );

    formVars.parqform_445930_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x3
        : null
    );

    formVars.parqform_38196_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );

    formVars.parqform_342330_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x4
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x4
        : null
    );

    formVars.parqform_918726_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );

    formVars.parqform_339185_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s5x5
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s5x5
        : null
    );

    formVars.parqform_210515_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s6
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s6
        : null
    );

    formVars.parqform_538276_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1
        : null
    );

    formVars.parqform_713132_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x1
        : null
    );

    formVars.parqform_154499_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x2
        : null
    );

    formVars.parqform_867208_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x3
        : null
    );

    formVars.parqform_622758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x4
        : null
    );

    formVars.parqform_590152_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x1x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x1x5
        : null
    );

    formVars.parqform_700493_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2
        : null
    );

    formVars.parqform_528451_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x1
        : null
    );

    formVars.parqform_581396_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x2
        : null
    );

    formVars.parqform_165036_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x3
        : null
    );

    formVars.parqform_996331_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x4
        : null
    );

    formVars.parqform_761897_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x5
        : null
    );

    formVars.parqform_782070_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x6
        : null
    );

    formVars.parqform_590758_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x2x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x2x7
        : null
    );

    formVars.parqform_847131_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3
        : null
    );

    formVars.parqform_996970_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x1
        : null
    );

    formVars.parqform_736647_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x2
        : null
    );

    formVars.parqform_718707_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x3
        : null
    );

    formVars.parqform_285485_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x4
        : null
    );

    formVars.parqform_710319_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x5
        : null
    );

    formVars.parqform_659579_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x6
        : null
    );

    formVars.parqform_257868_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x7
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x7
        : null
    );

    formVars.parqform_560619_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x8
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x8
        : null
    );

    formVars.parqform_275784_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x3x9
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x3x9
        : null
    );

    formVars.parqform_736437_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4
        : null
    );

    stateVars.isComp950581Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_786497_value", "value", "", "", "")
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.parqform_883656_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x1
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x1
        : null
    );

    formVars.parqform_627907_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x2
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x2
        : null
    );

    formVars.parqform_893733_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x3
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x3
        : null
    );

    formVars.parqform_786497_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x4
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x4
        : null
    );

    formVars.parqform_365318_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x5
        : null
    );

    formVars.parqform_342249_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x4x6
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x4x6
        : null
    );

    formVars.parqform_739962_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQSoru6ById?.length > 0
        ? stateVars.FormParQSoru6ById[0].s6x5
        : this.state.FormParQSoru6ById?.length > 0
        ? this.state.FormParQSoru6ById[0].s6x5
        : null
    );

    formVars.parqform_424187_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7
        : null
    );

    formVars.parqform_74533_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x1
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x1
        : null
    );

    formVars.parqform_740586_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x2
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x2
        : null
    );

    formVars.parqform_216720_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );

    formVars.parqform_357526_value = ReactSystemFunctions.toString(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s7x3
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s7x3
        : null
    );

    formVars.parqform_716276_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8
        : null
    );

    formVars.parqform_673600_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xGunde
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xGunde
        : null
    );

    formVars.parqform_161683_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xHaftada
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xHaftada
        : null
    );

    formVars.parqform_656623_value = ReactSystemFunctions.value(
      this,
      stateVars.FormParQById?.length > 0
        ? stateVars.FormParQById[0].s8xKacSene
        : this.state.FormParQById?.length > 0
        ? this.state.FormParQById[0].s8xKacSene
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  ParQFormComponent_68315_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_183279_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_790913_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_183279_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_68315_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_790913_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_790913_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_68315_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_183279_value", false, null);
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp26011Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_43178_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_567403_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_567403_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_235714_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_235714_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_43178_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_567403_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_576370_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_851781_value", false, null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp329331Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_774297_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp329331Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_303408_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_303408_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp329331Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_774297_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_481773_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp851647Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_846341_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_24523_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_24523_onChange_if0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp319278Visible",
      "visible"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_578820_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_851781_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ParQFormComponent_24523_onChange_else0 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp319278Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_851781_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_146506_value", "", null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_578820_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  ParQFormComponent_24523_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_24523_value", "value", "", "", "")),
        true
      )
    ) {
      isErrorOccurred = await this.ParQFormComponent_24523_onChange_if0();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.ParQFormComponent_24523_onChange_else0();
      if (isErrorOccurred) return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_149846_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_24523_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_409251_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.ParQFormComponent_24523_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_425265_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp127283Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_200490_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp80854Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_738934_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_200490_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_738934_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_552938_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp80854Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_200490_value", false, null);
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_738934_value", true, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_815856_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp217409Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_324203_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp210725Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_86276_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_86276_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_441666_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp210725Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_324203_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_301053_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp673097Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_350858_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp23734Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_696936_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_696936_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_366768_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp23734Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_350858_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_316213_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp707761Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_155638_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp177991Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_681775_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_681775_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_394297_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp177991Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_155638_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_395667_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp911981Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_270593_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp710369Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_597696_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_597696_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_503137_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp710369Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_270593_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_858125_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp714589Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_246596_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp118539Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_354052_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_354052_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_875104_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp118539Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_246596_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_985460_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp696700Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_558941_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp821128Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_323027_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_323027_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_202020_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp821128Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_558941_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_113946_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp532130Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_604531_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp882479Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_649868_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_649868_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_410924_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp882479Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_604531_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_221532_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp503462Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_117386_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp311406Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_212645_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_212645_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_813285_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp311406Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_117386_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_245992_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp761377Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_981775_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_138481_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Divide = await ReactSystemFunctions.divide(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_138481_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormComponent_981775_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormComponent_981775_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParQFormComponent_981775_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "parqform_337464_value",
        ReactSystemFunctions.value(this, this.state.Divide),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParQFormComponent_138481_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.Divide = await ReactSystemFunctions.divide(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_138481_value", "value", "", "", ""))
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormComponent_138481_onChange1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.ParQFormComponent_138481_onChange1_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  ParQFormComponent_138481_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        null
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "parqform_337464_value",
        ReactSystemFunctions.value(this, this.state.Divide),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  ParQFormComponent_703610_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp50002Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_117027_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_117027_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_751235_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp50002Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_703610_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_137075_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp629359Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_943372_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp449366Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_386786_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_386786_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_492488_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp449366Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_943372_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_683570_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp273435Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_443284_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp688878Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_167149_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_167149_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_56334_value", "", null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp688878Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_443284_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_485590_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp892138Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_925296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp502091Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_726714_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp165687Visible", "hidden");
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp165687Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_254468_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp164785Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_245234_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_245234_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp164785Visible", "hidden");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_254468_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_378575_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_336992_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_336992_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_378575_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_329156_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp494078Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_692037_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_692037_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_329156_value", false, null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp494078Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_477451_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp629321Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_75034_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp908768Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_85355_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp661928Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_38196_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp33607Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_918726_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp388124Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_210515_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp679493Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_213310_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_213310_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_210515_value", false, null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp679493Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_538276_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp311202Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_700493_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp389701Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_847131_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp346751Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_736437_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp950581Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_486661_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp671904Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_424187_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp189397Visible");
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_753927_value", false, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_753927_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_424187_value", false, null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp189397Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_216720_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp468286Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_716276_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_241259_value", false, null);
    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp943000Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_241259_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "parqform_716276_value", false, null);
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp943000Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_617340_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp673600Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_247127_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ToggleVisibilityOf = await ReactSystemFunctions.toggleVisibilityOf(this, "isComp161683Visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  ParQFormComponent_518528_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Param1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_235137_value", "value", "", "", "")
        ),
        "string"
      ),
      Param2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_95662_value", "value", "", "", "")
        ),
        "string"
      ),
      Param3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_517539_value", "value", "", "", "")
        ),
        "string"
      ),
      Param4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_3707_value", "value", "", "", "")
        ),
        "string"
      ),
      Param5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_125450_value", "value", "", "", "")
        ),
        "string"
      ),
      Param6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_57759_value", "value", "", "", "")
        ),
        "string"
      ),
      Param7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_802213_value", "value", "", "", "")
        ),
        "string"
      ),
      Param8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_90450_value", "value", "", "", "")
        ),
        "string"
      ),
      sedanter_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      kronik_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_210515_value", "value", "", "", "")
        ),
        "boolean"
      ),
      teshis_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_774297_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParQForm/ParQFormComponent_518528_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQBelirtiSemptom = result?.data.formParQBelirtiSemptom;
    stateVars.FormParQSonuc = result?.data.formParQSonuc;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormComponent_518528_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  ParQFormComponent_518528_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parqform_92022_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.FormParQSonuc?.length > 0
          ? stateVars.FormParQSonuc[0].condition_result
          : this.state.FormParQSonuc?.length > 0
          ? this.state.FormParQSonuc[0].condition_result
          : null
      ),
      null
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "parqform_974898_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.FormParQSonuc?.length > 0
          ? stateVars.FormParQSonuc[0].extra_info
          : this.state.FormParQSonuc?.length > 0
          ? this.state.FormParQSonuc[0].extra_info
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.ParQFormComponent_518528_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  ParQFormComponent_518528_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FormSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.FormParQSonuc?.length > 0
            ? stateVars.FormParQSonuc[0].condition_result
            : this.state.FormParQSonuc?.length > 0
            ? this.state.FormParQSonuc[0].condition_result
            : null
        ),
        "string"
      ),
      FormSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.FormParQSonuc?.length > 0
            ? stateVars.FormParQSonuc[0].extra_info
            : this.state.FormParQSonuc?.length > 0
            ? this.state.FormParQSonuc[0].extra_info
            : null
        ),
        "string"
      ),
      FormTarih_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      S1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_68315_value", "value", "", "", "")),
        "boolean"
      ),
      S1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_183279_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_43178_value", "value", "", "", "")),
        "boolean"
      ),
      S1x3x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_567403_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S1x3x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_235714_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_235137_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_95662_value", "value", "", "", "")),
        "boolean"
      ),
      S2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_517539_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_3707_value", "value", "", "", "")),
        "boolean"
      ),
      S2x5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_125450_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_57759_value", "value", "", "", "")),
        "boolean"
      ),
      S2x7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_802213_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S2x8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_90450_value", "value", "", "", "")),
        "boolean"
      ),
      S3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_774297_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_485590_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_527506_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_139967_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x1x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_426278_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_925296_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_726714_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_289204_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4x2x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_752131_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4xEfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_254468_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S4xEforxProblem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_378575_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_329156_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S5X1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_103815_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X1Check_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S5x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_654161_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X2Check_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S5x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_445930_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X3Check_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S5x4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_342330_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X4Check_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S5x5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_339185_value", "value", "", "", "")
        ),
        "string"
      ),
      S5X5Check_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_210515_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_424187_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7x1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_74533_value", "value", "", "", "")),
        "boolean"
      ),
      S7x2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_740586_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S7x3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_357526_value", "value", "", "", "")
        ),
        "string"
      ),
      S7x3xCheck_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_716276_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S8xGunde_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_673600_value", "value", "", "", "")
        ),
        "number"
      ),
      S8xHaftada_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_161683_value", "value", "", "", "")
        ),
        "number"
      ),
      S8xKacSene_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_656623_value", "value", "", "", "")
        ),
        "number"
      ),
      SedanterDegil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_790913_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Semptom_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          stateVars.FormParQBelirtiSemptom?.length > 0
            ? stateVars.FormParQBelirtiSemptom[0].result
            : this.state.FormParQBelirtiSemptom?.length > 0
            ? this.state.FormParQBelirtiSemptom[0].result
            : null
        ),
        "boolean"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormTarih_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      S3x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_481773_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x10_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_221532_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x10x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_813285_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x10xBoy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_35554_value", "value", "", "", "")),
        "number"
      ),
      S3x10xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x10xKilo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_84142_value", "value", "", "", "")),
        "number"
      ),
      S3x11_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_245992_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x11x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_751235_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x11xHDL_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_138481_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x11xLDL_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_816505_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xResult_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_337464_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x11xTK_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_981775_value", "value", "", "", "")
        ),
        "number"
      ),
      S3x12_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_137075_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x12x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_492488_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x12xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x13_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_683570_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x13x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "parqform_56334_value", "value", "", "", "")),
        "string"
      ),
      S3x13xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x1x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "parqform_146506_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x1xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_425265_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x2x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_552938_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x2xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_815856_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x3x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_441666_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x3xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_301053_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x4x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_366768_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x4xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x5_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_316213_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x5x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_394297_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x5xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x6_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_395667_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x6x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_503137_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x6xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x7_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_858125_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x7x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_875104_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x7xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x8_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_985460_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x8x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_202020_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x8xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      S3x9_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_113946_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S3x9x1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_410924_value", "value", "", "", "")
        ),
        "string"
      ),
      S3x9xIlac_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      FormId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.FormId ?? this.props.screenInputs.formid,
        "Guid"
      ),
      FormTarih_2: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      S6x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_538276_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_713132_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_154499_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_867208_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_622758_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x1x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_590152_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_700493_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_528451_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_581396_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_165036_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_996331_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_761897_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_782070_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x2x7_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_590758_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_847131_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_996970_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_736647_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_718707_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_285485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_710319_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_659579_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x7_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_257868_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x8_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_560619_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x3x9_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_275784_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_736437_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x1_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_883656_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x2_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_627907_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x3_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_893733_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x4_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_786497_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_365318_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x4x6_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_342249_value", "value", "", "", "")
        ),
        "boolean"
      ),
      S6x5_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_739962_value", "value", "", "", "")
        ),
        "string"
      ),
      Sx6x5Check_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "parqform_486661_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "ParQForm/ParQFormComponent_518528_onClick2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQSave = result?.data.formParQSave;
    stateVars.FormParQSoru3Save = result?.data.formParQSoru3Save;
    stateVars.FormParQSoru6Save = result?.data.formParQSoru6Save;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
