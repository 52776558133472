import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPersonCard_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPersonCard_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  KisiProtokolSonDurum: any[];
  KisiProtokolSonDurum_dummy: any[];
  KisiProtokolCheckupList: any[];
  KisiProtokolCheckupList_dummy: any[];
  KisiCheckuplari: any[];
  KisiCheckuplari_dummy: any[];
  KisiCheckuplariHeader: any[];
  KisiCheckuplariHeader_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  KisiCheckupProtokolleri: any[];
  KisiCheckupProtokolleri_dummy: any[];
  KisiSonCheckupProtokolu: any[];
  KisiSonCheckupProtokolu_dummy: any[];
  KisiSelectById: any[];
  KisiSelectById_dummy: any[];
  KisiObservedMi: any[];
  KisiObservedMi_dummy: any[];
  ServiceAll: any[];
  ServiceAll_dummy: any[];
  KisiCheckupBilesenleri: any[];
  KisiCheckupBilesenleri_dummy: any[];
  KisiCheckupBilesenTarihcesi: any[];
  KisiCheckupBilesenTarihcesi_dummy: any[];
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  FormParQByKisi: any[];
  FormParQByKisi_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  CheckupYapabilirMi: any[];
  CheckupYapabilirMi_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  DeleteCheckup: number;
  DeleteCheckup_dummy: number;
  FormParqDelete: number;
  FormParqDelete_dummy: number;
  KisiEkTestleri: any[];
  KisiEkTestleri_dummy: any[];
  StratejiUret: any[];
  StratejiUret_dummy: any[];
  ReceteBilesenPuanlariHesapla: any[];
  ReceteBilesenPuanlariHesapla_dummy: any[];
  HesapKrediHareketleri: any[];
  HesapKrediHareketleri_dummy: any[];
  KrediBakiye: any[];
  KrediBakiye_dummy: any[];
  CopyToClipboard: any;
  CopyToClipboard_dummy: any;
  SelectBasvuruEgitmen: any[];
  SelectBasvuruEgitmen_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp320842Visible: "visible" | "hidden";
  isComp542065Visible: "visible" | "hidden";
  isComp735571Visible: "visible" | "hidden";
  isComp427734Visible: "visible" | "hidden";
  isComp627849Visible: "visible" | "hidden";
  isComp36088Visible: "visible" | "hidden";
  isComp818771Visible: "visible" | "hidden";
  isComp142319Visible: "visible" | "hidden";
  isComp222946Visible: "visible" | "hidden";
  isComp98387Visible: "visible" | "hidden";
  isComp446402Visible: "visible" | "hidden";
  isComp74481Visible: "visible" | "hidden";
  isComp517796Visible: "visible" | "hidden";
  isComp238074Visible: "visible" | "hidden";
  isComp386981Visible: "visible" | "hidden";
  isComp955426Visible: "visible" | "hidden";
  isComp573165Visible: "visible" | "hidden";
  isComp712032Visible: "visible" | "hidden";
  isComp456460Visible: "visible" | "hidden";
  isComp830535Visible: "visible" | "hidden";
  isComp709495Visible: "visible" | "hidden";
  isComp924193Visible: "visible" | "hidden";
  isComp824502Visible: "visible" | "hidden";
  isComp893443Visible: "visible" | "hidden";
  isComp598572Visible: "visible" | "hidden";
  isComp449370Visible: "visible" | "hidden";
  isComp57776Visible: "visible" | "hidden";
  isComp338549Visible: "visible" | "hidden";
  isComp522321Visible: "visible" | "hidden";
  isComp434984Visible: "visible" | "hidden";
  isComp161828Visible: "visible" | "hidden";
  isComp273336Visible: "visible" | "hidden";
  isComp524096Visible: "visible" | "hidden";
  isComp420641Visible: "visible" | "hidden";
  isComp78756Visible: "visible" | "hidden";
  isComp303063Visible: "visible" | "hidden";
  isComp420323Visible: "visible" | "hidden";
  isComp524924Visible: "visible" | "hidden";
  isComp418834Visible: "visible" | "hidden";
  isComp802760Visible: "visible" | "hidden";
  isComp193852Visible: "visible" | "hidden";
  isComp526112Visible: "visible" | "hidden";
  isComp753567Visible: "visible" | "hidden";
  isComp703182Visible: "visible" | "hidden";
  isComp372424Visible: "visible" | "hidden";
  isComp973684Visible: "visible" | "hidden";
  isComp812300Visible: "visible" | "hidden";
  isComp271000Visible: "visible" | "hidden";
  isComp836912Visible: "visible" | "hidden";
  isComp122401Visible: "visible" | "hidden";
  isComp209066Visible: "visible" | "hidden";
  isComp141414Visible: "visible" | "hidden";
  isComp750815Visible: "visible" | "hidden";
  isComp627968Visible: "visible" | "hidden";
  isComp854157Visible: "visible" | "hidden";
  isComp738176Visible: "visible" | "hidden";
  isComp818465Visible: "visible" | "hidden";
  isComp230471Visible: "visible" | "hidden";
  isComp974303Visible: "visible" | "hidden";
  isComp191658Visible: "visible" | "hidden";
  isComp661755Visible: "visible" | "hidden";
  isComp365811Visible: "visible" | "hidden";
  isComp327706Visible: "visible" | "hidden";
  isComp541772Visible: "visible" | "hidden";
  isComp989056Visible: "visible" | "hidden";
  isComp954440Visible: "visible" | "hidden";
  isComp344016Visible: "visible" | "hidden";
  isComp343409Visible: "visible" | "hidden";
  isComp666219Visible: "visible" | "hidden";
  isComp891845Visible: "visible" | "hidden";
  isComp631461Visible: "visible" | "hidden";
  isComp177133Visible: "visible" | "hidden";
  isComp270588Visible: "visible" | "hidden";
  isComp108489Visible: "visible" | "hidden";
  isComp587740Visible: "visible" | "hidden";
  isComp368889Visible: "visible" | "hidden";
  isComp504776Visible: "visible" | "hidden";
  isComp995168Visible: "visible" | "hidden";
  isComp389332Visible: "visible" | "hidden";
  isComp427972Visible: "visible" | "hidden";
  isComp475091Visible: "visible" | "hidden";
  isComp390107Visible: "visible" | "hidden";
  isComp923815Visible: "visible" | "hidden";
  isComp421899Visible: "visible" | "hidden";
  isComp825628Visible: "visible" | "hidden";
  isComp641546Visible: "visible" | "hidden";
  isComp292193Visible: "visible" | "hidden";
  isComp68103Visible: "visible" | "hidden";
  isComp303224Visible: "visible" | "hidden";
  isComp238370Visible: "visible" | "hidden";
  isComp349106Visible: "visible" | "hidden";
  isComp493373Visible: "visible" | "hidden";
  isComp987773Visible: "visible" | "hidden";
  isComp656371Visible: "visible" | "hidden";
  isComp74551Visible: "visible" | "hidden";
  isComp739595Visible: "visible" | "hidden";
  isComp994383Visible: "visible" | "hidden";
  isComp669047Visible: "visible" | "hidden";
  isComp677294Visible: "visible" | "hidden";
  isComp137269Visible: "visible" | "hidden";
  isComp85021Visible: "visible" | "hidden";
  isComp249558Visible: "visible" | "hidden";
  isComp495097Visible: "visible" | "hidden";
  isComp834791Visible: "visible" | "hidden";
  isComp819355Visible: "visible" | "hidden";
  isComp291452Visible: "visible" | "hidden";
  isComp899495Visible: "visible" | "hidden";
  isComp480431Visible: "visible" | "hidden";
  isComp13127Visible: "visible" | "hidden";
  isComp91061Visible: "visible" | "hidden";
  isComp68062Visible: "visible" | "hidden";
  isComp709685Visible: "visible" | "hidden";
  isComp966909Visible: "visible" | "hidden";
  isComp251719Visible: "visible" | "hidden";
  isComp694754Visible: "visible" | "hidden";
  isComp844832Visible: "visible" | "hidden";
  isComp369076Visible: "visible" | "hidden";
  isComp550051Visible: "visible" | "hidden";
  isComp863402Visible: "visible" | "hidden";
  isComp612070Visible: "visible" | "hidden";
  isComp233726Visible: "visible" | "hidden";
  isComp411851Visible: "visible" | "hidden";
  isComp751981Visible: "visible" | "hidden";
  isComp535575Visible: "visible" | "hidden";
  isComp756175Visible: "visible" | "hidden";
  isComp999340Visible: "visible" | "hidden";
  isComp149979Visible: "visible" | "hidden";
  isComp434713Visible: "visible" | "hidden";
  isComp468216Visible: "visible" | "hidden";
  isComp469426Visible: "visible" | "hidden";
  isComp590486Visible: "visible" | "hidden";
  isComp227725Visible: "visible" | "hidden";
  isComp514246Visible: "visible" | "hidden";
  isComp205140Visible: "visible" | "hidden";
  isComp802946Visible: "visible" | "hidden";
  isComp974303Enabled: "enabled" | "disabled";
  isComp389332Enabled: "enabled" | "disabled";
  isComp677294Enabled: "enabled" | "disabled";
  isComp550051Enabled: "enabled" | "disabled";
  isComp863402Enabled: "enabled" | "disabled";
}

export class PersonCard_ScreenBase extends React.PureComponent<IPersonCard_ScreenProps, any> {
  personcard_703229_value_kuikaSelectBoxRef: React.RefObject<any>;
  personcard_843663_value_kuikaTableRef: React.RefObject<any>;
  personcard_670480_value_kuikaTableRef: React.RefObject<any>;
  personcard_702470_value_kuikaSelectBoxRef: React.RefObject<any>;
  personcard_1293305_value_kuikaTableRef: React.RefObject<any>;
  personcard_761946_value_kuikaTableRef: React.RefObject<any>;
  personcard_802342_value_kuikaMultiSelectRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "d0a7e3e7-b3b0-4ede-bade-1d0182ab4306",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 122247, PropertyName: "value", Value: "(" },
        { Id: 179008, PropertyName: "value", Value: ")" },
        { Id: 703229, PropertyName: "placeholder", Value: "Protokol" },
        { Id: 487611, PropertyName: "value", Value: "Sözleşmesi imzalanmamış kişi..." },
        { Id: 434658, PropertyName: "tab", Value: "Check-Up" },
        { Id: 974303, PropertyName: "label", Value: "Yeni Check-Up" },
        { Id: 843663, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 18222, PropertyName: "value", Value: "[datafield:kirilim1]" },
        { Id: 295818, PropertyName: "value", Value: "[datafield:kirilim2]" },
        { Id: 11037, PropertyName: "title", Value: "Checkup Tarihi" },
        { Id: 730227, PropertyName: "value", Value: "[datafield:checkuptarihi]" },
        { Id: 268173, PropertyName: "title", Value: "Yaş" },
        { Id: 622863, PropertyName: "value", Value: "[datafield:yas]" },
        { Id: 365811, PropertyName: "title", Value: "Bileşen 1" },
        { Id: 85409, PropertyName: "value", Value: "[datafield:bilesen1puan]" },
        { Id: 327706, PropertyName: "title", Value: "Bileşen 2" },
        { Id: 566640, PropertyName: "value", Value: "[datafield:bilesen2puan]" },
        { Id: 541772, PropertyName: "title", Value: "Bileşen 3" },
        { Id: 89272, PropertyName: "value", Value: "[datafield:bilesen3puan]" },
        { Id: 989056, PropertyName: "title", Value: "Bileşen 4" },
        { Id: 396921, PropertyName: "value", Value: "[datafield:bilesen4puan]" },
        { Id: 954440, PropertyName: "title", Value: "Bileşen 5" },
        { Id: 686111, PropertyName: "value", Value: "[datafield:bilesen5puan]" },
        { Id: 344016, PropertyName: "title", Value: "Bileşen 6" },
        { Id: 45932, PropertyName: "value", Value: "[datafield:bilesen6puan]" },
        { Id: 343409, PropertyName: "title", Value: "Bileşen 7" },
        { Id: 290476, PropertyName: "value", Value: "[datafield:bilesen7puan]" },
        { Id: 666219, PropertyName: "title", Value: "Bileşen 8" },
        { Id: 27133, PropertyName: "value", Value: "[datafield:bilesen8puan]" },
        { Id: 891845, PropertyName: "title", Value: "Bileşen 9" },
        { Id: 763659, PropertyName: "value", Value: "[datafield:bilesen9puan]" },
        { Id: 631461, PropertyName: "title", Value: "Bileşen 10" },
        { Id: 672831, PropertyName: "value", Value: "[datafield:bilesen10puan]" },
        { Id: 177133, PropertyName: "title", Value: "Bileşen 11" },
        { Id: 669806, PropertyName: "value", Value: "[datafield:bilesen11puan]" },
        { Id: 270588, PropertyName: "title", Value: "Bileşen 12" },
        { Id: 292678, PropertyName: "value", Value: "[datafield:bilesen12puan]" },
        { Id: 108489, PropertyName: "title", Value: "Bileşen 13" },
        { Id: 600356, PropertyName: "value", Value: "[datafield:bilesen13puan]" },
        { Id: 975968, PropertyName: "title", Value: "FitSkor" },
        { Id: 926429, PropertyName: "value", Value: "[datafield:fitskor]" },
        { Id: 995168, PropertyName: "tab", Value: "Sağlık Taraması" },
        { Id: 389332, PropertyName: "label", Value: "Sağlık Taraması" },
        { Id: 670480, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 11761, PropertyName: "title", Value: "Kontrol Tarihi" },
        { Id: 658808, PropertyName: "value", Value: "[datafield:formtarih]" },
        { Id: 704384, PropertyName: "title", Value: "Soru 1" },
        { Id: 427972, PropertyName: "value", Value: "Sedanter Değil" },
        { Id: 475091, PropertyName: "value", Value: "Sedanter" },
        { Id: 346795, PropertyName: "title", Value: "Soru 2" },
        { Id: 390107, PropertyName: "value", Value: "Semptom var" },
        { Id: 923815, PropertyName: "value", Value: "Semptom yok" },
        { Id: 509004, PropertyName: "title", Value: "Soru 3" },
        { Id: 33592, PropertyName: "title", Value: "Soru 4" },
        { Id: 246088, PropertyName: "value", Value: "Anne" },
        { Id: 353045, PropertyName: "value", Value: "Baba" },
        { Id: 532356, PropertyName: "title", Value: "Soru 5" },
        { Id: 736763, PropertyName: "title", Value: "Soru 6" },
        { Id: 406373, PropertyName: "title", Value: "Soru 7" },
        { Id: 749732, PropertyName: "title", Value: "Soru 8" },
        { Id: 353673, PropertyName: "title", Value: "Sonuç" },
        { Id: 362318, PropertyName: "value", Value: "[datafield:formsonuc1]" },
        { Id: 838421, PropertyName: "title", Value: "Öneri" },
        { Id: 690844, PropertyName: "value", Value: "[datafield:formsonuc2]" },
        { Id: 849168, PropertyName: "tab", Value: "Analizler" },
        { Id: 788537, PropertyName: "value", Value: "Bileşen" },
        { Id: 702470, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 669047, PropertyName: "tab", Value: "Ek Testler" },
        { Id: 677294, PropertyName: "label", Value: "Yeni Test" },
        { Id: 1293305, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 2043624, PropertyName: "title", Value: "Test Tarihi" },
        { Id: 3662414, PropertyName: "value", Value: "[datafield:testtarihi]" },
        { Id: 1936218, PropertyName: "title", Value: "Test Adı" },
        { Id: 2076860, PropertyName: "value", Value: "[datafield:testadi]" },
        { Id: 1247357, PropertyName: "title", Value: "Girdi" },
        { Id: 340338, PropertyName: "value", Value: "[datafield:girdi1adi]" },
        { Id: 1052734, PropertyName: "value", Value: "[datafield:girdi1]" },
        { Id: 921182, PropertyName: "value", Value: "[datafield:girdi2adi]" },
        { Id: 925581, PropertyName: "value", Value: "[datafield:girdi2]" },
        { Id: 753983, PropertyName: "value", Value: "[datafield:girdi3adi]" },
        { Id: 989099, PropertyName: "value", Value: "[datafield:girdi3]" },
        { Id: 910695, PropertyName: "value", Value: "[datafield:girdi4adi]" },
        { Id: 692747, PropertyName: "value", Value: "[datafield:girdi4]" },
        { Id: 999042, PropertyName: "title", Value: "Ara Sonuç" },
        { Id: 929793, PropertyName: "value", Value: "[datafield:arasonuc1adi]" },
        { Id: 843549, PropertyName: "value", Value: "[datafield:arasonuc1]" },
        { Id: 161566, PropertyName: "value", Value: "[datafield:arasonuc2adi]" },
        { Id: 794358, PropertyName: "value", Value: "[datafield:arasonuc2]" },
        { Id: 696730, PropertyName: "value", Value: "[datafield:arasonuc3adi]" },
        { Id: 434974, PropertyName: "value", Value: "[datafield:arasonuc3]" },
        { Id: 517338, PropertyName: "title", Value: "Geçmiş" },
        { Id: 971318, PropertyName: "value", Value: "[datafield:gecmis]" },
        { Id: 899495, PropertyName: "tab", Value: "Reçetelendirme" },
        { Id: 797227, PropertyName: "value", Value: "Kalp Gücü" },
        { Id: 113491, PropertyName: "value", Value: "Esneklik" },
        { Id: 153545, PropertyName: "value", Value: "Kuvvet" },
        { Id: 361721, PropertyName: "value", Value: "Strateji" },
        { Id: 431647, PropertyName: "value", Value: "Frekans" },
        { Id: 641210, PropertyName: "value", Value: "Süre" },
        { Id: 847034, PropertyName: "value", Value: "1. GÜN" },
        { Id: 665508, PropertyName: "value", Value: "2. GÜN" },
        { Id: 522665, PropertyName: "value", Value: "3. GÜN" },
        { Id: 340315, PropertyName: "value", Value: "4. GÜN" },
        { Id: 498494, PropertyName: "value", Value: "5. GÜN" },
        { Id: 266223, PropertyName: "value", Value: "6. GÜN" },
        { Id: 125445, PropertyName: "value", Value: "7. GÜN" },
        { Id: 844832, PropertyName: "tab", Value: "Hesap" },
        { Id: 944423, PropertyName: "value", Value: "Hesap No" },
        { Id: 550051, PropertyName: "label", Value: "Kredi Yükle" },
        { Id: 863402, PropertyName: "label", Value: "Bu Hesaptan Gönder" },
        { Id: 144992, PropertyName: "value", Value: "Kredi" },
        { Id: 761946, PropertyName: "nodatafoundmessage", Value: "Kredi hareketi bulunamadı." },
        { Id: 813856, PropertyName: "title", Value: "Title" },
        { Id: 671545, PropertyName: "value", Value: "[datafield:tarih]" },
        { Id: 372146, PropertyName: "title", Value: "Title" },
        { Id: 703487, PropertyName: "value", Value: "[datafield:kredi]" },
        { Id: 579790, PropertyName: "title", Value: "Title" },
        { Id: 296248, PropertyName: "value", Value: "[datafield:hesaptanimi]" },
        { Id: 999340, PropertyName: "tab", Value: "Eğitmen Profili" },
        { Id: 814154, PropertyName: "value", Value: "Akredite Hizmetler" },
        { Id: 802342, PropertyName: "placeholder", Value: "Choose..." },
        { Id: 848586, PropertyName: "value", Value: "Eğitim Düzeyi" },
        { Id: 156711, PropertyName: "value", Value: "Mezun olduğu okul" },
        { Id: 647962, PropertyName: "value", Value: "Bölüm" },
        { Id: 733446, PropertyName: "value", Value: "Lisans Üstü" },
        { Id: 29894, PropertyName: "value", Value: "Tez Konusu" },
        { Id: 647178, PropertyName: "value", Value: "Derece" },
        { Id: 492965, PropertyName: "value", Value: "Sertifika" },
        { Id: 568137, PropertyName: "value", Value: "Üniversite" },
        { Id: 697525, PropertyName: "value", Value: "Bölüm" },
        { Id: 268373, PropertyName: "value", Value: "Meslek" },
        { Id: 991751, PropertyName: "value", Value: "Meslek Tecrübesi" },
        { Id: 157905, PropertyName: "value", Value: "Kulüp" },
        { Id: 701989, PropertyName: "value", Value: "Brans" },
        { Id: 412055, PropertyName: "value", Value: "Okul" },
        { Id: 745735, PropertyName: "value", Value: "Sınıf" },
        { Id: 646823, PropertyName: "value", Value: "Merkez" },
        { Id: 228320, PropertyName: "value", Value: "Verdiği Dersler" },
        { Id: 530808, PropertyName: "value", Value: "Özel Populasyon" },
        { Id: 945197, PropertyName: "value", Value: "Memap platformunda yer almak ister misiniz ?\u2028" },
        { Id: 503945, PropertyName: "value", Value: "Memap platformunun faydaları hakkında bilgi almak istiyorum" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.personcard_703229_value_kuikaSelectBoxRef = React.createRef();
    this.personcard_843663_value_kuikaTableRef = React.createRef();
    this.personcard_670480_value_kuikaTableRef = React.createRef();
    this.personcard_702470_value_kuikaSelectBoxRef = React.createRef();
    this.personcard_1293305_value_kuikaTableRef = React.createRef();
    this.personcard_761946_value_kuikaTableRef = React.createRef();
    this.personcard_802342_value_kuikaMultiSelectRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      KisiProtokolSonDurum: [],
      KisiProtokolCheckupList: [],
      KisiCheckuplari: [],
      KisiCheckuplariHeader: [],
      MyContext: [],
      KisiCheckupProtokolleri: [],
      KisiSonCheckupProtokolu: [],
      KisiSelectById: [],
      KisiObservedMi: [],
      ServiceAll: [],
      IfThenElse: false,
      FormParQByKisi: [],
      ChangeEnabledOf: "",
      NAVIGATE: "",
      CheckupYapabilirMi: [],
      DeleteCheckup: 0,
      FormParqDelete: 0,
      KisiCheckupBilesenTarihcesi: [],
      KisiEkTestleri: [],
      StratejiUret: [],
      ReceteBilesenPuanlariHesapla: [],
      HesapKrediHareketleri: [],
      KrediBakiye: [],
      CopyToClipboard: "",
      SelectBasvuruEgitmen: [],
      SaveRecord: false,
      isComp320842Visible: "hidden",
      isComp542065Visible: "hidden",
      isComp735571Visible: "hidden",
      isComp427734Visible: "hidden",
      isComp627849Visible: "hidden",
      isComp36088Visible: "hidden",
      isComp818771Visible: "hidden",
      isComp142319Visible: "hidden",
      isComp222946Visible: "hidden",
      isComp98387Visible: "hidden",
      isComp446402Visible: "hidden",
      isComp74481Visible: "hidden",
      isComp517796Visible: "hidden",
      isComp238074Visible: "hidden",
      isComp386981Visible: "hidden",
      isComp955426Visible: "hidden",
      isComp573165Visible: "hidden",
      isComp712032Visible: "hidden",
      isComp456460Visible: "hidden",
      isComp830535Visible: "hidden",
      isComp709495Visible: "hidden",
      isComp924193Visible: "hidden",
      isComp824502Visible: "hidden",
      isComp893443Visible: "hidden",
      isComp598572Visible: "hidden",
      isComp449370Visible: "hidden",
      isComp57776Visible: "hidden",
      isComp338549Visible: "hidden",
      isComp522321Visible: "hidden",
      isComp434984Visible: "hidden",
      isComp161828Visible: "hidden",
      isComp273336Visible: "hidden",
      isComp524096Visible: "hidden",
      isComp420641Visible: "hidden",
      isComp78756Visible: "hidden",
      isComp303063Visible: "hidden",
      isComp420323Visible: "hidden",
      isComp524924Visible: "hidden",
      isComp418834Visible: "hidden",
      isComp802760Visible: "hidden",
      isComp193852Visible: "hidden",
      isComp526112Visible: "hidden",
      isComp753567Visible: "hidden",
      isComp703182Visible: "hidden",
      isComp372424Visible: "hidden",
      isComp973684Visible: "hidden",
      isComp812300Visible: "hidden",
      isComp271000Visible: "hidden",
      isComp836912Visible: "hidden",
      isComp122401Visible: "hidden",
      isComp209066Visible: "hidden",
      isComp141414Visible: "hidden",
      isComp750815Visible: "hidden",
      isComp627968Visible: "hidden",
      isComp854157Visible: "hidden",
      isComp738176Visible: "hidden",
      isComp818465Visible: "hidden",
      isComp230471Visible: "hidden",
      isComp974303Visible: "hidden",
      isComp191658Visible: "hidden",
      isComp661755Visible: "hidden",
      isComp365811Visible: "hidden",
      isComp327706Visible: "hidden",
      isComp541772Visible: "hidden",
      isComp989056Visible: "hidden",
      isComp954440Visible: "hidden",
      isComp344016Visible: "hidden",
      isComp343409Visible: "hidden",
      isComp666219Visible: "hidden",
      isComp891845Visible: "hidden",
      isComp631461Visible: "hidden",
      isComp177133Visible: "hidden",
      isComp270588Visible: "hidden",
      isComp108489Visible: "hidden",
      isComp587740Visible: "hidden",
      isComp368889Visible: "hidden",
      isComp504776Visible: "hidden",
      isComp995168Visible: "hidden",
      isComp389332Visible: "hidden",
      isComp427972Visible: "hidden",
      isComp475091Visible: "hidden",
      isComp390107Visible: "hidden",
      isComp923815Visible: "hidden",
      isComp421899Visible: "hidden",
      isComp825628Visible: "hidden",
      isComp641546Visible: "hidden",
      isComp292193Visible: "hidden",
      isComp68103Visible: "hidden",
      isComp303224Visible: "hidden",
      isComp238370Visible: "hidden",
      isComp349106Visible: "hidden",
      isComp493373Visible: "hidden",
      isComp987773Visible: "hidden",
      isComp656371Visible: "hidden",
      isComp74551Visible: "hidden",
      isComp739595Visible: "hidden",
      isComp994383Visible: "hidden",
      isComp669047Visible: "hidden",
      isComp677294Visible: "hidden",
      isComp137269Visible: "hidden",
      isComp85021Visible: "hidden",
      isComp249558Visible: "hidden",
      isComp495097Visible: "hidden",
      isComp834791Visible: "hidden",
      isComp819355Visible: "hidden",
      isComp291452Visible: "hidden",
      isComp899495Visible: "hidden",
      isComp480431Visible: "hidden",
      isComp13127Visible: "hidden",
      isComp91061Visible: "hidden",
      isComp68062Visible: "hidden",
      isComp709685Visible: "hidden",
      isComp966909Visible: "hidden",
      isComp251719Visible: "hidden",
      isComp694754Visible: "hidden",
      isComp844832Visible: "hidden",
      isComp369076Visible: "hidden",
      isComp550051Visible: "hidden",
      isComp863402Visible: "hidden",
      isComp612070Visible: "hidden",
      isComp233726Visible: "hidden",
      isComp411851Visible: "hidden",
      isComp751981Visible: "hidden",
      isComp535575Visible: "hidden",
      isComp756175Visible: "hidden",
      isComp999340Visible: "hidden",
      isComp149979Visible: "hidden",
      isComp434713Visible: "hidden",
      isComp468216Visible: "hidden",
      isComp469426Visible: "hidden",
      isComp590486Visible: "hidden",
      isComp227725Visible: "hidden",
      isComp514246Visible: "hidden",
      isComp205140Visible: "hidden",
      isComp802946Visible: "hidden",
      isComp974303Enabled: "disabled",
      isComp389332Enabled: "disabled",
      isComp677294Enabled: "disabled",
      isComp550051Enabled: "disabled",
      isComp863402Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("personcard", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PersonCardPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("personcard", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("personcard", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PersonCardPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      personcard_703229_value: this.state.KisiSonCheckupProtokolu?.at?.(0)?.protokolId ?? undefined,
      personcard_702470_value: this.state.KisiCheckupBilesenleri?.at?.(0)?.bilesenId ?? undefined,
      personcard_296047_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.foto ?? undefined,
      personcard_802342_value: this.state.KisiSelectById?.at?.(0)?.yetkiliHizmetIds ?? undefined,
      personcard_551151_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapYer ?? undefined,
      personcard_798203_value: this.state.SelectBasvuruEgitmen?.at?.(0)?.memapFayda ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  PersonCardPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      KisiId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_6: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp974303Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp191658Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Adi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp661755Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim2Adi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389332Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp677294Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550051Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesaptanParaTransferEdebilir
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesaptanParaTransferEdebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp863402Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesaptanParaTransferEdebilir
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesaptanParaTransferEdebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiCheckupProtokolleri = result?.data.kisiCheckupProtokolleri;

    formVars.personcard_703229_value =
      stateVars.KisiSonCheckupProtokolu?.length > 0
        ? stateVars.KisiSonCheckupProtokolu[0].protokolId
        : this.state.KisiSonCheckupProtokolu?.length > 0
        ? this.state.KisiSonCheckupProtokolu[0].protokolId
        : null;
    formVars.personcard_703229_options = stateVars.KisiCheckupProtokolleri;
    stateVars.KisiSonCheckupProtokolu = result?.data.kisiSonCheckupProtokolu;
    formVars.personcard_703229_value =
      stateVars.KisiSonCheckupProtokolu?.length > 0
        ? stateVars.KisiSonCheckupProtokolu[0].protokolId
        : this.state.KisiSonCheckupProtokolu?.length > 0
        ? this.state.KisiSonCheckupProtokolu[0].protokolId
        : null;
    formVars.personcard_703229_options = stateVars.KisiCheckupProtokolleri;
    stateVars.KisiSelectById = result?.data.kisiSelectById;
    formVars.personcard_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );
    formVars.personcard_152914_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );
    formVars.personcard_313997_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );
    formVars.personcard_589386_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].hesapId
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].hesapId
        : null
    );
    formVars.personcard_802342_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yetkiliHizmetIds
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yetkiliHizmetIds
        : null;
    formVars.personcard_802342_options = stateVars.ServiceAll;
    stateVars.isComp320842Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp369076Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp999340Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].egitimProfiliGorunmeliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].egitimProfiliGorunmeliMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974303Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp389332Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp677294Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp550051Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesabiHazirMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesabiHazirMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp863402Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesabiHazirMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesabiHazirMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.KisiObservedMi = result?.data.kisiObservedMi;
    stateVars.isComp974303Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368889Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_843663_value", "guncellenebilirMi"),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp504776Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_843663_value", "silinebilirMi"),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995168Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669047Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp899495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp844832Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.ServiceAll = result?.data.serviceAll;

    formVars.personcard_802342_value =
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yetkiliHizmetIds
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yetkiliHizmetIds
        : null;
    formVars.personcard_802342_options = stateVars.ServiceAll;
    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardPageInit_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      rolename_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        "Guid"
      ),
      rolename_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardPageInit_if1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiProtokolCheckupList = result?.data.kisiProtokolCheckupList;
    stateVars.KisiCheckupBilesenleri = result?.data.kisiCheckupBilesenleri;

    formVars.personcard_702470_value =
      stateVars.KisiCheckupBilesenleri?.length > 0
        ? stateVars.KisiCheckupBilesenleri[0].bilesenId
        : this.state.KisiCheckupBilesenleri?.length > 0
        ? this.state.KisiCheckupBilesenleri[0].bilesenId
        : null;
    formVars.personcard_702470_options = stateVars.KisiCheckupBilesenleri;
    stateVars.KisiCheckupBilesenTarihcesi = result?.data.kisiCheckupBilesenTarihcesi;

    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonCardPageInit_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupProtokolleri?.length > 0
            ? stateVars.KisiCheckupProtokolleri[0].protokolId
            : this.state.KisiCheckupProtokolleri?.length > 0
            ? this.state.KisiCheckupProtokolleri[0].protokolId
            : null
        ),
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupProtokolleri?.length > 0
            ? stateVars.KisiCheckupProtokolleri[0].protokolId
            : this.state.KisiCheckupProtokolleri?.length > 0
            ? this.state.KisiCheckupProtokolleri[0].protokolId
            : null
        ),
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      rolename_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupProtokolleri?.length > 0
            ? stateVars.KisiCheckupProtokolleri[0].protokolId
            : this.state.KisiCheckupProtokolleri?.length > 0
            ? this.state.KisiCheckupProtokolleri[0].protokolId
            : null
        ),
        "Guid"
      ),
      rolename_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupProtokolleri?.length > 0
            ? stateVars.KisiCheckupProtokolleri[0].protokolId
            : this.state.KisiCheckupProtokolleri?.length > 0
            ? this.state.KisiCheckupProtokolleri[0].protokolId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardPageInit_else1", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiProtokolCheckupList = result?.data.kisiProtokolCheckupList;
    stateVars.KisiCheckupBilesenleri = result?.data.kisiCheckupBilesenleri;

    formVars.personcard_702470_value =
      stateVars.KisiCheckupBilesenleri?.length > 0
        ? stateVars.KisiCheckupBilesenleri[0].bilesenId
        : this.state.KisiCheckupBilesenleri?.length > 0
        ? this.state.KisiCheckupBilesenleri[0].bilesenId
        : null;
    formVars.personcard_702470_options = stateVars.KisiCheckupBilesenleri;
    stateVars.KisiCheckupBilesenTarihcesi = result?.data.kisiCheckupBilesenTarihcesi;

    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonCardPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.PersonCardPageInit_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonCardPageInit_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  PersonCardPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQByKisi = result?.data.formParQByKisi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiCheckupProtokolleri?.length > 0
            ? stateVars.KisiCheckupProtokolleri[0].protokolId
            : this.state.KisiCheckupProtokolleri?.length > 0
            ? this.state.KisiCheckupProtokolleri[0].protokolId
            : null
        ),
        null
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp974303Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonCardPageInit4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonCardPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  PersonCardPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp320842Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "E"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp542065Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].cinsiyet
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].cinsiyet
            : null
        ),
        "K"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_640843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].adi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].adi
        : null
    );

    formVars.personcard_152914_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].soyadi
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].soyadi
        : null
    );

    formVars.personcard_313997_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yas
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yas
        : null
    );

    formVars.personcard_703229_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSonCheckupProtokolu?.length > 0
        ? stateVars.KisiSonCheckupProtokolu[0].protokolId
        : this.state.KisiSonCheckupProtokolu?.length > 0
        ? this.state.KisiSonCheckupProtokolu[0].protokolId
        : null
    );

    stateVars.dataSource_703229 = this.state.KisiCheckupProtokolleri;
    stateVars.dataSource_703229 = this.state.KisiCheckupProtokolleri;
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );

    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );

    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );

    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );

    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );

    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );

    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );

    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );

    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );

    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );

    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );

    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );

    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );

    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );

    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );

    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );

    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );

    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );

    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );

    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );

    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );

    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );

    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );

    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );

    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );

    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );

    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );

    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );

    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );

    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );

    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );

    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );

    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );

    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );

    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );

    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );

    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );

    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );

    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );

    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );

    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );

    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );

    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );

    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );

    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );

    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );

    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );

    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );

    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );

    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );

    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );

    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );

    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );

    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );

    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );

    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );

    stateVars.isComp230471Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974303Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974303Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    formVars.personcard_191658_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim1Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim1Adi
        : null
    );

    stateVars.isComp191658Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Adi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_661755_title = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].kirilim2Adi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].kirilim2Adi
        : null
    );

    stateVars.isComp661755Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim2Adi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim2Adi
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp368889Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_843663_value", "guncellenebilirMi"),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp504776Visible =
      (ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_843663_value", "silinebilirMi"),
        "1"
      ) &&
        ReactSystemFunctions.isNotEqualTo(
          ReactSystemFunctions.value(
            this,
            stateVars.KisiObservedMi?.length > 0
              ? stateVars.KisiObservedMi[0].kisiObservedMi
              : this.state.KisiObservedMi?.length > 0
              ? this.state.KisiObservedMi[0].kisiObservedMi
              : null
          ),
          "1"
        )) === true
        ? "visible"
        : "hidden";
    stateVars.isComp995168Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389332Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp389332Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";

    stateVars.dataSource_670480 = this.state.FormParQByKisi;
    stateVars.isComp427972Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_670480_value", "sedanterDegil"),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp475091Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_670480_value", "sedanterDegil"),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390107Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "semptom"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp923815Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "semptom"), false) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp421899Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s3"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp825628Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s3"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp641546Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s4x1"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp292193Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s4x1"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp68103Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s4x2"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303224Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s4x2"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp238370Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s5"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp349106Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s5"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp493373Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s6"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987773Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s6"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp656371Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s7"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74551Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s7"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp739595Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s8"), true) === true
        ? "visible"
        : "hidden";
    stateVars.isComp994383Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "personcard_670480_value", "s8"), true) ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp669047Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp677294Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].txnEkleyebilirMi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].txnEkleyebilirMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp677294Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].sozlesmeOnayliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].sozlesmeOnayliMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp137269Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "girdi1Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp85021Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "girdi2Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp249558Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "girdi3Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp495097Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "girdi4Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp834791Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "araSonuc1Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819355Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "araSonuc2Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp291452Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "personcard_1293305_value", "araSonuc3Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp899495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp480431Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13127Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91061Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68062Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709685Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966909Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp694754Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp844832Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiObservedMi?.length > 0
            ? stateVars.KisiObservedMi[0].kisiObservedMi
            : this.state.KisiObservedMi?.length > 0
            ? this.state.KisiObservedMi[0].kisiObservedMi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_589386_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].hesapId
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].hesapId
        : null
    );

    stateVars.isComp369076Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550051Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesaptanParaTransferEdebilir
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesaptanParaTransferEdebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp550051Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesabiHazirMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesabiHazirMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp863402Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesaptanParaTransferEdebilir
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesaptanParaTransferEdebilir
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp863402Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesabiHazirMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesabiHazirMi
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp612070Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_761946_value", "isArtiVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp233726Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "personcard_761946_value", "isMinusVisible"),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp411851Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_761946_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp751981Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_761946_value", "isFromMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp535575Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_761946_value", "isToMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp756175Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "personcard_761946_value", "isToMe"), "1") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp999340Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].egitimProfiliGorunmeliMi
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].egitimProfiliGorunmeliMi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.personcard_802342_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiSelectById?.length > 0
        ? stateVars.KisiSelectById[0].yetkiliHizmetIds
        : this.state.KisiSelectById?.length > 0
        ? this.state.KisiSelectById[0].yetkiliHizmetIds
        : null
    );

    stateVars.dataSource_802342 = this.state.ServiceAll;
    stateVars.dataSource_802342 = this.state.ServiceAll;
    stateVars.isComp149979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434713Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp469426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp590486Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227725Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514246Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp205140Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersonCardComponent_805655_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_703229_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_703229_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.KisiProtokolCheckupList = result?.data.kisiProtokolCheckupList;
    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_703229_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_703229_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );

    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );

    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );

    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );

    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );

    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );

    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );

    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );

    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );

    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );

    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );

    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );

    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );

    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );

    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );

    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );

    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );

    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );

    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );

    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );

    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );

    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );

    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );

    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );

    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );

    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );

    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );

    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );

    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );

    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );

    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );

    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );

    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );

    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );

    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );

    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );

    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );

    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );

    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );

    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );

    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );

    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );

    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );

    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );

    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );

    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );

    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );

    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );

    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );

    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );

    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );

    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );

    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );

    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );

    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );

    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );

    stateVars.dataSource_843663 = this.state.KisiCheckuplari;
    formVars.personcard_841035_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.KisiCheckuplariHeader?.length > 0
        ? stateVars.KisiCheckuplariHeader[0].header
        : this.state.KisiCheckuplariHeader?.length > 0
        ? this.state.KisiCheckuplariHeader[0].header
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_470071_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport",
      "CheckupId",
      ReactSystemFunctions.value(
        this,
        stateVars.KisiProtokolSonDurum?.length > 0
          ? stateVars.KisiProtokolSonDurum[0].sonCheckupId
          : this.state.KisiProtokolSonDurum?.length > 0
          ? this.state.KisiProtokolSonDurum[0].sonCheckupId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "CheckupReport",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "189806",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_974303_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      hesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      checkupyapanhesapID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].hesapId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].hesapId
            : null
        ),
        "Guid"
      ),
      kurumId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      hizmetid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "hizmetId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_974303_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckupYapabilirMi = result?.data.checkupYapabilirMi;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_974303_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_974303_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "KisiId",
      this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables("CheckupF", "CheckupId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(
          this,
          "personcard_703229_value",
          "value",
          "KisiCheckupProtokolleri",
          "protokolId",
          "protokolId"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables("CheckupF", "ShowDownload", false);
    KuikaAppManager.addToPageInputVariables("CheckupF", "RandevuId", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonCardComponent_974303_onClick_if11_,
      "PersonCard",
      "CheckupF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "537386",
      null,
      "right",
      null,
      "100%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonCardComponent_974303_onClick_if11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonCard/PersonCardComponent_974303_onClick_if11_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  PersonCardComponent_974303_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.toString(
        this,
        stateVars.CheckupYapabilirMi?.length > 0
          ? stateVars.CheckupYapabilirMi[0].notify
          : this.state.CheckupYapabilirMi?.length > 0
          ? this.state.CheckupYapabilirMi[0].notify
          : null
      ),
      "warning",
      "bottom-right",
      0,
      "",
      "",
      0
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  PersonCardComponent_974303_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupYapabilirMi?.length > 0
            ? stateVars.CheckupYapabilirMi[0].yapabilir
            : this.state.CheckupYapabilirMi?.length > 0
            ? this.state.CheckupYapabilirMi[0].yapabilir
            : null
        ),
        "1"
      )
    ) {
      isErrorOccurred = await this.PersonCardComponent_974303_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.PersonCardComponent_974303_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_587740_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport",
      "CheckupId",
      ReactSystemFunctions.value(this, "personcard_843663_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "CheckupReport",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "168529",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_734592_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("CheckupReport3", "ShowDownload", true);
    KuikaAppManager.addToPageInputVariables(
      "CheckupReport3",
      "CheckupId",
      ReactSystemFunctions.value(this, "personcard_843663_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "CheckupReport3",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "395237",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_368889_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "KisiId",
      ReactSystemFunctions.value(this, "personcard_843663_value", "kisiId")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "CheckupId",
      ReactSystemFunctions.value(this, "personcard_843663_value", "id")
    );
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(
          this,
          "personcard_703229_value",
          "value",
          "KisiCheckupProtokolleri",
          "protokolId",
          "protokolId"
        )
      )
    );
    KuikaAppManager.addToPageInputVariables("CheckupF", "ShowDownload", false);
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "RandevuId",
      ReactSystemFunctions.value(this, "personcard_843663_value", "randevuId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonCardComponent_368889_onClick1_,
      "PersonCard",
      "CheckupF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "1514",
      null,
      "right",
      null,
      "70%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonCardComponent_368889_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      rolename_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSonCheckupProtokolu?.length > 0
            ? stateVars.KisiSonCheckupProtokolu[0].protokolId
            : this.state.KisiSonCheckupProtokolu?.length > 0
            ? this.state.KisiSonCheckupProtokolu[0].protokolId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_368889_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

    stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

    stateVars.KisiProtokolSonDurum = result?.data.kisiProtokolSonDurum;
    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );
    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );
    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );
    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );
    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );
    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );
    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );
    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );
    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );
    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );
    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );
    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );
    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );
    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );
    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );
    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );
    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );
    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );
    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );
    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );
    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );
    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );
    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );
    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );
    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );
    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );
    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );
    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );
    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );
    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );
    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );
    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );
    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );
    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );
    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );
    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );
    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );
    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );
    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );
    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );
    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );
    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );
    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );
    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );
    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );
    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );
    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );
    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );
    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );
    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );
    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );
    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );
    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );
    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );
    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );
    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );
    stateVars.isComp735571Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp427734Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627849Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp36088Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen1IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818771Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp142319Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp222946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp98387Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen2IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp446402Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp74481Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp517796Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp238074Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen3IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp386981Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp573165Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp712032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen4IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp456460Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830535Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp924193Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen5IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp824502Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp893443Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp598572Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp449370Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen6IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57776Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp338549Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp522321Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434984Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen7IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp161828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp273336Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524096Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].fitSkorIsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420641Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp78756Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp303063Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp420323Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp524924Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp418834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp193852Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen8IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp526112Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp753567Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp703182Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp372424Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen10IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973684Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp812300Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271000Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp836912Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen11IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp122401Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp209066Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp141414Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp750815Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen12IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp627968Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854157Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "-1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp738176Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp818465Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen13IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365811Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp327706Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp541772Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp989056Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954440Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp344016Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp343409Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp666219Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp891845Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp631461Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp177133Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp270588Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108489Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesenAdedi
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp251719Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiProtokolSonDurum?.length > 0
            ? stateVars.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : this.state.KisiProtokolSonDurum?.length > 0
            ? this.state.KisiProtokolSonDurum[0].bilesen9IsAugmented
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_368889_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_368889_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_327360_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Adi
        : null
    );

    formVars.personcard_211038_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val1
        : null
    );

    formVars.personcard_144418_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val2
        : null
    );

    formVars.personcard_598455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen1Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen1Val3
        : null
    );

    formVars.personcard_357392_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Adi
        : null
    );

    formVars.personcard_997297_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val1
        : null
    );

    formVars.personcard_962491_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val2
        : null
    );

    formVars.personcard_592523_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen2Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen2Val3
        : null
    );

    formVars.personcard_658734_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Adi
        : null
    );

    formVars.personcard_25555_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val1
        : null
    );

    formVars.personcard_667910_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val2
        : null
    );

    formVars.personcard_453982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen3Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen3Val3
        : null
    );

    formVars.personcard_490016_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Adi
        : null
    );

    formVars.personcard_343790_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val1
        : null
    );

    formVars.personcard_999283_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val2
        : null
    );

    formVars.personcard_870222_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen4Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen4Val3
        : null
    );

    formVars.personcard_330982_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Adi
        : null
    );

    formVars.personcard_723972_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val1
        : null
    );

    formVars.personcard_206133_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val2
        : null
    );

    formVars.personcard_456557_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen5Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen5Val3
        : null
    );

    formVars.personcard_960004_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Adi
        : null
    );

    formVars.personcard_262452_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val1
        : null
    );

    formVars.personcard_171927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val2
        : null
    );

    formVars.personcard_275807_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen6Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen6Val3
        : null
    );

    formVars.personcard_699661_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Adi
        : null
    );

    formVars.personcard_782898_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val1
        : null
    );

    formVars.personcard_677416_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val2
        : null
    );

    formVars.personcard_354184_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen7Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen7Val3
        : null
    );

    formVars.personcard_896455_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorAdi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorAdi
        : null
    );

    formVars.personcard_714865_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal1
        : null
    );

    formVars.personcard_656078_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal2
        : null
    );

    formVars.personcard_288450_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].fitSkorVal3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].fitSkorVal3
        : null
    );

    formVars.personcard_750354_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Adi
        : null
    );

    formVars.personcard_717005_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val1
        : null
    );

    formVars.personcard_841994_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val2
        : null
    );

    formVars.personcard_241365_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen8Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen8Val3
        : null
    );

    formVars.personcard_910562_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Adi
        : null
    );

    formVars.personcard_923616_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val1
        : null
    );

    formVars.personcard_157993_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val2
        : null
    );

    formVars.personcard_43927_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen9Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen9Val3
        : null
    );

    formVars.personcard_156520_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[stateVars.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[this.state.KisiProtokolSonDurum.length - 1].bilesen10Adi
        : null
    );

    formVars.personcard_333745_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val1
        : null
    );

    formVars.personcard_705132_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val2
        : null
    );

    formVars.personcard_668156_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen10Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen10Val3
        : null
    );

    formVars.personcard_786453_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Adi
        : null
    );

    formVars.personcard_569873_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val1
        : null
    );

    formVars.personcard_941843_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val2
        : null
    );

    formVars.personcard_71576_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen11Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen11Val3
        : null
    );

    formVars.personcard_433063_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Adi
        : null
    );

    formVars.personcard_387408_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val1
        : null
    );

    formVars.personcard_543796_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val2
        : null
    );

    formVars.personcard_468041_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen12Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen12Val3
        : null
    );

    formVars.personcard_339049_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Adi
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Adi
        : null
    );

    formVars.personcard_613210_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val1
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val1
        : null
    );

    formVars.personcard_755444_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val2
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val2
        : null
    );

    formVars.personcard_399100_value = ReactSystemFunctions.toString(
      this,
      stateVars.KisiProtokolSonDurum?.length > 0
        ? stateVars.KisiProtokolSonDurum[0].bilesen13Val3
        : this.state.KisiProtokolSonDurum?.length > 0
        ? this.state.KisiProtokolSonDurum[0].bilesen13Val3
        : null
    );

    stateVars.dataSource_843663 = this.state.KisiCheckuplari;
    formVars.personcard_841035_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.KisiCheckuplariHeader?.length > 0
        ? stateVars.KisiCheckuplariHeader[0].header
        : this.state.KisiCheckuplariHeader?.length > 0
        ? this.state.KisiCheckuplariHeader[0].header
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_504776_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      checkupId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "personcard_843663_value", "id"),
        "Guid"
      ),
      rolename_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      ),
      rolename_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "string"
      ),
      kisiId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_703229_value",
            "value",
            "KisiCheckupProtokolleri",
            "protokolId",
            "protokolId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "da251faf_7e74_c441_b8e0_c0995ce1a271_confirmation",
        this.defaultML,
        "Kaydı silmek istediğinizden emin misiniz? (Bu işlem geri alınamaz)"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_504776_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteCheckup = result?.data.deleteCheckup;
      stateVars.KisiCheckuplari = result?.data.kisiCheckuplari;

      stateVars.KisiCheckuplariHeader = result?.data.kisiCheckuplariHeader;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.PersonCardComponent_504776_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_504776_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_843663 = this.state.KisiCheckuplari;
    formVars.personcard_841035_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.KisiCheckuplariHeader?.length > 0
        ? stateVars.KisiCheckuplariHeader[0].header
        : this.state.KisiCheckuplariHeader?.length > 0
        ? this.state.KisiCheckuplariHeader[0].header
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_389332_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParQFormcopy",
      "KisiId",
      this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables("ParQFormcopy", "FormId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables("ParQFormcopy", "NewEditView", "N");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonCardComponent_389332_onClick1_,
      "PersonCard",
      "ParQFormcopy",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "177324",
      null,
      "right",
      null,
      "70%",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonCardComponent_389332_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_389332_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParQByKisi = result?.data.formParQByKisi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_389332_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_389332_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_670480 = this.state.FormParQByKisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_687408_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParQFormcopy",
      "KisiId",
      this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables(
      "ParQFormcopy",
      "FormId",
      ReactSystemFunctions.value(this, "personcard_670480_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("ParQFormcopy", "NewEditView", "V");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "ParQFormcopy",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "635164",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_631645_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "ParQFormcopy",
      "KisiId",
      this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables(
      "ParQFormcopy",
      "FormId",
      ReactSystemFunctions.value(this, "personcard_670480_value", "id")
    );
    KuikaAppManager.addToPageInputVariables("ParQFormcopy", "NewEditView", "E");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "ParQFormcopy",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "550396",
      null,
      "right",
      null,
      "70%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_219962_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      FormId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "personcard_670480_value", "id"),
        "Guid"
      ),
      KisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_219962_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.FormParqDelete = result?.data.formParqDelete;
    stateVars.FormParQByKisi = result?.data.formParQByKisi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_219962_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_219962_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_670480 = this.state.FormParQByKisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_702470_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      bilesenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "personcard_702470_value",
            "value",
            "KisiCheckupBilesenleri",
            "bilesenId",
            "bilesenId"
          )
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_702470_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiCheckupBilesenTarihcesi = result?.data.kisiCheckupBilesenTarihcesi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_702470_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_702470_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_267342 = this.state.KisiCheckupBilesenTarihcesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_669047_onActive = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_669047_onActive", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KisiEkTestleri = result?.data.kisiEkTestleri;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_669047_onActive1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_669047_onActive1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_1293305 = this.state.KisiEkTestleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_677294_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EkTestF",
      "KisiId",
      this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables("EkTestF", "EkTEstId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "EkTestF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "277918",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_477648_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EkTestGecmisi",
      "TestId",
      ReactSystemFunctions.value(this, "personcard_1293305_value", "testId")
    );
    KuikaAppManager.addToPageInputVariables(
      "EkTestGecmisi",
      "KisiId",
      ReactSystemFunctions.value(this, "personcard_1293305_value", "kisiId")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonCard",
      "EkTestGecmisi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "633023",
      null,
      "right",
      null,
      "680px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_899495_onActive = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      frekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_211053_value", "value", "", "", "")
        ),
        "number"
      ),
      sure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_39371_value", "value", "", "", "")
        ),
        "number"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_899495_onActive", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.StratejiUret = result?.data.stratejiUret;
    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );
    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );
    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );
    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );
    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );
    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );
    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );
    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );
    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );
    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );
    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );
    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );
    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );
    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );
    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );
    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );
    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );
    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );
    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );
    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );
    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );
    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );
    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );
    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );
    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );
    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );
    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );
    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );
    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );
    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );
    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );
    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );
    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );
    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );
    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );
    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );
    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );
    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );
    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );
    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );
    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );
    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );
    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );
    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );
    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );
    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );
    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );
    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );
    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );
    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );
    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );
    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );
    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );
    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );
    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );
    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );
    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );
    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );
    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );
    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );
    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );
    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );
    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );
    stateVars.isComp480431Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13127Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91061Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68062Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709685Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966909Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp694754Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.ReceteBilesenPuanlariHesapla = result?.data.receteBilesenPuanlariHesapla;
    formVars.personcard_644887_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].kalpGucu
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].kalpGucu
        : null
    );
    formVars.personcard_111350_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].esneklik
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].esneklik
        : null
    );
    formVars.personcard_293313_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].kuvvet
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].kuvvet
        : null
    );
    formVars.personcard_111248_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].strateji
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].strateji
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_899495_onActive1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_899495_onActive1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_644887_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].kalpGucu
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].kalpGucu
        : null
    );

    formVars.personcard_111350_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].esneklik
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].esneklik
        : null
    );

    formVars.personcard_293313_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].kuvvet
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].kuvvet
        : null
    );

    formVars.personcard_111248_value = ReactSystemFunctions.toString(
      this,
      stateVars.ReceteBilesenPuanlariHesapla?.length > 0
        ? stateVars.ReceteBilesenPuanlariHesapla[0].strateji
        : this.state.ReceteBilesenPuanlariHesapla?.length > 0
        ? this.state.ReceteBilesenPuanlariHesapla[0].strateji
        : null
    );

    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );

    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );

    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );

    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );

    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );

    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );

    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );

    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );

    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );

    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );

    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );

    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );

    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );

    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );

    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );

    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );

    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );

    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );

    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );

    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );

    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );

    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );

    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );

    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );

    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );

    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );

    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );

    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );

    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );

    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );

    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );

    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );

    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );

    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );

    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );

    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );

    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );

    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );

    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );

    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );

    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );

    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );

    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );

    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );

    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );

    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );

    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );

    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );

    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );

    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );

    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );

    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );

    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );

    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );

    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );

    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );

    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );

    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );

    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );

    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );

    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );

    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );

    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_211053_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      frekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_211053_value", "value", "", "", "")
        ),
        "number"
      ),
      sure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_39371_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_211053_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.StratejiUret = result?.data.stratejiUret;
    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );
    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );
    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );
    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );
    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );
    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );
    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );
    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );
    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );
    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );
    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );
    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );
    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );
    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );
    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );
    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );
    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );
    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );
    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );
    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );
    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );
    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );
    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );
    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );
    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );
    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );
    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );
    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );
    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );
    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );
    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );
    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );
    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );
    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );
    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );
    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );
    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );
    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );
    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );
    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );
    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );
    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );
    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );
    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );
    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );
    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );
    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );
    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );
    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );
    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );
    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );
    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );
    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );
    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );
    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );
    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );
    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );
    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );
    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );
    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );
    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );
    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );
    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );
    stateVars.isComp480431Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13127Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91061Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68062Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709685Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966909Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp694754Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_211053_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_211053_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );

    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );

    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );

    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );

    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );

    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );

    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );

    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );

    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );

    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );

    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );

    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );

    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );

    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );

    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );

    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );

    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );

    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );

    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );

    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );

    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );

    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );

    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );

    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );

    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );

    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );

    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );

    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );

    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );

    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );

    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );

    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );

    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );

    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );

    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );

    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );

    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );

    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );

    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );

    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );

    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );

    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );

    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );

    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );

    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );

    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );

    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );

    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );

    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );

    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );

    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );

    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );

    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );

    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );

    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );

    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );

    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );

    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );

    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );

    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );

    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );

    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );

    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_39371_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      kisiId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      frekans_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_211053_value", "value", "", "", "")
        ),
        "number"
      ),
      sure_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toInteger(
          this,
          ReactSystemFunctions.value(this, "personcard_39371_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_39371_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.StratejiUret = result?.data.stratejiUret;
    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );
    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );
    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );
    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );
    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );
    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );
    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );
    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );
    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );
    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );
    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );
    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );
    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );
    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );
    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );
    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );
    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );
    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );
    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );
    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );
    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );
    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );
    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );
    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );
    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );
    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );
    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );
    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );
    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );
    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );
    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );
    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );
    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );
    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );
    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );
    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );
    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );
    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );
    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );
    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );
    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );
    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );
    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );
    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );
    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );
    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );
    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );
    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );
    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );
    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );
    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );
    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );
    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );
    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );
    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );
    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );
    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );
    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );
    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );
    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );
    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );
    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );
    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );
    stateVars.isComp480431Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp13127Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp91061Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp68062Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp709685Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp966909Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp694754Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.StratejiUret?.length > 0
            ? stateVars.StratejiUret[0].frekans
            : this.state.StratejiUret?.length > 0
            ? this.state.StratejiUret[0].frekans
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_39371_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_39371_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_775907_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label1
        : null
    );

    formVars.personcard_347799_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l1
        : null
    );

    formVars.personcard_538837_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay1
        : null
    );

    formVars.personcard_885114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label2
        : null
    );

    formVars.personcard_965597_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l2
        : null
    );

    formVars.personcard_521814_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay2
        : null
    );

    formVars.personcard_65357_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].label3
        : null
    );

    formVars.personcard_138937_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].l3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].l3
        : null
    );

    formVars.personcard_163583_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].detay3
        : null
    );

    formVars.personcard_835090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label1
        : null
    );

    formVars.personcard_358734_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L1
        : null
    );

    formVars.personcard_489625_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay1
        : null
    );

    formVars.personcard_48054_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label2
        : null
    );

    formVars.personcard_297361_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L2
        : null
    );

    formVars.personcard_434314_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay2
        : null
    );

    formVars.personcard_145588_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Label3
        : null
    );

    formVars.personcard_868545_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_L3
        : null
    );

    formVars.personcard_432098_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g2_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g2_Detay3
        : null
    );

    formVars.personcard_887087_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label1
        : null
    );

    formVars.personcard_521491_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L1
        : null
    );

    formVars.personcard_740059_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay1
        : null
    );

    formVars.personcard_714773_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label2
        : null
    );

    formVars.personcard_885883_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L2
        : null
    );

    formVars.personcard_558594_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay2
        : null
    );

    formVars.personcard_338187_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Label3
        : null
    );

    formVars.personcard_161152_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_L3
        : null
    );

    formVars.personcard_193584_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g3_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g3_Detay3
        : null
    );

    formVars.personcard_148587_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label1
        : null
    );

    formVars.personcard_895889_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L1
        : null
    );

    formVars.personcard_371726_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay1
        : null
    );

    formVars.personcard_105723_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label2
        : null
    );

    formVars.personcard_846253_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L2
        : null
    );

    formVars.personcard_395840_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay2
        : null
    );

    formVars.personcard_603649_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Label3
        : null
    );

    formVars.personcard_2815_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_L3
        : null
    );

    formVars.personcard_883218_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g4_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g4_Detay3
        : null
    );

    formVars.personcard_922089_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label1
        : null
    );

    formVars.personcard_246082_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L1
        : null
    );

    formVars.personcard_92428_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay1
        : null
    );

    formVars.personcard_192797_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label2
        : null
    );

    formVars.personcard_806011_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L2
        : null
    );

    formVars.personcard_857336_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay2
        : null
    );

    formVars.personcard_592544_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Label3
        : null
    );

    formVars.personcard_586114_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_L3
        : null
    );

    formVars.personcard_100879_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g5_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g5_Detay3
        : null
    );

    formVars.personcard_335090_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label1
        : null
    );

    formVars.personcard_741104_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L1
        : null
    );

    formVars.personcard_4768_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay1
        : null
    );

    formVars.personcard_242605_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label2
        : null
    );

    formVars.personcard_64395_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L2
        : null
    );

    formVars.personcard_889575_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay2
        : null
    );

    formVars.personcard_337300_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Label3
        : null
    );

    formVars.personcard_311043_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_L3
        : null
    );

    formVars.personcard_457860_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g6_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g6_Detay3
        : null
    );

    formVars.personcard_648009_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label1
        : null
    );

    formVars.personcard_149249_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L1
        : null
    );

    formVars.personcard_40399_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay1
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay1
        : null
    );

    formVars.personcard_610227_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label2
        : null
    );

    formVars.personcard_772499_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L2
        : null
    );

    formVars.personcard_833458_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay2
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay2
        : null
    );

    formVars.personcard_888775_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Label3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Label3
        : null
    );

    formVars.personcard_534786_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_L3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_L3
        : null
    );

    formVars.personcard_693339_value = ReactSystemFunctions.toString(
      this,
      stateVars.StratejiUret?.length > 0
        ? stateVars.StratejiUret[0].g7_Detay3
        : this.state.StratejiUret?.length > 0
        ? this.state.StratejiUret[0].g7_Detay3
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_844832_onActive = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      hesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      ),
      hesapId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_844832_onActive", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_844832_onActive1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_844832_onActive1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_761946 = this.state.HesapKrediHareketleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_369076_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.CopyToClipboard = await ReactSystemFunctions.copyToClipboard(
      ReactSystemFunctions.toString(
        this,
        stateVars.KisiSelectById?.length > 0
          ? stateVars.KisiSelectById[0].hesapId
          : this.state.KisiSelectById?.length > 0
          ? this.state.KisiSelectById[0].hesapId
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_550051_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KrediYukle",
      "KimeHesapId",
      ReactSystemFunctions.value(
        this,
        stateVars.KisiSelectById?.length > 0
          ? stateVars.KisiSelectById[0].hesapId
          : this.state.KisiSelectById?.length > 0
          ? this.state.KisiSelectById[0].hesapId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonCardComponent_550051_onClick1_,
      "PersonCard",
      "KrediYukle",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "101102",
      null,
      "right",
      null,
      "440px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonCardComponent_550051_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      hesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      ),
      hesapId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_550051_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_550051_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_550051_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_761946 = this.state.HesapKrediHareketleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_863402_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "KrediTransferi",
      "KimdenHesapId",
      ReactSystemFunctions.value(
        this,
        stateVars.KisiSelectById?.length > 0
          ? stateVars.KisiSelectById[0].hesapId
          : this.state.KisiSelectById?.length > 0
          ? this.state.KisiSelectById[0].hesapId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.PersonCardComponent_863402_onClick1_,
      "PersonCard",
      "KrediTransferi",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "372956",
      null,
      "right",
      null,
      "440px",
      "100vh",
      false
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  PersonCardComponent_863402_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      hesapId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      ),
      hesapId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].hesapId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].hesapId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_863402_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.HesapKrediHareketleri = result?.data.hesapKrediHareketleri;

    stateVars.KrediBakiye = result?.data.krediBakiye;
    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_863402_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_863402_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_174116_value = ReactSystemFunctions.toString(
      this,
      stateVars.KrediBakiye?.length > 0
        ? stateVars.KrediBakiye[0].bakiye
        : this.state.KrediBakiye?.length > 0
        ? this.state.KrediBakiye[0].bakiye
        : null
    );

    stateVars.dataSource_761946 = this.state.HesapKrediHareketleri;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_999340_onActive = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.KisiSelectById?.length > 0
            ? stateVars.KisiSelectById[0].basvuruEgitmenId
            : this.state.KisiSelectById?.length > 0
            ? this.state.KisiSelectById[0].basvuruEgitmenId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_999340_onActive", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectBasvuruEgitmen = result?.data.selectBasvuruEgitmen;
    formVars.personcard_296047_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].foto
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].foto
        : null
    );
    formVars.personcard_807712_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
        : null
    );
    formVars.personcard_269774_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansMezun
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansMezun
        : null
    );
    formVars.personcard_915098_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansBolum
        : null
    );
    formVars.personcard_907571_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuDerece
        : null
    );
    formVars.personcard_144184_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuKonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuKonu
        : null
    );
    formVars.personcard_95422_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseDerece
        : null
    );
    formVars.personcard_107717_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseSertifika
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseSertifika
        : null
    );
    formVars.personcard_352479_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciUni
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciUni
        : null
    );
    formVars.personcard_337786_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciBolum
        : null
    );
    formVars.personcard_342304_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].meslek
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].meslek
        : null
    );
    formVars.personcard_248469_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].tecrubeSure
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].tecrubeSure
        : null
    );
    formVars.personcard_341468_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorKlup
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorKlup
        : null
    );
    formVars.personcard_784681_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorBrans
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorBrans
        : null
    );
    formVars.personcard_510422_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenOkul
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenOkul
        : null
    );
    formVars.personcard_904969_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenSinif
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenSinif
        : null
    );
    formVars.personcard_781236_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessMerkez
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessMerkez
        : null
    );
    formVars.personcard_385449_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessDers
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessDers
        : null
    );
    formVars.personcard_846382_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ozelPopulasyon
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ozelPopulasyon
        : null
    );
    formVars.personcard_551151_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapYer
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapYer
        : null
    );
    formVars.personcard_798203_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapFayda
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapFayda
        : null
    );
    stateVars.isComp149979Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp434713Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp468216Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lisans üstü"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp469426Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Lise"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp590486Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
            : null
        ),
        "Spor bilimleri fakültesinde öğrenciyim"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227725Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp514246Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Antrenör"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp205140Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Beden eğitimi öğretmeni"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp802946Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectBasvuruEgitmen?.length > 0
            ? stateVars.SelectBasvuruEgitmen[0].meslek
            : this.state.SelectBasvuruEgitmen?.length > 0
            ? this.state.SelectBasvuruEgitmen[0].meslek
            : null
        ),
        "Fitness eğitmeni"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonCardComponent_999340_onActive1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonCardComponent_999340_onActive1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.personcard_296047_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].foto
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].foto
        : null
    );

    formVars.personcard_807712_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].egitimDuzey
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].egitimDuzey
        : null
    );

    formVars.personcard_269774_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansMezun
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansMezun
        : null
    );

    formVars.personcard_915098_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansBolum
        : null
    );

    formVars.personcard_907571_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuDerece
        : null
    );

    formVars.personcard_144184_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].lisansustuKonu
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].lisansustuKonu
        : null
    );

    formVars.personcard_95422_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseDerece
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseDerece
        : null
    );

    formVars.personcard_107717_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].liseSertifika
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].liseSertifika
        : null
    );

    formVars.personcard_352479_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciUni
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciUni
        : null
    );

    formVars.personcard_337786_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ogrenciBolum
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ogrenciBolum
        : null
    );

    formVars.personcard_342304_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].meslek
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].meslek
        : null
    );

    formVars.personcard_248469_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].tecrubeSure
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].tecrubeSure
        : null
    );

    formVars.personcard_341468_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorKlup
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorKlup
        : null
    );

    formVars.personcard_784681_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].antrenorBrans
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].antrenorBrans
        : null
    );

    formVars.personcard_510422_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenOkul
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenOkul
        : null
    );

    formVars.personcard_904969_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].bedenSinif
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].bedenSinif
        : null
    );

    formVars.personcard_781236_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessMerkez
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessMerkez
        : null
    );

    formVars.personcard_385449_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].fitnessDers
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].fitnessDers
        : null
    );

    formVars.personcard_846382_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].ozelPopulasyon
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].ozelPopulasyon
        : null
    );

    formVars.personcard_551151_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapYer
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapYer
        : null
    );

    formVars.personcard_798203_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectBasvuruEgitmen?.length > 0
        ? stateVars.SelectBasvuruEgitmen[0].memapFayda
        : this.state.SelectBasvuruEgitmen?.length > 0
        ? this.state.SelectBasvuruEgitmen[0].memapFayda
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonCardComponent_802342_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.kisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      YetkiliHizmetIds_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "personcard_802342_value", "value", "ServiceAll", "id", "id")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonCard/PersonCardComponent_802342_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [189806, 635164, 550396, 277918, 633023] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PersonCardPageInit();
    }
    if (diId == 537386) {
      isErrorOccurred = await this.PersonCardComponent_974303_onClick_if11_();
      if (isErrorOccurred) return true;
    } else if (diId == 1514) {
      isErrorOccurred = await this.PersonCardComponent_368889_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 177324) {
      isErrorOccurred = await this.PersonCardComponent_389332_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 101102) {
      isErrorOccurred = await this.PersonCardComponent_550051_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 372956) {
      isErrorOccurred = await this.PersonCardComponent_863402_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
