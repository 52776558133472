import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestFilter_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestFilter_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SiniflandirmaSelectAll: any[];
  SiniflandirmaSelectAll_dummy: any[];
  NormmAll: any[];
  NormmAll_dummy: any[];
  BilesenAll: any[];
  BilesenAll_dummy: any[];
  GoBack: any;
  GoBack_dummy: any;
  TestArama: any[];
  TestArama_dummy: any[];
  WriteToLocalStorage: any;
  WriteToLocalStorage_dummy: any;
}

export class TestFilter_ScreenBase extends React.PureComponent<ITestFilter_ScreenProps, any> {
  testfilter_639459_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_105448_value_kuikaMultiSelectRef: React.RefObject<any>;
  testfilter_537552_value_kuikaMultiSelectRef: React.RefObject<any>;
  testfilter_845288_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_108534_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_65627_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_46999_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_405121_value_kuikaSelectBoxRef: React.RefObject<any>;
  testfilter_1725575_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "57e2ee27-a550-40c9-bfe6-5eba16f06007",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "TEST TANIMI" },
        { Id: 303928, PropertyName: "value", Value: "Fitness Bileşeni" },
        { Id: 639459, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 164207, PropertyName: "value", Value: "Amaç" },
        { Id: 105448, PropertyName: "placeholder", Value: "Seçiniz.." },
        { Id: 220536, PropertyName: "value", Value: "Alan" },
        { Id: 537552, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 571306, PropertyName: "value", Value: "Yüklenme" },
        { Id: 845288, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 918177, PropertyName: "value", Value: "Risk Sınıfı" },
        { Id: 108534, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 674175, PropertyName: "value", Value: "Yöntem" },
        { Id: 65627, PropertyName: "placeholder", Value: "Seçiniz" },
        { Id: 529347, PropertyName: "value", Value: "Hedef Bölge" },
        { Id: 46999, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 891032, PropertyName: "value", Value: "Taraf" },
        { Id: 405121, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 421612, PropertyName: "label", Value: "ARA" },
        { Id: 1725575, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 5041034, PropertyName: "title", Value: "Yas Aralığı" },
        { Id: 3816453, PropertyName: "value", Value: "[datafield:yasaraligialt]" },
        { Id: 724408, PropertyName: "value", Value: "-" },
        { Id: 3250234, PropertyName: "value", Value: "[datafield:yasaraligiust]" },
        { Id: 6742937, PropertyName: "title", Value: "Tanım" },
        { Id: 4441913, PropertyName: "value", Value: "[datafield:tanim]" },
        { Id: 795517, PropertyName: "label", Value: "Seç" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testfilter_639459_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_105448_value_kuikaMultiSelectRef = React.createRef();
    this.testfilter_537552_value_kuikaMultiSelectRef = React.createRef();
    this.testfilter_845288_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_108534_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_65627_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_46999_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_405121_value_kuikaSelectBoxRef = React.createRef();
    this.testfilter_1725575_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SiniflandirmaSelectAll: [],
      NormmAll: [],
      BilesenAll: [],
      GoBack: "",
      TestArama: [],
      WriteToLocalStorage: "",
      dataSource_105448: [
        { key: "Saglik", text: "Sağlık" },
        { key: "Beceri", text: "Beceri" },
        { key: "Performans", text: "Performans" }
      ],
      dataSource_537552: [
        { key: "Fitness", text: "Fitness" },
        { key: "Saha", text: "Saha" },
        { key: "Laboratuvar", text: "Laboratuvar" }
      ],
      dataSource_845288: [
        { key: "Maksimal", text: "Maksimal" },
        { key: "Submaksimal", text: "Submaksimal" }
      ],
      dataSource_108534: [
        { key: "Dusuk", text: "Düşük" },
        { key: "Orta", text: "Orta" },
        { key: "Yuksek", text: "Yüksek" }
      ],
      dataSource_65627: [
        { text: "Dinamik", key: "Dinamik" },
        { text: "İzometrik", key: "Izometrik" },
        { text: "İzokinetik", key: "Izokinetik" },
        { text: "Çevre", key: "Cevre" },
        { text: "Koşu", key: "Kosu" },
        { text: "Yürüyüş", key: "Yuruyus" },
        { text: "Basamak", key: "Basamak" },
        { text: "Ergometre", key: "Ergometre" },
        { text: "Yüzme", key: "Yuzme" }
      ],
      dataSource_46999: [
        { text: "Alt Ekstremite", key: "AltEkstremite" },
        { text: "Üst Ekstremite", key: "UstEkstremite" },
        { text: "Gövde", key: "Govde" },
        { text: "Diz", key: "Diz" },
        { text: "Kalça", key: "Kalca" },
        { text: "Omuz", key: "Omuz" },
        { text: "Ayak Bileği", key: "AyakBilegi" },
        { text: "El Bileği", key: "ElBilegi" },
        { text: "Boyun", key: "Boyun" }
      ],
      dataSource_405121: [
        { key: "Bilateral", text: "Bilateral" },
        { key: "Unilateral", text: "Unilateral" }
      ]
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testfilter", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestFilterPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testfilter", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testfilter", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestFilterPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestFilterPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestFilter/TestFilterPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SiniflandirmaSelectAll = result?.data.siniflandirmaSelectAll;
    stateVars.NormmAll = result?.data.normmAll;
    stateVars.BilesenAll = result?.data.bilesenAll;

    formVars.testfilter_639459_options = stateVars.BilesenAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFilterPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFilterPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_639459 = this.state.BilesenAll;
    stateVars.dataSource_639459 = this.state.BilesenAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestFilterComponent_653854_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFilterComponent_421612_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      fitnessBilseniId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testfilter_639459_value", "value", "BilesenAll", "id", "id")
        ),
        "Guid"
      ),
      amac_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_105448_value", "value", "", "key", "")
        ),
        "string"
      ),
      alan_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_537552_value", "value", "", "key", "")
        ),
        "string"
      ),
      yuklenme_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_845288_value", "value", "", "key", "")
        ),
        "string"
      ),
      riskSinifi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_108534_value", "value", "", "key", "")
        ),
        "string"
      ),
      yontem_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_65627_value", "value", "", "key", "")
        ),
        "string"
      ),
      hedefBolge_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_46999_value", "value", "", "key", "")
        ),
        "string"
      ),
      taraf_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testfilter_405121_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestFilter/TestFilterComponent_421612_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestArama = result?.data.testArama;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFilterComponent_421612_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFilterComponent_421612_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_1725575 = this.state.TestArama;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFilterComponent_795517_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage(
      "testId",
      ReactSystemFunctions.value(this, "testfilter_1725575_value", "id")
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
