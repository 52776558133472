import { FormInstance } from "antd/es/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEkTestGecmisi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEkTestGecmisi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  TestById: any[];
  TestById_dummy: any[];
  KisiEkTestGecmisi: any[];
  KisiEkTestGecmisi_dummy: any[];
  DeleteRecord: number;
  DeleteRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp926912Visible: "visible" | "hidden";
  isComp117318Visible: "visible" | "hidden";
  isComp196473Visible: "visible" | "hidden";
  isComp452848Visible: "visible" | "hidden";
  isComp842580Visible: "visible" | "hidden";
  isComp405520Visible: "visible" | "hidden";
  isComp244985Visible: "visible" | "hidden";
}

export class EkTestGecmisi_ScreenBase extends React.PureComponent<IEkTestGecmisi_ScreenProps, any> {
  ektestgecmisi_514829_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "Tests", TagName: "lblHead_value" },
        {
          Id: 399769,
          PropertyName: "nodatafoundmessage",
          Value: "No data found",
          TagName: "tblFitnessbileseniolcumsekli_nodatafoundmessage"
        },
        { Id: 8107049, PropertyName: "title", Value: "Test Name", TagName: "clmTanim_title" },
        { Id: 8143247, PropertyName: "value", Value: "[datafield:description]", TagName: "lblTanim_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "7b469ded-6906-421b-bc34-d742ef1098c4",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923160, PropertyName: "value", Value: "EK TEST GEÇMİŞİ" },
        { Id: 332323, PropertyName: "value", Value: "Test Adı" },
        { Id: 514829, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 62072, PropertyName: "title", Value: "Test Tarihi" },
        { Id: 779844, PropertyName: "value", Value: "[datafield:testtarihi]" },
        { Id: 282702, PropertyName: "title", Value: "Girdi" },
        { Id: 146823, PropertyName: "value", Value: "[datafield:girdi1adi]" },
        { Id: 162941, PropertyName: "value", Value: "[datafield:girdi1]" },
        { Id: 25503, PropertyName: "value", Value: "[datafield:girdi2adi]" },
        { Id: 378500, PropertyName: "value", Value: "[datafield:girdi2]" },
        { Id: 990006, PropertyName: "value", Value: "[datafield:girdi3adi]" },
        { Id: 590307, PropertyName: "value", Value: "[datafield:girdi3]" },
        { Id: 32757, PropertyName: "value", Value: "[datafield:girdi4adi]" },
        { Id: 106167, PropertyName: "value", Value: "[datafield:girdi4]" },
        { Id: 681049, PropertyName: "title", Value: "Ara Sonuç" },
        { Id: 346747, PropertyName: "value", Value: "[datafield:arasonuc1adi]" },
        { Id: 409742, PropertyName: "value", Value: "[datafield:arasonuc1]" },
        { Id: 688404, PropertyName: "value", Value: "[datafield:arasonuc2adi]" },
        { Id: 538913, PropertyName: "value", Value: "[datafield:arasonuc2]" },
        { Id: 473900, PropertyName: "value", Value: "[datafield:arasonuc3adi]" },
        { Id: 208851, PropertyName: "value", Value: "[datafield:arasonuc3]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.ektestgecmisi_514829_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      TestById: [],
      KisiEkTestGecmisi: [],
      DeleteRecord: false,
      NAVIGATE: "",
      isComp926912Visible: "hidden",
      isComp117318Visible: "hidden",
      isComp196473Visible: "hidden",
      isComp452848Visible: "hidden",
      isComp842580Visible: "hidden",
      isComp405520Visible: "hidden",
      isComp244985Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("ektestgecmisi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EkTestGecmisiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("ektestgecmisi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("ektestgecmisi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EkTestGecmisiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EkTestGecmisiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "EkTestGecmisi/EkTestGecmisiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestById = result?.data.testById;
    formVars.ektestgecmisi_743953_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanim
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanim
        : null
    );
    stateVars.KisiEkTestGecmisi = result?.data.kisiEkTestGecmisi;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EkTestGecmisiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EkTestGecmisiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.ektestgecmisi_743953_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestById?.length > 0
        ? stateVars.TestById[0].tanim
        : this.state.TestById?.length > 0
        ? this.state.TestById[0].tanim
        : null
    );

    stateVars.dataSource_514829 = this.state.KisiEkTestGecmisi;
    stateVars.isComp926912Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "girdi1Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp117318Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "girdi2Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp196473Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "girdi3Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452848Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "girdi4Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp842580Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "araSonuc1Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp405520Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "araSonuc2Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp244985Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "araSonuc3Adi"),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EkTestGecmisiComponent_210016_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EkTestGecmisiComponent_254384_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "id"),
        "Guid"
      ),
      kisiId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "76b090f4_f65b_0320_5b75_9398f0c23f8e_confirmation",
        this.defaultML,
        "Emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EkTestGecmisi/EkTestGecmisiComponent_254384_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.DeleteRecord = result?.data.deleteRecord_1;
      stateVars.KisiEkTestGecmisi = result?.data.kisiEkTestGecmisi;

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EkTestGecmisiComponent_254384_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EkTestGecmisiComponent_254384_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_514829 = this.state.KisiEkTestGecmisi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  EkTestGecmisiComponent_368579_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EkTestF",
      "KisiId",
      ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "kisiId")
    );
    KuikaAppManager.addToPageInputVariables(
      "EkTestF",
      "EkTEstId",
      ReactSystemFunctions.value(this, "ektestgecmisi_514829_value", "id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "EkTestGecmisi",
      "EkTestF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "508758",
      null,
      "right",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [508758] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.EkTestGecmisiPageInit();
    }
  }
}
