import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITestF_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITestF_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NormmAll: any[];
  NormmAll_dummy: any[];
  SiniflandirmaAll: any[];
  SiniflandirmaAll_dummy: any[];
  TestById: any[];
  TestById_dummy: any[];
  TestFormulById: any[];
  TestFormulById_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  TestExecuteWithoutDB: any[];
  TestExecuteWithoutDB_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp727435Visible: "visible" | "hidden";
  isComp299208Visible: "visible" | "hidden";
  isComp873230Visible: "visible" | "hidden";
  isComp538244Visible: "visible" | "hidden";
  isComp669224Visible: "visible" | "hidden";
  isComp763921Visible: "visible" | "hidden";
  isComp943834Visible: "visible" | "hidden";
  isComp505655Visible: "visible" | "hidden";
  isComp188023Visible: "visible" | "hidden";
  isComp15436Visible: "visible" | "hidden";
  isComp586065Visible: "visible" | "hidden";
  isComp177822Enabled: "enabled" | "disabled";
  isComp169442Enabled: "enabled" | "disabled";
  isComp589737Enabled: "enabled" | "disabled";
  isComp477533Enabled: "enabled" | "disabled";
  isComp676491Enabled: "enabled" | "disabled";
}

export class TestF_ScreenBase extends React.PureComponent<ITestF_ScreenProps, any> {
  testf_953654_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf_455258_value_kuikaSelectBoxRef: React.RefObject<any>;
  testf_632409_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "721b6442-402b-4483-984b-f6fcbbfe6810",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "TEST GELİŞTİRME" },
        { Id: 5131003, PropertyName: "value", Value: "Sınıflandırma" },
        { Id: 953654, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 75070, PropertyName: "value", Value: "Norm" },
        { Id: 455258, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 310201, PropertyName: "value", Value: "Girdi Adedi" },
        { Id: 18190, PropertyName: "placeholder", Value: "15" },
        { Id: 403334, PropertyName: "value", Value: "Ara Sonuc Adedi" },
        { Id: 8021, PropertyName: "placeholder", Value: "15" },
        { Id: 895526, PropertyName: "value", Value: "Yaş" },
        { Id: 109176, PropertyName: "placeholder", Value: "15" },
        { Id: 589540, PropertyName: "value", Value: "Boy" },
        { Id: 912809, PropertyName: "placeholder", Value: "15" },
        { Id: 340696, PropertyName: "value", Value: "Cinsiyet" },
        { Id: 632409, PropertyName: "placeholder", Value: "Seçiniz..." },
        { Id: 807956, PropertyName: "value", Value: "Vücut Ağırlığı" },
        { Id: 649440, PropertyName: "placeholder", Value: "15" },
        { Id: 886476, PropertyName: "value", Value: "Parametre 1 Adı" },
        { Id: 801246, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 300004, PropertyName: "value", Value: "Parametre 1 Placeholder" },
        { Id: 592654, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 786225, PropertyName: "value", Value: "Parametre 1 Açıklama" },
        { Id: 249614, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 534841, PropertyName: "value", Value: "Parametre 2 Adı" },
        { Id: 938989, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 660324, PropertyName: "value", Value: "Parametre 2 Placeholder" },
        { Id: 800780, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 566839, PropertyName: "value", Value: "Parametre 2 Açıklama" },
        { Id: 184734, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 626891, PropertyName: "value", Value: "Parametre 3 Adı" },
        { Id: 283821, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 580593, PropertyName: "value", Value: "Parametre 3 Placeholder" },
        { Id: 230179, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 835712, PropertyName: "value", Value: "Parametre 3 Açıklama" },
        { Id: 742045, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 35298, PropertyName: "value", Value: "Parametre 4 Adı" },
        { Id: 122715, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 944, PropertyName: "value", Value: "Parametre 4 Placeholder" },
        { Id: 881150, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 817015, PropertyName: "value", Value: "Parametre 4 Açıklama" },
        { Id: 753522, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 262586, PropertyName: "value", Value: "Ara Sonuç 1 Adı" },
        { Id: 360187, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 442611, PropertyName: "value", Value: "Formül" },
        {
          Id: 222714,
          PropertyName: "title",
          Value: "@boy, @vucutAgirligi, @yas, @parametre1, @parametre2, @parametre3, @parametre4"
        },
        { Id: 652608, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 947830, PropertyName: "value", Value: "Ara Sonuç 2 Adı" },
        { Id: 226680, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 482762, PropertyName: "value", Value: "Formül" },
        {
          Id: 507627,
          PropertyName: "title",
          Value: "@boy, @vucutAgirligi, @yas, @parametre1, @parametre2, @parametre3, @parametre4, @araSonuc1"
        },
        { Id: 352441, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 407778, PropertyName: "value", Value: "Ara Sonuç 3 Adı" },
        { Id: 282168, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 884985, PropertyName: "value", Value: "Formül" },
        {
          Id: 768054,
          PropertyName: "title",
          Value:
            "@boy, @vucutAgirligi, @yas, @parametre1, @parametre2, @parametre3, @parametre4, @araSonuc1, @araSonuc2"
        },
        { Id: 377341, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 419284, PropertyName: "value", Value: "Sonuç" },
        { Id: 454640, PropertyName: "value", Value: "Formül" },
        {
          Id: 657541,
          PropertyName: "title",
          Value:
            "@boy, @vucutAgirligi, @yas, @parametre1, @parametre2, @parametre3, @parametre4, @araSonuc1, @araSonuc2, @araSonuc3, @puan"
        },
        { Id: 703092, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 332022, PropertyName: "value", Value: "Puan" },
        { Id: 477533, PropertyName: "placeholder", Value: "15" },
        { Id: 299382, PropertyName: "value", Value: "Sınıflandırma" },
        { Id: 676491, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 429753, PropertyName: "label", Value: "İLERİ" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.testf_953654_value_kuikaSelectBoxRef = React.createRef();
    this.testf_455258_value_kuikaSelectBoxRef = React.createRef();
    this.testf_632409_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NormmAll: [],
      SiniflandirmaAll: [],
      TestById: [],
      TestFormulById: [],
      ChangeEnabledOf: "",
      GoBack: "",
      TestExecuteWithoutDB: [],
      SaveRecord: false,
      NAVIGATE: "",
      dataSource_632409: [
        { key: "E", text: "Erkek" },
        { key: "K", text: "Kadın" }
      ],
      isComp727435Visible: "hidden",
      isComp299208Visible: "hidden",
      isComp873230Visible: "hidden",
      isComp538244Visible: "hidden",
      isComp669224Visible: "hidden",
      isComp763921Visible: "hidden",
      isComp943834Visible: "hidden",
      isComp505655Visible: "hidden",
      isComp188023Visible: "hidden",
      isComp15436Visible: "hidden",
      isComp586065Visible: "hidden",
      isComp177822Enabled: "enabled",
      isComp169442Enabled: "enabled",
      isComp589737Enabled: "enabled",
      isComp477533Enabled: "enabled",
      isComp676491Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("testf", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TestFPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("testf", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("testf", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TestFPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      testf_18190_value: this.state.TestFormulById?.at?.(0)?.girdiAdedi ?? undefined,
      testf_8021_value: this.state.TestFormulById?.at?.(0)?.araSonucAdedi ?? undefined,
      testf_109176_value: undefined,
      testf_912809_value: undefined,
      testf_649440_value: undefined,
      testf_801246_value: this.state.TestFormulById?.at?.(0)?.girdi1Adi ?? undefined,
      testf_592654_value: this.state.TestFormulById?.at?.(0)?.girdi1Placeholder ?? undefined,
      testf_249614_value: this.state.TestFormulById?.at?.(0)?.girdi1Aciklama ?? undefined,
      testf_814903_value: undefined,
      testf_938989_value: this.state.TestFormulById?.at?.(0)?.girdi2Adi ?? undefined,
      testf_800780_value: this.state.TestFormulById?.at?.(0)?.girdi2Placeholder ?? undefined,
      testf_184734_value: this.state.TestFormulById?.at?.(0)?.girdi2Aciklama ?? undefined,
      testf_630521_value: undefined,
      testf_283821_value: this.state.TestFormulById?.at?.(0)?.girdi3Adi ?? undefined,
      testf_230179_value: this.state.TestFormulById?.at?.(0)?.girdi3Placeholder ?? undefined,
      testf_742045_value: this.state.TestFormulById?.at?.(0)?.girdi3Aciklama ?? undefined,
      testf_824318_value: undefined,
      testf_122715_value: this.state.TestFormulById?.at?.(-1)?.girdi4Adi ?? undefined,
      testf_881150_value: this.state.TestFormulById?.at?.(0)?.girdi4Placeholder ?? undefined,
      testf_753522_value: this.state.TestFormulById?.at?.(0)?.girdi4Aciklama ?? undefined,
      testf_873618_value: undefined,
      testf_360187_value: this.state.TestFormulById?.at?.(0)?.araSonuc1Adi ?? undefined,
      testf_652608_value: this.state.TestFormulById?.at?.(0)?.araSonuc1Formul ?? undefined,
      testf_177822_value: this.state.TestExecuteWithoutDB?.at?.(0)?.araSonuc1 ?? undefined,
      testf_226680_value: this.state.TestFormulById?.at?.(0)?.araSonuc2Adi ?? undefined,
      testf_352441_value: this.state.TestFormulById?.at?.(0)?.araSonuc2Formul ?? undefined,
      testf_169442_value: this.state.TestExecuteWithoutDB?.at?.(0)?.araSonuc2 ?? undefined,
      testf_282168_value: this.state.TestFormulById?.at?.(0)?.araSonuc3Adi ?? undefined,
      testf_377341_value: this.state.TestFormulById?.at?.(0)?.araSonuc3Formul ?? undefined,
      testf_589737_value: this.state.TestExecuteWithoutDB?.at?.(0)?.araSonuc3 ?? undefined,
      testf_703092_value: this.state.TestFormulById?.at?.(0)?.puanFormul ?? undefined,
      testf_477533_value: this.state.TestExecuteWithoutDB?.at?.(0)?.puan ?? undefined,
      testf_676491_value: this.state.TestExecuteWithoutDB?.at?.(0)?.puanSinif ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TestFPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Id_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.NormmAll = result?.data.normmAll;

    formVars.testf_455258_options = stateVars.NormmAll;
    stateVars.SiniflandirmaAll = result?.data.siniflandirmaAll;

    formVars.testf_953654_options = stateVars.SiniflandirmaAll;
    stateVars.TestById = result?.data.testById;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp177822Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp169442Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp589737Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp477533Enabled", "disabled");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp676491Enabled", "disabled");

    stateVars.dataSource_953654 = this.state.SiniflandirmaAll;
    stateVars.dataSource_953654 = this.state.SiniflandirmaAll;

    stateVars.dataSource_455258 = this.state.NormmAll;
    stateVars.dataSource_455258 = this.state.NormmAll;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TestFComponent_632226_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_953654_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_953654_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_953654_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_953654_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_455258_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_455258_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_455258_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_455258_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_18190_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_18190_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "testf_18190_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      GirdiAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_18190_value", "value", "", "", "")),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_18190_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_18190_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_18190_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_8021_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_8021_value", "value", "", "", "")),
        null
      ),
      message: "*",
      formName: "testf_8021_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonucAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_8021_value", "value", "", "", "")),
        "number"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_8021_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_8021_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_8021_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_109176_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_109176_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_109176_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_109176_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_912809_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_912809_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_912809_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_912809_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_632409_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_632409_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_632409_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_632409_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_649440_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_649440_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_649440_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_649440_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_801246_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi1Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_801246_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_801246_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_801246_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_801246_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_592654_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi1Placeholder_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_592654_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_592654_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_592654_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_592654_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_249614_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi1Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_249614_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_249614_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_249614_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_249614_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_814903_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_814903_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_814903_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_814903_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_938989_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi2Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_938989_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_938989_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_938989_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_938989_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_800780_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi2Placeholder_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_800780_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_800780_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_800780_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_800780_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_184734_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi2Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_184734_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_184734_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_184734_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_184734_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_630521_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_630521_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_630521_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_630521_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_283821_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi3Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_283821_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_283821_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_283821_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_283821_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_230179_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi3Placeholder_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_230179_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_230179_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_230179_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_230179_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_742045_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi3Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_742045_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_742045_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_742045_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_742045_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_824318_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_824318_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_824318_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_824318_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_122715_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi4Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_122715_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_122715_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_12;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_122715_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_122715_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_881150_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi4Placeholder_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_881150_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_881150_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_13;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_881150_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_881150_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_753522_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      Girdi4Aciklama_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_753522_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_753522_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_14;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_753522_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_753522_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_873618_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      testId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_873618_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_873618_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_873618_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_360187_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc1Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_360187_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_360187_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_15;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_360187_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_360187_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_652608_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc1Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_652608_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_16;
    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_652608_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_652608_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_226680_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc2Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_226680_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_226680_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_17;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_226680_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_226680_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_352441_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc2Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")
        ),
        "string"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_352441_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_18;
    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_352441_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_352441_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_282168_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc3Adi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_282168_value", "value", "", "", "")),
        "string"
      ),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_282168_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_19;
    stateVars.TestFormulById = result?.data.testFormulById;
    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );
    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );
    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );
    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );
    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );
    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );
    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );
    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );
    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );
    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );
    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );
    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );
    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );
    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );
    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );
    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );
    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );
    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );
    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );
    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );
    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );
    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );
    stateVars.isComp727435Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp299208Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi1Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi1Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp873230Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp538244Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi2Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi2Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp669224Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp763921Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi3Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi3Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp943834Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdiAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdiAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp505655Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].girdi4Aciklama
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].girdi4Aciklama
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp188023Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp15436Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp586065Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.TestFormulById?.length > 0
            ? stateVars.TestFormulById[0].araSonucAdedi
            : this.state.TestFormulById?.length > 0
            ? this.state.TestFormulById[0].araSonucAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_282168_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_282168_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_18190_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdiAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdiAdedi
        : null
    );

    formVars.testf_8021_value = ReactSystemFunctions.value(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonucAdedi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonucAdedi
        : null
    );

    formVars.testf_801246_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_592654_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_249614_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_486592_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Adi
        : null
    );

    formVars.testf_299208_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Aciklama
        : null
    );

    formVars.testf_814903_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi1Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi1Placeholder
        : null
    );

    formVars.testf_938989_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_800780_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_184734_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_525956_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Adi
        : null
    );

    formVars.testf_538244_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Aciklama
        : null
    );

    formVars.testf_630521_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi2Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi2Placeholder
        : null
    );

    formVars.testf_283821_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_230179_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_742045_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_551266_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Adi
        : null
    );

    formVars.testf_763921_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Aciklama
        : null
    );

    formVars.testf_824318_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi3Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi3Placeholder
        : null
    );

    formVars.testf_122715_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[stateVars.TestFormulById.length - 1].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[this.state.TestFormulById.length - 1].girdi4Adi
        : null
    );

    formVars.testf_881150_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_753522_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_649152_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Adi
        : null
    );

    formVars.testf_505655_title = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Aciklama
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Aciklama
        : null
    );

    formVars.testf_873618_placeholder = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].girdi4Placeholder
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].girdi4Placeholder
        : null
    );

    formVars.testf_360187_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_652608_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Formul
        : null
    );

    formVars.testf_141713_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc1Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc1Adi
        : null
    );

    formVars.testf_226680_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_352441_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Formul
        : null
    );

    formVars.testf_809450_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc2Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc2Adi
        : null
    );

    formVars.testf_282168_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_377341_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Formul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Formul
        : null
    );

    formVars.testf_19421_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].araSonuc3Adi
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].araSonuc3Adi
        : null
    );

    formVars.testf_703092_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestFormulById?.length > 0
        ? stateVars.TestFormulById[0].puanFormul
        : this.state.TestFormulById?.length > 0
        ? this.state.TestFormulById[0].puanFormul
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_377341_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc3Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")
        ),
        "string"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_377341_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_20;
    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_377341_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_377341_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_703092_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      PuanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      ),
      testId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      siniflandirmaId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_953654_value", "value", "SiniflandirmaAll", "id", "id")
        ),
        "Guid"
      ),
      normId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "testf_455258_value", "value", "NormmAll", "id", "id")
        ),
        "Guid"
      ),
      yas_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_109176_value", "value", "", "", "")),
        "number"
      ),
      boy_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_912809_value", "value", "", "", "")),
        "number"
      ),
      cinsiyet_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_632409_value", "value", "", "key", "")
        ),
        "string"
      ),
      vucutAgirligi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_649440_value", "value", "", "", "")),
        "number"
      ),
      parametre1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_814903_value", "value", "", "", "")),
        "number"
      ),
      parametre2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_630521_value", "value", "", "", "")),
        "number"
      ),
      parametre3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_824318_value", "value", "", "", "")),
        "number"
      ),
      parametre4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_873618_value", "value", "", "", "")),
        "number"
      ),
      formulAraSonuc1_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")),
        "string"
      ),
      formulAraSonuc3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")),
        "string"
      ),
      puanFormul_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_703092_onBlur", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_21;
    stateVars.TestExecuteWithoutDB = result?.data.testExecuteWithoutDB;
    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );
    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );
    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );
    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );
    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_703092_onBlur1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_703092_onBlur1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.testf_177822_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc1
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc1
        : null
    );

    formVars.testf_169442_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc2
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc2
        : null
    );

    formVars.testf_589737_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].araSonuc3
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].araSonuc3
        : null
    );

    formVars.testf_477533_value = ReactSystemFunctions.value(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puan
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puan
        : null
    );

    formVars.testf_676491_value = ReactSystemFunctions.toString(
      this,
      stateVars.TestExecuteWithoutDB?.length > 0
        ? stateVars.TestExecuteWithoutDB[0].puanSinif
        : this.state.TestExecuteWithoutDB?.length > 0
        ? this.state.TestExecuteWithoutDB[0].puanSinif
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TestFComponent_429753_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.TestId ?? this.props.screenInputs.testid,
        "Guid"
      ),
      AraSonuc1Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_652608_value", "value", "", "", "")),
        "string"
      ),
      AraSonuc2Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_352441_value", "value", "", "", "")
        ),
        "string"
      ),
      AraSonuc3Formul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "testf_377341_value", "value", "", "", "")
        ),
        "string"
      ),
      PuanFormul_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_703092_value", "value", "", "", "")),
        "string"
      ),
      AraSonucAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_8021_value", "value", "", "", "")),
        "number"
      ),
      GirdiAdedi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "testf_18190_value", "value", "", "", "")),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "TestF/TestFComponent_429753_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_22;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TestFComponent_429753_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TestFComponent_429753_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "TestF2",
      "TestId",
      this.props.screenInputs.TestId ?? this.props.screenInputs.testid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.TestFComponent_429753_onClick2_,
      "TestF",
      "TestF2",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "475223",
      null,
      "right",
      null,
      "1080px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  TestFComponent_429753_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [475223] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TestFPageInit();
    }
    if (diId == 475223) {
      isErrorOccurred = await this.TestFComponent_429753_onClick2_();
      if (isErrorOccurred) return true;
    }
  }
}
