import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMyScheduleBurhan_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMyScheduleBurhan_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ParametreDate: any[];
  ParametreDate_dummy: any[];
  RandevuAlBir: any[];
  RandevuAlBir_dummy: any[];
  isCompMinus3Visible: "visible" | "hidden";
  isComp97044Visible: "visible" | "hidden";
  isComp925909Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class MyScheduleBurhan_ScreenBase extends React.PureComponent<IMyScheduleBurhan_ScreenProps, any> {
  myscheduleburhan_923072_value_kuikaDatetimeRef: React.RefObject<any>;
  myscheduleburhan_798028_value_kuikaFlexGridRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "abb42a3b-27fe-49bc-aa8f-13ab2361fd5d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 923072, PropertyName: "placeholder", Value: "Select Date Time..." },
        { Id: 669371, PropertyName: "value", Value: "RANDEVULARIM" },
        { Id: 798028, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 217641, PropertyName: "value", Value: "[datafield:randevusaati]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.myscheduleburhan_923072_value_kuikaDatetimeRef = React.createRef();
    this.myscheduleburhan_798028_value_kuikaFlexGridRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      SetValueOf: "",
      ParametreDate: [],
      RandevuAlBir: [],
      isCompMinus3Visible: "hidden",
      isComp97044Visible: "hidden",
      isComp925909Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("myscheduleburhan", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.MyScheduleBurhanPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("myscheduleburhan", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("myscheduleburhan", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MyScheduleBurhanPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.MyScheduleBurhanPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  MyScheduleBurhanPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyScheduleBurhan/MyScheduleBurhanPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "myscheduleburhan_923072_value",
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanPageInit2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MyScheduleBurhanPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      ekGun_0: ReactSystemFunctions.convertToTypeByName(0, "number"),
      date_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      EgitmenId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      date_1: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MyScheduleBurhan/MyScheduleBurhanPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParametreDate = result?.data.parametreDate;
    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );
    stateVars.RandevuAlBir = result?.data.randevuAlBir;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanPageInit3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );

    stateVars.dataSource_798028 = this.state.RandevuAlBir;
    stateVars.isComp97044Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "myscheduleburhan_798028_value", "statu"),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp925909Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, "myscheduleburhan_798028_value", "statu"),
        "2"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MyScheduleBurhanComponent_638497_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ekGun_0: ReactSystemFunctions.convertToTypeByName(-1, "number"),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "myscheduleburhan_923072_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MyScheduleBurhan/MyScheduleBurhanComponent_638497_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParametreDate = result?.data.parametreDate;
    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_638497_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_638497_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "myscheduleburhan_923072_value",
      ReactSystemFunctions.value(
        this,
        stateVars.ParametreDate?.length > 0
          ? stateVars.ParametreDate[0].dateprm
          : this.state.ParametreDate?.length > 0
          ? this.state.ParametreDate[0].dateprm
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_638497_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_638497_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ParametreDate?.length > 0
            ? stateVars.ParametreDate[0].dateprm
            : this.state.ParametreDate?.length > 0
            ? this.state.ParametreDate[0].dateprm
            : null
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MyScheduleBurhan/MyScheduleBurhanComponent_638497_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RandevuAlBir = result?.data.randevuAlBir;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_638497_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_638497_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );

    stateVars.dataSource_798028 = this.state.RandevuAlBir;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MyScheduleBurhanComponent_925033_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ekGun_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "myscheduleburhan_923072_value", "value", "", "", "")
        ),
        "Date"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MyScheduleBurhan/MyScheduleBurhanComponent_925033_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ParametreDate = result?.data.parametreDate;
    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_925033_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_925033_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "myscheduleburhan_923072_value",
      ReactSystemFunctions.value(
        this,
        stateVars.ParametreDate?.length > 0
          ? stateVars.ParametreDate[0].dateprm
          : this.state.ParametreDate?.length > 0
          ? this.state.ParametreDate[0].dateprm
          : null
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_925033_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_925033_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EgitmenId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kisiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kisiId
            : null
        ),
        "Guid"
      ),
      date_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ParametreDate?.length > 0
            ? stateVars.ParametreDate[0].dateprm
            : this.state.ParametreDate?.length > 0
            ? this.state.ParametreDate[0].dateprm
            : null
        ),
        "Date"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "MyScheduleBurhan/MyScheduleBurhanComponent_925033_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RandevuAlBir = result?.data.randevuAlBir;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MyScheduleBurhanComponent_925033_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MyScheduleBurhanComponent_925033_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.myscheduleburhan_924363_value = ReactSystemFunctions.toString(
      this,
      stateVars.ParametreDate?.length > 0
        ? stateVars.ParametreDate[0].gunBir
        : this.state.ParametreDate?.length > 0
        ? this.state.ParametreDate[0].gunBir
        : null
    );

    stateVars.dataSource_798028 = this.state.RandevuAlBir;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
