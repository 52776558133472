import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IDashboard_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IDashboard_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  CheckupDonemiByKirilim1Id: any[];
  CheckupDonemiByKirilim1Id_dummy: any[];
  fnPreviousMonth: any[];
  fnPreviousMonth_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  WriteToLocalStorage: any;
  WriteToLocalStorage_dummy: any;
  ProtokolAll: any[];
  ProtokolAll_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  DashboardMevcutDurum: any[];
  DashboardMevcutDurum_dummy: any[];
  DashboardMevcutDurumSummary: any[];
  DashboardMevcutDurumSummary_dummy: any[];
  DashboardMevcutDurumGraph: any[];
  DashboardMevcutDurumGraph_dummy: any[];
  DashboardCheckuplari: any[];
  DashboardCheckuplari_dummy: any[];
  DashboardCheckuplariHeader: any[];
  DashboardCheckuplariHeader_dummy: any[];
  DashboardCheckuplariAvg: any[];
  DashboardCheckuplariAvg_dummy: any[];
  ProtokolSelectById: any[];
  ProtokolSelectById_dummy: any[];
  DashboarAktifCheckupDonemi: any[];
  DashboarAktifCheckupDonemi_dummy: any[];
  OkulFitnessIstatistigi: any[];
  OkulFitnessIstatistigi_dummy: any[];
  OkulKatilimIstatistikleri: any[];
  OkulKatilimIstatistikleri_dummy: any[];
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  isComp880340Visible: "visible" | "hidden";
  isComp748517Visible: "visible" | "hidden";
  isComp310903Visible: "visible" | "hidden";
  isComp724287Visible: "visible" | "hidden";
  isComp966032Visible: "visible" | "hidden";
  isComp218368Visible: "visible" | "hidden";
  isComp212092Visible: "visible" | "hidden";
  isComp512011Visible: "visible" | "hidden";
  isComp5125580Visible: "visible" | "hidden";
  isComp9172801Visible: "visible" | "hidden";
  isComp7997680Visible: "visible" | "hidden";
  isComp7071839Visible: "visible" | "hidden";
  isComp8989893Visible: "visible" | "hidden";
  isComp8216050Visible: "visible" | "hidden";
  isComp3531977Visible: "visible" | "hidden";
  isComp9859756Visible: "visible" | "hidden";
  isComp8692831Visible: "visible" | "hidden";
  isComp277347Visible: "visible" | "hidden";
  isComp131879Visible: "visible" | "hidden";
  isComp947232Visible: "visible" | "hidden";
  isComp735273Visible: "visible" | "hidden";
  isComp269779Visible: "visible" | "hidden";
  isComp936490Visible: "visible" | "hidden";
  isComp53847Visible: "visible" | "hidden";
  isComp43725Visible: "visible" | "hidden";
  isComp226031Visible: "visible" | "hidden";
  isComp933926Visible: "visible" | "hidden";
  isComp758432Visible: "visible" | "hidden";
  isComp820245Visible: "visible" | "hidden";
  isComp354183Visible: "visible" | "hidden";
  isComp306650Visible: "visible" | "hidden";
  isComp706570Visible: "visible" | "hidden";
  isComp115236Visible: "visible" | "hidden";
  isComp197969Visible: "visible" | "hidden";
  isComp779525Visible: "visible" | "hidden";
  isComp41699Visible: "visible" | "hidden";
  isComp75947Visible: "visible" | "hidden";
  isComp506987Visible: "visible" | "hidden";
  isComp696253Visible: "visible" | "hidden";
  isComp187555Visible: "visible" | "hidden";
  isComp879974Visible: "visible" | "hidden";
  isComp115636Visible: "visible" | "hidden";
  isComp57389Visible: "visible" | "hidden";
  isComp564832Visible: "visible" | "hidden";
  isComp143664Visible: "visible" | "hidden";
  isComp374173Visible: "visible" | "hidden";
  isComp987728Visible: "visible" | "hidden";
  isComp679678Visible: "visible" | "hidden";
  isComp843737Visible: "visible" | "hidden";
  isComp621973Visible: "visible" | "hidden";
  isComp350228Visible: "visible" | "hidden";
  isComp139169Visible: "visible" | "hidden";
  isComp390934Visible: "visible" | "hidden";
  isComp294Visible: "visible" | "hidden";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
  shouldTriggerPageInit?: boolean;
}

export class Dashboard_ScreenBase extends React.PureComponent<IDashboard_ScreenProps, any> {
  dashboard_611755_value_kuikaDateRef: React.RefObject<any>;
  dashboard_769300_value_kuikaDateRef: React.RefObject<any>;
  dashboard_48309_value_kuikaSelectBoxRef: React.RefObject<any>;
  dashboard_5125580_value_kuikaTableRef: React.RefObject<any>;
  dashboard_566388_value_kuikaTableRef: React.RefObject<any>;
  dashboard_5561_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 611755, PropertyName: "placeholder", Value: "Starting Date", TagName: "dtBaslangic_placeholder" },
        { Id: 769300, PropertyName: "placeholder", Value: "Finish Date", TagName: "dtBitis_placeholder" },
        { Id: 48309, PropertyName: "placeholder", Value: "Protocol", TagName: "selProtokol_placeholder" },
        { Id: 586122, PropertyName: "value", Value: "Curent State", TagName: "Label3_value" },
        {
          Id: 616100,
          PropertyName: "value",
          Value: "Total number of participants with data:",
          TagName: "Label8_value"
        },
        { Id: 352822, PropertyName: "value", Value: "Participants with a FitScore < 25", TagName: "Label4_value" },
        { Id: 765937, PropertyName: "value", Value: "consultant", TagName: "Label14_value" },
        { Id: 904338, PropertyName: "value", Value: "Participants with a FitScore 25 - 50", TagName: "Label5_1_value" },
        { Id: 363632, PropertyName: "value", Value: "consultant", TagName: "Label16_value" },
        { Id: 65752, PropertyName: "value", Value: "Participants with a FitScore 50 - 75", TagName: "Label4_1_value" },
        { Id: 941747, PropertyName: "value", Value: "consultant", TagName: "Label18_value" },
        {
          Id: 634452,
          PropertyName: "value",
          Value: "Participants with a FitScore 75 - 100",
          TagName: "Label6_1_value"
        },
        { Id: 776212, PropertyName: "value", Value: "consultant", TagName: "Label19_value" },
        { Id: 979489, PropertyName: "value", Value: "Active Period:", TagName: "Label2_value" },
        { Id: 601475, PropertyName: "value", Value: "Evaluated", TagName: "Label10_value" },
        { Id: 43827, PropertyName: "value", Value: "student", TagName: "Label32_value" },
        { Id: 66513, PropertyName: "value", Value: "Not evaluated", TagName: "Label9_value" },
        { Id: 474298, PropertyName: "value", Value: "student", TagName: "Label34_value" },
        { Id: 110197, PropertyName: "value", Value: "CURRENT STATITISTICS", TagName: "Label11_value" },
        {
          Id: 5125580,
          PropertyName: "nodatafoundmessage",
          Value: "No data found.",
          TagName: "tblDashboardcheckuplari_5125580_nodatafoundmessage"
        },
        { Id: 8214351, PropertyName: "title", Value: "Consultant", TagName: "clmFullname_8214351_title" },
        { Id: 7421762, PropertyName: "value", Value: "[datafield:fullname]", TagName: "lblFullname_7421762_value" },
        { Id: 9172801, PropertyName: "title", Value: "Component 1", TagName: "clmBilesen1Puan_9172801_title" },
        {
          Id: 3118420,
          PropertyName: "value",
          Value: "[datafield:bilesen1puan]",
          TagName: "lblBilesen1Puan_3118420_value"
        },
        { Id: 7997680, PropertyName: "title", Value: "Component 2", TagName: "clmBilesen2Puan_7997680_title" },
        {
          Id: 3625460,
          PropertyName: "value",
          Value: "[datafield:bilesen2puan]",
          TagName: "lblBilesen2Puan_3625460_value"
        },
        { Id: 7071839, PropertyName: "title", Value: "Component 3", TagName: "clmBilesen3Puan_7071839_title" },
        {
          Id: 7003050,
          PropertyName: "value",
          Value: "[datafield:bilesen3puan]",
          TagName: "lblBilesen3Puan_7003050_value"
        },
        { Id: 8989893, PropertyName: "title", Value: "Component 4", TagName: "clmBilesen4Puan_8989893_title" },
        {
          Id: 7276599,
          PropertyName: "value",
          Value: "[datafield:bilesen4puan]",
          TagName: "lblBilesen4Puan_7276599_value"
        },
        { Id: 8216050, PropertyName: "title", Value: "Component 5", TagName: "clmBilesen5Puan_8216050_title" },
        {
          Id: 9224188,
          PropertyName: "value",
          Value: "[datafield:bilesen5puan]",
          TagName: "lblBilesen5Puan_9224188_value"
        },
        { Id: 3531977, PropertyName: "title", Value: "Component 6", TagName: "clmBilesen6Puan_3531977_title" },
        {
          Id: 5703748,
          PropertyName: "value",
          Value: "[datafield:bilesen6puan]",
          TagName: "lblBilesen6Puan_5703748_value"
        },
        { Id: 9859756, PropertyName: "title", Value: "Component 7", TagName: "clmBilesen7Puan_9859756_title" },
        {
          Id: 2559166,
          PropertyName: "value",
          Value: "[datafield:bilesen7puan]",
          TagName: "lblBilesen7Puan_2559166_value"
        },
        { Id: 8692831, PropertyName: "title", Value: "Component 8", TagName: "clmBilesen8Puan_8692831_title" },
        {
          Id: 8635235,
          PropertyName: "value",
          Value: "[datafield:bilesen8puan]",
          TagName: "lblBilesen8Puan_8635235_value"
        },
        { Id: 8240454, PropertyName: "title", Value: "FitSkor®", TagName: "clmFitSkor_8240454_title" },
        { Id: 2574500, PropertyName: "value", Value: "[datafield:fitskor]", TagName: "lblFitSkor_2574500_value" },
        { Id: 512011, PropertyName: "value", Value: "PARTICIPATİION AND", TagName: "Label62_value" },
        { Id: 277347, PropertyName: "title", Value: "Component 9", TagName: "TableTemplateColumn31_title" },
        { Id: 590452, PropertyName: "value", Value: "[datafield:bilesen9puan]", TagName: "Label55_0_value" },
        { Id: 131879, PropertyName: "title", Value: "Component 10", TagName: "TableTemplateColumn30_title" },
        { Id: 200852, PropertyName: "value", Value: "[datafield:bilesen10puan]", TagName: "Label54_0_value" },
        { Id: 947232, PropertyName: "title", Value: "Component 11", TagName: "TableTemplateColumn29_title" },
        { Id: 424514, PropertyName: "value", Value: "[datafield:bilesen11puan]", TagName: "Label53_0_value" },
        { Id: 735273, PropertyName: "title", Value: "Component 12", TagName: "TableTemplateColumn28_title" },
        { Id: 454225, PropertyName: "value", Value: "[datafield:bilesen12puan]", TagName: "Label52_0_value" },
        { Id: 269779, PropertyName: "title", Value: "Component 13", TagName: "TableTemplateColumn27_title" },
        { Id: 958721, PropertyName: "value", Value: "[datafield:bilesen13puan]", TagName: "Label51_0_value" },
        { Id: 590367, PropertyName: "tab", Value: "Participation", TagName: "TabItem1_0_tab" },
        {
          Id: 566388,
          PropertyName: "nodatafoundmessage",
          Value: "No data found",
          TagName: "Table2_nodatafoundmessage"
        },
        { Id: 228026, PropertyName: "title", Value: "Class", TagName: "TableTemplateColumn11_0_title" },
        { Id: 851062, PropertyName: "value", Value: "[datafield:sinifiadi]", TagName: "Label37_value" },
        { Id: 136888, PropertyName: "title", Value: "Present", TagName: "TableTemplateColumn26_title" },
        { Id: 826732, PropertyName: "value", Value: "[datafield:mevcut]", TagName: "Label51_0_value" },
        { Id: 53847, PropertyName: "value", Value: "[datafield:adet1]", TagName: "Label37_0_value" },
        { Id: 933926, PropertyName: "value", Value: "[datafield:adet2]", TagName: "Label38_0_value" },
        { Id: 354183, PropertyName: "value", Value: "[datafield:adet3]", TagName: "Label49_0_value" },
        { Id: 115236, PropertyName: "value", Value: "[datafield:adet4]", TagName: "Label50_0_value" },
        { Id: 41699, PropertyName: "value", Value: "[datafield:adet5]", TagName: "Label63_value" },
        { Id: 696253, PropertyName: "value", Value: "[datafield:adet6]", TagName: "Label64_value" },
        { Id: 37333, PropertyName: "tab", Value: "FitSkor Components", TagName: "TabItem1_1_tab" },
        { Id: 5561, PropertyName: "nodatafoundmessage", Value: "No data found.", TagName: "Table3_nodatafoundmessage" },
        { Id: 5480, PropertyName: "title", Value: "Class", TagName: "TableTemplateColumn13_0_title" },
        { Id: 981262, PropertyName: "value", Value: "[datafield:sinifadi]", TagName: "Label39_0_value" },
        { Id: 879974, PropertyName: "title", Value: "Component 1", TagName: "TableTemplateColumn13_1_title" },
        { Id: 832720, PropertyName: "value", Value: "[datafield:bilesen1puan]", TagName: "Label39_0_value" },
        { Id: 115636, PropertyName: "title", Value: "Component 2", TagName: "TableTemplateColumn13_2_title" },
        { Id: 389797, PropertyName: "value", Value: "[datafield:bilesen2puan]", TagName: "Label39_0_value" },
        { Id: 57389, PropertyName: "title", Value: "Component 3", TagName: "TableTemplateColumn13_3_title" },
        { Id: 580691, PropertyName: "value", Value: "[datafield:bilesen3puan]", TagName: "Label39_0_value" },
        { Id: 564832, PropertyName: "title", Value: "Component 4", TagName: "TableTemplateColumn13_4_title" },
        { Id: 610440, PropertyName: "value", Value: "[datafield:bilesen4puan]", TagName: "Label39_0_value" },
        { Id: 143664, PropertyName: "title", Value: "Component 5", TagName: "TableTemplateColumn13_5_title" },
        { Id: 329039, PropertyName: "value", Value: "[datafield:bilesen5puan]", TagName: "Label39_0_value" },
        { Id: 374173, PropertyName: "title", Value: "Component 6", TagName: "TableTemplateColumn13_6_title" },
        { Id: 377317, PropertyName: "value", Value: "[datafield:bilesen6puan]", TagName: "Label39_0_value" },
        { Id: 987728, PropertyName: "title", Value: "Component 7", TagName: "TableTemplateColumn13_7_title" },
        { Id: 123691, PropertyName: "value", Value: "[datafield:bilesen7puan]", TagName: "Label39_0_value" },
        { Id: 679678, PropertyName: "title", Value: "Component 8", TagName: "TableTemplateColumn13_8_title" },
        { Id: 25689, PropertyName: "value", Value: "[datafield:bilesen8puan]", TagName: "Label39_0_value" },
        { Id: 843737, PropertyName: "title", Value: "Component 9", TagName: "TableTemplateColumn36_title" },
        { Id: 803308, PropertyName: "value", Value: "[datafield:bilesen9puan]", TagName: "Label60_0_value" },
        { Id: 621973, PropertyName: "title", Value: "Component 10", TagName: "TableTemplateColumn35_title" },
        { Id: 426012, PropertyName: "value", Value: "[datafield:bilesen10puan]", TagName: "Label59_0_value" },
        { Id: 350228, PropertyName: "title", Value: "Component 11", TagName: "TableTemplateColumn34_title" },
        { Id: 309273, PropertyName: "value", Value: "[datafield:bilesen11puan]", TagName: "Label58_0_value" },
        { Id: 139169, PropertyName: "title", Value: "Component 12", TagName: "TableTemplateColumn33_title" },
        { Id: 409483, PropertyName: "value", Value: "[datafield:bilesen12puan]", TagName: "Label57_0_value" },
        { Id: 390934, PropertyName: "title", Value: "Component 13", TagName: "TableTemplateColumn32_title" },
        { Id: 877213, PropertyName: "value", Value: "[datafield:bilesen13puan]", TagName: "Label56_0_value" },
        { Id: 101727, PropertyName: "title", Value: "FitSkor®", TagName: "TableTemplateColumn13_9_title" },
        { Id: 907410, PropertyName: "value", Value: "[datafield:fitskor]", TagName: "Label39_0_value" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "3717f159-2d6b-4b2f-ae43-0bd04a17be16",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 611755, PropertyName: "placeholder", Value: "Başlangıç" },
        { Id: 769300, PropertyName: "placeholder", Value: "Bitiş" },
        { Id: 48309, PropertyName: "placeholder", Value: "Protokol" },
        { Id: 586122, PropertyName: "value", Value: "Mevcut Durum" },
        { Id: 616100, PropertyName: "value", Value: "Veriye sahip toplam" },
        { Id: 512908, PropertyName: "value", Value: "sayısı:" },
        { Id: 352822, PropertyName: "value", Value: "FitSkor’u < 25 olan" },
        { Id: 904338, PropertyName: "value", Value: "FitSkor’u 25-50 arası olan" },
        { Id: 65752, PropertyName: "value", Value: "FitSkor’u 50-75 arası olan" },
        { Id: 634452, PropertyName: "value", Value: "FitSkor’u 75-100 arası olan" },
        { Id: 979489, PropertyName: "value", Value: "Aktif Dönem:" },
        { Id: 601475, PropertyName: "value", Value: "Değerlendirilen" },
        { Id: 66513, PropertyName: "value", Value: "Değerlendirilmeyen" },
        { Id: 512011, PropertyName: "value", Value: "KATILIM VE" },
        { Id: 110197, PropertyName: "value", Value: "MEVCUT DURUM İSTATİSTİKLERİ" },
        { Id: 5125580, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 8214351, PropertyName: "title", Value: "Danışan" },
        { Id: 7421762, PropertyName: "value", Value: "[datafield:fullname]" },
        { Id: 9172801, PropertyName: "title", Value: "Bilesen1" },
        { Id: 3118420, PropertyName: "value", Value: "[datafield:bilesen1puan]" },
        { Id: 7997680, PropertyName: "title", Value: "Bilesen2" },
        { Id: 3625460, PropertyName: "value", Value: "[datafield:bilesen2puan]" },
        { Id: 7071839, PropertyName: "title", Value: "Bilesen3" },
        { Id: 7003050, PropertyName: "value", Value: "[datafield:bilesen3puan]" },
        { Id: 8989893, PropertyName: "title", Value: "Bilesen4" },
        { Id: 7276599, PropertyName: "value", Value: "[datafield:bilesen4puan]" },
        { Id: 8216050, PropertyName: "title", Value: "Bilesen5" },
        { Id: 9224188, PropertyName: "value", Value: "[datafield:bilesen5puan]" },
        { Id: 3531977, PropertyName: "title", Value: "Bilesen6" },
        { Id: 5703748, PropertyName: "value", Value: "[datafield:bilesen6puan]" },
        { Id: 9859756, PropertyName: "title", Value: "Bilesen7" },
        { Id: 2559166, PropertyName: "value", Value: "[datafield:bilesen7puan]" },
        { Id: 8692831, PropertyName: "title", Value: "Bilesen8" },
        { Id: 8635235, PropertyName: "value", Value: "[datafield:bilesen8puan]" },
        { Id: 277347, PropertyName: "title", Value: "Bilesen9" },
        { Id: 590452, PropertyName: "value", Value: "[datafield:bilesen9puan]" },
        { Id: 131879, PropertyName: "title", Value: "Bilesen10" },
        { Id: 200852, PropertyName: "value", Value: "[datafield:bilesen10puan]" },
        { Id: 947232, PropertyName: "title", Value: "Bilesen11" },
        { Id: 424514, PropertyName: "value", Value: "[datafield:bilesen11puan]" },
        { Id: 735273, PropertyName: "title", Value: "Bilesen12" },
        { Id: 454225, PropertyName: "value", Value: "[datafield:bilesen12puan]" },
        { Id: 269779, PropertyName: "title", Value: "Bilesen13" },
        { Id: 958721, PropertyName: "value", Value: "[datafield:bilesen13puan]" },
        { Id: 8240454, PropertyName: "title", Value: "FitSkor" },
        { Id: 2574500, PropertyName: "value", Value: "[datafield:fitskor]" },
        { Id: 590367, PropertyName: "tab", Value: "Katılım" },
        { Id: 566388, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 851062, PropertyName: "value", Value: "[datafield:sinifiadi]" },
        { Id: 136888, PropertyName: "title", Value: "Mevcut" },
        { Id: 826732, PropertyName: "value", Value: "[datafield:mevcut]" },
        { Id: 53847, PropertyName: "value", Value: "[datafield:adet1]" },
        { Id: 933926, PropertyName: "value", Value: "[datafield:adet2]" },
        { Id: 354183, PropertyName: "value", Value: "[datafield:adet3]" },
        { Id: 115236, PropertyName: "value", Value: "[datafield:adet4]" },
        { Id: 41699, PropertyName: "value", Value: "[datafield:adet5]" },
        { Id: 696253, PropertyName: "value", Value: "[datafield:adet6]" },
        { Id: 37333, PropertyName: "tab", Value: "FitSkor® Bileşenleri" },
        { Id: 5561, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 5480, PropertyName: "title", Value: "Sınıf" },
        { Id: 981262, PropertyName: "value", Value: "[datafield:sinifadi]" },
        { Id: 879974, PropertyName: "title", Value: "Bilesen1" },
        { Id: 832720, PropertyName: "value", Value: "[datafield:bilesen1puan]" },
        { Id: 115636, PropertyName: "title", Value: "Bilesen2" },
        { Id: 389797, PropertyName: "value", Value: "[datafield:bilesen2puan]" },
        { Id: 57389, PropertyName: "title", Value: "Bilesen3" },
        { Id: 580691, PropertyName: "value", Value: "[datafield:bilesen3puan]" },
        { Id: 564832, PropertyName: "title", Value: "Bilesen4" },
        { Id: 610440, PropertyName: "value", Value: "[datafield:bilesen4puan]" },
        { Id: 143664, PropertyName: "title", Value: "Bilesen5" },
        { Id: 329039, PropertyName: "value", Value: "[datafield:bilesen5puan]" },
        { Id: 374173, PropertyName: "title", Value: "Bilesen6" },
        { Id: 377317, PropertyName: "value", Value: "[datafield:bilesen6puan]" },
        { Id: 987728, PropertyName: "title", Value: "Bilesen7" },
        { Id: 123691, PropertyName: "value", Value: "[datafield:bilesen7puan]" },
        { Id: 679678, PropertyName: "title", Value: "Bilesen8" },
        { Id: 25689, PropertyName: "value", Value: "[datafield:bilesen8puan]" },
        { Id: 843737, PropertyName: "title", Value: "Bilesen9" },
        { Id: 803308, PropertyName: "value", Value: "[datafield:bilesen9puan]" },
        { Id: 621973, PropertyName: "title", Value: "Bilesen10" },
        { Id: 426012, PropertyName: "value", Value: "[datafield:bilesen10puan]" },
        { Id: 350228, PropertyName: "title", Value: "Bilesen11" },
        { Id: 309273, PropertyName: "value", Value: "[datafield:bilesen11puan]" },
        { Id: 139169, PropertyName: "title", Value: "Bilesen12" },
        { Id: 409483, PropertyName: "value", Value: "[datafield:bilesen12puan]" },
        { Id: 390934, PropertyName: "title", Value: "Bilesen13" },
        { Id: 877213, PropertyName: "value", Value: "[datafield:bilesen13puan]" },
        { Id: 101727, PropertyName: "title", Value: "FitSkor®" },
        { Id: 907410, PropertyName: "value", Value: "[datafield:fitskor]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.dashboard_611755_value_kuikaDateRef = React.createRef();
    this.dashboard_769300_value_kuikaDateRef = React.createRef();
    this.dashboard_48309_value_kuikaSelectBoxRef = React.createRef();
    this.dashboard_5125580_value_kuikaTableRef = React.createRef();
    this.dashboard_566388_value_kuikaTableRef = React.createRef();
    this.dashboard_5561_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      CheckupDonemiByKirilim1Id: [],
      fnPreviousMonth: [],
      NAVIGATE: "",
      WriteToLocalStorage: "",
      ProtokolAll: [],
      SetValueOf: "",
      DashboardMevcutDurum: [],
      DashboardMevcutDurumSummary: [],
      DashboardMevcutDurumGraph: [],
      DashboardCheckuplari: [],
      DashboardCheckuplariHeader: [],
      DashboardCheckuplariAvg: [],
      ProtokolSelectById: [],
      DashboarAktifCheckupDonemi: [],
      OkulFitnessIstatistigi: [],
      OkulKatilimIstatistikleri: [],
      KurumTipiSelectById: [],
      ChangeVisibilityOf: "",
      TriggerEvent: "",
      isComp880340Visible: "visible",
      isComp748517Visible: "hidden",
      isComp310903Visible: "hidden",
      isComp724287Visible: "hidden",
      isComp966032Visible: "hidden",
      isComp218368Visible: "hidden",
      isComp212092Visible: "hidden",
      isComp512011Visible: "hidden",
      isComp5125580Visible: "hidden",
      isComp9172801Visible: "hidden",
      isComp7997680Visible: "hidden",
      isComp7071839Visible: "hidden",
      isComp8989893Visible: "hidden",
      isComp8216050Visible: "hidden",
      isComp3531977Visible: "hidden",
      isComp9859756Visible: "hidden",
      isComp8692831Visible: "hidden",
      isComp277347Visible: "hidden",
      isComp131879Visible: "hidden",
      isComp947232Visible: "hidden",
      isComp735273Visible: "hidden",
      isComp269779Visible: "hidden",
      isComp936490Visible: "hidden",
      isComp53847Visible: "hidden",
      isComp43725Visible: "hidden",
      isComp226031Visible: "hidden",
      isComp933926Visible: "hidden",
      isComp758432Visible: "hidden",
      isComp820245Visible: "hidden",
      isComp354183Visible: "hidden",
      isComp306650Visible: "hidden",
      isComp706570Visible: "hidden",
      isComp115236Visible: "hidden",
      isComp197969Visible: "hidden",
      isComp779525Visible: "hidden",
      isComp41699Visible: "hidden",
      isComp75947Visible: "hidden",
      isComp506987Visible: "hidden",
      isComp696253Visible: "hidden",
      isComp187555Visible: "hidden",
      isComp879974Visible: "hidden",
      isComp115636Visible: "hidden",
      isComp57389Visible: "hidden",
      isComp564832Visible: "hidden",
      isComp143664Visible: "hidden",
      isComp374173Visible: "hidden",
      isComp987728Visible: "hidden",
      isComp679678Visible: "hidden",
      isComp843737Visible: "hidden",
      isComp621973Visible: "hidden",
      isComp350228Visible: "hidden",
      isComp139169Visible: "hidden",
      isComp390934Visible: "hidden",
      isComp294Visible: "hidden",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false,
      shouldTriggerPageInit: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("dashboard", "master_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.DashboardPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("dashboard", "master_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("dashboard", "master_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.DashboardPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.DashboardPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      dashboard_611755_value: this.state.fnPreviousMonth?.at?.(0)?.firstDayOfYear ?? undefined,
      dashboard_769300_value: moment().format("YYYY-MM-DDTHH:mm:ss"),
      dashboard_48309_value: this.state.ProtokolAll?.at?.(0)?.id ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  checkTabErrors = () => {
    document.querySelectorAll(".rc-tabs-tab").forEach((tab) => {
      (tab as HTMLElement).style.color = "";
    });

    const tabPanes = document.querySelectorAll(".rc-tabs-tabpane");
    const tabs = document.querySelectorAll(".rc-tabs-tab");

    tabPanes.forEach((tabPane, index) => {
      const uniquePaneId = `rc-tabs-tabpane-${index}`;
      if (!tabPane.hasAttribute("id")) {
        tabPane.setAttribute("id", uniquePaneId);
      }

      const uniqueTabId = `rc-tabs-tab-${index}`;
      if (tabs[index]) {
        if (!tabs[index].hasAttribute("id")) {
          tabs[index].setAttribute("id", uniqueTabId);
        }
        tabs[index].setAttribute("aria-controls", uniquePaneId);
      }
    });

    const errorElements = document.querySelectorAll(".rc-tabs .ant-form-item-explain-error");

    const tabsWithErrors = new Set<string>();

    errorElements.forEach((errorElement) => {
      const tabPane = errorElement.closest(".rc-tabs-tabpane");
      if (tabPane && tabPane.id) {
        tabsWithErrors.add(tabPane.id);
      }
    });

    tabsWithErrors.forEach((tabPaneId) => {
      const tab = document.querySelector(`.rc-tabs-tab[aria-controls="${tabPaneId}"]`);
      if (tab) {
        (tab as HTMLElement).style.color = "red";
      }
    });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };
  setShouldTriggerPageInit = (value: boolean) => {
    this.setState({ shouldTriggerPageInit: value });
  };

  DashboardPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      date_2: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.dashboard_437202_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].checkupDonemi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].checkupDonemi
        : null
    );
    stateVars.isComp748517Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp310903Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp724287Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp218368Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212092Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp5125580Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp936490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.CheckupDonemiByKirilim1Id = result?.data.checkupDonemiByKirilim1Id;
    stateVars.isComp226031Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id2
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp820245Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id3
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp706570Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id4
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp779525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id5
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp506987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id6
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.fnPreviousMonth = result?.data.fnPreviousMonth;
    formVars.dashboard_611755_value = ReactSystemFunctions.value(
      this,
      stateVars.fnPreviousMonth?.length > 0
        ? stateVars.fnPreviousMonth[0].firstDayOfYear
        : this.state.fnPreviousMonth?.length > 0
        ? this.state.fnPreviousMonth[0].firstDayOfYear
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      )
    ) {
      KuikaAppManager.prepareForNavigation();
      KuikaAppManager.addToPageInputVariables("Persons", "role", "Rol_Observed");

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.DashboardPageInit2_,
        "Dashboard",
        "Persons",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      )
    ) {
      stateVars.WriteToLocalStorage = await ReactSystemFunctions.writeToLocalStorage(
        "lastVisitedMenuId_920284",
        "Menu_Danisanlarim"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkCalisanOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkCalisanOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.DashboardPageInit4_,
        "Dashboard",
        "KVKKAydinlatmaSozlesmesi",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "492360",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkMerkezSozlesmeOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkMerkezSozlesmeOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.DashboardPageInit5_,
        "Dashboard",
        "KurumSozlesmesi",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "549614",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit5_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kvvkEgitmenSozlesmeOkunmali
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kvvkEgitmenSozlesmeOkunmali
            : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.DashboardPageInit6_,
        "Dashboard",
        "EgitmenSozlesmesiYeni",
        "",
        "9924ef5f-4b84-4200-b624-8d76348c7686",
        "774566",
        null,
        null,
        "true",
        "800px",
        "70vh",
        false
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardPageInit6_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ProtokolAll = result?.data.protokolAll;

    formVars.dashboard_48309_value =
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null;
    formVars.dashboard_48309_options = stateVars.ProtokolAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardPageInit7_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "dashboard_48309_value",
      ReactSystemFunctions.toString(
        this,
        stateVars.ProtokolAll?.length > 0
          ? stateVars.ProtokolAll[0].id
          : this.state.ProtokolAll?.length > 0
          ? this.state.ProtokolAll[0].id
          : null
      ),
      null,
      "Id"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardPageInit8_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  DashboardPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "dashboard_611755_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "dashboard_769300_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kurumtipiid_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiId
            : null
        ),
        "Guid"
      ),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      Id_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolAll?.length > 0
            ? stateVars.ProtokolAll[0].id
            : this.state.ProtokolAll?.length > 0
            ? this.state.ProtokolAll[0].id
            : null
        ),
        "Guid"
      ),
      kirilim1Id_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      checkupDonemiId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      profileId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      protokolId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupDonemiId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      profileId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      lang_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      protokolId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kirilim1Id_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      sezonId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      kuserId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      protokolId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kurumID_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      profileId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      Id_10: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardPageInit8_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DashboardMevcutDurum = result?.data.dashboardMevcutDurum;
    formVars.dashboard_653303_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].adet
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].adet
        : null
    );
    formVars.dashboard_604297_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l1
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l1
        : null
    );
    formVars.dashboard_641373_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l2
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l2
        : null
    );
    formVars.dashboard_639869_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l3
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l3
        : null
    );
    formVars.dashboard_349053_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l4
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l4
        : null
    );
    stateVars.isComp512011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurum?.length > 0
            ? stateVars.DashboardMevcutDurum[0].okulyadakulupmu
            : this.state.DashboardMevcutDurum?.length > 0
            ? this.state.DashboardMevcutDurum[0].okulyadakulupmu
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboardMevcutDurumSummary = result?.data.dashboardMevcutDurumSummary;
    stateVars.DashboardMevcutDurumGraph = result?.data.dashboardMevcutDurumGraph;

    stateVars.isComp966032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurumGraph?.length > 0
            ? stateVars.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : this.state.DashboardMevcutDurumGraph?.length > 0
            ? this.state.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboardCheckuplari = result?.data.dashboardCheckuplari;

    stateVars.DashboardCheckuplariHeader = result?.data.dashboardCheckuplariHeader;

    stateVars.DashboardCheckuplariAvg = result?.data.dashboardCheckuplariAvg;
    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.isComp9172801Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7997680Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7071839Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8989893Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8216050Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3531977Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9859756Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8692831Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp277347Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp131879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947232Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735273Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269779Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879974Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115636Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57389Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564832Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp143664Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374173Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987728Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp679678Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843737Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp621973Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp350228Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139169Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390934Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboarAktifCheckupDonemi = result?.data.dashboarAktifCheckupDonemi;

    formVars.dashboard_166050_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapildi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapildi
        : null
    );
    formVars.dashboard_932140_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmadi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmadi
        : null
    );
    stateVars.OkulFitnessIstatistigi = result?.data.okulFitnessIstatistigi;

    stateVars.OkulKatilimIstatistikleri = result?.data.okulKatilimIstatistikleri;

    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.dashboard_401689_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_765937_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_363632_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_941747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_776212_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_43827_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    formVars.dashboard_474298_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardPageInit9_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurumGraph?.length > 0
            ? stateVars.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : this.state.DashboardMevcutDurumGraph?.length > 0
            ? this.state.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : null
        ),
        false
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp880340Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.DashboardPageInit10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  DashboardPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp748517Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboard_611755_value = ReactSystemFunctions.value(
      this,
      stateVars.fnPreviousMonth?.length > 0
        ? stateVars.fnPreviousMonth[0].firstDayOfYear
        : this.state.fnPreviousMonth?.length > 0
        ? this.state.fnPreviousMonth[0].firstDayOfYear
        : null
    );

    stateVars.isComp310903Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboard_48309_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null
    );

    formVars.dashboard_48309_value = ReactSystemFunctions.toString(
      this,
      stateVars.ProtokolAll?.length > 0
        ? stateVars.ProtokolAll[0].id
        : this.state.ProtokolAll?.length > 0
        ? this.state.ProtokolAll[0].id
        : null
    );

    stateVars.dataSource_48309 = this.state.ProtokolAll;
    stateVars.dataSource_48309 = this.state.ProtokolAll;
    stateVars.isComp724287Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_880340 = this.state.DashboardMevcutDurumGraph;
    formVars.dashboard_828218_text = ReactSystemFunctions.toString(
      this,
      stateVars.OkulFitnessIstatistigi?.length > 0
        ? stateVars.OkulFitnessIstatistigi[0].fitSkor
        : this.state.OkulFitnessIstatistigi?.length > 0
        ? this.state.OkulFitnessIstatistigi[0].fitSkor
        : null
    );

    stateVars.dataSource_966032 = this.state.DashboardMevcutDurumGraph;
    stateVars.isComp966032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurumGraph?.length > 0
            ? stateVars.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : this.state.DashboardMevcutDurumGraph?.length > 0
            ? this.state.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboard_681839_text = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariAvg?.length > 0
        ? stateVars.DashboardCheckuplariAvg[0].fitskor
        : this.state.DashboardCheckuplariAvg?.length > 0
        ? this.state.DashboardCheckuplariAvg[0].fitskor
        : null
    );

    formVars.dashboard_401689_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    formVars.dashboard_653303_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].adet
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].adet
        : null
    );

    formVars.dashboard_604297_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l1
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l1
        : null
    );

    formVars.dashboard_765937_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    formVars.dashboard_641373_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l2
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l2
        : null
    );

    formVars.dashboard_363632_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    formVars.dashboard_639869_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l3
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l3
        : null
    );

    formVars.dashboard_941747_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    formVars.dashboard_349053_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l4
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l4
        : null
    );

    formVars.dashboard_776212_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    stateVars.isComp218368Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboard_437202_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].checkupDonemi
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].checkupDonemi
        : null
    );

    stateVars.dataSource_753751 = this.state.DashboarAktifCheckupDonemi;
    formVars.dashboard_588440_text = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmaOrani
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmaOrani
        : null
    );

    formVars.dashboard_166050_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapildi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapildi
        : null
    );

    formVars.dashboard_43827_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    formVars.dashboard_932140_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmadi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmadi
        : null
    );

    formVars.dashboard_474298_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].lowercheckupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].lowercheckupOzneAdi
        : null
    );

    stateVars.isComp212092Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Observer"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp512011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurum?.length > 0
            ? stateVars.DashboardMevcutDurum[0].okulyadakulupmu
            : this.state.DashboardMevcutDurum?.length > 0
            ? this.state.DashboardMevcutDurum[0].okulyadakulupmu
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_5125580 = this.state.DashboardCheckuplari;
    stateVars.isComp5125580Visible =
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.dashboard_1772294_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariHeader?.length > 0
        ? stateVars.DashboardCheckuplariHeader[0].header
        : this.state.DashboardCheckuplariHeader?.length > 0
        ? this.state.DashboardCheckuplariHeader[0].header
        : null
    );

    stateVars.isComp9172801Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7997680Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7071839Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8989893Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8216050Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3531977Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9859756Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8692831Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp277347Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp131879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947232Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735273Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269779Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp936490Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_566388 = this.state.OkulKatilimIstatistikleri;
    formVars.dashboard_228026_title = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );

    formVars.dashboard_314050_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi1Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi1Adi
        : null
    );

    stateVars.isComp53847Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet1"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp43725Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet1"), "0") === true
        ? "visible"
        : "hidden";
    formVars.dashboard_226031_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi2Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi2Adi
        : null
    );

    stateVars.isComp226031Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id2
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id2
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp933926Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet2"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp758432Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet2"), "0") === true
        ? "visible"
        : "hidden";
    formVars.dashboard_820245_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi3Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi3Adi
        : null
    );

    stateVars.isComp820245Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id3
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id3
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp354183Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet3"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp306650Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet3"), "0") === true
        ? "visible"
        : "hidden";
    formVars.dashboard_706570_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi4Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi4Adi
        : null
    );

    stateVars.isComp706570Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id4
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id4
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115236Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet4"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp197969Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet4"), "0") === true
        ? "visible"
        : "hidden";
    formVars.dashboard_779525_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi5Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi5Adi
        : null
    );

    stateVars.isComp779525Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id5
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id5
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp41699Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet5"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp75947Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet5"), "0") === true
        ? "visible"
        : "hidden";
    formVars.dashboard_506987_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi6Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi6Adi
        : null
    );

    stateVars.isComp506987Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckupDonemiByKirilim1Id?.length > 0
            ? stateVars.CheckupDonemiByKirilim1Id[0].id6
            : this.state.CheckupDonemiByKirilim1Id?.length > 0
            ? this.state.CheckupDonemiByKirilim1Id[0].id6
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp696253Visible =
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet6"), "0") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp187555Visible =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, "dashboard_566388_value", "adet6"), "0") === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_5561 = this.state.OkulFitnessIstatistigi;
    formVars.dashboard_204881_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariHeader?.length > 0
        ? stateVars.DashboardCheckuplariHeader[0].header
        : this.state.DashboardCheckuplariHeader?.length > 0
        ? this.state.DashboardCheckuplariHeader[0].header
        : null
    );

    stateVars.isComp879974Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115636Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57389Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564832Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp143664Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374173Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987728Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp679678Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843737Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp621973Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp350228Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139169Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390934Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp294Visible =
      ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "dashboard_5561_value", "sinifId"), null) ===
      true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  DashboardComponent_611755_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardComponent_48309_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_769300_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.DashboardComponent_48309_onChange()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_48309_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "dashboard_611755_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "dashboard_769300_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      protokolId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      startDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_611755_value", "value", "", "", "")
        ),
        "Date"
      ),
      endDate_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_769300_value", "value", "", "", "")
        ),
        "Date"
      ),
      protokolId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      protokolId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      profileId_4: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      protokolId_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kurumtipiid_5: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumTipiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumTipiId
            : null
        ),
        "Guid"
      ),
      Id_6: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      checkupDonemiId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      profileId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      lang_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lang
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lang
            : null
        ),
        "string"
      ),
      protokolId_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kirilim1Id_7: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      sezonId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      kuserId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      protokolId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      ),
      kurumID_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      profileId_8: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      kirilim1Id_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kirilim1Id
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kirilim1Id
            : null
        ),
        "Guid"
      ),
      checkupDonemiId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].checkupDonemiId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].checkupDonemiId
            : null
        ),
        "Guid"
      ),
      profileId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      protokolId_9: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Dashboard/DashboardComponent_48309_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DashboardMevcutDurum = result?.data.dashboardMevcutDurum;
    formVars.dashboard_653303_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].adet
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].adet
        : null
    );
    formVars.dashboard_604297_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l1
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l1
        : null
    );
    formVars.dashboard_641373_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l2
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l2
        : null
    );
    formVars.dashboard_639869_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l3
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l3
        : null
    );
    formVars.dashboard_349053_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l4
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l4
        : null
    );
    stateVars.isComp512011Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurum?.length > 0
            ? stateVars.DashboardMevcutDurum[0].okulyadakulupmu
            : this.state.DashboardMevcutDurum?.length > 0
            ? this.state.DashboardMevcutDurum[0].okulyadakulupmu
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboardMevcutDurumSummary = result?.data.dashboardMevcutDurumSummary;
    stateVars.DashboardMevcutDurumGraph = result?.data.dashboardMevcutDurumGraph;

    stateVars.isComp966032Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.DashboardMevcutDurumGraph?.length > 0
            ? stateVars.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : this.state.DashboardMevcutDurumGraph?.length > 0
            ? this.state.DashboardMevcutDurumGraph[0].okulyadakulupmu
            : null
        ),
        false
      ) === true
        ? "visible"
        : "hidden";
    stateVars.DashboardCheckuplari = result?.data.dashboardCheckuplari;

    stateVars.DashboardCheckuplariAvg = result?.data.dashboardCheckuplariAvg;
    stateVars.DashboardCheckuplariHeader = result?.data.dashboardCheckuplariHeader;

    stateVars.ProtokolSelectById = result?.data.protokolSelectById;
    stateVars.isComp9172801Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7997680Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7071839Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8989893Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8216050Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp3531977Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp9859756Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp8692831Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp277347Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp131879Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp947232Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp735273Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp269779Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp879974Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "0"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp115636Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp57389Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "2"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp564832Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "3"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp143664Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "4"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374173Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "5"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp987728Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "6"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp679678Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "7"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp843737Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "8"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp621973Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "9"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp350228Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "10"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp139169Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "11"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp390934Visible =
      ReactSystemFunctions.isGreaterThan(
        ReactSystemFunctions.value(
          this,
          stateVars.ProtokolSelectById?.length > 0
            ? stateVars.ProtokolSelectById[0].bilesenAdedi
            : this.state.ProtokolSelectById?.length > 0
            ? this.state.ProtokolSelectById[0].bilesenAdedi
            : null
        ),
        "12"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.OkulFitnessIstatistigi = result?.data.okulFitnessIstatistigi;

    stateVars.OkulKatilimIstatistikleri = result?.data.okulKatilimIstatistikleri;

    stateVars.DashboarAktifCheckupDonemi = result?.data.dashboarAktifCheckupDonemi;

    formVars.dashboard_166050_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapildi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapildi
        : null
    );
    formVars.dashboard_932140_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmadi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmadi
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.DashboardComponent_48309_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  DashboardComponent_48309_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_880340 = this.state.DashboardMevcutDurumGraph;
    formVars.dashboard_828218_text = ReactSystemFunctions.toString(
      this,
      stateVars.OkulFitnessIstatistigi?.length > 0
        ? stateVars.OkulFitnessIstatistigi[0].fitSkor
        : this.state.OkulFitnessIstatistigi?.length > 0
        ? this.state.OkulFitnessIstatistigi[0].fitSkor
        : null
    );

    stateVars.dataSource_966032 = this.state.DashboardMevcutDurumGraph;
    formVars.dashboard_681839_text = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariAvg?.length > 0
        ? stateVars.DashboardCheckuplariAvg[0].fitskor
        : this.state.DashboardCheckuplariAvg?.length > 0
        ? this.state.DashboardCheckuplariAvg[0].fitskor
        : null
    );

    formVars.dashboard_653303_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].adet
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].adet
        : null
    );

    formVars.dashboard_604297_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l1
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l1
        : null
    );

    formVars.dashboard_641373_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l2
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l2
        : null
    );

    formVars.dashboard_639869_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l3
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l3
        : null
    );

    formVars.dashboard_349053_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardMevcutDurum?.length > 0
        ? stateVars.DashboardMevcutDurum[0].l4
        : this.state.DashboardMevcutDurum?.length > 0
        ? this.state.DashboardMevcutDurum[0].l4
        : null
    );

    stateVars.dataSource_753751 = this.state.DashboarAktifCheckupDonemi;
    formVars.dashboard_588440_text = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmaOrani
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmaOrani
        : null
    );

    formVars.dashboard_166050_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapildi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapildi
        : null
    );

    formVars.dashboard_932140_value = ReactSystemFunctions.toString(
      this,
      stateVars.DashboarAktifCheckupDonemi?.length > 0
        ? stateVars.DashboarAktifCheckupDonemi[0].yapilmadi
        : this.state.DashboarAktifCheckupDonemi?.length > 0
        ? this.state.DashboarAktifCheckupDonemi[0].yapilmadi
        : null
    );

    stateVars.dataSource_5125580 = this.state.DashboardCheckuplari;
    formVars.dashboard_1772294_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariHeader?.length > 0
        ? stateVars.DashboardCheckuplariHeader[0].header
        : this.state.DashboardCheckuplariHeader?.length > 0
        ? this.state.DashboardCheckuplariHeader[0].header
        : null
    );

    stateVars.dataSource_566388 = this.state.OkulKatilimIstatistikleri;
    formVars.dashboard_314050_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi1Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi1Adi
        : null
    );

    formVars.dashboard_226031_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi2Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi2Adi
        : null
    );

    formVars.dashboard_820245_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi3Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi3Adi
        : null
    );

    formVars.dashboard_706570_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi4Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi4Adi
        : null
    );

    formVars.dashboard_779525_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi5Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi5Adi
        : null
    );

    formVars.dashboard_506987_title = ReactSystemFunctions.toString(
      this,
      stateVars.OkulKatilimIstatistikleri?.length > 0
        ? stateVars.OkulKatilimIstatistikleri[0].checkupDonemi6Adi
        : this.state.OkulKatilimIstatistikleri?.length > 0
        ? this.state.OkulKatilimIstatistikleri[0].checkupDonemi6Adi
        : null
    );

    stateVars.dataSource_5561 = this.state.OkulFitnessIstatistigi;
    formVars.dashboard_204881_columnTitles = ReactSystemFunctions.toString(
      this,
      stateVars.DashboardCheckuplariHeader?.length > 0
        ? stateVars.DashboardCheckuplariHeader[0].header
        : this.state.DashboardCheckuplariHeader?.length > 0
        ? this.state.DashboardCheckuplariHeader[0].header
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_443195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DashboardKatilimDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "dashboard_566388_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "DashboardKatilimDetay",
      "Kirilim1ID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kirilim1Id
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kirilim1Id
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "DashboardKatilimDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Dashboard",
      "DashboardKatilimDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "854447",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  DashboardComponent_294_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations, this.checkTabErrors)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "DashboardBilesenDetay",
      "Kirilim2ID",
      ReactSystemFunctions.value(this, "dashboard_5561_value", "sinifId")
    );
    KuikaAppManager.addToPageInputVariables(
      "DashboardBilesenDetay",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "dashboard_48309_value", "value", "ProtokolAll", "id", "id")
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Dashboard",
      "DashboardBilesenDetay",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "354038",
      null,
      "right",
      null,
      "1400px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 854447, 354038] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.DashboardPageInit();
    }
    if (diId == 492360) {
      isErrorOccurred = await this.DashboardPageInit4_();
      if (isErrorOccurred) return true;
    } else if (diId == 549614) {
      isErrorOccurred = await this.DashboardPageInit5_();
      if (isErrorOccurred) return true;
    } else if (diId == 774566) {
      isErrorOccurred = await this.DashboardPageInit6_();
      if (isErrorOccurred) return true;
    }
  }
}
