import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPersonConnected_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPersonConnected_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  ObserverClientList: any[];
  ObserverClientList_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DeleteObserverSchool: number;
  DeleteObserverSchool_dummy: number;
  isComp838876Visible: "visible" | "hidden";
  isComp15953Visible: "visible" | "hidden";
  isComp68472Visible: "visible" | "hidden";
}

export class PersonConnected_ScreenBase extends React.PureComponent<IPersonConnected_ScreenProps, any> {
  personconnected_461205_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b4d02266-3bcd-4c9d-a959-ed8b85571032",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 714493, PropertyName: "value", Value: "Kişiler" },
        { Id: 838876, PropertyName: "label", Value: "YENİ" },
        { Id: 15953, PropertyName: "label", Value: "YENİ" },
        { Id: 461205, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 68472, PropertyName: "title", Value: "Sınıfı" },
        { Id: 472629, PropertyName: "value", Value: "[datafield:kirilim2]" },
        { Id: 603139, PropertyName: "title", Value: "Adı" },
        { Id: 105681, PropertyName: "value", Value: "[datafield:adi]" },
        { Id: 374840, PropertyName: "title", Value: "Soyadı" },
        { Id: 764084, PropertyName: "value", Value: "[datafield:soyadi]" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.personconnected_461205_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      ObserverClientList: [],
      NAVIGATE: "",
      DeleteObserverSchool: 0,
      isComp838876Visible: "hidden",
      isComp15953Visible: "hidden",
      isComp68472Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("personconnected", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.PersonConnectedPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("personconnected", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("personconnected", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.PersonConnectedPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  PersonConnectedPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      kisiID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      role_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "PersonConnected/PersonConnectedPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.ObserverClientList = result?.data.observerClientList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp838876Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Client") ===
      true
        ? "visible"
        : "hidden";
    stateVars.isComp15953Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Observer") ===
      true
        ? "visible"
        : "hidden";

    stateVars.dataSource_461205 = this.state.ObserverClientList;
    stateVars.isComp68472Visible =
      ReactSystemFunctions.isEqualTo(this.props.screenInputs.role ?? this.props.screenInputs.role, "Rol_Observer") ===
      true
        ? "visible"
        : "hidden";
    formVars.personconnected_472629_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].kirilim2
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].kirilim2
        : null
    );

    formVars.personconnected_105681_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].adi
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].adi
        : null
    );

    formVars.personconnected_764084_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].soyadi
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].soyadi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  PersonConnectedComponent_695674_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedComponent_838876_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PersonConnectedList", "role", "Rol_Observer");
    KuikaAppManager.addToPageInputVariables(
      "PersonConnectedList",
      "KisiId",
      this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnectedList",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonConnected",
      "PersonConnectedList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "806747",
      null,
      "right",
      null,
      "720px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedComponent_15953_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PersonConnectedList", "role", "Rol_Client");
    KuikaAppManager.addToPageInputVariables(
      "PersonConnectedList",
      "KisiId",
      this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid
    );
    KuikaAppManager.addToPageInputVariables(
      "PersonConnectedList",
      "kurumID",
      ReactSystemFunctions.value(
        this,
        stateVars.MyContext?.length > 0
          ? stateVars.MyContext[0].kurumId
          : this.state.MyContext?.length > 0
          ? this.state.MyContext[0].kurumId
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonConnected",
      "PersonConnectedList",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "908892",
      null,
      "right",
      null,
      "720px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedComponent_964911_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PersonCard",
      "kisiId",
      ReactSystemFunctions.value(this, "personconnected_461205_value", "kisiID")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "PersonConnected",
      "PersonCard",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  PersonConnectedComponent_542176_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      tableID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "personconnected_461205_value", "kisiID"),
        "Guid"
      ),
      scrInputID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      kurumID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      ),
      role_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kisiID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.KisiId ?? this.props.screenInputs.kisiid,
        "Guid"
      ),
      role_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.role ?? this.props.screenInputs.role,
        "string"
      ),
      kurumID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kurumId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kurumId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "PersonConnected/PersonConnectedComponent_542176_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.DeleteObserverSchool = result?.data.deleteObserverSchool;
    stateVars.ObserverClientList = result?.data.observerClientList;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.PersonConnectedComponent_542176_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  PersonConnectedComponent_542176_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_461205 = this.state.ObserverClientList;
    formVars.personconnected_472629_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].kirilim2
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].kirilim2
        : null
    );

    formVars.personconnected_105681_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].adi
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].adi
        : null
    );

    formVars.personconnected_764084_value = ReactSystemFunctions.toString(
      this,
      stateVars.ObserverClientList?.length > 0
        ? stateVars.ObserverClientList[0].soyadi
        : this.state.ObserverClientList?.length > 0
        ? this.state.ObserverClientList[0].soyadi
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [806747, 908892] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.PersonConnectedPageInit();
    }
  }
}
