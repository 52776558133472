import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKurumTipiForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKurumTipiForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  KurumTipiSelectById: any[];
  KurumTipiSelectById_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class KurumTipiForm_ScreenBase extends React.PureComponent<IKurumTipiForm_ScreenProps, any> {
  ml = [
    {
      Id: "74503767-9450-4cae-9db5-729d038bcb36",
      Name: "en_US",
      ShortName: "English",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Company Form Type2", TagName: "lblHead_value" },
        { Id: 1553174, PropertyName: "value", Value: "Description", TagName: "lblTanim_value" },
        { Id: 4994050, PropertyName: "placeholder", Value: "Tyoe here...", TagName: "txtTanim_placeholder" },
        { Id: 393869, PropertyName: "value", Value: "Are they providing education?", TagName: "Label1_0_value" },
        {
          Id: 6068907,
          PropertyName: "value",
          Value: "Is there a group Check-Up?",
          TagName: "lblTopluCheckupVarMi_value"
        },
        {
          Id: 5027455,
          PropertyName: "value",
          Value: "Is there a organisational breakage? ",
          TagName: "lblOrganizasyonelKirilimVarMi_value"
        },
        { Id: 5040380, PropertyName: "value", Value: "First breakage name", TagName: "lblBirinciKirilimAdi_value" },
        {
          Id: 2467851,
          PropertyName: "placeholder",
          Value: "Type here...",
          TagName: "txtBirinciKirilimAdi_placeholder"
        },
        { Id: 9179005, PropertyName: "value", Value: "Second breakage name", TagName: "lblIkinciKirilimAdi_value" },
        { Id: 3448477, PropertyName: "placeholder", Value: "Type here...", TagName: "txtIkinciKirilimAdi_placeholder" },
        { Id: 7602228, PropertyName: "value", Value: "Is there a Viewer", TagName: "lblIzleyiciVarMi_value" },
        { Id: 6116438, PropertyName: "value", Value: "Viewer name", TagName: "lblIzleyiciAdi_value" },
        { Id: 3898871, PropertyName: "placeholder", Value: "Type here...", TagName: "txtIzleyiciAdi_placeholder" },
        { Id: 6482915, PropertyName: "value", Value: "Check-Up name", TagName: "lblCheckupOzneAdi_value" },
        { Id: 4070893, PropertyName: "placeholder", Value: "Type here...", TagName: "txtCheckupOzneAdi_placeholder" },
        { Id: 6572404, PropertyName: "value", Value: "Instructor's name", TagName: "lblEgitmenAdi_value" },
        { Id: 9444366, PropertyName: "placeholder", Value: "Type. here...", TagName: "txtEgitmenAdi_placeholder" },
        { Id: 717187, PropertyName: "value", Value: "Code", TagName: "Label1_0_value" },
        { Id: 741639, PropertyName: "placeholder", Value: "Type here...", TagName: "txtKod_placeholder" },
        { Id: 9544, PropertyName: "label", Value: "Save", TagName: "btnSave_label" }
      ]
    },
    { Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a", Name: "tr_TR", ShortName: "Turkish", LanguagePhrases: [] },
    {
      Id: "28443b90-fa39-4dbe-8ace-a554144139d1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Kurum tipi2 Form" },
        { Id: 1553174, PropertyName: "value", Value: "Tanim" },
        { Id: 4994050, PropertyName: "placeholder", Value: "Yazınız..." },
        { Id: 393869, PropertyName: "value", Value: "Eğitim Veriliyor mu?" },
        { Id: 6068907, PropertyName: "value", Value: "Toplu checkup var mi" },
        { Id: 5027455, PropertyName: "value", Value: "Organizasyonel kirilim var mi" },
        { Id: 5040380, PropertyName: "value", Value: "Birinci kirilim adi" },
        { Id: 2467851, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9179005, PropertyName: "value", Value: "Ikinci kirilim adi" },
        { Id: 3448477, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 7602228, PropertyName: "value", Value: "Izleyici var mi" },
        { Id: 6116438, PropertyName: "value", Value: "Izleyici adi" },
        { Id: 3898871, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 6482915, PropertyName: "value", Value: "Checkup ozne adi" },
        { Id: 4070893, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 6572404, PropertyName: "value", Value: "Egitmen adi" },
        { Id: 9444366, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 717187, PropertyName: "value", Value: "Kod" },
        { Id: 741639, PropertyName: "placeholder", Value: "Type here..." },
        { Id: 9544, PropertyName: "label", Value: "Kaydet" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      KurumTipiSelectById: [],
      SaveRecord: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kurumtipiform", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KurumTipiFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("kurumtipiform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kurumtipiform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KurumTipiFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kurumtipiform_4994050_value: this.state.KurumTipiSelectById?.at?.(0)?.tanim ?? undefined,
      kurumtipiform_844461_value: this.state.KurumTipiSelectById?.at?.(0)?.egitimVerebilirMi ?? undefined,
      kurumtipiform_2155162_value: this.state.KurumTipiSelectById?.at?.(0)?.topluCheckupVarMi ?? undefined,
      kurumtipiform_2276334_value: this.state.KurumTipiSelectById?.at?.(0)?.organizasyonelKirilimVarMi ?? undefined,
      kurumtipiform_2467851_value: this.state.KurumTipiSelectById?.at?.(0)?.birinciKirilimAdi ?? undefined,
      kurumtipiform_3448477_value: this.state.KurumTipiSelectById?.at?.(0)?.ikinciKirilimAdi ?? undefined,
      kurumtipiform_604829_value: false,
      kurumtipiform_3898871_value: this.state.KurumTipiSelectById?.at?.(0)?.izleyiciAdi ?? undefined,
      kurumtipiform_4070893_value: this.state.KurumTipiSelectById?.at?.(0)?.checkupOzneAdi ?? undefined,
      kurumtipiform_9444366_value: this.state.KurumTipiSelectById?.at?.(0)?.egitmenAdi ?? undefined,
      kurumtipiform_741639_value: this.state.KurumTipiSelectById?.at?.(0)?.kod ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  KurumTipiFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KurumTipiForm/KurumTipiFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KurumTipiSelectById = result?.data.kurumTipiSelectById;
    formVars.kurumtipiform_4994050_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].tanim
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].tanim
        : null
    );
    formVars.kurumtipiform_844461_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].egitimVerebilirMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].egitimVerebilirMi
        : null
    );
    formVars.kurumtipiform_2155162_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].topluCheckupVarMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].topluCheckupVarMi
        : null
    );
    formVars.kurumtipiform_2276334_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].organizasyonelKirilimVarMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].organizasyonelKirilimVarMi
        : null
    );
    formVars.kurumtipiform_2467851_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].birinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].birinciKirilimAdi
        : null
    );
    formVars.kurumtipiform_3448477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );
    formVars.kurumtipiform_3898871_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].izleyiciAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].izleyiciAdi
        : null
    );
    formVars.kurumtipiform_4070893_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].checkupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].checkupOzneAdi
        : null
    );
    formVars.kurumtipiform_9444366_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].egitmenAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].egitmenAdi
        : null
    );
    formVars.kurumtipiform_741639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kod
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kod
        : null
    );
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumTipiFormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumTipiFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.kurumtipiform_4994050_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].tanim
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].tanim
        : null
    );

    formVars.kurumtipiform_844461_value = ReactSystemFunctions.value(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].egitimVerebilirMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].egitimVerebilirMi
        : null
    );

    formVars.kurumtipiform_2155162_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].topluCheckupVarMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].topluCheckupVarMi
        : null
    );

    formVars.kurumtipiform_2276334_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].organizasyonelKirilimVarMi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].organizasyonelKirilimVarMi
        : null
    );

    formVars.kurumtipiform_2467851_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].birinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].birinciKirilimAdi
        : null
    );

    formVars.kurumtipiform_3448477_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].ikinciKirilimAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].ikinciKirilimAdi
        : null
    );

    formVars.kurumtipiform_3898871_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].izleyiciAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].izleyiciAdi
        : null
    );

    formVars.kurumtipiform_4070893_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].checkupOzneAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].checkupOzneAdi
        : null
    );

    formVars.kurumtipiform_9444366_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].egitmenAdi
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].egitmenAdi
        : null
    );

    formVars.kurumtipiform_741639_value = ReactSystemFunctions.toString(
      this,
      stateVars.KurumTipiSelectById?.length > 0
        ? stateVars.KurumTipiSelectById[0].kod
        : this.state.KurumTipiSelectById?.length > 0
        ? this.state.KurumTipiSelectById[0].kod
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KurumTipiFormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  KurumTipiFormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      BirinciKirilimAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_2467851_value", "value", "", "", "")
        ),
        "string"
      ),
      CheckupOzneAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_4070893_value", "value", "", "", "")
        ),
        "string"
      ),
      EgitimVerebilirMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_844461_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EgitmenAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_9444366_value", "value", "", "", "")
        ),
        "string"
      ),
      IkinciKirilimAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_3448477_value", "value", "", "", "")
        ),
        "string"
      ),
      IzleyiciAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_3898871_value", "value", "", "", "")
        ),
        "string"
      ),
      IzleyiciVarMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_604829_value", "value", "", "", "")
        ),
        "boolean"
      ),
      OrganizasyonelKirilimVarMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_844461_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Tanim_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_4994050_value", "value", "", "", "")
        ),
        "string"
      ),
      TopluCheckupVarMi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_2155162_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Kod_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "kurumtipiform_741639_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "KurumTipiForm/KurumTipiFormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KurumTipiFormComponent_9544_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KurumTipiFormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
