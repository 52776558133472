import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IKVKKAydinlatmaSozlesmesi_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IKVKKAydinlatmaSozlesmesi_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  UserSettingsByUserId: any[];
  UserSettingsByUserId_dummy: any[];
  AnyProfileOfEmail: any[];
  AnyProfileOfEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  SendMail: boolean;
  SendMail_dummy: boolean;
  GoBack: any;
  GoBack_dummy: any;
  isComp217376Enabled: "enabled" | "disabled";
}

export class KVKKAydinlatmaSozlesmesi_ScreenBase extends React.PureComponent<
  IKVKKAydinlatmaSozlesmesi_ScreenProps,
  any
> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "1f680e76-a2d2-4681-846a-708610ef82ec",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 552925, PropertyName: "value", Value: "KVKK AYDINLATMA METNİ" },
        { Id: 727329, PropertyName: "value", Value: "Son Güncelleme:" },
        { Id: 227123, PropertyName: "value", Value: "17.12.2021" },
        { Id: 204138, PropertyName: "value", Value: "Veri Sorumlusu Kimliği:" },
        { Id: 348713, PropertyName: "value", Value: "ScoreSoft Solutions A.Ş." },
        { Id: 938619, PropertyName: "value", Value: "Yazışma Adresi:" },
        { Id: 782117, PropertyName: "value", Value: "Çubuklu Mah. Boğaziçi Cad. No:9 Kavacık-İstanbul" },
        { Id: 154730, PropertyName: "value", Value: "E-posta:" },
        { Id: 648925, PropertyName: "value", Value: "info@memap.com.tr" },
        {
          Id: 675821,
          PropertyName: "value",
          Value: "KİŞİSEL VERİLERİN ELDE EDİLMESİ VE İŞLENMESİ İLE İLGİLİ AYDINLATMA BEYANI VE AÇIK RIZA METNİ:"
        },
        {
          Id: 708259,
          PropertyName: "value",
          Value:
            "memap.com.tr (“MeMap”) tarafından, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”), 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Yönetmeliği ve ilgili mevzuat kapsamında Veri Sorumlusu sıfatıyla, kişisel verileriniz, aşağıda detaylı olarak açıklanan çerçevede ve ilgili Kanun ve Kanun Hükmünde Kararname ve Yönetmelik hükümleri uyarınca sair mevzuatlara uygun olarak işlenebilecektir."
        },
        {
          Id: 907158,
          PropertyName: "value",
          Value: "KİŞİSEL VERİLERİN ELDE EDİLMESİ VE İŞLENMESİ İLE İLGİLİ AYDINLATMA BEYANI VE AÇIK RIZA METNİ:"
        },
        {
          Id: 97258,
          PropertyName: "value",
          Value:
            "İşbu aydınlatma metni https://memap.com.tr/ adresini ziyaret eden ve kullanan ilgili kişilerin kişisel verilerini konu edinmektedir."
        },
        { Id: 432345, PropertyName: "value", Value: "Adreslerde bulunan çerez verileriyle ilgili olarak ayrıca" },
        { Id: 693586, PropertyName: "value", Value: "Çerez Bildirimini" },
        {
          Id: 242223,
          PropertyName: "value",
          Value: "inceleyebilirsiniz. Bu adres üzerinden kişisel verileriniz şu amaçlarla işlenir:"
        },
        {
          Id: 804828,
          PropertyName: "value",
          Value:
            "a) Web adresi üzerinden MeMap ürün ve hizmetlerinin tanıtımı ve sunumu, fitness sporuyla uğraşan kişilerin fiziksel kapasite ölçümlerinin yapılması, bu kişilerin eğitmenlerle buluşturulması, fitness eğitmenlerinin ve merkezlerinin verimli fitness desteği sağlamaları, kurumların çalışanlarına fitness eğitimi sağlamaları,"
        },
        {
          Id: 12812,
          PropertyName: "value",
          Value:
            "b) Fitness eğitimlerinin düzenlenmesi ve fitness konusunda bilimsel veri üretimi, fitness sporuyla ilgilenen bilim insanlarına destek sağlanması, sertifikalı eğitimler düzenlenmesi,"
        },
        {
          Id: 932209,
          PropertyName: "value",
          Value: "c) Web adresinin ve adres üzerinden alınan verilerin güvenliğinin sağlanması."
        },
        {
          Id: 176698,
          PropertyName: "value",
          Value:
            "d) Eğer bülten ve sair ticari e-ileti gönderimine onay verirseniz verileriniz iletişim verileriniz bu amaçla da kullanılır. Ticari e-ileti gönderimini ileriye dönük olarak istediğiniz zaman sonlandırabilirsiniz."
        },
        { Id: 38632, PropertyName: "value", Value: "Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği:" },
        {
          Id: 923000,
          PropertyName: "value",
          Value:
            "Kişisel verilerin aktarılmaması ve açıklanmaması önceliğimizdir. Bununla birlikte yazılım geliştirme, veri analizi ve barındırma konusunda destek aldığımız alt yükleniciler gerekli güvenlik kriterlerini sağlamak koşuluyla yukarıda sayılan amaçlarla sınırlı olarak bizimle eş zamanlı verilerinizi işleyebilirler."
        },
        { Id: 57829, PropertyName: "value", Value: "Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi:" },
        {
          Id: 785194,
          PropertyName: "value",
          Value:
            "Verileriniz adı geçen adreste yer alan ilgili alanları işleyerek gönder butonuna tıklamanızla birlikte web-adresi ara yüzüne yüklenmektedir. Sizi doğru tanımamız ve ürün ve hizmetlerimizin sunulduğu MeMap Platformundan yararlanmanız için öncelikle kullanıcı kaydı oluşturmanız gerekmektedir. Fiziksel kapasite ölçümleriniz dışında verileriniz otomatik işleme tabi tutulmayacaktır. Verilerinizi işlerken “Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve web adresi ve kayıt sistemlerimizin güvenliği bakımından ise “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebine dayanırız."
        },
        {
          Id: 922112,
          PropertyName: "value",
          Value: "İlgili Kişinin Kanunun 11 İnci Maddesinde Sayılan Diğer Hakları:"
        },
        {
          Id: 805939,
          PropertyName: "value",
          Value:
            "İlgili kişilerin 6698 Sayılı Kanun’da yer alan haklarını yukarıdaki yazışma adresine başvuruda bulunarak her zaman işletebilirler."
        },
        { Id: 428266, PropertyName: "value", Value: "6698 SK kapsamında İlgili Kişi Başvuru Formu" },
        { Id: 213416, PropertyName: "value", Value: "Son Güncelleme: 17.12.2021" },
        { Id: 318124, PropertyName: "value", Value: "6698 sayılı Kişisel Verilerin Korunması Kanunu" },
        { Id: 188245, PropertyName: "value", Value: "’nda (" },
        { Id: 592486, PropertyName: "value", Value: '"KVKK”' },
        {
          Id: 65323,
          PropertyName: "value",
          Value: "olarak anılacaktır) “İlgili kişi” olarak tanımlanan kişisel verisi işlenen gerçek kişilere ("
        },
        { Id: 925763, PropertyName: "value", Value: "“Başvuru Sahibi”" },
        {
          Id: 301374,
          PropertyName: "value",
          Value: "olarak anılacaktır), KVKK’nın 11. maddesi kapsamında aşağıdaki haklar tanınmıştır:"
        },
        { Id: 875860, PropertyName: "value", Value: "Kişisel verilerinizin" },
        { Id: 140789, PropertyName: "value", Value: "işlenip işlenmediğini öğrenme," },
        { Id: 345770, PropertyName: "value", Value: "Kişisel verileriniz işlenmişse buna" },
        { Id: 876453, PropertyName: "value", Value: "ilişkin bilgi talep etme," },
        { Id: 147768, PropertyName: "value", Value: "Kişisel verilerinizin işlenme amacını" },
        { Id: 818084, PropertyName: "value", Value: "ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme," },
        { Id: 486221, PropertyName: "value", Value: "Yurt içinde veya yurt dışında" },
        { Id: 335151, PropertyName: "value", Value: "kişisel verilerinizin aktarıldığı üçüncü kişileri bilme," },
        { Id: 6299, PropertyName: "value", Value: "Kişisel verilerinizin eksik veya yanlış" },
        {
          Id: 241658,
          PropertyName: "value",
          Value:
            "işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,"
        },
        { Id: 869780, PropertyName: "value", Value: "KVKK ile ilgili diğer kanun ve sair" },
        {
          Id: 852620,
          PropertyName: "value",
          Value:
            "mevzuat hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde, kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,"
        },
        { Id: 367935, PropertyName: "value", Value: "İşlenen verilerin münhasıran" },
        {
          Id: 143145,
          PropertyName: "value",
          Value:
            "otomatik sistemler vasıtasıyla analiz edilmesi suretiyle şahsınız aleyhine bir sonucun ortaya çıkmasına itiraz etme,"
        },
        { Id: 818683, PropertyName: "value", Value: "Kişisel verilerinizin kanuna  aykırı" },
        {
          Id: 484752,
          PropertyName: "value",
          Value:
            "Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme."
        },
        { Id: 936305, PropertyName: "value", Value: "KVKK’nın 13. maddesinin 1. fıkrası ile" },
        { Id: 44658, PropertyName: "value", Value: "Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ" },
        { Id: 623041, PropertyName: "value", Value: "uyarınca, işletmemize bu" },
        {
          Id: 992502,
          PropertyName: "value",
          Value:
            "haklara ilişkin olarak yapılacak başvuruların yazılı olarak ya da tarafınızca İşletmemize daha önce bildirilen ve sistemlerimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle kvkk@memap.com.tr adresine elektronik posta göndererek veya"
        },
        { Id: 493380, PropertyName: "value", Value: "Kişisel Verileri Koruma Kurulu (“Kurul”" },
        {
          Id: 214557,
          PropertyName: "value",
          Value: "olarak anılacaktır) tarafından gelecekte belirlenecek diğer yöntemlerle iletilmesi gerekmektedir."
        },
        {
          Id: 337888,
          PropertyName: "value",
          Value: "Bu çerçevede “yazılı” olarak Şirketimize yapılacak başvurular, işbu"
        },
        { Id: 503175, PropertyName: "value", Value: "İlgili Kişi Başvuru Formu" },
        { Id: 253133, PropertyName: "value", Value: "çıktısı alınarak," },
        {
          Id: 248301,
          PropertyName: "value",
          Value: "a) Başvuru Sahibi tarafından İşletmemize bizzat başvurulması ile,"
        },
        { Id: 491032, PropertyName: "value", Value: "b) Noter aracılığı ile veya iadeli taahhütlü mektup ile," },
        {
          Id: 602976,
          PropertyName: "value",
          Value:
            "c) Başvuru Sahibinin, sistemlerimizde kayıtlı e-posta adresinin bulunması durumunda, bu adresinden kvkk@memap.com.tr adresine e-posta gönderilmek suretiyle, iletilebilecektir."
        },
        {
          Id: 925257,
          PropertyName: "value",
          Value:
            "Tarafımıza iletilmiş olan başvurularınız KVKK’nın 13. maddesinin 2. fıkrası gereğince, talebin niteliğine göre talebinizin bizlere ulaştığı tarihten itibaren otuz gün içinde yanıtlanacaktır. Yanıtlarımız KVKK’nın 13. maddesi hükmü gereğince yazılı olarak veya elektronik ortamdan tarafınıza ulaştırılacaktır."
        },
        { Id: 850089, PropertyName: "value", Value: "Okudum, anladım." },
        { Id: 217376, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      UserSettingsByUserId: [],
      AnyProfileOfEmail: [],
      SaveRecord: false,
      ChangeEnabledOf: "",
      SendMail: false,
      GoBack: "",
      isComp217376Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("kvkkaydinlatmasozlesmesi", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.KVKKAydinlatmaSozlesmesiPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("kvkkaydinlatmasozlesmesi", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("kvkkaydinlatmasozlesmesi", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.KVKKAydinlatmaSozlesmesiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      kvkkaydinlatmasozlesmesi_295296_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  KVKKAydinlatmaSozlesmesiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KVKKAydinlatmaSozlesmesi/KVKKAydinlatmaSozlesmesiPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.UserSettingsByUserId = result?.data.userSettingsByUserId;
    stateVars.AnyProfileOfEmail = result?.data.anyProfileOfEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KVKKAydinlatmaSozlesmesiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KVKKAydinlatmaSozlesmesiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      LastChosenProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.AnyProfileOfEmail?.length > 0
            ? stateVars.AnyProfileOfEmail[0].id
            : this.state.AnyProfileOfEmail?.length > 0
            ? this.state.AnyProfileOfEmail[0].id
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UserSettingsByUserId?.length > 0
            ? stateVars.UserSettingsByUserId[0].id
            : this.state.UserSettingsByUserId?.length > 0
            ? this.state.UserSettingsByUserId[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "KVKKAydinlatmaSozlesmesi/KVKKAydinlatmaSozlesmesiPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KVKKAydinlatmaSozlesmesiPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.KVKKAydinlatmaSozlesmesiPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KVKKAydinlatmaSozlesmesiPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  KVKKAydinlatmaSozlesmesiComponent_295296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KVKKAydinlatmaSozlesmesiComponent_295296_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  KVKKAydinlatmaSozlesmesiComponent_295296_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "kvkkaydinlatmasozlesmesi_295296_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  KVKKAydinlatmaSozlesmesiComponent_217376_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KvkkOkudum_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("c873e5f6-20cc-4105-b307-5d8653065f15", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName("info@memap.com.tr", "string"),
      subject_1: ReactSystemFunctions.convertToTypeByName("Yeni bir üye kayıt oldu", "string"),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_1: ReactSystemFunctions.convertToTypeByName("tr_TR", "string"),
      uyeAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].firstName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].firstName
            : null
        ),
        "string"
      ),
      uyeSoyadi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastName
            : null
        ),
        "string"
      ),
      username_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      templateId_2: ReactSystemFunctions.convertToTypeByName("d94fbc6d-954a-4222-b384-e866e7cae3c4", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      subject_2: ReactSystemFunctions.convertToTypeByName("MeMap ailesine hoşgeldiniz", "string"),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      memap_2: ReactSystemFunctions.convertToTypeByName("MeMap", "string"),
      language_2: ReactSystemFunctions.convertToTypeByName("tr_TR", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "KVKKAydinlatmaSozlesmesi/KVKKAydinlatmaSozlesmesiComponent_217376_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.SendMail = result?.data.sendMail;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.KVKKAydinlatmaSozlesmesiComponent_217376_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  KVKKAydinlatmaSozlesmesiComponent_217376_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
