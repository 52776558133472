import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IEgitmenSozlesmesiYeni_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IEgitmenSozlesmesiYeni_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  UserSettingsByUserId: any[];
  UserSettingsByUserId_dummy: any[];
  AnyProfileOfEmail: any[];
  AnyProfileOfEmail_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  isComp217376Enabled: "enabled" | "disabled";
}

export class EgitmenSozlesmesiYeni_ScreenBase extends React.PureComponent<IEgitmenSozlesmesiYeni_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "68e4ee2b-e8e5-48b7-9957-b147bfd79c24",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 552925, PropertyName: "value", Value: "MEMAP SCORESOFT KULLANICI SÖZLESMESİ" },
        {
          Id: 182944,
          PropertyName: "value",
          Value: "ScoreSoft Kullanıcısı - MeMap Eğitmeni - MeMap Platform Eğitmeni"
        },
        { Id: 136149, PropertyName: "value", Value: "Madde 1: SÖZLEŞMENİN KONUSU:" },
        {
          Id: 971386,
          PropertyName: "value",
          Value:
            "İşbu ScoreSoft Kullanıcı Sözleşmesinin konusu (Sözleşme), ScoreSoft Kullanıcısı (“Eğitmen”) tarafından danışanlara verilecek olan MeMap hizmetlerinin sunulmasına ilişkin MeMap ile Eğitmen arasındaki hak ve yükümlülüklerin belirlenmesidir."
        },
        { Id: 417792, PropertyName: "value", Value: "Madde 2. TARAFLAR:" },
        {
          Id: 938513,
          PropertyName: "value",
          Value:
            'İşbu ScoreSoft Kullanıcı Sözleşmesi ("Sözleşme") MeMap Platformuna üye olan, aşağıda kişisel bilgileri yer alan ScoreSoft Kullanıcısı __________________ adresinde mukim _______________________ ("Eğitmen") ile “Çubuklu Mah. Boğaziçi Cad. No:9/1 Beykoz/ İSTANBUL” adresinde mukim SCORESOFT SOLUTIONS TEKNOLOJİA.Ş. (“Şirket") arasında imzalanmıştır.'
        },
        { Id: 895103, PropertyName: "value", Value: "Madde 2.1: TARAFLAR İLE İLGİLİ DETAYLAR:" },
        { Id: 786724, PropertyName: "value", Value: "FİRMA ADI: SCORESOFT SOLUTIONS TEKNOLOJİ A.Ş." },
        {
          Id: 644795,
          PropertyName: "value",
          Value: "POSTA ADRESİ: Çubuklu Mah. Boğaziçi Cad. No:9/1 K.2 Beykoz/İstanbul"
        },
        { Id: 113168, PropertyName: "value", Value: "TELEFON: 0532 468 89 87" },
        { Id: 852797, PropertyName: "value", Value: "E-POSTA ADRESİ: info@memap.com.tr" },
        { Id: 150350, PropertyName: "value", Value: "VERGİ DAİRESİ: 7571003233" },
        {
          Id: 955516,
          PropertyName: "value",
          Value: 'Yukarıda bilgileri bulunan firma sözleşmede "Şirket" olarak anılacaktır.'
        },
        { Id: 668134, PropertyName: "value", Value: "SCORESOFT KULLANICISI: ___________________" },
        { Id: 639548, PropertyName: "value", Value: "ADI SOYADI: ________________" },
        { Id: 556862, PropertyName: "value", Value: "TCKN: ________________" },
        { Id: 761060, PropertyName: "value", Value: "ADRESİ: ________________" },
        { Id: 886456, PropertyName: "value", Value: "TELEFON: ____________________" },
        { Id: 746518, PropertyName: "value", Value: "E-POSTA ADRESİ: _______________" },
        {
          Id: 851000,
          PropertyName: "value",
          Value: 'Yukarıda bilgileri bulunan kişi sözleşmede "Eğitmen" olarak anılacaktır.'
        },
        {
          Id: 600659,
          PropertyName: "value",
          Value:
            'İşbu Sözleşmede Şirket ile ScoreSoft Kullanıcısı ayrı ayrı "Taraf" olarak, birlikte "Taraflar" olarak anılacaktır.'
        },
        { Id: 517167, PropertyName: "value", Value: "Madde 3: TANIMLAR:" },
        { Id: 495160, PropertyName: "value", Value: "MeMap Internet Sitesi:" },
        { Id: 60155, PropertyName: "value", Value: "www.memap.com.tr URL adresli internet sitesini ifade eder." },
        { Id: 310532, PropertyName: "value", Value: "MeMap Platformu:" },
        {
          Id: 20613,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısının MeMap Hizmet Kredilerinin dağıtılması, MeMap hizmetlerine ilişkin randevuların yönetilmesi ve danişanların FitSkor puanlamasını takip etmesine dair işlemlerin yapıldığı dijital fitness platformudur."
        },
        { Id: 368472, PropertyName: "value", Value: "MeMap Hizmetleri:" },
        {
          Id: 725672,
          PropertyName: "value",
          Value:
            "Detayları memap.com.tr adresinde bulunan ve MeMap Hizmet Sertifikası’na sahip Eğitmenler tarafından Danışan’a verilen hizmetlerdir."
        },
        { Id: 219523, PropertyName: "value", Value: "MeMap Hizmet Sertifikası:" },
        {
          Id: 954823,
          PropertyName: "value",
          Value:
            "MeMap Fitness Akademisi tarafından, ilgili MeMap Hizmetini verebileceğini sınav ile kanıtlamış ScoreSoft Kullanıcısına verilen sertifikadır. ScoreSoft Kullanıcısının sahip olduğu sertifikalar ve bu sertifikalara istinaden verebileceği MeMap Hizmetleri ilgili ScoreSoft Kullanıcısının profil sayfasında yer alır."
        },
        { Id: 909611, PropertyName: "value", Value: "Hizmet Kredisi:" },
        {
          Id: 109905,
          PropertyName: "value",
          Value: "Hizmet Kredisi her MeMap hizmeti için kullanılan hayali bir para birimidir."
        },
        { Id: 512678, PropertyName: "value", Value: "Üye:" },
        { Id: 615110, PropertyName: "value", Value: "MeMap platformuna kaydolan kişiyi ifade eder." },
        { Id: 581538, PropertyName: "value", Value: "Danisan:" },
        { Id: 625933, PropertyName: "value", Value: "MeMap hizmetlerinden faydalanmış MeMap üyesini ifade eder." },
        { Id: 39247, PropertyName: "value", Value: "FitSkor:" },
        {
          Id: 751477,
          PropertyName: "value",
          Value:
            "Kişinin yaş ve cinsiyetine göre amaca uygun fiziksel uygunluk seviyesini sayısal olarak ifade eden puanlama sisteminin sonucudur."
        },
        { Id: 376798, PropertyName: "value", Value: "ScoreSoft Yazılımı:" },
        {
          Id: 289571,
          PropertyName: "value",
          Value:
            "ScoreSoft Lisansı ile kullanılabilen, fitness bileşenlerini geliştirmek için kişilerin fiziksel ve fizyolojik verilerini değerlendirerek kişinin yaşa ve cinsiyete göre fiziksel uygunluk notunu belirleyen ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan dijital içerikli hizmet sunan bir yazılımdır."
        },
        { Id: 141238, PropertyName: "value", Value: "ScoreSoft Lisansı:" },
        {
          Id: 812790,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcıları için, e-posta adresine özel, tek kişilik ScoreSoft Yazılımını kullanma amaçlı yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 37379, PropertyName: "value", Value: "ScoreSoft Kullanıcısı:" },
        {
          Id: 402883,
          PropertyName: "value",
          Value: "MeMap Hizmet Kredilerini sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder."
        },
        { Id: 110863, PropertyName: "value", Value: "MeMap Eğitmeni:" },
        {
          Id: 133149,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerini sağlayan, kendisi veya merkez tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlamış ancak halka açık MeMap platformunda yer almayan eğitmeni ifade eder. Kısaca “Eğitmen” ‘olarak anılacaktır."
        },
        { Id: 629039, PropertyName: "value", Value: "MeMap Platform Eğitmeni:" },
        {
          Id: 39294,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerini sağlayan, kendisi tarafından satın alınmış geçerli ScoreSoft Lisansına sahip, MeMap Fitness Akademisi tarafından belirlenen eğitim kriterlerini karşılayan ve MeMap Hizmetlerini verebileceğini sınav ile ispatlayarak halka açık MeMap platformunda yer alan eğitmeni ifade eder. Kısaca “Eğitmen” olarak anılacaktır."
        },
        { Id: 947027, PropertyName: "value", Value: "MeMap Elçisi:" },
        {
          Id: 732090,
          PropertyName: "value",
          Value:
            "MeMap felsefesini, standartlarını ve iş modelini lisans sağlayıcılarına aktaran ve bu sağlayıcılar ile MeMap arasındaki iş ilişkilerini yürüten kişidir."
        },
        { Id: 136232, PropertyName: "value", Value: "Kurum:" },
        {
          Id: 436113,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder. Fitness Merkezlerini, Okulları ve Spor Kulüpleri için kullanılan ortak bir tanımdır."
        },
        { Id: 103963, PropertyName: "value", Value: "Kurum Lisansı:" },
        {
          Id: 738447,
          PropertyName: "value",
          Value:
            "MeMap Hizmet Kredilerinin sağlanması ve buna bağlı olarak MeMap Hizmetlerinin satılabilmesi için MeMap Dijital Platformunu kullanma hakkının temin edilmesi suretiyle yıllık ücretlendirilen dijital hizmettir."
        },
        { Id: 738124, PropertyName: "value", Value: "ScoreSoft Kurumu:" },
        {
          Id: 586117,
          PropertyName: "value",
          Value: "Geçerli Kurum Lisansına sahip, MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 314493, PropertyName: "value", Value: "MeMap Kurumu:" },
        {
          Id: 466031,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Kurum standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, ancak halka açık MeMap Platformunda yer almayan ve MeMap MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 905605, PropertyName: "value", Value: "MeMap Platform Kurumu:" },
        {
          Id: 466604,
          PropertyName: "value",
          Value:
            "Geçerli Kurum Lisansına sahip, MeMap Hizmetlerini sunmak üzere MeMap Kurum standartlarını karşılamış ve bünyesinde minimum bir MeMap Eğitmeni barındıran, bununla birlikte halka açık MeMap Platformunda yer alan ve MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 906767, PropertyName: "value", Value: "MeMap Platform Lisansı:" },
        {
          Id: 629309,
          PropertyName: "value",
          Value:
            "MeMap eğitmeninin ve MeMap Kurumunun halka açık MeMap Platformunda yer alarak tüm MeMap üyelerine hizmet vermesini sağlayan ücretli dijital hizmettir."
        },
        { Id: 629873, PropertyName: "value", Value: "Kurumsal Şirket:" },
        {
          Id: 108866,
          PropertyName: "value",
          Value:
            "Bir ya da birden fazla MeMap Kurumu ile anlaşmalı MeMap Hizmet Kredilerini sağlayan ticari işletmeyi ifade eder."
        },
        { Id: 219799, PropertyName: "value", Value: "MeMap Fitness Akademisi:" },
        {
          Id: 604677,
          PropertyName: "value",
          Value:
            "www.yeninesilfitnessegitimi.com URL adresli, eğitmenlerin standardize edilmiş MeMap Hizmetlerini verebilmek amaçlı eğitimler alabildikleri çevrimiçi Fitness Eğitim Platformunu ifade eder."
        },
        { Id: 833317, PropertyName: "value", Value: "Egzersiz Reçetelendirme:" },
        {
          Id: 103810,
          PropertyName: "value",
          Value:
            "FitSkor’u hesaplanmış kişilere, haftada egzersize ayrılacak gün ve süresine bağlı, kişiye özel tavsiye niteliğindeki önceliklendirilmiş fitness bileşenlerinin sıralanışını ifade eder."
        },
        { Id: 847408, PropertyName: "value", Value: "Veri:" },
        {
          Id: 274271,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının hesaplamalar yapabilmesi için danışan ve/veya eğitmen tarafından sağlanan kişisel verilerdir."
        },
        { Id: 370060, PropertyName: "value", Value: "Know-How:" },
        {
          Id: 82942,
          PropertyName: "value",
          Value:
            "İşbu sözleşme ile ScoreSoft Kullanıcısının kullanımına tahsis edilen alanlarda bulunduğu şekli ile ScoreSoft Yazılımı, MeMap’in işletme sistemleri ve metotlarını ifade eder."
        },
        {
          Id: 869171,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında ScoreSoft Kullanıcısı, MeMap Eğitmeni ve MeMap Platform Eğitmeni birlikte kullanıldığında “ScoreSoft Kullanıcısı” olarak anılacaktır."
        },
        {
          Id: 460847,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında MeMap Eğitmeni ve MeMap Platform Eğitmeni birlikte kullanıldığında “Eğitmen” olarak anılacaktır."
        },
        {
          Id: 613553,
          PropertyName: "value",
          Value:
            "İşbu sözleşme kapsamında MeMap Kurumu ve MeMap Platform Kurumu birlikte kullanıldığında “Kurum” olarak anılacaktır."
        },
        {
          Id: 137907,
          PropertyName: "value",
          Value:
            "İşbu Sözleşme kapsamında ScoreSoft Kullanıcısı, MeMap Eğitmeni, MeMap Platform Eğitmeni, ScoreSoft Kurumu, MeMap Kurumu, MeMap Platform Kurumu ve Kurumsal Şirket birlikte “MeMap Hizmet Kredi Sağlayıcıları” olarak anılacaktır."
        },
        { Id: 43367, PropertyName: "value", Value: "MADDE 4: MEMAP PLATFORMU HAKKINDA GENEL BİLGİLENDİRME" },
        {
          Id: 438083,
          PropertyName: "value",
          Value:
            "MeMap, fiziksel uygunluk ile ilgili hizmetler sunan dijital fitness platformudur. MeMap Platformu’na üye olan kişiler, MeMap hizmetlerinden yararlanabilmek için MeMap Hizmet Kredisi edinirler. MeMap Hizmet Kredisine sahip üyeler danışan olarak adlandırılırlar. Danışanlar MeMap hizmetlerini, ScoreSoft® yazılımını kullanan eğitmenler tarafından, ilgili eğitmenin bağlı olduğu MeMap akredite kurumlarda alırlar."
        },
        {
          Id: 290563,
          PropertyName: "value",
          Value:
            "MeMap Platformunda yer alan MeMap Platform Eğitmeni, MeMap Fitness Akademisi tarafından belirlenmiş kriterleri karşılayan ve MeMap hizmetlerini verebileceğini sınav ile ispatlamış eğitmenlerdir. MeMap Platformu’nda yer alan kurumlar ise MeMap kurum standartlarını karşılayan ve bünyesinde en az bir MeMap Platform Eğitmeni bulunduran kurumlardır. MeMap eğitmenleri ve Kurumları, aynı zamanda MeMap Hizmet Kredi Sağlayıcılarıdır."
        },
        {
          Id: 94310,
          PropertyName: "value",
          Value:
            "ScoreSoft Kullanıcısı ise MeMap Hizmet Kredilerini sağlayan, geçerli ScoreSoft Lisansına sahip kullanıcıyı ifade eder. ScoreSoft Kullanıcısının ScoreSoft Lisansına sahip olmasına rağmen MeMap Hizmetlerini, MeMap Fitness Akademisi tarafından belirlenmiş kriter ve standartlara göre verme taahhüdü bulunmamaktadır. Bu bakımdan ScoreSoft Kullanıcısı MeMap Dijital Platformu’nda yer almamakta ve MeMap Hizmetlerini verememektedir."
        },
        {
          Id: 396821,
          PropertyName: "value",
          Value:
            "Eğitmenlerin kullanımı için geliştirilen ScoreSoft yazılımı, kişinin fiziksel ve fizyolojik verilerini değerlendirerek, yaşına ve cinsiyetine göre fiziksel uygunluk notunu (FitSkor®) hesaplayan ve buna bağlı olarak tavsiye niteliğinde egzersiz stratejisi oluşturan bir dijital içerikli hizmet yazılımıdır."
        },
        {
          Id: 955947,
          PropertyName: "value",
          Value:
            "ScoreSoft yazılımının tasarlandığı amaca uygun hesaplamalar yapabilmesi için, danışanın platforma kayıt esnasında girilen yaş ve cinsiyet bilgisi kullanılır. Danışan tarafından sağlanan diğer sağlık bilgileri danışanın ibraz ettiği raporlar ve beyanına istinaden eğitmen tarafından kaydedilir. Bunlar dışında hesaplamalar için kullanılan tüm veriler, eğitmenin yaptığı testlerden elde edilir. Eğitmen, vücut kompozisyonu, kas kuvveti ve esneklik ölçümlerinden elde ettiği fiziksel veriler ile kardiyorespiratuar kapasite ölçümlerinden elde ettiği fizyolojik verileri yazılıma girer. Tüm veriler girildikten sonra yazılım, danışanın FitSkor®’unu hesaplar. Egzersiz reçetelendirme stratejisi, danışanın FitSkor®’una, egzersize ayıracağı haftalık gün sayısına ve egzersiz süresine göre tamamen kişiselleştirilmiş olarak oluşturulur."
        },
        { Id: 334425, PropertyName: "value", Value: "MADDE 5: MEMAP PLATFORMUNUN KULLANIM ESASLARI" },
        {
          Id: 388856,
          PropertyName: "value",
          Value:
            "5.1.Şirket, işbu Sözleşme’de detayları belirtilen www.memap.com.tr internet sitesinde yer alan MeMap Platformu’nu işbu Sözleşme gereğince Eğitmen’in kullanımına sunmayı kabul, beyan ve taahhüt etmektedir."
        },
        {
          Id: 666045,
          PropertyName: "value",
          Value:
            "5.2 MeMap Platformu, işbu Sözleşmede belirlenen süre ve şartlar doğrultusunda Eğitmenin kullanımına sunulmaktadır."
        },
        {
          Id: 667749,
          PropertyName: "value",
          Value:
            "5.3. Şirket, MeMap Platformunun kullanıma sunulmasından önce Eğitmene yeninesilfitnessegitimi.com internet sitesi üzerinden online ya da yerinde eğitim verir. Eğitimin tamamlanmasına müteakip işbu sözleşmeye konu hizmetin verilmesine yönelik gerekliliklerin sağlanması için online sınav yapılır ve danışana hizmet verilebilmesi için gerekli olan MeMap Hizmet Sertifikası ilgili eğitmene özel olarak verilir. Eğitim ücreti işbu sözleşmede belirtilen bedele dahil olmayıp buna ilişkin ücret taraflar arasında ayrıca belirlenecektir. Bu doğrultuda, Şirket tarafından verilebilecek eğitim adı, eğitim içeriği ve eğitim bedelleri ayrıca “Eğitim Ücretleri Tablosunda belirtilmiştir."
        },
        {
          Id: 663907,
          PropertyName: "value",
          Value:
            "5.4. Şirket, Eğitmene en iyi hizmeti verebilmek için MeMap Platformu üzerinde geliştirme, hata düzeltmeleri yapma, yeni versiyonlara yükseltme ve benzeri iyileştirme işlemleri için en az 2 gün öncesinden e-posta ile bilgi verilmesi suretiyle teknik bakımlar ve güncellemeler yapabilecektir. Gecikmeksizin müdahale edilmesi gereken durumlarda Şirket 2 günlük süre ile kısıtlanmayacaktır."
        },
        {
          Id: 261755,
          PropertyName: "value",
          Value:
            "5.5. Danışanın sağlık durumuna ilişkin bilgiler, sadece danışanın fitness düzeyinin iyileştirilmesi ve danışanın hedeflerine ulaşmasını motive etmek amacıyla kullanılmaktadır. Danışanın, fiziksel uygunluk seviyesi, bir Fitness Notu olan FitSkor® ile ifade edilecektir. Eğitmenin MeMap sistemini kullanabilmesi için reşit olması gerekmektedir."
        },
        {
          Id: 888657,
          PropertyName: "value",
          Value:
            "5.6. MeMap Eğitmen üyeliği Eğitmene özel bir üyeliktir. Satın alınmış olan üyelik; sözleşme dışı üçüncü kişilere ödünç verilemez, devredilemez, herhangi bir nam altında kullandırılamaz. Böyle girişimlere bağlı oluşabilecek sorunlardan Şirket sorumlu değildir. Şirket, Eğitmenin, MeMap Platformunu işbu sözleşmede belirlenen kullanım amacına, yürürlükteki kanun hükümlerine ya da kendi ticari amaçları doğrultusunda aykırı bir şekilde kullandığını tespit etmesi durumunda Eğitmenin üyeliğini durdurabilir, askıya alabilir ya da tamamen sonlandırabilir."
        },
        {
          Id: 982846,
          PropertyName: "value",
          Value:
            "5.7. Üyelik süresinin sona ermesine müteakip Şirket, Eğitmenin MeMap Platformuna yönetici olarak erişimini engelleyecektir."
        },
        {
          Id: 15892,
          PropertyName: "value",
          Value:
            "5.8. ScoreSoft Yazılımı, kişinin fiziksel uygunluk seviyesini kendi geliştirdiği algoritma sayesinde, bilimsel literatürde yer alan, yaş ve cinsiyete has binlerce kişiden ortaya çıkan normlara göre değerlendirerek oluşturmaktadır. Eğitmen; ScoreSoft Yazılımının gelişmesinde ve kullanılmasında hiçbir teknik, fikri ve sınai hakkı bulunmadığını, Şirket ile haksız rekabet teşkil edecek faaliyetlere girmeyeceğini şimdiden kabul, beyan ve taahhüt eder."
        },
        {
          Id: 204241,
          PropertyName: "value",
          Value:
            '5.9. Eğitmen, Şirket ile ilgili olarak, Şirket’in bilgisi dışında ticari amaç taşıyan ya da reklam içeren hiçbir faaliyette bulunamaz. Diğer üyelerden herhangi bir ürün satın almak ya da onlara ürün satmak gibi bir talepte ve reklam faaliyetinde bulunamaz. Bu amaçla üyelere yönelik "e-posta zincirleri" kurulamaz; "gereksiz e-posta" gönderilemez.'
        },
        { Id: 235635, PropertyName: "value", Value: "MADDE 6: ŞİRKETİN HAK VE YÜKÜMLÜLÜKLERİ" },
        {
          Id: 21334,
          PropertyName: "value",
          Value:
            "6.1. Şirket, ScoreSoft Kullanıcısının, ScoreSoft Yazılımına sunmuş olduğu kişisel verileri 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun şekilde işleyeceğini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 598864,
          PropertyName: "value",
          Value:
            "6.2. Şirket tarafından oluşturulan ScoreSoft Yazılımına kişisel veriler, ScoreSoft Kullanıcısı aracılığıyla girildikten sonra, üyeye ait fiziksel uygunluk notu (FitSkor) ve buna bağlı belirlenmiş bireysel egzersiz reçetelendirme tavsiyesi sağlanacaktır. ScoreSoft Yazılımının sunduğu bilgi ve içerik, tavsiye niteliğinde olup, buna ilişkin sair uygulama, değerlendirme ve tespitten dolayı Şirket sorumlu tutulamaz. Bu doğrultuda, ScoreSoft Kullanıcıları, ScoreSoft Yazılımı ve İnternet sitesi içindeki her türlü bilgi, egzersiz ve içeriğin tavsiye niteliğinde olduğunu ve hiçbir hastalık veya sağlık durumunun teşhis ve tedavisini sağlamadığını, bu içerikleri kullanıp kullanmaması konusundaki bütün takdir ve sorumluluğun kendisinde olduğunu gayri kabili rücu kabul, beyan ve taahhüt eder. Şirket, ScoreSoft Kullanıcılarının kendilerinin seçerek uyguladığı programla ilgili olarak sorumlu tutulamaz."
        },
        {
          Id: 373250,
          PropertyName: "value",
          Value:
            "6.3. Şirket, ScoreSoft Kullanıcısına bağlı olan Danışana daha iyi bir hizmet verebilmek ya da Danışanı çeşitli kampanyalara davet edebilmek amacıyla Danışanın kişisel verilerini talep edebilir ve bu amaçla Danışana anket ya da bilgi formu doldurtabilir. Şirket sunucu ve bilgisayarlarında toplanan bu bilgiler, dönemsel kampanya çalışmaları, kullanıcı profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen iletilere yönelik Danışan sınıflandırma çalışmalarında Şirket tarafından kullanılabilecektir."
        },
        {
          Id: 695639,
          PropertyName: "value",
          Value:
            "6.4. Şirket, ScoreSoft Kullanıcısı ile Danışan arasındaki iletişimle ilgili oluşabilecek herhangi bir hata, ihmal, kesinti, silinme, bozulma, işlem veya iletimde gecikme ya da iletişim ağı başarısızlığından sorumlu tutulamaz."
        },
        {
          Id: 586818,
          PropertyName: "value",
          Value:
            "6.5. Şirket, uygun gördüğü zamanlarda önceden hiçbir bildirimde bulunmadan ScoreSoft Yazılımını geliştirebilir, değiştirebilir ve bazı hizmetler için güncelleştirmeler ve hatta eksiltmeler yapabilir, yazılımla ilgili görsel, içeriksel ve uygulamaya yönelik değişikliğe gidebilir. ScoreSoft Kullanıcısı, Şirket’in MeMap üyeliği için öngördüğü üyelik bedelini, yazılım içi fiyatları, kampanya ve paketleri her zaman tek taraflı olarak değiştirebileceğini, ek ödemeler oluşabileceğini, güncel değişikliklerden yararlanabilmesi için güncellemeleri düzenli olarak yüklemesi ve bunun için bedel ödemesi gerektiğini kabul, beyan ve taahhüt eder. Yazılımsal güncellemeleri kabul edip yükleyen üyeler hizmet almaya devam eder."
        },
        {
          Id: 247392,
          PropertyName: "value",
          Value:
            "6.6. Şirket, takdir hakkı münhasıran kendisine ait olmak üzere, kendisi veya yetkilendirdiği MeMap Elçisi tarafından MeMap Eğitmenini işbu Sözleşmedeki yükümlülüklerini sözleşmeye uygun şekilde yerine getirip getirmediğine yönelik hiçbir ihbarda bulunmaksızın ve ek yetkilendirmeye gerek olmaksızın her zaman denetleme hak ve yetkisine sahiptir. Şirketin işbu madde kapsamındaki denetim yetkisi, MeMap Hizmetlerini MeMap Fitness Akademisi tarafından belirlenmiş kriter ve standartlara göre verme taahhüdü bulunmayan ScoreSoft Kullanıcısı için geçerli değildir."
        },
        {
          Id: 613994,
          PropertyName: "value",
          Value:
            "6.7. Şirket kalite standartları uyarınca; kendisini temsil eden MeMap Eğitmeninin, FitSkor ölçümünde kullandığı bireysel ekipmanları ile MeMap Hizmetlerini Danışana sunacağı esnada kullanacağı kılık ve kıyafetleri denetleme, FitSkor oluşturma ve uygulama şeklini belirleme haklarına sahiptir."
        },
        { Id: 872578, PropertyName: "value", Value: "MADDE 7: SCORESOFT KULLANICISININ HAK VE YÜKÜMLÜLÜKLERİ" },
        {
          Id: 100638,
          PropertyName: "value",
          Value:
            "7.1. ScoreSoft Kullanıcısının kaydının yapılabilmesi için https://fitnesscheckup.com/SigninPro adresinde yer alan kayıt formları ile, işbu sözleşmenin akdedildiği tarihte, ilgili ScoreSoft Kullanıcısının bizzat kendisi tarafından doldurulacaktır. Elektronik ortamda doldurulan form ışığında ScoreSoft Kullanıcı kayıt işlemleri tamamlanır. ScoreSoft Kullanıcısının, MeMap Fitness Akademisi tarafından belirlenmiş kriterleri karşılaması ve MeMap hizmetlerini verebileceğini sınav ile ispatlamış olması gerekmektedir."
        },
        {
          Id: 134596,
          PropertyName: "value",
          Value:
            "7.2. Eğitmen Kayıt Formunda bulunan bilgilerin ScoreSoft Kullanıcısının kendisine ait olması gerekmektedir. ScoreSoft Kullanıcısının kendisinden başka bir kişiye ait belge, bilgi vermesinden oluşabilecek aksaklıklardan ve zararlardan ScoreSoft Kullanıcısının bizatihi kendisi sorumludur. ScoreSoft Kullanıcısı, bu nedenle doğabilecek zararlardan dolayı Şirkete rücu hakkı bulunmadığını gayri kabili rücu kabul, beyan ve taahhüt eder."
        },
        {
          Id: 422464,
          PropertyName: "value",
          Value:
            "7.3. ScoreSoft Kullanıcısının profil sayfasında kullanılan fotoğraflar, logolar ve diğer görseller, ScoreSoft Kullanıcısının kendisine ait kabul edilir. ScoreSoft Kullanıcısının; MeMap Platformuna üye olurken verdiği e-posta adresinin, ScoreSoft Kullanıcısına ait ve aktif bir e-posta adresi olma zorunluluğu bulunmaktadır. Sözleşme kapsamında yapılacak tüm yazışmalarda, MeMap Platformunda yer alan “şifremi unuttum”, “mesajı şikâyet et” bölümünde sisteme üye olurken verilen e-posta adresi esas alınır ve bu adres kullanılır. Yanlış ve/veya gerçek dışı beyan edilen e-postalardan oluşabilecek aksaklık ve zararların tümünden ScoreSoft Kullanıcısı tek başına sorumludur."
        },
        {
          Id: 475367,
          PropertyName: "value",
          Value:
            "7.4. Tarafların yapacağı müzakere neticesinde alınacak ortak karara göre ScoreSoft Kullanıcılarına üç farklı üyelik tanımlanabilir:"
        },
        {
          Id: 154868,
          PropertyName: "value",
          Value: "7.4.1. Halka açık MeMap Platformunda yer alan MeMap Platform Eğitmeni,"
        },
        {
          Id: 70846,
          PropertyName: "value",
          Value: "7.4.2. Halka açık MeMap Platformunda yer almayan MeMap Eğitmeni veya"
        },
        {
          Id: 354685,
          PropertyName: "value",
          Value: "7.4.3. Halka açık MeMap Platformunda yer almayan ScoreSoft Kullanıcısı"
        },
        {
          Id: 202552,
          PropertyName: "value",
          Value:
            "halka açık olan MeMap Platformu Eğitmeninin profil sayfası tüm MeMap üyelerine açık iken, sadece kendi üyelerine hizmet veren MeMap Eğitmeni, MeMap platformunda yer almadığından üçüncü kişilerin ulaşımına kapalıdır."
        },
        {
          Id: 14482,
          PropertyName: "value",
          Value:
            "7.5. ScoreSoft Kullanıcısı, kişisel sosyal medya hesaplarında veya fiziki ortamda MeMap Platformuyla ilgili olarak Şirket bilgisi dışında herhangi bir şekilde reklam yapamaz. Reklam içeriği ancak önceden Şirketle yazılı mutabakat yapılarak Şirketin rızasıyla yapılabilir."
        },
        {
          Id: 14673,
          PropertyName: "value",
          Value:
            "7.6. ScoreSoft Kullanıcısının, ScoreSoft Yazılımını kullanabilmesi için 18 yaşından büyük olması gerekmektedir."
        },
        {
          Id: 140424,
          PropertyName: "value",
          Value:
            "7.7. MeMap Eğitmeni, Danışana Şirket’in ilgili MeMap Hizmetine özgülenmiş şekilde özel olarak belirlediği standartlara uymasını temin etmesi suretiyle, Danışanın sağlık kontrolünden geçmesini sağlamak, sağlık durumu değerlendirmesi yapmak, Danışana uygun şekilde MeMap standartlarındaki ölçüm protokollerini uygulayarak FitSkor oluşturmak, makul sürede randevu ayarlamak, Danışana verilerini kontrol ettirmek ve onaylattırmakla yükümlü ve doğrudan sorumludur. İşbu maddede belirtilen yükümlülükler, MeMap Hizmetlerini MeMap Fitness Akademisi tarafından belirlenmiş kriter ve standartlara göre sunma taahhüdü bulunmayan ScoreSoft Kullanıcısı için geçerli değildir."
        },
        {
          Id: 486045,
          PropertyName: "value",
          Value:
            "7.8. ScoreSoft Kullanıcısı, Danışandan talep ettiği ya da talep etmese dahi Danışan tarafından yazılı ve/veya sözlü olarak bildirilen kişisel verilerin, doğru ve gerçeği yansıttığı faraziyesiyle ScoreSoft Yazılımına girmektedir. Danışan tarafından yazılı ve/veya sözlü olarak bildirilen verilerin, sisteme doğru olarak işlenmesindeki münhasır sorumluluk -verilerin, danışan tarafından doğru verilmemesi hali hariç olmak üzere- ScoreSoft Kullanıcısına aittir. Danışan tarafından kişisel verilerin yazılı ve/veya sözlü olarak doğru bildirildiği ancak ScoreSoft Kullanıcısı tarafından yanlış kaydedilmesi halinde Danışanın uğrayacağı doğrudan ve/veya dolaylı ve sair zararlardan ScoreSoft Kullanıcısı münhasıran sorumludur. İşbu halde, ScoreSoft Kullanıcısı Şirkete karşı her türlü talep ve/veya rücu hakkından feragat ettiğini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 106714,
          PropertyName: "value",
          Value:
            "7.9. MeMap Eğitmeni ve/veya Danışan randevusunu 6 saat öncesine kadar iptal edebilir. Randevu saatinden 6 saatten daha az kaldığı durumlarda, hastalık, ölüm hallerinde rapor ya da belge sunulduğunda seans ücretsiz olarak iptal edilecektir. Eğitmen, onayladığı randevuya katılmaması halinde danışan ölçüm ücretini iade alabilir veya yeni bir randevu oluşturabilir. Ancak Danışan, randevusunu yukarıda yer alan şartlar dahilinde iptal etmez ve/veya randevu saatinde hazır bulunmazsa eğitmene seans ücreti ödeyeceğini kabul, beyan ve taahhüt etmiştir. Eğitmen ile Danışanın randevulaşma sürecinde Şirket taraf değildir. Taraflar işbu minvalde Şirketten hiçbir hak talebinde bulunmayacağını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 422047,
          PropertyName: "value",
          Value:
            "7.10. ScoreSoft Kullanıcısı, kendisine tahsis edilen ScoreSoft Yazılımı üzerinde sadece bir kişinin kullanımına yeterli olacak MeMap Hizmet Kredisi ile aynı anda 2 (iki) veya daha fazla Danışana hizmet vermeyeceğini kabul, taahhüt ve beyan eder."
        },
        {
          Id: 876825,
          PropertyName: "value",
          Value:
            "7.11. ScoreSoft Kullanıcısı gerek Şirket’in kendisini tanıtıcı reklam, görsel ve malzemelerinde gerekse sponsorluk sözleşmelerinden doğan gerekler doğrultusunda Şirket’in talimatlarına uyacağını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 777966,
          PropertyName: "value",
          Value:
            "7.12. ScoreSoft Kullanıcısı her ne şekil, suret ve nam altında olursa olsun herhangi bir üyenin ve/veya Şirket’in her türlü verilerinin gizliliğini, fikri ve mülkiyet haklarını ve sair kanuni haklardan kaynaklanan bilgi, yazılım ya da başka bir malzemeyi hak sahibinden ya da yararlananından izin almaksızın, kullanmayacağını, yayınlamayacağını ya da paylaşmayacağını kabul, beyan ve taahhüt eder. İşbu ihlallerden dolayı ScoreSoft Kullanıcısı, Şirketin kendisine cezai yaptırımda bulunma hakkının saklı bulunduğunu kabul eder."
        },
        {
          Id: 486190,
          PropertyName: "value",
          Value:
            "7.13. ScoreSoft Kullanıcısı, sadece Şirket tarafından sağlanmış veya onaylanmış reklam ve tanıtım malzeme ve programlarını kullanabilir. Şirket tarafından yazılı olarak kararlaştırılmayan her türlü reklam harcaması ScoreSoft Kullanıcısının şahsına ait olacaktır."
        },
        {
          Id: 303520,
          PropertyName: "value",
          Value:
            '7.14. ScoreSoft Kullanıcısı, yazılım veri tabanında, blog ya da yorum ekranlarında, MeMap’in bilgisi dışında ticari amaç taşıyan ya da reklam içeren hiçbir bilgi, yazılım ya da malzemenin ticareti, yayınlanmasını, reklamını veya paylaşımını yapmayacağını kabul ve beyan eder. Bu amaçla üyelere yönelik "e-posta zincirleri"; "gereksiz e-posta" gönderemeyeceğini kabul, beyan ve taahhüt eder.'
        },
        {
          Id: 602927,
          PropertyName: "value",
          Value:
            "7.15. ScoreSoft Kullanıcısının, Danışana yönelik aktif ya da pasif hareketi ile taksiren, kasten ya da kusuru sebebiyle Danışan nezdinde ortaya çıkabilecek tüm zararların tazmini gayrikabili rücu şekilde ScoreSoft Kullanıcısına aittir."
        },
        {
          Id: 545569,
          PropertyName: "value",
          Value:
            "7.16. ScoreSoft Kullanıcısı, işbu sözleşmenin ihlal edilmesinden dolayı Şirketin, şubelerinin, yetkililerinin, temsilcilerinin ve diğer çalışanlarının uğradığı ya da uğrayacağı maddi ve manevi zararları ile yoksun kalınan karları karşılamayı kabul, beyan ve taahhüt eder."
        },
        {
          Id: 770057,
          PropertyName: "value",
          Value:
            "7.17. ScoreSoft Kullanıcısı, Şirket’in yazılı onayını almak kaydıyla başka markalar ile sponsorluk sözleşmeleri yapabilir.  Eğitmen’in işbu sponsorluk sözleşmesi kapsamında elde ettiği gelirlerin paylaşılmasına ilişkin hükümler saklıdır."
        },
        {
          Id: 138720,
          PropertyName: "value",
          Value:
            "7.18. ScoreSoft Kullanıcısı, Şirket tarafından imzalanan sponsorluk sözleşmelerinden hiçbir nam ve ad adı altında ücret talep etmeyeceğini kabul, beyan ve taahhüt eder."
        },
        {
          Id: 951472,
          PropertyName: "value",
          Value:
            "7.19. ScoreSoft Kullanıcısı, kendisinin ve/veya Danışanının; kişisel verilerinde, ölçüm değerleri ve FitSkor parametrelerinde olağandışı hareketlilik, devamlı olarak yeni değer ve veriler girdiğinin tespit edilmesi halinde ScoreSoft Kullanıcısının sisteme kullanma hakkı geçici olarak dondurulacaktır."
        },
        {
          Id: 957030,
          PropertyName: "value",
          Value:
            "7.20. ScoreSoft Kullanıcısı tarafından kendi uzmanlık alanlarına girmeyen konulara ve/veya MeMap hizmet standartlarına aykırı faaliyet gösterilmesine ilişkin ortaya çıkan iş ve işlemlerden ötürü, müşteri ve/veya sair 3. kişiler nezdinde ortaya çıkabilecek her türlü hasar, zarar vb. tazmini gerektirir hususa ilişkin sorumluluk ScoreSoft Kullanıcısına aittir. Söz konusu durumlar uyarınca MeMap’e gelebilecek her türlü talep kapsamında, MeMap’e tazmin edecek ve herhangi bir zarar görmemesini temin edecektir. Herhangi bir şekilde MeMap’in zarar görmesi halinde söz konusu zararı derhal, aynen, nakden ve defaten tazmin edecektir. ScoreSoft Kullanıcısı yanlış veri kullanımdan dolayı yazılım tarafından oluşturulan sonuçlardan MeMap’in sorumlu tutulamayacağını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 861681,
          PropertyName: "value",
          Value:
            "7.21. ScoreSoft Kullanıcısı, Şirket ismini kullanırken yazılı izin almak ve rekabet yasağı kuralına uyacağını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 603992,
          PropertyName: "value",
          Value: "MADDE 8: EĞİTMENE VERİLEN LİSANS HAKKI VE LİSANS TÜRLERİ İLE LİSANS TÜRÜNE BAĞLI GELİR PAYLAŞIMI"
        },
        {
          Id: 530546,
          PropertyName: "value",
          Value:
            "Şirketin ScoreSoft kullanıcısına vermiş olduğu inhisari olmayan (basit) Lisans Hakkı; Türkiye Cumhuriyeti coğrafi bölgesiyle ile sınırlı olmak üzere işbu sözleşmede belirtilen tanımlı ürünlerin (lisansların) satış ve dağıtımıyla sınırlıdır. MeMap Platformu faaliyetine özgü tüm kulanım alanları ile ilgili kullanma, üretim, satış, dağıtım, ithalat, ihracat, pazarlama ve benzeri tüm kullanım hakları Şirkete aittir."
        },
        { Id: 671435, PropertyName: "value", Value: "HİZMET DAĞITIM ŞEKİLLERİ" },
        {
          Id: 492275,
          PropertyName: "value",
          Value:
            "8.1. ScoreSoft kullanıcısı kredi harcamadan, daha önce Şirket tarafından akredite edilmiş MeMap hizmetleri için kendi üyelerine davetiye gönderebilir. Bu davetiye kredi harcaması yapmaz. Üye hizmeti aldıktan sonra kredi düşümü gerçekleşir."
        },
        {
          Id: 328326,
          PropertyName: "value",
          Value:
            "8.2. ScoreSoft Kullanıcısı istediği üyesine kredi aktarabilir. Aktardığı kredi miktarı ile üye istediği hizmeti alabilir. Aktarılan kredi ScoreSoft Kullanıcı hesabından otomatik düşer."
        },
        {
          Id: 339439,
          PropertyName: "value",
          Value:
            "8.3. MeMap Platform Merkezleri ve MeMap Eğitmenleri, işbu sözleşme kapsamında yer alan hizmetleri Danışanlara sunmayı kabul, beyan ve taahhüt eder. Şirket, sözleşme kapsamındaki Hizmet Kredilerini ve MeMap Hizmetlerini kendisine ait MeMap Platformu aracılığıyla da satabilir. MeMap Hizmeti MeMap Platform aracılığıyla ScoreSoft Kullanıcısına yönlendirilmiş ise verilmiş hizmet ücretlerinden Şirket, %25 komisyon aldıktan sonra geri kalan meblağı MeMap Hizmet Sağlayıcı olan ScoreSoft Kullanıcısına aktarır."
        },
        { Id: 858146, PropertyName: "value", Value: "MEMAP HİZMET KREDİSİ:" },
        {
          Id: 397431,
          PropertyName: "value",
          Value:
            "8.4. MeMap Hizmet Kredisi her MeMap hizmeti için ScoreSoft Kullanıcı hesabından düşülen hayali bir para birimidir.  MeMap Hizmet Sağlayıcılarına MeMap Hizmet Kredisi Şirket tarafından yüklenir ve ScoreSoft Kullanıcıları bu kredileri farklı seçenekler ile kendi üyelerine sunabilir."
        },
        {
          Id: 340552,
          PropertyName: "value",
          Value:
            "8.5. Tüm MeMap hizmetlerinin bir kredi değeri bulunmaktadır. Baz alınan hizmet Yetişkin Fitness Check-Up hizmetidir. Bu hizmetin kredi değeri 100’dür. Diğer MeMap hizmetleri bu hizmetin kat sayıları şeklinde belirlenmektedir. 100 kredinin MeMap Hizmet Sağlayıcılar için alışı tarafların anlaşmasına bağlıdır."
        },
        { Id: 372028, PropertyName: "value", Value: "MADDE 9: SATIN ALMA VE ÖDEMELER" },
        {
          Id: 131088,
          PropertyName: "value",
          Value:
            "Ödeme Metodu: Ödemelere ilişkin şartlar, ScoreSoft Kullanıcısı ile yapılacak karşılıklı görüşmeler sonucunda, tarafların ortak iradelerine göre şekillendirilecektir. Buna göre ödemeler, önceden satın alınan Toplu MeMap Hizmet Kredisi veya Konsinye MeMap Hizmet Kredisi için ScoreSoft Kullanıcısının tercihine göre iki farklı şekilde yapılabilir:"
        },
        {
          Id: 197577,
          PropertyName: "value",
          Value:
            "9.1. Toplu MeMap Hizmet Kredisi: Kurum tarafından ulaşılması planlanan Danışan sayısına göre MeMap kredisinin önceden satın alınmasıdır. Tarafların anlaşmasına bağlı Hizmet Kredileri Şirket tarafından Kurum hesabına yüklenir."
        },
        {
          Id: 656681,
          PropertyName: "value",
          Value:
            "9.2. Konsinye MeMap Hizmet Kredisi: Belirli sayıda hizmet verebilmek adına ScoreSoft Kullanıcısına yüklenen MeMap Hizmet Kredisi için önceden bir ücret alınmaz. Kredi miktarı belirlenmiş eksi bakiyeye düştükten sonra ScoreSoft Kullanıcısının hizmet vermesi otomatik durdurulur. ScoreSoft Kullanıcısı tarafından harcanmış olan Hizmet Kredileri için ücret ödendikten sonra ScoreSoft Kullanıcısı, MeMap Hizmetlerini tekrar sunmaya devam edebilir."
        },
        {
          Id: 54850,
          PropertyName: "value",
          Value:
            "9.3. ScoreSoft Kullanıcısı, Şirket'in uygulama fiyatını ve uygulama içi fiyatları, kampanya ve paketleri her zaman tek taraflı olarak değiştirebileceğini, yapılan değişiklikler için güncelleme ve uygulama bilgilerini düzenli olarak kontrol edeceğini kabul ve beyan eder."
        },
        {
          Id: 226265,
          PropertyName: "value",
          Value:
            "9.4. ScoreSoft Kullanıcısı, Şirket tarafından www.memap.com.tr internet adresinde belirlenmiş olan hizmet bedellerinin üzerinde bir fiyat ile Danışanlara işbu sözleşmeye konu hizmetleri temin edemeyeceğini, hizmet bedelleri konusunda www.memap.com.tr internet sitesinde belirlenmiş fiyatlara riayet edeceğini kabul eder."
        },
        {
          Id: 814009,
          PropertyName: "value",
          Value:
            "9.5. ScoreSoft Kullanıcısı tarafından www.memap.com.tr internet adresinde belirlenmiş olan hizmet bedellerinin altında bir fiyat ile Danışanlara işbu sözleşmeye konu hizmetlerin temin edilmesinin talep edilmesi halinde, öncelikle ilgili lisans türü, hizmet kapsamı ve hizmet bedeli hakkında Şirket yazılı olarak bilgilendirecektir. Şirket’in ilgili lisans türü, hizmet kapsamı ve hizmet bedeline yazılı şekilde onay vermesi halinde Merkez belirlemiş olduğu fiyatı kendi bölgesi içerisinde uygulamaya geçirebilecektir."
        },
        { Id: 37125, PropertyName: "value", Value: "MADDE 10: ŞİRKET’İN KURUM ÜZERİNDEKİ DENETİM YETKİSİ" },
        {
          Id: 19845,
          PropertyName: "value",
          Value:
            "10.1. Şirket, hiçbir şekilde kendisine ait bir yükümlülük olmaksızın ve Sözleşme kapsamında ScoreSoft Kullanıcısına atfedilen yükümlülükler bakımından ScoreSoft Kullanıcısına münhasır sorumluluğuna halel getirmeksizin, takdir hakkı münhasıran kendisine ait olmak üzere, kendisi veya yetkilendirdiği MeMap Elçisi tarafından işbu Sözleşmeye uygunluğunu hiçbir ihbarda bulunmaksızın ve ek yetkilendirmeye gerek olmaksızın her zaman denetleme hak ve yetkisine sahiptir."
        },
        {
          Id: 414519,
          PropertyName: "value",
          Value:
            "10.2. ScoreSoft Kullanıcısı, kendisinin ve bu kişilerin, işbu Madde kapsamında yapılacak ve/ veya yaptırılacak denetimler sırasında, Şirket ile iş birliği içinde hareket edeceğini; Şirket’e her türlü kolaylığı ve desteği sağlayacağını; Şirket tarafından ve/veya Şirket adına MeMap Hizmetlerinin tespit edilmiş standartlara uygun şekilde verilip verilmediğine dair bilgileri sunacağını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 587282,
          PropertyName: "value",
          Value: "MADDE 11: SCORESOFT KULLANICISININ AKDE AYKIRILIK TEŞKİL EDEN EYLEMLERİ"
        },
        { Id: 800672, PropertyName: "value", Value: "Eğitmenin;" },
        {
          Id: 120491,
          PropertyName: "value",
          Value:
            "11.1. MeMap standartlarındaki ölçüm protokollerini doğru uygulamamak, kullanılacak ekipmanların doğru kullanılmaması, MeMap tarafından öngörülen standartlara uygun bir şekilde davranılmaması,"
        },
        {
          Id: 186642,
          PropertyName: "value",
          Value: "11.2. MeMap’e ödenmesi gereken ücretleri, bu ödemelerin muaccel olduğu tarihte ödememesi,"
        },
        {
          Id: 31517,
          PropertyName: "value",
          Value: "11.3. ScoreSoft Kullanıcısının işbu Sözleşme hükümlerine aykırı olarak devirler gerçekleştirmesi,"
        },
        {
          Id: 874847,
          PropertyName: "value",
          Value:
            "11.4. Sözleşmeye aykırı olarak MeMap Platformu ve/veya ScoreSoft Yazılımını çoğaltması veya taklit etmesi, Markaların tüketicide iltibas yaratacak (ihtimali de dahil olmak üzere) şekilde birebir aynısını veya ayırt edilemeyecek kadar benzerini veya benzerini kullanması,"
        },
        { Id: 946735, PropertyName: "value", Value: "11.5. Gizlilik yükümlülüğünü yerine getirmemesi," },
        {
          Id: 5317,
          PropertyName: "value",
          Value:
            "11.6. Hizmet verdiği Kurum tarafından kullanımına tahsisli alanlar ve Eğitmenlik statüsü için gerekli izin veya ruhsatı ilgili resmi mercilerden almaması,"
        },
        {
          Id: 83013,
          PropertyName: "value",
          Value: "11.7. Ekipman güvenliği ile ilgili tedbirlerin alınması yükümlülüklerini yerine getirmemesi,"
        },
        {
          Id: 875016,
          PropertyName: "value",
          Value:
            "11.8. İş mevzuatı ve MeMap tarafından belirlenmiş talimatlara, işletme standartlara ve/veya prosedür ve politikalara uymaması,"
        },
        {
          Id: 524876,
          PropertyName: "value",
          Value: "11.9. MeMap platformuna üye olurken başkasına ait kişisel verileri kullanması,"
        },
        {
          Id: 949326,
          PropertyName: "value",
          Value:
            "ve işbu maddede yer alan haller sınırlı olmayıp, ScoreSoft Kullanıcısının Sözleşmenin diğer hükümlerine aykırı davranışları da sözleşmenin ihlali teşkil eder."
        },
        { Id: 115698, PropertyName: "value", Value: "MADDE 12: SÖZLEŞMENİN SÜRESİ ve ÜYELİĞİN DEVAM ETTİRİLMESİ" },
        {
          Id: 744898,
          PropertyName: "value",
          Value:
            "12.1. İşbu Sözleşme kapsamında Kuruma sağlanan üyelik süresi sözleşme imza tarihinden itibaren bir (1) yıldır. Sözleşme bitim tarihinden 30 gün önce, e-posta marifetiyle Şirket tarafından Kuruma sözleşmenin sona ereceği bilgisi verilecektir. Kurum yeni dönemde de işbu sözleşmeye devam etmek isterse, ödemesini yaparak, Şirket’e sözleşmeye devam etmek istediğine yönelik olumlu görüşünü e-posta marifetiyle bildirecektir."
        },
        { Id: 83422, PropertyName: "value", Value: "MADDE 13: GİZLİLİK, GÜVENLİK VE HİZMET KALİTESİ POLİTİKASI" },
        {
          Id: 690615,
          PropertyName: "value",
          Value:
            "13.1 Şirket, üyelik formlarından ve danışanlardan topladığı kişisel verileri, üçüncü şahıslarla kesinlikle paylaşmamakta, sadece işbu sözleşmenin ifası amacıyla kullanmakta, başka hiçbir ticari amaçla kullanmamaktadır."
        },
        {
          Id: 5908,
          PropertyName: "value",
          Value:
            "13.2. Merkez, kurumsal sistem kullanıcısı ve danışan bilgilerini ancak resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapmak zorunda olduğu durumlarda, önceden Şirketi bilgilendirmek kaydıyla, resmi makamlara açıklanabilecektir."
        },
        {
          Id: 399227,
          PropertyName: "value",
          Value:
            "13.3. Şirket, başka sitelere tanıtım amaçlı linkler verebilir. MeMap sitesinden verilen linkler veya yapılan banner çalışmaları nedeniyle, Şirket; diğer sitelerin içeriğinden ve gizlilik politikaları ile yaptıkları satışlardan sorumlu değildir."
        },
        {
          Id: 432101,
          PropertyName: "value",
          Value:
            "13.4. Ödeme formlarında Kurumdan yetkili adı soyadı, telefon numarası, Kurum vergi dairesi, vergi numarası talep edilmektedir. Bu bilgiler, Kurumdan ödemeyi almak ve fatura kesmek için kullanılacaktır. Ödemede sorun yaşanması ihtimalinde, iş bu bilgiler Kurum ile temasa geçmek için kullanılacaktır."
        },
        {
          Id: 467778,
          PropertyName: "value",
          Value:
            "13.5. Kurum, Şirket’in ihmali ve kusuru görülmeden kişisel veri ve bilgilerinin yetkisiz 3. Kişiler tarafından okunması, paylaşılması, işlenmesi veya ticari amaçla kullanılması nedeniyle (Kurumun bilgilerini başka kişilerle paylaşması, siteden ayrılırken çıkış butonuna basmaması vb. durumlardan) doğabilecek zarara bağlı maddi ve manevi tazminat taleplerini Şirkete yöneltmeyeceklerini şimdiden kabul, beyan ve taahhüt ederler."
        },
        {
          Id: 533107,
          PropertyName: "value",
          Value:
            "13.6. Taraflar, işin yapılması sırasında öğrenilecek her türlü bilgilerde gizlilik esasına uyacaktır. Bu bağlamda gerek taraflar gerekse tarafların sorumlu olduğu tüm çalışanların işleri gereği öğrenmiş oldukları danışanlara ait kişisel veriler dahil olmak üzere, Taraflarca karşı Tarafa yazılı olarak veya elektronik ortamda teslim edilen her türlü bilgi ve Kurumun aldığı hizmetlere ait MeMap Platformunda tanımlayacağı bilgilerin yanı sıra sözleşme dolayısıyla Taraflar’ın vakıf olduğu ve karşı Tarafça geliştirilmiş herhangi bir bilgi, bir Tarafın patent, lisans ve telif hakları gibi bütün fikri ve sınai mülkiyet hakları ve mali hakları ile, ticari marka, ticari sır, her türlü iyileştirme fikri, icat, yöntem, iş ve her türlü yenilik dahil kendisinin yazdığı, bulduğu, geliştirdiği, yapmayı veya uygulamaya döktüğü ve bunlarla sınırlı olmaksızın, veri tabanı, bilgisayar programları ve bunların dokümanları, şifreleme teknikleri, prosesler, reklam, ambalajlama ve pazarlama planları ürün planları, teknik planlar, iş stratejileri, stratejik ittifaklar ve ortaklar, mali bilgiler, mühendislik verileri, ürün ve servislere ait veriler, her türlü yöntem ve prosesler, tahminler, personel bilgileri, listeleri, ticari sırlar, ürün tasarım kabiliyetleri, şartnameler, potansiyel ve gerçek müşterilerin kimliği, tedarikçiler ve bir tarafın diğer tarafa temin ettiği her türlü doküman, malzeme, bilgi ve belgeler dahil, her türlü şifahi, yazılı, grafiksel veya makine veya bilgisayarlarda okunabilir bilgiyi kapsar. Gizli bilgi ve veriler, yazılı olarak gizli veya müseccel oldukları belirtilmese dahi gizli bilgi olarak kabul edilir."
        },
        {
          Id: 854765,
          PropertyName: "value",
          Value:
            "13.7. Taraflar, kendilerinin, personellerinin, sözleşmeli çalışanlarının, danışmanlarının ve sair herhangi bir kişinin bu Sözleşme nedeniyle öğrendiği her türlü bilgi ve belgeyi kesinlikle gizli tutacaklarını ve diğer tarafın yazılı onayı olmaksızın özel ve tüzel kişilere açıklamamayı ve özel ve tüzel üçüncü şahısların bunları kullanmasına ve/veya çoğaltmasına izin vermeyeceklerini kabul, beyan ve taahhüt etmişlerdir."
        },
        {
          Id: 379893,
          PropertyName: "value",
          Value:
            "13.8. Taraflar, çalışanlarının, danışmanlarının ve sair hizmet aldıkları veya herhangi bir sair nedenle diğer Taraf hakkında gizli nitelikli bilgileri paylaştıkları gerçek ve/veya tüzel kişilerin, kendileri ile mevcut hizmet veya sair nitelikli ilişkinin sona ermesinden sonra dahi iş bu maddede yer alan gizlilik yükümlülüğüne, iş bu sözleşme ve/veya taraflar arası ticari ilişki sona erse dahi süresiz olarak uymalarını sağlamakla yükümlü olacaktır."
        },
        {
          Id: 908069,
          PropertyName: "value",
          Value:
            "13.9. Tarafların kamuya mal olmuş bilgileri ve yürürlükte olan yasalar ya da verilmiş olan bir mahkeme kararı ve idari emir gereğince açıklanması gereken bilgiler “gizli bilgi” kapsamına girmeyecektir."
        },
        { Id: 242652, PropertyName: "value", Value: "MADDE 14: KİŞİSEL VERİLERİN KORUNMASI" },
        {
          Id: 120134,
          PropertyName: "value",
          Value:
            "14.1. Taraflar, işbu sözleşmenin akdi yahut ifası kapsamında, yazılı, sözlü, fiziki, elektronik veyahut sistem erişimi sağlamak suretiyle olarak paylaşmış oldukları her türlü kişisel verinin, KVKK’ya, ikincil mevzuatına, Kurul düzenlemeleri ile kararlarına ve sair mevzuata uygun şekilde elde edildiğini, işlendiğini ve aktarıldığını kabul, beyan ve taahhüt eder."
        },
        {
          Id: 688138,
          PropertyName: "value",
          Value:
            "14.2. Taraflar, taraflar arasındaki hizmet tedarikine ilişkin tüm ticari ilişkilere yönelik yürürlükteki sözleşme veya sözleşmelerin kapsamındaki süreçler sırasında, ilgili sözleşme veya sözleşmelerin ifası için zorunlu olan ve tarafların birbirlerine kişisel veri aktarımı gerçekleştirdiği hallerde, kişisel verilerin hukuka aykırı olarak islenmesini önlemek, kişisel verilere hukuka aykırı olarak erişilmesini önlemek ve kişisel verilerin muhafazasını sağlamak amacıyla kişisel verinin niteliğine göre uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almakla yükümlüdür."
        },
        {
          Id: 938990,
          PropertyName: "value",
          Value:
            "14.3. Taraflar, işbu sözleşme kapsamında kendilerine iletilen her türlü kişisel veriyi KVKK ve sair mevzuat kapsamında tamamen gizli tutacaklarını, kişisel verileri yalnızca işbu sözleşme konusu hizmetlerin yerine getirilebilmesi bakımından zorunlu olduğu kadarıyla isleyeceklerini kabul, beyan ve taahhüt eder. Veri Sorumlusu olan taraf; Veri işleyen tarafa hizmet ile sinirli, bağlantılı ve ölçülü olmayan kişisel verileri aktarmayacağını; aksi halde sorumluluğun kendisine ait olduğunu peşinen kabul, beyan ve taahhüt eder."
        },
        {
          Id: 546295,
          PropertyName: "value",
          Value:
            "14.4. Taraflar birbirlerinin çalışanlarına, müşterilerine ve muhatap oldukları diğer kategorideki ilgili kişilere dair kişisel verileri yasal işleme şartları ve kuralları doğrultusunda kullanacaklarını, bunların gizliliğine dair her tür idari ve teknik tedbiri alacaklarını taahhüt ederler. Bu doğrultuda;"
        },
        { Id: 649857, PropertyName: "value", Value: "Aydınlatma Yükümlülüğü" },
        {
          Id: 692404,
          PropertyName: "value",
          Value:
            "Sözleşme tarafları, birbirlerinin hissedarlarının, ortaklarının, yetkililerinin ve çalışanlarının (hepsi birlikte “taraf temsilcisi” olarak anılır) verilerini faaliyetlerin mevzuata uygun yürütülmesi ve sözleşmenin kuruluşu ve doğrudan ifası amaçları ve şartlarıyla sınırlı olarak işler. Her taraf süreç boyunca kullanılacak ve aktarılacak kişisel verilerle ilgili olarak kendi taraf temsilcisini diğer taraf adına aydınlatmakla ve bu işlemi tevsik etmekle yükümlüdür."
        },
        { Id: 135907, PropertyName: "value", Value: "Kişisel Verileri İşleme ve Aktarma Kuralları" },
        {
          Id: 873917,
          PropertyName: "value",
          Value:
            "14.5. Taraflar sözleşmenin ifası için gerekli olan asgari kategorideki kişisel verileri güvenli yöntemlerle birbirlerine aktarırlar. Bu veriler, sözleşmenin ifası dışında yalnızca taraf temsilcisinin ayrıca aydınlatılması halinde ve mevzuatla öngörülen hukuki sebepler doğrultusunda kullanılır. Taraflar bu verileri güvenli ortamlarda saklamak ve sözleşmenin sona ermesinden itibaren saklama ve imha politikalarına uygun olarak ve en geç on yıl içerisinde imha etmekle yükümlüdürler. Aksi durumlarda her bir taraf kendi hukuka aykırı eyleminden sorumludur."
        },
        {
          Id: 727636,
          PropertyName: "value",
          Value:
            "14.6. Merkez, alınan hizmet kapsamında Şirket’e verdiği / vereceği kişisel veri sayılan her türlü veriyi, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”), bağlı mevzuatı ve kişisel verilerin korunmasına ilişkin uluslararası mevzuat uyarınca yalnızca işbu Sözleşme kapsamındaki hizmet / iş / ticari ilişki gereği zorunlu olması sebebi ile ve yalnızca işbu Sözleşmeden doğan yükümlülüklerin ifası amacı ile sınırlı olmak üzere paylaşmaktadır. Şirket kendisi ile paylaşılan verilerin ilgili mevzuat kapsamında birer kişisel veri niteliğine haiz olduğunu bilir ve kişisel verilerin korunmasının sağlanması için azami özeni gösterir."
        },
        {
          Id: 624057,
          PropertyName: "value",
          Value:
            "14.7. Taraflar, kendisi ile paylaşılan verileri, sözleşmenin ifası için gereken haller saklı kalmak kaydıyla üçüncü kişiler ile paylaşmayacağını, bu verileri yalnızca işbu Sözleşmede belirtilen amaç ile sınırlı bir biçimde kullanacağını, bu verileri kullanırken KVKK mevzuatına uygun davranacağını, bu verilerin hukuka aykırı olarak işlenmesini önlemek, kanuni süreler ve amacı ile sınırlı olmak kaydıyla muhafazasını sağlamak amacıyla KVKK ve ilgili mevzuat uyarınca gerekli her türlü teknik ve idari tedbirleri alacağını, işbu Sözleşmenin sona ermesinden sonra işbu Sözleşme gereği üstlenmiş olduğu kişisel verilerin korunmasına ilişkin yükümlülüklerinin ifasına süresiz olarak devam edeceğini ve sözleşmenin sona ermesinden sonraki süreçte KVKK’nın 5. Maddesinin 2. Fıkrasındaki haller dışında bu verileri işlemeyeceğini ve imha edeceğini, bunun dışındaki her türlü işlemenin yetkisiz olacağını ve bu işleme sebebi ile diğer Tarafın mahkeme kararıyla tespit edilmiş zararları, kusur ispat yükü zararı talep edende olması kaydıyla tazmin edeceğini kabul, beyan ve taahhüt eder."
        },
        { Id: 456884, PropertyName: "value", Value: "MADDE 15: FİKRİ MÜLKİYET HAKLARI" },
        {
          Id: 9426,
          PropertyName: "value",
          Value:
            "15.1. ScoreSoft Yazilimi ve Internet Sitesi’nin bütün fikri ve sinai mülkiyet haklari Sirket’e aittir. Yazilimin yapisi, düzeni, tasarimi, görselleri, içinde yer alan her türlü egzersiz, içerik ve bilgi ile yazilimin kaynak kodlari Şirket’e ait fikri ve sinai mülkiyet varliklarini olusturur. Tüm bu varliklar, 6769 sayili Sinai Mülkiyet Kanunu (SMK) ve 5846 sayili Fikir ve Sanat Eserleri Kanunu’na göre korunmaktadir. Isbu Sözlesme, yazilimin ve/veya içerigin herhangi bir fikri mülkiyet hakkini ScoreSoft Kullanicisi ve/veya Danisan’a vermemekte ve buna iliskin tüm haklar Sirket tarafindan sakli tutulmaktadir. Bu dogrultuda, Sirket, 6769 sayili Sinai Mülkiyet Kanunu’na (SMK) göre ortaya çikabilecek marka, patent, faydali model, ticari sir, süreç, üretim yöntemi, fikir, bulus, taslak, tasarim, model arastirma sonucu, test sonucu, üretim bilgisi, performans bilgisi, plan, metot, spesifikasyon gibi SMK kapsamindaki tüm sinai ürünler (kisaca “Sinai Ürünler”) ve 5846 sayili Fikir ve Sanat Eserleri Kanunu’na göre ortaya çikabilecek telif hukukunun kapsamina giren tüm fikri ürünler (kisaca “Fikri Ürünler”) ile bunlarin mali haklarinin ve diger yan haklarinin tamami üzerinde münhasir ve süresiz olarak tek hak sahibidir. Kullanim lisans hakki disinda kalan hukuka aykiri kopya eylemi ve islemler eylemin nevine göre cezai yaptirima sahiptir."
        },
        {
          Id: 619011,
          PropertyName: "value",
          Value:
            "15.2. Kurum, Sözleşmeye konu hizmetin uygulaması ile ilgili Şirket’e ait olan Yazılım ve programlar üzerinde hiçbir şekilde değişiklik yapamaz, bunları kopyalayamaz, başkalarına kullandıramaz."
        },
        {
          Id: 55050,
          PropertyName: "value",
          Value:
            "15.3. Kurum tarafından talep edilen özel bir çözüm olsa dahi fikri mülkiyet hukuku anlamında Kuruma yazılımın kendisine ilişkin herhangi bir mülkiyet hakkı vermeyecektir. Yazılıma ilişkin kullanım lisansı ve satış lisansı işbu sözleşme hükümleri kapsamında Kuruma sunulmaktadır."
        },
        {
          Id: 634403,
          PropertyName: "value",
          Value:
            "15.4. Şirket’in işbu sözleşme kapsamında verdiği hizmetle ilgili uyarlama, dokümantasyon, ürün, yazılım, kaynak kodu ve sair fikir eseri üzerindeki Fikir ve Sanat Eserleri Kanunu ve diğer kanunlardan doğmuş ve doğacak tüm haklar, fikri, mali ve sınai mülkiyet hakları, her türlü faydalanma hakları, mali ve işletme hakları ve ticari sır niteliğindeki değerler Şirket’e aittir. Bunun yanında Şirket; yazılım, proje geliştirme gibi hizmetler sırasında ürettiği, üreteceği veya geliştireceği her türlü program, ilave programlama, özelleştirme, uyarlama, dokümantasyon, ürün, yazılım, kaynak kodu ve sair fikir eseri üzerindeki Fikir ve Sanat Eserleri Kanunu ve diğer kanunlardan doğmuş ve doğacak tüm haklar, fikri, mali ve sınai mülkiyet hakları, her türlü faydalanma hakları, mali ve işletme hakları ve kendi işletmesine ait ticari sır niteliğindeki değerlerin münhasıran sahibi olacaktır. Merkez, sözleşmeye konu hizmeti sadece Sözleşme süresi boyunca lisans hakkı kapsamında kullanabilecek olup, yazılıma ilişkin başkaca bir hak iddia edemeyecektir."
        },
        {
          Id: 800838,
          PropertyName: "value",
          Value:
            "15.5. Şirket, herhangi bir süre, yer, sayı ve muhteva kısıtlaması olmaksızın, hizmete konu yazılım ve materyalleri dilediği şekilde kullanma, üçüncü kişilere kullandırma, yararlanma, bu materyaller üzerinde ek geliştirmeler yapma ve yaptırma haklarına sahip olacaktır. Merkez, kendisine verilen hizmetlerle entegrasyon için oluşturulmuş veya uzaktan erişimi sağlanmış yazılımların veya yazılım parçalarının tamamının veya herhangi bir kısmının kaynak kodunun, türetilmesi amacıyla yapılacak çalışmaları engellemekle ve engelleyecek ortamı ve şartları ve işbu sözleşme kapsamı haricinde kullanılmamasını, kopyalanmamasını sağlamakla ve ayrıca kaynak kodun gizliliğini sağlayacak ortamı oluşturmakla yükümlüdür."
        },
        {
          Id: 461104,
          PropertyName: "value",
          Value:
            "15.6. İşbu sözleşme kapsamındaki ürünlerin, geliştirmelerin ve/veya materyallerin Merkez tarafından kullanılmasının bir patent hakkını, telif hakkını, ticari markayı, ticari sırrı veya diğer fikri ve sınai haklarını ihlal ettiğinin bir üçüncü tarafça iddia edilmesi halinde, Merkez bu durumu derhal Şirket’e bildirecektir. Şirket, anılan iddiaya karşı tüm masrafları kendisine ait olmak üzere Merkezi savunacaktır."
        },
        { Id: 753205, PropertyName: "value", Value: "MADDE 16: SÜRÜM/VERSIYON DEĞİŞİMİ VE BAKIM" },
        {
          Id: 961692,
          PropertyName: "value",
          Value:
            '16.1. Şirket önceden haber vermeksizin sistemde ve/veya yazılı dökümanlarında değişiklikler yapma, sistem ürünlerinin yeni versiyon ve/veya sürümlerini çıkarma hakkına sahiptir, Kurum bu yeni sistemi ve/veya yeni yazılı dokümanları ancak "güncelleme” bedelini ödeyerek güncel sistemi kullanabilir. Şirket isterse güncellemeleri belli sürelerle ücretsiz şekilde  yapabilir.'
        },
        { Id: 329861, PropertyName: "value", Value: "MADDE 17: DİĞER HÜKÜMLER" },
        {
          Id: 204198,
          PropertyName: "value",
          Value:
            "İş bu sözleşmenin imzalanmasından ve ifasından doğacak her türlü vergi, resim, harç ile giderlerden Kurum sorumludur."
        },
        {
          Id: 455110,
          PropertyName: "value",
          Value:
            "Taraflar arasındaki tüm tebligatlar, Tarafların madde 1.1. ve 2. maddede belirtilen adreslerine yapılır. Bu adreslere yapılan tebligatlar, taraflara yapılmış sayılır. Taraflar adres değişikliklerini, değişiklik tarihinden itibaren 7 gün içinde karşı tarafa bildirmek zorundadır. Adres değişiklikleri zamanında bildirilmediği takdirde madde 1.1. ve 2. maddede gösterilen adreslere yapılan tebligatlar geçerli olarak tüm hüküm ve sonuçlarını doğuracaktır."
        },
        {
          Id: 591983,
          PropertyName: "value",
          Value:
            "Kurum, MeMap internet sitesinde Sözleşme konusu ürünün temel nitelikleri, tüm vergiler dahil satış fiyatı ve ödeme şekli masraflarının kendisi tarafından karşılanacağına ve Şirketin tam ticari unvanı, açık adresi ve iletişim bilgilerine ilişkin ön bilgileri okuyup doğru ve eksiksiz bilgi sahibi olduğunu beyan eder."
        },
        { Id: 439519, PropertyName: "value", Value: "17.1. FERAGAT OLMAMASI" },
        {
          Id: 198871,
          PropertyName: "value",
          Value:
            "İşbu sözleşme ile tanınan haklardan feragat, ilgili Tarafça yazılı olarak yapılmadığı ve imzalanmadığı sürece bağlayıcı olmayacaktır. Herhangi bir Tarafın işbu Sözleşmedeki herhangi bir hakkini, yetkisini veya imtiyazını kullanmaması veya kullanmakta gecikmesi, söz konusu haktan, yetkiden veya imtiyazdan feragat olarak kabul edilmeyecektir veya tek başına veya kısmen kullanım, söz konusu hakkin, yetkisini veya imtiyazın başkaca kullanımını veya gelecekte kullanımını veya işbu Sözleşmedeki herhangi bir diğer hakkin, yetkinin veya imtiyazın kullanılmasını engellemeyecektir."
        },
        { Id: 559256, PropertyName: "value", Value: "17.2. BÖLÜNEBILIRLIK" },
        {
          Id: 720656,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin, yetkili bir mahkeme tarafindan yasadışı, geçersiz veya icra edilemez olduğuna karar verilen bir hükmü veya şartı, işbu Sözleşmenin diger hüküm ve şartlarının geçerliliğini etkilemeyecektir. Taraflar söz konusu yasadışı, geçersiz veya icra edilemez hüküm veya şartı, niyetlerini mümkün olan en yakın şekilde yansıtan bir hüküm ile değiştirmeyi kabul ederler."
        },
        { Id: 871832, PropertyName: "value", Value: "17.3. DEĞİŞİKLİKLER" },
        {
          Id: 777464,
          PropertyName: "value",
          Value:
            "İşbu Sözleşmenin hükümlerinden herhangi birinde yapılacak değişiklik, tadilat veya ekleme, Taraflarca yazılı olarak yapılmadığı ve imzalanmadığı sürece bağlayıcı olmayacaktır."
        },
        { Id: 663719, PropertyName: "value", Value: "17.4 DEVİR ve TEMLİK" },
        {
          Id: 76395,
          PropertyName: "value",
          Value:
            "Taraflar işbu Sözleşmeden doğan hak ve yükümlülüklerini diğer tarafın açık ve yazılı izni olmaksızın gerçek ve/veya tüzel üçüncü kişilere/kurumlara devredemez. Aksi durumda yapılan devir veya temlik taraflar açısından geçerli kabul edilmeyecektir."
        },
        { Id: 368100, PropertyName: "value", Value: "17.5 DELİL SÖZLEŞMESİ" },
        {
          Id: 388061,
          PropertyName: "value",
          Value:
            "Taraflar, iş bu sözleşmenin yürütülmesi, yorumu veya doğacak her türlü ihtilaflarda her iki tarafın defter, evrak, eposta yazışmaları ve bilgisayar kayıtlarının HMK 193. maddesi gereğince münhasır delil teşkil edeceğini kabul ve taahhüt eder."
        },
        { Id: 354693, PropertyName: "value", Value: "17.6 MUCBİR SEBEP" },
        {
          Id: 229017,
          PropertyName: "value",
          Value:
            "İşbu sözleşme, 6098 sayılı Türk Borçlar Kanunu’nda belirtilen ve tarafların kendi iradeleri dışındaki veya kendilerine isnat olunamayacak sebeplerle (deprem, sel, olağanüstü hal, terör, savaş, Dünya Sağlık Örgütü tarafında küresel ölçekte salgın hastalık-pandemi ilan edilmesi ve bu ilana bağlı olarak devlet makamları tarafından ülkesel salgın hastalık hali veya devletin resmi kurumlarınca ya da İstanbul Ticaret Odası tarafından ekonomik kriz olarak kabul edilen ekonomik dalgalanmalar, grev, lokavt, vb.) borçların ifası imkânsız hale gelirse ve mücbir sebep durumu üç (3) ayı asarsa tarafların, o güne kadarki yükümlülüklerini karşılıklı olarak yerine getirmesi şartıyla, Sözleşmeyi tazminatsız olarak feshetme hakki doğar."
        },
        { Id: 828025, PropertyName: "value", Value: "17.7. TEBLIĞ ADRESİ" },
        {
          Id: 190762,
          PropertyName: "value",
          Value:
            "Taraflar, işbu sözleşmeden doğan uyuşmazlıklarda ve diğer hususlarda İşbu Sözleşme’nin 1. Maddesinde belirtilen adreslerine yapılacak tebligatların kanuni ikametgâhlarına yapılmış sayılacağını, adres değişikliklerini değişiklikten itibaren 2 (iki) gün içinde diğer tarafa yazılı olarak bildirmedikleri takdirde bu hususun geçerli olmaya devam edeceğini kabul ve beyan ederler. İşbu sözleşme çerçevesinde temerrüde ilişkin ihbarlar, fesih ihbarları ve bu konulara cevaben yapılacak ihbar ve ihtarlar noter kanalı ile yapılmadıkça bağlayıcı olmaz."
        },
        { Id: 655603, PropertyName: "value", Value: "MADDE 18: UYGULANACAK HUKUK VE YETKİLİ MAHKEME" },
        {
          Id: 11242,
          PropertyName: "value",
          Value:
            "18.1. İşbu sözleşmenin kuruluşu ya da yorumlanması, Tarafların ya da Taraflarca izin verilmiş kanuni ya da diğer her türlü haleflerin işbu sözleşmede yer alan hak ve yükümlülüklerinden doğacak her türlü ihtilafların tümü öncelikle Taraflar arasında otuz (30) gün içinde iyi niyet çerçevesinde, sulh yoluyla çalışılacaktır. İhtilafların yapılan görüşmeler neticesinde sulh yoluyla çözülememesi, Tarafların hukuki yollara başvurarak hak arayışlarına halel getirmeyecektir."
        },
        {
          Id: 954857,
          PropertyName: "value",
          Value:
            "18.2. İşbu sözleşmeden kaynaklanan veya işbu Sözleşmeye ilişkin sözleşme dışı yükümlülükler, Türkiye Cumhuriyeti kanunlarına tabi olacaktır. Her bir Taraf, işbu Sözleşmenin mevcudiyeti, geçerliliği, yorumlanması, ihlali veya feshi ile ilgili olan herhangi bir konu dâhil, ancak bunlarla sınırlı olmaksızın, işbu Sözleşmeden kaynaklanan veya işbu Sözleşmeye ilişkin herhangi bir ihtilaf, çelişki, iddia veya işlem ile ilgili olarak İstanbul (Çağlayan) Mahkemelerinin ve İcra Müdürlüklerinin münhasır yargı yetkisini gayrikabili rücu ve kayıtsız şartsız kabul eder."
        },
        { Id: 885076, PropertyName: "value", Value: "MADDE 19: YÜRÜRLÜK MADDESI" },
        {
          Id: 246369,
          PropertyName: "value",
          Value:
            "İşbu sözleşme, 19 (on dokuz) ana madde ve alt maddeler ile birlikte toplam 14 (on dört) sayfa olmak üzere 2 (iki) nüsha olarak hazırlanmış olup, birer nüshası taraflarca okunarak muhafazaya alınmış ve tarafların ortak mutabakatı ile .. /.. /…. tarihinde imza altına alınarak yürürlüğe girmiştir."
        },
        { Id: 850089, PropertyName: "value", Value: "Okudum, anladim." },
        { Id: 217376, PropertyName: "label", Value: "KAYDET" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      UserSettingsByUserId: [],
      AnyProfileOfEmail: [],
      SaveRecord: false,
      ChangeEnabledOf: "",
      GoBack: "",
      isComp217376Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesiyeni", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.EgitmenSozlesmesiYeniPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesiyeni", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("egitmensozlesmesiyeni", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.EgitmenSozlesmesiYeniPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      egitmensozlesmesiyeni_295296_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  EgitmenSozlesmesiYeniPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      email_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenSozlesmesiYeni/EgitmenSozlesmesiYeniPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.UserSettingsByUserId = result?.data.userSettingsByUserId;
    stateVars.AnyProfileOfEmail = result?.data.anyProfileOfEmail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiYeniPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiYeniPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].userId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].userId
            : null
        ),
        "Guid"
      ),
      LastChosenProfileId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.AnyProfileOfEmail?.length > 0
            ? stateVars.AnyProfileOfEmail[0].id
            : this.state.AnyProfileOfEmail?.length > 0
            ? this.state.AnyProfileOfEmail[0].id
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UserSettingsByUserId?.length > 0
            ? stateVars.UserSettingsByUserId[0].id
            : this.state.UserSettingsByUserId?.length > 0
            ? this.state.UserSettingsByUserId[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "EgitmenSozlesmesiYeni/EgitmenSozlesmesiYeniPageInit1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenSozlesmesiYeniPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.EgitmenSozlesmesiYeniPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiYeniPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  EgitmenSozlesmesiYeniComponent_295296_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiYeniComponent_295296_onChange1_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  EgitmenSozlesmesiYeniComponent_295296_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "egitmensozlesmesiyeni_295296_value", "value", "", "", "")
        ),
        true
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp217376Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  EgitmenSozlesmesiYeniComponent_217376_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KvkkOkudum_0: ReactSystemFunctions.convertToTypeByName(1, "number"),
      Id_0_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastChosenProfileId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastChosenProfileId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "EgitmenSozlesmesiYeni/EgitmenSozlesmesiYeniComponent_217376_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.EgitmenSozlesmesiYeniComponent_217376_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  EgitmenSozlesmesiYeniComponent_217376_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
