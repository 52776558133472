import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRandevuSummary_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRandevuSummary_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  RandevuById: any[];
  RandevuById_dummy: any[];
  EgitmenById: any[];
  EgitmenById_dummy: any[];
  MerkezById: any[];
  MerkezById_dummy: any[];
  HizmetById: any[];
  HizmetById_dummy: any[];
  KisiById: any[];
  KisiById_dummy: any[];
  RandevuIptalById: number;
  RandevuIptalById_dummy: number;
  SendMail: boolean;
  SendMail_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp767125Visible: "visible" | "hidden";
  isComp14352Visible: "visible" | "hidden";
}

export class RandevuSummary_ScreenBase extends React.PureComponent<IRandevuSummary_ScreenProps, any> {
  ml = [
    {
      Id: "423ed54a-d6f3-fccb-7d3e-72c7b24c8b7a",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "74503767-9450-4cae-9db5-729d038bcb36", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "944579d3-9537-4d2f-bc95-7849f2774bf9",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 796540, PropertyName: "value", Value: "Randevu" },
        { Id: 527942, PropertyName: "value", Value: "Danışan" },
        { Id: 561211, PropertyName: "value", Value: "Hizmet" },
        { Id: 582819, PropertyName: "value", Value: "Süre" },
        { Id: 489099, PropertyName: "value", Value: "dk" },
        { Id: 941093, PropertyName: "value", Value: "Eğitmen" },
        { Id: 404525, PropertyName: "value", Value: "Yaş" },
        { Id: 110606, PropertyName: "value", Value: "Merkez" },
        { Id: 6462, PropertyName: "value", Value: "Zaman" },
        { Id: 937031, PropertyName: "value", Value: "Tarih" },
        { Id: 660697, PropertyName: "value", Value: "." },
        { Id: 9243, PropertyName: "value", Value: "." },
        { Id: 559483, PropertyName: "value", Value: "Saat" },
        { Id: 243979, PropertyName: "label", Value: "İptal Et" },
        { Id: 14352, PropertyName: "label", Value: "Checkup Girişi" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      RandevuById: [],
      EgitmenById: [],
      MerkezById: [],
      HizmetById: [],
      KisiById: [],
      RandevuIptalById: 0,
      SendMail: false,
      NAVIGATE: "",
      isComp767125Visible: "hidden",
      isComp14352Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("randevusummary", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.RandevuSummaryPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("randevusummary", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("randevusummary", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.RandevuSummaryPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      randevusummary_905830_value: this.state.HizmetById?.at?.(0)?.image ?? undefined,
      randevusummary_237710_value: this.state.EgitmenById?.at?.(0)?.resim ?? undefined,
      randevusummary_52143_value: this.state.MerkezById?.at?.(0)?.logo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  RandevuSummaryPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      randevuId_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "RandevuSummary/RandevuSummaryPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.isComp14352Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Instructor"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.RandevuById = result?.data.randevuById;
    formVars.randevusummary_51787_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].danisan
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].danisan
        : null
    );
    formVars.randevusummary_949690_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuGun
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuGun
        : null
    );
    formVars.randevusummary_12902_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuAy
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuAy
        : null
    );
    formVars.randevusummary_10011_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuYil
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuYil
        : null
    );
    formVars.randevusummary_643834_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].saat
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].saat
        : null
    );
    stateVars.isComp767125Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].davetiyeId
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].davetiyeId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.EgitmenById = result?.data.egitmenById;
    formVars.randevusummary_237710_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].resim
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].resim
        : null
    );
    formVars.randevusummary_237710_placeholderimage = "";
    formVars.randevusummary_429398_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].fullname
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].fullname
        : null
    );
    formVars.randevusummary_442994_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].unvan
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].unvan
        : null
    );
    formVars.randevusummary_356614_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].yas
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].yas
        : null
    );
    stateVars.MerkezById = result?.data.merkezById;
    formVars.randevusummary_52143_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );
    formVars.randevusummary_52143_placeholderimage = "";
    formVars.randevusummary_964169_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );
    formVars.randevusummary_635037_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );
    stateVars.HizmetById = result?.data.hizmetById;
    formVars.randevusummary_905830_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].image
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].image
        : null
    );
    formVars.randevusummary_174736_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].title
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].title
        : null
    );
    formVars.randevusummary_348950_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].sure
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].sure
        : null
    );
    stateVars.KisiById = result?.data.kisiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSummaryPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSummaryPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.randevusummary_51787_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].danisan
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].danisan
        : null
    );

    stateVars.isComp767125Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].davetiyeId
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].davetiyeId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.randevusummary_905830_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].image
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].image
        : null
    );

    formVars.randevusummary_174736_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].title
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].title
        : null
    );

    formVars.randevusummary_348950_value = ReactSystemFunctions.toString(
      this,
      stateVars.HizmetById?.length > 0
        ? stateVars.HizmetById[0].sure
        : this.state.HizmetById?.length > 0
        ? this.state.HizmetById[0].sure
        : null
    );

    formVars.randevusummary_237710_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].resim
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].resim
        : null
    );

    formVars.randevusummary_429398_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].fullname
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].fullname
        : null
    );

    formVars.randevusummary_442994_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].unvan
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].unvan
        : null
    );

    formVars.randevusummary_356614_value = ReactSystemFunctions.toString(
      this,
      stateVars.EgitmenById?.length > 0
        ? stateVars.EgitmenById[0].yas
        : this.state.EgitmenById?.length > 0
        ? this.state.EgitmenById[0].yas
        : null
    );

    formVars.randevusummary_52143_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].logo
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].logo
        : null
    );

    formVars.randevusummary_964169_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].unvani
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].unvani
        : null
    );

    formVars.randevusummary_635037_value = ReactSystemFunctions.toString(
      this,
      stateVars.MerkezById?.length > 0
        ? stateVars.MerkezById[0].acikAdres
        : this.state.MerkezById?.length > 0
        ? this.state.MerkezById[0].acikAdres
        : null
    );

    formVars.randevusummary_949690_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuGun
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuGun
        : null
    );

    formVars.randevusummary_12902_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuAy
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuAy
        : null
    );

    formVars.randevusummary_10011_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].randevuYil
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].randevuYil
        : null
    );

    formVars.randevusummary_643834_value = ReactSystemFunctions.toString(
      this,
      stateVars.RandevuById?.length > 0
        ? stateVars.RandevuById[0].saat
        : this.state.RandevuById?.length > 0
        ? this.state.RandevuById[0].saat
        : null
    );

    stateVars.isComp14352Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolename
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolename
            : null
        ),
        "Rol_Instructor"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  RandevuSummaryComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuSummaryComponent_243979_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      randevuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "4bef3287_f9f1_e43b_aaf5_93497f37e32f_confirmation",
        this.defaultML,
        "İptal Etmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RandevuSummary/RandevuSummaryComponent_243979_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.RandevuIptalById = result?.data.randevuIptalById;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSummaryComponent_243979_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSummaryComponent_243979_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("838a98f0-5d2e-49fa-8f92-b9af7f26d2cc", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].eposta
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].eposta
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("MeMap randevunuz iptal oldu", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      hizmetAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.HizmetById?.length > 0
            ? stateVars.HizmetById[0].title
            : this.state.HizmetById?.length > 0
            ? this.state.HizmetById[0].title
            : null
        ),
        "string"
      ),
      merkezAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MerkezById?.length > 0
            ? stateVars.MerkezById[0].unvani
            : this.state.MerkezById?.length > 0
            ? this.state.MerkezById[0].unvani
            : null
        ),
        "string"
      ),
      egitmenAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EgitmenById?.length > 0
            ? stateVars.EgitmenById[0].fullname
            : this.state.EgitmenById?.length > 0
            ? this.state.EgitmenById[0].fullname
            : null
        ),
        "string"
      ),
      randevuTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].mailTarih
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].mailTarih
            : null
        ),
        "string"
      ),
      randevuSaati_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].randevuSaat
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].randevuSaat
            : null
        ),
        "string"
      ),
      RandevuGunAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[stateVars.RandevuById.length - 1].mailGun
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[this.state.RandevuById.length - 1].mailGun
            : null
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          stateVars.KisiById?.length > 0
            ? stateVars.KisiById[0].eposta
            : this.state.KisiById?.length > 0
            ? this.state.KisiById[0].eposta
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "RandevuSummary/RandevuSummaryComponent_243979_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SendMail = result?.data.sendMail;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSummaryComponent_243979_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.RandevuSummaryComponent_243979_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSummaryComponent_243979_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      templateId_0: ReactSystemFunctions.convertToTypeByName("834752b9-500e-46bd-8f20-1d066e5c5bd3", "string"),
      receivers_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EgitmenById?.length > 0
            ? stateVars.EgitmenById[0].eposta
            : this.state.EgitmenById?.length > 0
            ? this.state.EgitmenById[0].eposta
            : null
        ),
        "string"
      ),
      subject_0: ReactSystemFunctions.convertToTypeByName("Randevunuz iptal oldu", "string"),
      sender_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      uyeAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].danisan
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].danisan
            : null
        ),
        "string"
      ),
      hizmetAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.HizmetById?.length > 0
            ? stateVars.HizmetById[0].title
            : this.state.HizmetById?.length > 0
            ? this.state.HizmetById[0].title
            : null
        ),
        "string"
      ),
      merkezAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MerkezById?.length > 0
            ? stateVars.MerkezById[0].unvani
            : this.state.MerkezById?.length > 0
            ? this.state.MerkezById[0].unvani
            : null
        ),
        "string"
      ),
      randevuTarihi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].mailTarih
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].mailTarih
            : null
        ),
        "string"
      ),
      randevuSaati_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].randevuSaat
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].randevuSaat
            : null
        ),
        "string"
      ),
      RandevuGunAdi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].mailGun
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].mailGun
            : null
        ),
        "string"
      ),
      templateId_1: ReactSystemFunctions.convertToTypeByName("c67a725b-58af-4855-b26a-a5729254d23a", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MerkezById?.length > 0
            ? stateVars.MerkezById[0].kurumEposta
            : this.state.MerkezById?.length > 0
            ? this.state.MerkezById[0].kurumEposta
            : null
        ),
        "string"
      ),
      subject_1: ReactSystemFunctions.convertToTypeByName("Bir randevu iptal oldu", "string"),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      uyeAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].danisan
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].danisan
            : null
        ),
        "string"
      ),
      hizmetAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.HizmetById?.length > 0
            ? stateVars.HizmetById[0].title
            : this.state.HizmetById?.length > 0
            ? this.state.HizmetById[0].title
            : null
        ),
        "string"
      ),
      egitmenAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.EgitmenById?.length > 0
            ? stateVars.EgitmenById[0].fullname
            : this.state.EgitmenById?.length > 0
            ? this.state.EgitmenById[0].fullname
            : null
        ),
        "string"
      ),
      randevuTarihi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].mailTarih
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].mailTarih
            : null
        ),
        "string"
      ),
      randevuSaati_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].randevuSaat
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].randevuSaat
            : null
        ),
        "string"
      ),
      RandevuGunAdi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.RandevuById?.length > 0
            ? stateVars.RandevuById[0].mailGun
            : this.state.RandevuById?.length > 0
            ? this.state.RandevuById[0].mailGun
            : null
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "RandevuSummary/RandevuSummaryComponent_243979_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMail = result?.data.sendMail;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.RandevuSummaryComponent_243979_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  RandevuSummaryComponent_243979_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  RandevuSummaryComponent_14352_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "KisiId",
      ReactSystemFunctions.value(
        this,
        stateVars.RandevuById?.length > 0
          ? stateVars.RandevuById[0].danisanKisiId
          : this.state.RandevuById?.length > 0
          ? this.state.RandevuById[0].danisanKisiId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("CheckupF", "CheckupId", Guid.create().toString());
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "ProtokolId",
      ReactSystemFunctions.value(
        this,
        stateVars.RandevuById?.length > 0
          ? stateVars.RandevuById[0].protokolId
          : this.state.RandevuById?.length > 0
          ? this.state.RandevuById[0].protokolId
          : null
      )
    );
    KuikaAppManager.addToPageInputVariables("CheckupF", "ShowDownload", false);
    KuikaAppManager.addToPageInputVariables(
      "CheckupF",
      "RandevuId",
      this.props.screenInputs.RandevuId ?? this.props.screenInputs.randevuid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.RandevuSummaryComponent_14352_onClick1_,
      "RandevuSummary",
      "CheckupF",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "449466",
      null,
      "right",
      null,
      "1280px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  RandevuSummaryComponent_14352_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [449466] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.RandevuSummaryPageInit();
    }
    if (diId == 449466) {
      isErrorOccurred = await this.RandevuSummaryComponent_14352_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
